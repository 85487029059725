import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { ContractRequest } from '../../models/request/contract-request.model';

@Injectable({
  providedIn: 'root',
})
export class EnergyContractsService extends BaseService {
  constructor() {
    super();
  }


  async getEnergyContractById(id: number) {
    return await this.get(`/contracts/${id}/contract`).then((res) => {
      return res.data;
    });
  }

  async getAllEnergyContracts() {
    const userData = this.getUserData();

    if (!userData || userData.org.ugridIds.length === 0) return [];

    const ugridId = userData.org.ugridIds[0]; // TODO: Get the first uGrid while the selected one isn't implemented

    return await this.get(`/contracts/ugrid/${ugridId}`).then((res) => {
      return res.data;
    });
  }

  async getAllKeywords() {
    return await this.get(`/contracts/keywords`).then((res) => {
      return res.data;
    });
  }

  async getAllContractRules() {
    const userData = this.getUserData();

    if (!userData || userData.org.ugridIds.length === 0) return [];
    
    return await this.get(`/contracts/rules`).then((res) => {
      return res.data;
    });
  }

  async getContractRuleById(contractRuleId: number) {
    return await this.get(`/contracts/rule/${contractRuleId}`).then((res) => {
      return res.data;
    });
  }

  async createContract(contract: ContractRequest) {
    const userData = this.getUserData();

    if (!userData || userData.org.ugridIds.length === 0) return;

    const ugridId = userData.org.ugridIds[0]; // TODO: Get the first uGrid while the selected one isn't implemented
    contract.ugridId = ugridId;

    return this.post('/contracts', contract)
      .then((res) => {
        return res;
      })
      .catch((res) => {
        return res;
      });
  }

  async updateContract(contract: ContractRequest) {
    const userData = this.getUserData();

    if (!userData || userData.org.ugridIds.length === 0) return;

    const ugridId = userData.org.ugridIds[0]; // TODO: Get the first uGrid while the selected one isn't implemented
    contract.ugridId = ugridId;

    return this.put(`/contracts/${contract.contractId}`, contract)
      .then((res) => {
        return res;
      })
      .catch((res) => {
        return res;
      });
  }

  async deleteContract(id: number) {
    return await this.delete(`/contracts/${id}`).then((resp) => {
      return resp.status;
    });
  }
}
