<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.4.1/dist/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous">

<div class="dialog" id="dialog">
    <div class="header" *ngIf="indicatorStep == 0">
        <h2>{{ "analysisTimeSeries.indicator.register.title" | translate }}</h2>
        <p>{{ "analysisTimeSeries.indicator.register.description" | translate }}</p>
    </div>
    <div class="header" *ngIf="indicatorStep == 1">
        <h2>{{ "analysisTimeSeries.indicator.register.titleEdit" | translate }}</h2>
        <p>{{ "analysisTimeSeries.indicator.register.descriptionEdit" | translate }}</p>
    </div>

    <div class="forms">
        <form #indicatorForm="ngForm">
            <div class="form-group forms-form padding">
                <span for="timeSerie" class="label-font">{{ "analysisTimeSeries.indicator.register.timeSerie" | translate }}</span>
                <input 
                    [ngModel]="timeSeries.name"
                    #name="ngModel"
                    class="input-field large-input form-control" 
                    name="timeSerie"
                    disabled
                    required
                    >
            </div>

            <div class="row" id="row">
                <div class="form-group forms-form" style="margin-right: 24px;">
                    <label class="label-font" for="indicatorType">{{ "analysisTimeSeries.indicator.register.indicatorType" | translate }}</label>
                    <mat-form-field class="card-module-select" appearance="outline">
                        <mat-select [(ngModel)]="timeSeries.indicatorType" name="indicatorType" placeholder="{{select}}" required>
                            <mat-select-trigger> {{selectedIndicator}} </mat-select-trigger>
                            <mat-option *ngFor="let indicator of indicator" [value]="indicator.type" class="options" (click)="indicatorSelected(indicator.title, indicator.type)">
                                <p class="title">{{ indicator.title }}</p>
                                <span class="description">{{ indicator.desc }}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="form-group forms-form">
                    <mat-panel-description>
                        <label class="label-font" for="indicatorValue">{{ value | translate }}</label>
                        <img 
                            src="assets/icons/circle-info.svg" 
                            alt="Icon Info"
                            matTooltip="{{valueTip}}"
                            matTooltipPosition="above"
                            matTooltipClass="indicator-tooltip"
                            >     
                    </mat-panel-description>
                    <div class="input-group input-div">
                        <input 
                            [(ngModel)]="timeSeries.indicatorValue"
                            #indicatorValue="ngModel"
                            name="indicatorValue" 
                            type="number" 
                            class="input-field small-input" 
                            placeholder="{{value}}" 
                            required
                            [class.invalid]="indicatorValue.invalid && (indicatorValue.dirty || indicatorValue.touched)"
                            (keyup)="checkingValue(timeSeries.indicatorValue)"
                            >
                        <div class="input-group-append">
                            <span class="unit input-group-text"> {{timeSeries.unit}} </span>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div class="tip" *ngIf="indicatorStep == 0">
        <span>{{ "analysisTimeSeries.indicator.register.tip" | translate }}</span>
        {{ "analysisTimeSeries.indicator.register.tipDetail" | translate }}
    </div>
    <div class="tip" *ngIf="indicatorStep == 1">
        <span>{{ "analysisTimeSeries.indicator.register.tip" | translate }}</span>
        {{ "analysisTimeSeries.indicator.register.tipDetailEdit" | translate }}
    </div>

    <p class="line"></p>
    <div class="actions" *ngIf="afterSave == false">
        <button (click)="onDismiss()" class="cancel">{{  textBtn1 | translate }}</button>
        <button 
            *ngIf="indicatorStep == 0"
            (click)="registerIndicator(indicatorForm.form.valid)" 
            class="send"
            [disabled]="!indicatorForm.form.valid"
            >
            {{  textBtn2 | translate }}
        </button>
        <button 
            *ngIf="indicatorStep == 1"
            (click)="editIndicator(indicatorForm.form.valid)" 
            class="send"
            [disabled]="(!indicatorForm.form.valid) || ((!valueChanged) && (!indicatorChanged))"
            >
            {{  textBtn2 | translate }}
        </button>
    </div>
    <div class="actions" *ngIf="afterSave == true">
        <button (click)="onDismiss()" class="cancel">{{  "analysisTimeSeries.indicator.register.cancelToLater" | translate }}</button>
        <button 
            (click)="registerIndicator(indicatorForm.form.valid)" 
            class="send"
            [disabled]="!indicatorForm.form.valid"
            >
            {{  "analysisTimeSeries.indicator.register.saveNow" | translate }}
        </button>
    </div>
</div>
