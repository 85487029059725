<div class="confirmation-modal">
    <app-tag-icon
        [icon]="dialog.icon"
        [type]="dialog.iconType"
        [size]="22">        
    </app-tag-icon>

    <div class="confirmation-modal__info">
        <h1 class="confirmation-modal__info-title">{{ dialog.title | translate}}</h1>
        <p class="confirmation-modal__info-description">{{ dialog.description | translate }}</p>
    </div>

    <div class="confirmation-modal__btns">
        <button class="cancel" (click)="onDismiss()">{{ dialog.btnCancel | translate }}</button>
        <button class="confirm" (click)="onConfirm()">{{ dialog.btnConfirmation | translate }}</button>
    </div>
</div>