import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '../auth.service';
import { LoadingService } from '../loading.service';
import { ForgotPassword } from '../../../../app/core/models/interfaces/forgot-password.model';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  enableSubmitButton: boolean = false;
  navigateTo: string | undefined;
  access: ForgotPassword = { email: '' };
  retrieveForm: FormGroup;
  isError: boolean;
  status: number;
  complement: string = '';

  constructor(
    private translate: TranslateService,
    private matIconRegistry: MatIconRegistry,
    private authService: AuthService,
    private domSanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private loadingService: LoadingService,
  ) {
    this.translate.setDefaultLang('pt');
    this.isError = false;
    this.status = 0;
    this.retrieveForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });

    this.translate
      .get('login.feedbacks.fogoutPassword.complement', {
        link: '<a href="#" class="underline text-color-green">:textAnchor</a>',
      })
      .subscribe((message: string) => (this.complement = message));

    this.translate
      .get('login.feedbacks.fogoutPassword.ancor')
      .subscribe((message: string) => (this.complement = this.complement.replace(':textAnchor', message)));

    this.matIconRegistry.addSvgIcon(
      'error',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/error.svg'),
    );

    this.loadingService.setLoadingStatus(true);
  }

  ngAfterViewInit() {
    this.retrieveForm.reset();
    this.retrieveForm.controls['email'].setValue('');
  }

  ngOnInit(): void {
    this.navigateTo = this.activatedRoute.snapshot.queryParams['to'] || '/';

    this.retrieveForm.valueChanges.subscribe(() => {
      this.access.email = this.retrieveForm?.get('email')?.value ?? '';
    });

    this.loadingService.setLoadingStatus(false);
  }

  async onSubmit() {
    this.loadingService.setLoadingStatus(true);
    this.enableSubmitButton = true;
    this.isError = false;

    await this.authService
      .forgotPassword(this.access.email)
      .then((response) => {
        this.status = response?.status ?? 0;
      })
      .catch((response) => {
        this.status = response?.status ?? 0;
      });

    this.enableSubmitButton = false;
    this.loadingService.setLoadingStatus(false);
  }
}
