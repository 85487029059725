import { Component, AfterViewInit, ViewChild, ElementRef, Input, OnChanges, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'chart.js';
import 'chartjs-adapter-luxon';
import {
  DashboardTimeSeriesRemovedResponse,
  DashboardTimeSeriesResponse,
} from 'src/app/core/models/interfaces/dashboard-time-series-response.model';
import { DashboardGraph } from 'src/app/core/models/interfaces/dashboard.model';
import { DashboardService } from 'src/app/core/services/dashboards/dashboard.service';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent implements AfterViewInit, OnChanges {
  @ViewChild('lineCanvas') lineCanvas!: ElementRef<HTMLCanvasElement>;
  lineChart: any;

  @Input()
  graph!: DashboardGraph;

  status?: number;
  deletedAt?: string;

  @Output()
  missingPrediction?: boolean;

  constructor(
    private translate: TranslateService,
    private dashboardService: DashboardService,
  ) {
  }

  get getDeletedAtMessage() {
    const message = this.translate.instant('shared.components.line_chart.soft_delete.modal_text');
    return message.replace(':deletedAt', this.deletedAt)
  }

  async generateGraphCandidate() {
    const result = await this.dashboardService.getTimeSeries(this.graph!.timesSeries!.id, this.graph!.data);

    if (result == null || result == undefined) {
      this.status = -1;
    } else if (result.status == 410) {
      const obj = result as DashboardTimeSeriesRemovedResponse;
      const date = new Date(obj.deletedAt);

      this.deletedAt = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1)
        .toString()
        .padStart(2, '0')}/${date.getFullYear().toString().slice(-2)} às ${date
        .getHours()
        .toString()
        .padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
      this.status = 0;
    } else if (result.status == 200) {
      const dashboard = result as DashboardTimeSeriesResponse;
      let dataset = [];

      if (dashboard.data == null || (dashboard?.data?.length ?? 0 ) == 0) {
        this.status = -1;
        return;
      }

      this.missingPrediction = (dashboard.forecast == null || (dashboard?.forecast?.length ?? 0) == 0);

      this.status = 1;

      const normalizeDate = (dateStr: string | Date) => {
        const date = typeof dateStr === 'string' ? new Date(dateStr) : dateStr;

        if (isNaN(date.getTime())) {
          return null;
        }

        return new Date(2000, date.getMonth(), date.getDate());
      };

      const normalizedData = (dashboard?.data ?? []).map((item) => ({
        x: normalizeDate(item.x),
        y: item.y,
      }));

      const normalizedPrevious = (dashboard?.previous ?? []).map((item) => ({
        x: normalizeDate(item.x),
        y: item.y,
      }));

      const normalizedForecast = (dashboard?.forecast ?? []).map((item) => ({
        x: normalizeDate(item.x),
        y: item.y,
      }));

      const min = Math.min(
        ...[...normalizedData, ...normalizedPrevious, ...normalizedForecast].map((item) => item.y),
      );
      const max = Math.max(
        ...[...normalizedData, ...normalizedPrevious, ...normalizedForecast].map((item) => item.y),
      );

      const stepSizeY = Math.round((max + min) / 20);

      if (normalizedPrevious) {
        dataset.push({
          label: this.translate.instant('reports.legends.previous'),
          data: normalizedPrevious,
          borderColor: '#E062B4',
          fill: false,
          borderWidth: 1,
          pointRadius: 0,
          tension: 0.4,
        });
      }

      if (normalizedData) {
        dataset.push({
          label: this.translate.instant('reports.legends.data'),
          data: normalizedData,
          borderColor: '#33CC99',
          fill: false,
          borderWidth: 1,
          pointRadius: 0,
          tension: 0.4,
        });
      }

      if (normalizedForecast) {
        dataset.push({
          label: this.translate.instant('reports.legends.forecast'),
          data: normalizedForecast,
          borderColor: '#737373',
          fill: false,
          borderWidth: 1,
          pointRadius: 0,
          tension: 0.4,
        });
      }

      if (
        dashboard?.timeSeriesHeader?.indicatorValue != null &&
        dashboard?.timeSeriesHeader?.indicatorValue != undefined
      ) {
        const normalizedLimit = (dashboard?.previous ?? []).map((item) => ({
          x: normalizeDate(item.x),
          y: dashboard.timeSeriesHeader?.indicatorValue ?? 0,
        }));

        const dts = {
          label: this.translate.instant('reports.legends.goal_and_limit'),
          data: normalizedLimit,
          borderColor: '#48A0F0',
          fill: false,
          borderWidth: 1,
          pointRadius: 0,
          tension: 0.4,
        };

        dataset.push(dts);
      }

      const labels = normalizedData.map(
        (item) => item.x?.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit' }),
      );

      this.lineChart = new Chart(this.lineCanvas.nativeElement, {
        type: 'line',
        data: {
          labels: labels,
          datasets: dataset,
        },
        options: {
          scales: {
            x: {
              type: 'category',
              title: {
                display: true,
                text: this.translate.instant('reports.legends.labels.date'),
              },
            },
            y: {
              type: 'linear',
              display: true,
              title: {
                display: true,
                text: this.translate.instant('reports.legends.labels.value'),
              },
              ticks: {
                autoSkip: false,
                stepSize: stepSizeY,
              },
              min: min,
              max: max,
            },
          },
          responsive: true,
          plugins: {
            title: {
              display: false,
            },
            legend: {
              display: true,
              position: 'bottom',
              labels: {
                usePointStyle: false,
                borderRadius: 5,
                boxWidth: 15,
                boxHeight: 15,
                padding: 10,
                color: 'rgba(0, 0, 0)',
              },
            },
          },
        },
      });
    } else {
      this.status = -1;
    }
  }

  async ngOnChanges() {
  }

  async ngAfterViewInit() {
    await this.generateGraphCandidate();
  }
}
