import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TechnicalSupportModal } from 'src/app/core/models/interfaces/technical-support-modal.model';
import { TechnicalSupportService } from 'src/app/core/services/technical-support.service';

@Component({
  selector: 'app-technical-support-modal',
  templateUrl: './technical-support-modal.component.html',
  styleUrls: ['./technical-support-modal.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatSelectModule,
    MatIconModule,
    TranslateModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TechnicalSupportModalComponent {
  supportForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private technicalSupportService: TechnicalSupportService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<TechnicalSupportModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TechnicalSupportModal
  ) {
    this.supportForm = this.fb.group({
      name: [data.name, [Validators.required]],
      email: [data.email, [Validators.required, Validators.email]],
      location: [data.location, [Validators.required]],
      message: [null, [Validators.required, Validators.minLength(10)]],
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  async onSubmit() {
    if (this.supportForm.valid) {
      const message = this.supportForm.get("message")?.value ?? "";
      const location = this.supportForm.get("location")?.value ?? "";

      await this.technicalSupportService.register(message, location);
      this.dialogRef.close();
    }
  }
}
