<div class="energy-contracts">
    <div class="energy-contracts__header">
        <div class="energy-contracts__header-info" *ngIf="isListContract">
            <h2>{{ "register.energyContracts.title" | translate }}</h2>
            <p>{{ "register.energyContracts.info" | translate }}</p>
        </div>

        <div class="energy-contracts__header-info" *ngIf="isContractRegister">
            <h2>{{ "register.energyContracts.newContractTitle" | translate }}</h2>
            <p>{{ "register.energyContracts.newContractInfo" | translate }}</p>
        </div>

        <button class="energy-contracts__header-btn" (click)="goToRegisterContract()" *ngIf="isListContract">
            <i class="fa-light fa-file-invoice-dollar"></i>
            <span>{{ "register.energyContracts.createContract" | translate }}</span>
        </button>
    </div>

    <div class="energy-contracts__container" *ngIf="isListContract && contractsNumber == 0">
        <app-resource-not-found [icon]="icon" [type]="type" [message]="message" [resource]="resource"
            [contractMessage]="contractMessage" [noHeader]="noHeader">
        </app-resource-not-found>
    </div>
    <br>

    <app-new-energy-contracts *ngIf="isContractRegister" (cancelForm)="cancelEditForm($event)" ></app-new-energy-contracts>

    <div class="energy-contracts__container-list" *ngIf="isListContract && contractsNumber > 0">
        <div class="energy-contracts__container-list__header">
            <div class="energy-contracts__container-list__header-title">
                {{ "register.energyContracts.contractList" | translate }}
                <div class="users-number">{{ contractsNumber }} {{ "register.items" | translate }}</div>
            </div>
            <mat-form-field appearance="outline">
                <mat-label>{{ "activity.filter" | translate }}</mat-label>
                <input matInput (keyup)="applyFilter($event)" #input>
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
        </div>

        <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "register.energyContracts.table.name" | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>
            <ng-container matColumnDef="validityPeriod">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "register.energyContracts.table.validityPeriod" | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.startValidityPeriod}} - {{element.endValidityPeriod}} </td>
            </ng-container>
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "register.energyContracts.table.description" | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.description}} </td>
            </ng-container>
            <ng-container matColumnDef="modal">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" class="resource-actions">
                    <div class="actions">
                        <button mat-icon-button [matMenuTriggerFor]="optionsModule" aria-label="Example icon-button with a menu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #optionsModule="matMenu" class="menu">
                            <button mat-menu-item class="action">
                                <i class="fa-regular fa-pen-to-square icon"></i>
                                <span class="texto">{{ "register.energyContracts.table.edit" | translate }}</span>
                            </button>
                            <button mat-menu-item class="action">
                                <i class="fa-regular fa-copy"></i>
                                <span class="texto">{{ "register.energyContracts.table.duplicate" | translate }}</span>
                            </button>
                            <button mat-menu-item class="action">
                                <i class="fa-regular fa-trash"></i>
                                <span class="texto">{{ "register.energyContracts.table.remove" | translate }}</span>
                            </button>
                        </mat-menu>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="expand">
                <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button aria-label="expand row"
                        (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation();
                            selectedRow = selectedRow === element ? null : element"
                            [ngClass]="{ 'selected': element === selectedRow}">
                        <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_right</mat-icon>
                        <mat-icon *ngIf="expandedElement === element">keyboard_arrow_down</mat-icon>
                    </button>
                </td>
            </ng-container>

            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
                    <div class="example-element-detail"
                        [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
            <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;" class="example-element-row"
                [class.example-expanded-row]="expandedElement === element"
                (click)="expandedElement = expandedElement === element ? null : element; 
                    selectedRow = selectedRow === element ? null : element"
                    [ngClass]="{ 'selected': element === selectedRow}">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">{{ "register.energyContracts.filterReturn" | translate }} "{{input.value}}"</td>
            </tr>
        </table>
        <mat-paginator #paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="10"
            aria-label="Items por pagina" showFirstLastButtons class="border">
        </mat-paginator>
    </div>

</div>