import { Component, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BatterySetupEnum } from 'src/app/core/models/enums/formSelectsModuleEnum';
import { NewModuleRequest, StorageFormRequest } from 'src/app/core/models/request/new-module-request.model';

@Component({
  selector: 'app-storage-form',
  templateUrl: './storage-form.component.html',
  styleUrls: ['./storage-form.component.scss'],
})
export class StorageFormComponent {
  basicForm: NewModuleRequest = {} as NewModuleRequest;
  storageForm: StorageFormRequest = {} as StorageFormRequest;
  batteryConfiguration: number = NaN;
  insertModel = this.translate.instant('register.resources.newResource.insertModel');
  insertManufacturer = this.translate.instant('register.resources.newResource.insertManufacturer');
  insertType = this.translate.instant('register.resources.newResource.insertType');
  value = this.translate.instant('register.resources.newResource.value');
  select = this.translate.instant('register.resources.newResource.select');
  specificationsTip = this.translate.instant('register.resources.newResource.toolTip.specificationsTip');
  storageTypeTip = this.translate.instant('register.resources.newResource.toolTip.storageTypeTip');
  lifeCyclesTip = this.translate.instant('register.resources.newResource.toolTip.lifeCyclesTip');
  private storage: Storage;
  editing: boolean = false;

  @Output()
  formStorage: EventEmitter<{ form: NewModuleRequest; valid: boolean }> = new EventEmitter();
  batteryTypes: any[] = [
    {
      type: BatterySetupEnum.series,
      title: this.translate.instant('register.resources.newResource.storage.batterySeries'),
    },
    {
      type: BatterySetupEnum.parallel,
      title: this.translate.instant('register.resources.newResource.storage.batteryParallel'),
    },
    {
      type: BatterySetupEnum.seriesParallel,
      title: this.translate.instant('register.resources.newResource.storage.batterySeriesParallel'),
    },
  ];

  constructor(private translate: TranslateService) {
    this.storage = window.localStorage;
  }

  ngOnInit(): void {
    this.editing = JSON.parse(this.storage.getItem('editing')!);
    if (this.storage.getItem('form')) {
      this.basicForm = JSON.parse(this.storage.getItem('form') || '[]');
      this.storageForm = this.basicForm.storageAttributes!;
      this.storage.removeItem('form');
      this.storage.removeItem('editing');
      this.sendForm(true);
    }
  }

  sendForm(valid: boolean) {
    this.basicForm.storageAttributes = this.storageForm;
    const form: NewModuleRequest = this.basicForm;
    this.formStorage.emit({ form, valid });
  }
}
