import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SelectStatusEnum } from 'src/app/core/models/enums/select-status.enum';
import { SubTypeModuleEnum } from 'src/app/core/models/enums/subtype-module.enum';
import { StatusSelectViewModel } from 'src/app/core/models/interfaces/status-select.model';
import ModuleRequest from 'src/app/core/models/request/module-request.model';
import { ModuleService } from 'src/app/core/services/moduleServices/module.service';
import { ModalNewStatusComponent } from '../modal-new-status/modal-new-status.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CreateScheduleRequest, ScheduleStatus } from 'src/app/core/models/request/schedules-request.model';
import { SchedulingService } from 'src/app/core/services/schedulingServices/scheduling.service';
import { SchedulePolicyStatusEnum } from 'src/app/core/models/enums/schedules-policy-status.enum';
import { CustomSnackbarSuccessComponent } from 'src/app/shared/components/custom-snackbar-success/custom-snackbar-success.component';
import { AppConstants } from 'src/app/shared/constants/app.constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomSnackbarErrorComponent } from 'src/app/shared/components/custom-snackbar-error/custom-snackbar-error.component';
import { SchedulePolicyViewModal } from 'src/app/core/models/interfaces/schedule-policy.model';
import { DaySchedulePolicyEnum } from 'src/app/core/models/enums/day-schedule-policy.enum';
import { ConfirmationModalComponent } from 'src/app/shared/components/confirmation-modal/confirmation-modal.component';
import { icon } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-create-schedule',
  templateUrl: './create-schedule.component.html',
  styleUrls: ['./create-schedule.component.scss']
})

export class CreateScheduleComponent {
  titlePage!: string;
  modules:  ModuleRequest[] = [];
  module: ModuleRequest = {} as ModuleRequest;
  status: StatusSelectViewModel[] = []
  select: string = 'register.users.select';
  enabled: boolean = false;
  hide: boolean = true;
  dialogRef!: MatDialogRef<any>;
  subType!: SubTypeModuleEnum;
  form: CreateScheduleRequest = {} as CreateScheduleRequest;
  schedulesStatus: ScheduleStatus[] = [];
  scheduleFormGroup: FormGroup;
  labelInfo01: string = "controlPanel.schedules.container.info.infoLabel01";
  labelInfo02: string = "controlPanel.schedules.container.info.infoLabel02";
  scheduleSunday: ScheduleStatus[] = [];
  scheduleMonday: ScheduleStatus[] = [];
  scheduleTuesday: ScheduleStatus[] = [];
  scheduleWednesday: ScheduleStatus[] = [];
  scheduleThursday: ScheduleStatus[] = [];
  scheduleFriday: ScheduleStatus[] = [];
  scheduleSaturday: ScheduleStatus[] = [];
  moduleId?: number;
  isNewSchedule: boolean = true;
  sundayOfSchedule: DaySchedulePolicyEnum = DaySchedulePolicyEnum.Sunday;
  mondayOfSchedule: DaySchedulePolicyEnum = DaySchedulePolicyEnum.Monday;
  tuesdayOfSchedule: DaySchedulePolicyEnum = DaySchedulePolicyEnum.Tuesday;
  wednesdayOfSchedule: DaySchedulePolicyEnum = DaySchedulePolicyEnum.Wednesday;
  thrusdayOfSchedule: DaySchedulePolicyEnum = DaySchedulePolicyEnum.Thursday;
  fridayOfSchedule: DaySchedulePolicyEnum = DaySchedulePolicyEnum.Friday;
  saturdayOfSchedule: DaySchedulePolicyEnum = DaySchedulePolicyEnum.Saturday;

  constructor(
    private translate: TranslateService, 
    private moduleService: ModuleService,
    private schedulingService: SchedulingService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
  ) { 
    this.scheduleFormGroup = this.fb.group({
      moduleId: ['', [Validators.required]],
      statusDefault: ['', [Validators.required]]
    })
  }

  async ngOnInit(): Promise<void> {    
    this.activatedRoute.params.subscribe(param => {
      this.moduleId = +param['moduleId'];
    })

    this.moduleId ? this.titlePage = "controlPanel.schedules.editSchedule" : this.titlePage = "controlPanel.schedules.createSchedule";


    this.getAllModules();

    if (this.moduleId) {
      this.isNewSchedule = false;
      await this.getShedulePolicy(this.moduleId);
      await this.getModuleById(this.moduleId);
    }
  }
  
  getAllModules(): void {
    this.moduleService.getAll().then((resp) => {
      this.modules = resp;
    })
  }

  getModuleById(moduleId: number): void {
    this.moduleService.getById(moduleId).then((resp) => {
      this.module = resp;

      if (this.module) {
        this.scheduleFormGroup.controls['moduleId'].setValue(this.module.moduleId);
        
        if (!this.module.defaultScheduleStatus) {
          switch (this.module.subtype) {
            case SubTypeModuleEnum.dispatchable:
              this.scheduleFormGroup.controls['statusDefault'].setValue(SchedulePolicyStatusEnum.standBy);
            break;
            case SubTypeModuleEnum.controllable:
              this.scheduleFormGroup.controls['statusDefault'].setValue(SchedulePolicyStatusEnum.off);
            break;        
            default:
              this.scheduleFormGroup.controls['statusDefault'].setValue(SchedulePolicyStatusEnum.standBy);
              break;
          }
          this.setOptionStatus(this.module.subtype);
        } else {
          this.scheduleFormGroup.controls['statusDefault'].setValue(this.module.defaultScheduleStatus);
          this.setOptionStatus(this.module.subtype);
        }

        this.form.moduleId = this.module.moduleId;
        this.form.statusDefault = this.module.defaultScheduleStatus;
      }
    })
  }

  getShedulePolicy(moduleId: number): void {
    this.schedulingService.getSchedulePolicy(moduleId).then((resp) => {
      this.schedulesStatus = resp;
      this.form.schedulerStatus = resp;
      
    }).then(() => {
      this.schedulesStatus.forEach((x) => {
        this.setCardSchedule(x);
      })
    })
  }

  get isValidForm(): boolean {
    return this.scheduleFormGroup.valid;
  }

  get isValidSubmit(): boolean {
    return this.scheduleFormGroup.valid && this.enabled
  }

  setOptionStatus(subType: SubTypeModuleEnum): void {
    this.subType = subType;
    switch (subType) {
      case SubTypeModuleEnum.dispatchable:
        this.status = [
          {name: 'module.statusLoadOn', statusType: SchedulePolicyStatusEnum.on}, 
          {name: 'module.actionStandBy', statusType: SchedulePolicyStatusEnum.standBy}
        ]
        break;

        case SubTypeModuleEnum.controllable:
        this.status = [
          {name: 'module.statusLoadOn', statusType: SchedulePolicyStatusEnum.on}, 
          {name: 'module.statusLoadOff', statusType: SchedulePolicyStatusEnum.off}
        ]
        break;
    
      default:
        this.status = [
          {name: 'module.statusCharge', statusType: SchedulePolicyStatusEnum.charge}, 
          {name: 'module.statusUncharge', statusType: SchedulePolicyStatusEnum.uncharge},
          {name: 'module.actionStandBy', statusType: SchedulePolicyStatusEnum.standBy},
        ]
        break;
    }
  }

  openDialogNewStatus(): void {
    this.dialogRef = this.dialog.open(ModalNewStatusComponent, {
      disableClose: true,
      data: { subType: this.subType}
    });

    this.dialogRef.componentInstance.confirm.subscribe({
      next: async (result: ScheduleStatus) => {
        this.schedulesStatus.push(result)

        if (result) {
          this.setBtnSaveEnabled()
        }

        this.buildFormRequest();
        this.setCardSchedule(result)
        this.dialogRef.close();
      },
    });
  }

  setCardSchedule(value: ScheduleStatus): void {
    value.days.forEach(x => {
      switch (x) {
        case DaySchedulePolicyEnum.AllDays:
          this.scheduleSunday.push(value)
          this.scheduleMonday.push(value)
          this.scheduleTuesday.push(value)
          this.scheduleWednesday.push(value)
          this.scheduleThursday.push(value)
          this.scheduleFriday.push(value)
          this.scheduleSaturday.push(value)
          this.form.schedulerStatus[0].days = [0, 1, 2, 3, 4, 5, 6]
        break;
        case DaySchedulePolicyEnum.Sunday:
          this.scheduleSunday.push(value)
        break;
        case DaySchedulePolicyEnum.Monday:
          this.scheduleMonday.push(value)
        break;
        case DaySchedulePolicyEnum.Tuesday:
          this.scheduleTuesday.push(value)
        break;
        case DaySchedulePolicyEnum.Wednesday:
          this.scheduleWednesday.push(value)
        break;
        case DaySchedulePolicyEnum.Thursday:
          this.scheduleThursday.push(value)
        break;
        case DaySchedulePolicyEnum.Friday:
          this.scheduleFriday.push(value)
        break;
        case DaySchedulePolicyEnum.Saturday:
          this.scheduleSaturday.push(value)
        break;
      
        default:
          break;
      }
    })
  }

  onSubmit(): void {
    if (this.isNewSchedule) {
      this.createNewSchedule();
    } else {
      this.editSchedule();
    }
  }

  onCancel(path: string): void {
    if (this.schedulesStatus.length > 0 ) {
      this.openDialogCancelEditSchedule(path);
    } else {
      this.goTo(path)
    }
  }

  createNewSchedule(): void {
    this.schedulingService.createNewStatus(this.form).then((resp) => {
      this.openSnackBar(resp);
    }) 
  }

  editSchedule(): void {
    this.buildFormRequest();
    const request = {
      statusDefault: this.form.statusDefault,
      schedulerStatus: this.schedulesStatus
    }
    
    this.schedulingService.editScheduleStatus(this.module.moduleId, request).then((resp) => {
      this.openSnackBar(resp.status);
    })
  }

  buildFormRequest(): void {
    this.form.moduleId = this.scheduleFormGroup.get(['moduleId'])?.value;
    this.form.statusDefault = this.scheduleFormGroup.get(['statusDefault'])?.value;
    this.form.schedulerStatus = this.schedulesStatus;
  }

  openSnackBar(status: number): void {
    switch (status) {
      case AppConstants.CREATE_SUCESS:
        this.snackBar.openFromComponent(CustomSnackbarSuccessComponent, {
          data: {
            message: this.translate.instant('controlPanel.schedules.container.info.createScheduleSuccess'),
            snackBar: this.snackBar,
          },
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: AppConstants.TREE_SECOND_WAIT,
        });
        this.goTo('/control-panel/scheduling');
        break;
      case AppConstants.OK:
        this.snackBar.openFromComponent(CustomSnackbarSuccessComponent, {
          data: {
            message: this.translate.instant('controlPanel.schedules.container.info.createScheduleSuccess'),
            snackBar: this.snackBar,
          },
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: AppConstants.TREE_SECOND_WAIT,
        });
        this.goTo('/control-panel/scheduling');
        break;
      default:
        this.snackBar.openFromComponent(CustomSnackbarErrorComponent, {
          data: {
            message: this.translate.instant('controlPanel.schedules.container.info.createScheduleBadRequest'),
            snackBar: this.snackBar,
          },
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: AppConstants.TREE_SECOND_WAIT,
        });
        break;
    }
  }

  openDialogRemoveSchedule(value: boolean, policyId?: number, day?: number): void {
    this.dialogRef = this.dialog.open(ConfirmationModalComponent, {
      disableClose: true,
      data: { 
        title: 'controlPanel.schedules.container.modalRemoverSchedule.title',
        description: 'controlPanel.schedules.container.modalRemoverSchedule.description',
        btnCancel: 'controlPanel.schedules.container.modalRemoverSchedule.btnCancel',
        btnConfirmation: 'controlPanel.schedules.container.modalRemoverSchedule.btnConfirmation',
        icon: 'warning',
        iconType: 'warning'
      }
    });

    this.dialogRef.componentInstance.confirm.subscribe({
      next: () => {
        this.removeScheduleByPolicyId(policyId, day);
        this.setBtnSaveEnabled();
        this.dialogRef.close()
      }
    })
  }

  openDialogCancelEditSchedule(path: string): void {
    this.dialogRef = this.dialog.open(ConfirmationModalComponent, {
      disableClose: true,
      data: { 
        title: 'controlPanel.schedules.container.modalCancelEditSchedule.title',
        description: 'controlPanel.schedules.container.modalCancelEditSchedule.description',
        btnCancel: 'controlPanel.schedules.container.modalCancelEditSchedule.btnCancel',
        btnConfirmation: 'controlPanel.schedules.container.modalCancelEditSchedule.btnConfirmation',
        icon: 'warning',
        iconType: 'warning'
      }
    });

    this.dialogRef.componentInstance.confirm.subscribe({
      next: () => {
        this.router.navigate([path]);
        this.dialogRef.close();
      }
    })
  }

  clearArrayOfDays(): void {
    this.scheduleSunday = []
    this.scheduleMonday = []
    this.scheduleTuesday = []
    this.scheduleWednesday = []
    this.scheduleThursday = []
    this.scheduleFriday = []
    this.scheduleSaturday = []
  }

  removeScheduleByPolicyId(policyId?: number, day?: number): void {
    this.clearArrayOfDays();
    this.schedulesStatus.forEach((x) => {
      if (x.policyId == policyId) {
        x.days = x.days.filter(d => d != day)
      }
    })
    this.schedulesStatus.forEach((x) => {
      this.setCardSchedule(x);
    })
  }

  setBtnSaveEnabled(): void {
    this.enabled = true;
  }

  goTo(path: string) {
    this.router.navigate([path]);
  }
}
