import { Component, Input, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { SnackBarData } from '../../interfaces/snackbar.model';

@Component({
  selector: 'app-custom-snackbar-success',
  templateUrl: './custom-snackbar-success.component.html',
  styleUrls: ['./custom-snackbar-success.component.scss'],
})
export class CustomSnackbarSuccessComponent {
  @Input() text: string = 'Recurso';

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackBarData) {}

  closeSnackBar(): void {
    this.data.snackBar.dismiss();
  }
}
