import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { TimeSeriesIndicator } from 'src/app/core/models/enums/timeSeriesIndicatorEnum';
import { AuthUserViewModel } from 'src/app/core/models/interfaces/auth-user.model';
import { DialogViewModel } from 'src/app/core/models/interfaces/dialog.model';
import TimeSeriesRequest from 'src/app/core/models/request/time-series-request.model';
import { TimeSeriesService } from 'src/app/core/services/timeSeries/time-series.service';
import { UserServiceService } from 'src/app/core/services/userService/user-service.service';
import { ModalDialogRegisterComponent } from '../../register/components/modal-dialog-register/modal-dialog-register.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppConstants } from 'src/app/shared/constants/app.constants';
import { CustomSnackbarSuccessComponent } from 'src/app/shared/components/custom-snackbar-success/custom-snackbar-success.component';
import { IndicatorComponent } from './indicator/indicator.component';
import { TimeSeriesDataSource } from 'src/app/core/models/enums/timeSeriesDataSourceEnum';
import { EmitTimeSeries } from 'src/app/core/models/interfaces/emit-ts.model';

@Component({
  selector: 'app-time-series',
  templateUrl: './time-series.component.html',
  styleUrls: ['./time-series.component.scss'],
})
export class TimeSeriesComponent {
  @ViewChild(MatPaginator) set paginator(value: MatPaginator) {
    this.translateMatPaginator(value);
    if (this.dataSource) {
      this.dataSource.paginator = value;
    }
  }

  @ViewChild(MatSort) set sort(value: MatSort) {
    if (this.dataSource) {
      this.dataSource.sort = value;
    }
  }

  icon: string = 'error';
  type: string = 'default';
  message: string = 'analysisTimeSeries.page.message';
  resource: string = 'analysisTimeSeries.page.selectRule';
  contractMessage: string = 'analysisTimeSeries.page.contractMessage';
  noHeader: boolean = true;
  timeSeries: TimeSeriesRequest[] = [];
  timeSeriesNumber: number = 0;
  logedUser: AuthUserViewModel | undefined;
  showTable: boolean = true;
  dataUpload: boolean = false;
  dataCrossing: boolean = false;
  constantValue: boolean = false;
  dialogRef!: MatDialogRef<any>;
  toEdit: any = '';

  dataSource = new MatTableDataSource();
  columnsToDisplay = [
    'name',
    'unit',
    'dataSource',
    'indicator',
    'createdBy',
    'firstData',
    'lastData',
    'modal',
  ];

  constructor(
    private translate: TranslateService,
    private timeSeriesService: TimeSeriesService,
    private userServiceService: UserServiceService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.getTimeSeries();
    this.getLoggedUser();
  }

  getLoggedUser(): void {
    this.userServiceService.getUser().then((res) => {
      this.logedUser = res;
    });
  }

  getTimeSeries(): void {
    this.timeSeriesService.getAllTimeSeries().then((resp) => {
      this.timeSeries = resp;
      this.convertData(this.timeSeries);
      this.toEdit = '';
    });
  }

  convertData(timeSeries: TimeSeriesRequest[]): void {
    timeSeries.forEach((element) => {
      if (element.dateStart == null) {
        element.dateStart = '-';
      } else {
        const startDate = new Date(element.dateStart);
        element.dateStart = moment(startDate).format('DD/MM/YYYY');
      }
      if (element.dateEnd == null) {
        element.dateEnd = '-';
      } else {
        const endDate = new Date(element.dateEnd);
        element.dateEnd = moment(endDate).format('DD/MM/YYYY');
      }

      switch (element.dataSource) {
        case TimeSeriesDataSource.API:
          element.dataSource = this.translate.instant('analysisTimeSeries.table.api');
          break;
        case TimeSeriesDataSource.Autopilot:
          element.dataSource = this.translate.instant('analysisTimeSeries.table.autopilot');
          break;
        case TimeSeriesDataSource.ConstValue:
          element.dataSource = this.translate.instant('analysisTimeSeries.table.constantValue');
          break;
        case TimeSeriesDataSource.DataCrossReferencing:
          element.dataSource = this.translate.instant('analysisTimeSeries.table.dataCross');
          break;
        case TimeSeriesDataSource.DataUpload:
          element.dataSource = this.translate.instant('analysisTimeSeries.table.dataUpload');
          break;
        default:
          element.dataSource = this.translate.instant('analysisTimeSeries.table.indicatorNothing');
          break;
      }

      switch (element.indicatorType) {
        case TimeSeriesIndicator.limit:
          element.indicatorType = this.translate.instant('analysisTimeSeries.table.indicatorLimit');
          break;
        case TimeSeriesIndicator.goal:
          element.indicatorType = this.translate.instant('analysisTimeSeries.table.indicatorGoal');
          break;
        default:
          element.indicatorType = this.translate.instant('analysisTimeSeries.table.indicatorNothing');
          break;
      }
    });
    this.dataSource.data = timeSeries;
    this.dataSource.filteredData = timeSeries;
    this.dataSource.sort = this.sort;
    this.timeSeriesNumber = this.dataSource.data.length;
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  translateMatPaginator(paginator: MatPaginator) {
    this.translate.get('scheduling.paginator').subscribe(() => {
      paginator._intl.itemsPerPageLabel = this.translate.instant('scheduling.paginator.itemsPerPage');
      paginator._intl.firstPageLabel = this.translate.instant('scheduling.paginator.firstPageLabel');
      paginator._intl.lastPageLabel = this.translate.instant('scheduling.paginator.lastPageLabel');
      paginator._intl.nextPageLabel = this.translate.instant('scheduling.paginator.nextPageLabel');
      paginator._intl.previousPageLabel = this.translate.instant('scheduling.paginator.previousPageLabel');
      paginator._intl.getRangeLabel = this.rangeLabel;
    });
  }

  rangeLabel = (page: number, pageSize: number, length: number) => {
    if (length == 0 || pageSize == 0) {
      return `0 ${this.translate.instant('scheduling.paginator.of')} ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} ${this.translate.instant('scheduling.paginator.of')} ${length}`;
  };

  dataUploadSet(): void {
    this.dataUpload = true;
    this.showTable = false;
  }

  constantValueSet(): void {
    this.constantValue = true;
    this.showTable = false;
  }

  timeSeriesSaved(data: EmitTimeSeries): void {
    if (data.valid == true) {
      this.getTimeSeries();
      this.constantValue = false;
      this.showTable = true;
      this.openDialogRegisterIndicator(data.tsId, data.valid);
    }
    if (data.valid == false && data.tsId == 0) {
      this.getTimeSeries();
      this.dataSource.filter = '';
      this.constantValue = false;
      this.dataCrossing = false;
      this.showTable = true;
    }
  }

  timeSeriesCanceled(value: boolean): void {
    if (value) {
      this.getTimeSeries();
      this.dataSource.filter = '';
      this.dataUpload = false;
      this.constantValue = false;
      this.dataCrossing = false;
      this.showTable = true;
    }
  }


  dataCrossingSet(): void {
    this.dataCrossing = true;
    this.showTable = false;
  }

  uploadDataTsSaved(data: EmitTimeSeries): void {
    if (data.valid) {
      this.getTimeSeries();
      this.dataUpload = false;
      this.showTable = true;
      this.openDialogRegisterIndicator(data.tsId, data.valid);
    }
    if (data.valid == false && data.tsId == 0) {
      this.getTimeSeries();
      this.dataSource.filter = '';
      this.dataUpload = false;
      this.showTable = true;
    }
  }

  editTimeSerie(event: TimeSeriesRequest): void {
    if (event.dataSource.toString() == "Valor constante" || event.dataSource.toString() == "Constant value") {
      this.toEdit = event.timeSeriesId;
      this.constantValue = true;
      this.showTable = false;
    }
    else if (event.dataSource.toString() == "Upload de dados" || event.dataSource.toString() == "Data upload") {
      this.toEdit = event.timeSeriesId;
      this.dataUpload = true;
      this.showTable = false;
    }
  }

  dataCrossingSaved(value: EmitTimeSeries): void {
    if (value.valid) {
      this.getTimeSeries();
      this.dataCrossing = false;
      this.showTable = true;
      this.openDialogRegisterIndicator(value.tsId, value.valid);
    }
  }

  openSnackbar(status: number): void {
    switch (status) {
      case AppConstants.OK:
        this.snackBar.openFromComponent(CustomSnackbarSuccessComponent, {
          data: {
            message: this.translate.instant('analysis.timeSeries.snackBarTimeSeriesDeleted'),
            snackBar: this.snackBar,
          },
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: AppConstants.TREE_SECOND_WAIT,
        });
        break;
    
      default:
        break;
    }
  }

  openialogDeleteTimeSeries(id: number) {
    const dialogModel: DialogViewModel = {} as DialogViewModel;
    dialogModel.title = this.translate.instant('register.modal.deletetitle');
    dialogModel.message = this.translate.instant('analysis.timeSeries.messageDelete');
    dialogModel.btnError = true;

    this.dialogRef = this.dialog.open(ModalDialogRegisterComponent, {
      disableClose: true,
      data: { info: dialogModel},
      width: '404px',
    });

    this.dialogRef.componentInstance.confirm.subscribe({
      next: async () => {
        await this.timeSeriesService.deleteTimeSeries(id).then((result) => {
          this.openSnackbar(result);
        });
        this.getTimeSeries();
        this.dialogRef.close();
      },
    });
  }

  openDialogRegisterIndicator(timeSeriesId: number, check: boolean) {
    let data = {afterTs: check, tsId: timeSeriesId}
    this.dialogRef = this.dialog.open(IndicatorComponent, {
      disableClose: true,
      data: { info: data},
      //width: '538px',
      height: '460px'
    });

    this.dialogRef.componentInstance.confirm.subscribe({
      next: () => {
        this.getTimeSeries();
        this.dialogRef.close();
      }
    });
  }

  openDialogEditIndicator(timeSeriesId: number, check: boolean, step?: number) {
    let data: any;
    if (step) {
      data = {afterTs: check, tsId: timeSeriesId, indicatorStep: step}
    } else {
      data = {afterTs: check, tsId: timeSeriesId}
    }
    this.dialogRef = this.dialog.open(IndicatorComponent, {
      disableClose: true,
      data: { info: data},
      //width: '538px',
      height: '460px'
    });

    this.dialogRef.componentInstance.confirm.subscribe({
      next: () => {
        this.dialogRef.close();
        this.getTimeSeries();
      }
    });
  }  
}
