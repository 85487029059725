<div class="dialog-register">
    <div class="dialog-register__icon">
        <app-tag-icon
            [icon]="dialogModel.icon"
            [type]="dialogModel.iconType"
            [size]="22">        
        </app-tag-icon>
    </div>

    <div class="dialog-register__title" mat-dialog-title>
		<h4 class="modal-title">{{ dialogModel.title | translate }}</h4>
	</div>

    <div class="dialog-register__body">
        <div class="dialog-register__body-text">
            {{ dialogModel.message | translate}}            
        </div>
        <div class="dialog-register__body-users" *ngIf="!dialogModel.btnError">
            <div *ngFor="let user of users">
                <p>
                    {{ "register.users.name" | translate }}: <span>{{user.name}}</span>
                </p>
                <p>
                    {{ "register.users.email" | translate }}: <span>{{user.email}}</span>
                </p>
                <p>
                    {{ "register.users.typeUser" | translate }}: <span>{{ user.role | translate }}</span>
                </p>
            </div>
        </div>
    </div>
    <div class="dialog-register__actions">
        <button (click)="onDismiss()" class="cancel">{{  textBtn1 | translate }}</button>
        <button (click)="onConfirm()" [ngClass]="dialogModel.btnError ? 'remove' : 'send'">
            {{  textBtn2 | translate }}
        </button>
    </div>
</div>