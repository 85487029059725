import axios from 'axios';
import { AppConstants } from 'src/app/shared/constants/app.constants';
import { environment } from 'src/environments/environment';
import { AuthUserViewModel } from '../models/interfaces/auth-user.model';


export abstract class BaseService {
  protected storage: Storage;

  constructor() {
    this.storage = window.localStorage;
  }

  private apiService = axios.create({
    baseURL: environment.apiDomain,
  });

  protected checkTokenExpired(): boolean {
    const storedUser = this.getUserData();
    return storedUser != undefined && storedUser.exp < Date.now() / 1000;
  }

  protected getUserData(): AuthUserViewModel | undefined {
    const storedUser = this.storage.getItem(AppConstants.STORED_USER_KEY);
    if (storedUser && storedUser !== undefined) {
      const token = JSON.parse(storedUser).token;
      return JSON.parse(atob(token.split('.')[1]));
    }
    return undefined;
  }

  protected composeDefaultHeader() {
    const storedUser = this.storage.getItem(AppConstants.STORED_USER_KEY);
    if (storedUser && storedUser !== undefined) {
      return {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(storedUser).token}`,
      };
    }

    return {
      'Content-Type': 'application/json',
    };
  }

  protected composeHeaderUploadFile() {
    const storedUser = this.storage.getItem(AppConstants.STORED_USER_KEY);
    if (storedUser && storedUser !== undefined) {
      return {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${JSON.parse(storedUser).token}`,
      };
    }

    return {
      'Content-Type': 'multipart/form-data',
    };
  }

  protected get(endPoint: string): Promise<any> {
    const serviceUrl = `${environment.apiDomain}${endPoint}`;
    const headers = this.composeDefaultHeader();
    return this.apiService.get(serviceUrl, { headers }).catch((exception) => {
      console.log(exception);
      return exception?.response;
      // TODO: show gereric error message
    });
  }

  protected async downloadPdf(endPoint: string, body: any) {
    const serviceUrl = `${environment.apiDomain}${endPoint}`;
    let bodyString = null;

    bodyString = JSON.stringify(body);
    const headers = this.composeDefaultHeader();

    const response = await this.apiService.post(serviceUrl, bodyString, { headers, responseType: 'blob' });
    const document = new Blob([response.data], { type: 'application/pdf' });
    let url = window.URL.createObjectURL(document);
    window.open(url)
  }

  protected getWithParams(endPoint: string, params: any): Promise<any> {
    const serviceUrl = `${environment.apiDomain}${endPoint}`;
    const headers = this.composeDefaultHeader();

    let urlParams = new URLSearchParams();
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const value = params[key];
        if (Array.isArray(value)) {
          value.forEach((item, index) => {
            for (const subKey in item) {
              if (item.hasOwnProperty(subKey)) {
                urlParams.append(`${key}[${index}][${subKey}]`, item[subKey]);
              }
            }
          });
        } else {
          urlParams.set(key, value);
        }
      }
    }
    return this.apiService
      .get(serviceUrl, { headers, params: urlParams });
  }

  protected getWithPromise(endPoint: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const headers = this.composeDefaultHeader();

      return this.apiService
        .get(endPoint, { headers })
        .then((response) => resolve(response))
        .catch(reject);
    });
  }

  protected post(endPoint: string, body: any): Promise<any> {
    let bodyString = null;

    bodyString = JSON.stringify(body);
    const headers = this.composeDefaultHeader();

    return this.apiService.post(endPoint, bodyString, { headers }).catch((exception) => {
      if (exception.request.status !== 500) {
        throw exception;
      }
      // TODO: show gereric error message
    });
  }

  protected postFile(endPoint: string, body: any): Promise<any> {
    //let bodyString = null;

    //bodyString = JSON.stringify(body);
    const headers = this.composeHeaderUploadFile();

    return this.apiService.post(endPoint, body, { headers }).catch((exception) => {
      if (exception.request.status !== 500) {
        throw exception;
      }
      // TODO: show gereric error message
    });
  }

  protected put(endPoint: string, body: any): Promise<any> {
    let bodyString = null;

    bodyString = JSON.stringify(body);
    const headers = this.composeDefaultHeader();

    return this.apiService.put(endPoint, bodyString, { headers }).catch((exception) => {
      if (exception.request.status >= 500) {
        throw exception;
      }
      throw exception;
      // TODO: show gereric error message
    });
  }

  protected putFile(endPoint: string, body: any): Promise<any> {
    //let bodyString = null;

    //bodyString = JSON.stringify(body);
    const headers = this.composeHeaderUploadFile();

    return this.apiService.put(endPoint, body, { headers }).catch((exception) => {
      if (exception.request.status !== 500) {
        throw exception;
      }
      // TODO: show gereric error message
    });
  }

  protected patch(endPoint: string, body: any): Promise<any> {
    const serviceUrl = `${environment.apiDomain}${endPoint}`;
    let bodyString = null;

    bodyString = JSON.stringify(body);
    const headers = this.composeDefaultHeader();

    return this.apiService.patch(serviceUrl, bodyString, { headers }).catch((exception) => {
      if (exception.request.status !== 500) {
        throw exception;
      }
      // TODO: show gereric error message
    });
  }

  protected delete(endPoint: string): Promise<any> {
    const serviceUrl = `${environment.apiDomain}${endPoint}`;
    const headers = this.composeDefaultHeader();

    return this.apiService.delete(serviceUrl, { headers }).catch((exception) => {
      if (exception.request.status >= 500) {
        throw exception;
      }
    });
  }
}
