<div class="header-toolbar">
  <div class="header-left">
    <h1 class="title">{{ request.name }}</h1>
    <h2 class="timestamp">{{ "module_monitoring.label_last_update" | translate }}: {{ request.updatedAt  }}</h2>
  </div>
  <div class="header-right">
    <mat-form-field appearance="outline" class="custom-form-field" required>
      <input matInput [matDatepicker]="dp" formControlName="date">
      <mat-datepicker-toggle matIconPrefix [for]="dp"></mat-datepicker-toggle>
      <mat-datepicker #dp
                      startView="multi-year"
                      (monthSelected)="setMonthAndYear($event, dp)"
                      panelClass="month-picker">
      </mat-datepicker>
    </mat-form-field>
  </div>
</div>

<div class="content">
  <div class="column">
    <div class="header-content">
      <h4 style="font-weight: 500;">{{ "module_monitoring.title_attributes" | translate }}</h4>
      <h6>{{ "module_monitoring.sub_title_attributes" | translate }}</h6>
    </div>
    <div>
      <div class="mat-table-container  mat-elevation-z0">
        <mat-table #table [dataSource]="getOtherAttributesDataSource()">
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef> {{ "module_monitoring.label_attr_attribute" | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.name }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="value">
            <mat-header-cell *matHeaderCellDef> {{ "module_monitoring.label_attr_data" | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.value }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="unity">
            <mat-header-cell *matHeaderCellDef> {{ "module_monitoring.label_attr_mensure" | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.unity }} </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="getColumns()"></mat-header-row>
          <mat-row *matRowDef="let row; columns: getColumns()"></mat-row>
        </mat-table>
      </div>
      <div class="bottom-content"></div>
    </div>
  </div>
  <div class="column">
    <div class="header-content">
      <h4 style="font-weight: 500;">{{ "module_monitoring.title_parameters" | translate }}</h4>
      <h6>{{ "module_monitoring.sub_title_parameters" | translate }}</h6>
    </div>
    <div>
      <div class="mat-table-container  mat-elevation-z0">
        <mat-table #table [dataSource]="getStorageAttributesDataSource()">
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef> {{ "module_monitoring.header_indicator" | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.name }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="value">
            <mat-header-cell *matHeaderCellDef> {{ "module_monitoring.header_data" | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.value }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="unity">
            <mat-header-cell *matHeaderCellDef> {{ "module_monitoring.header_unity" | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.unity }} </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="getColumns()"></mat-header-row>
          <mat-row *matRowDef="let row; columns: getColumns()"></mat-row>
        </mat-table>
      </div>
      <div class="bottom-content"></div>
    </div>
  </div>
</div>
