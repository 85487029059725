<div class="register-resource">
    <div class="register-resource__header" *ngIf="addNewResource == false">
        <div class="register-resource__header-info">
            <h2>{{ "register.resources.title" | translate }}</h2>
            <p>{{ "register.resources.info" | translate }}</p>
        </div>

        <button class="register-resource__header-btn" (click)="newResource()" *ngIf="logedUserRole == 'master'">
            <span>{{ "register.resources.createResource" | translate }}</span>
        </button>
    </div>

    <app-new-resource *ngIf="addNewResource == true" (moduleSaved)="moduleIsSaved($event)"></app-new-resource>

    <div class="register-resource__container">
        <app-resource-not-found *ngIf="addNewResource == false && modules.length == 0"
            [title]="title"
            [icon]="icon"
            [type]="type"
            [message]="message"
            [resource]="resource"
            [role]="logedUserRole">
        </app-resource-not-found>

        <div class="register-resource__container-list" *ngIf="modules.length > 0 && addNewResource == false">
            <div class="register-resource__container-list__header">
                <div class="register-resource__container-list__header-title">
                    {{ "register.registerList" | translate }}
                    <div class="resources-number">{{ modulesNumber }} {{ "register.items" | translate }}</div>
                </div>
                <mat-form-field appearance="outline">
                    <mat-label>{{ "activity.filter" | translate }}</mat-label>
                    <input matInput (keyup)="applyFilter($event)" #input>
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
            </div>

            <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="header"> {{ "register.resources.name" | translate }} </th>
                    <td mat-cell *matCellDef="let module"> {{module.name}} </td>
                </ng-container>
                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="header"> {{ "register.resources.type" | translate }} </th>
                    <td mat-cell *matCellDef="let module"> {{module.type}} </td>
                </ng-container>
                <ng-container matColumnDef="subtype">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="header"> {{ "register.resources.subtype" | translate }} </th>
                    <td mat-cell *matCellDef="let module"> {{ module.subtype | translate }}</td>
                </ng-container>
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let module" class="resource-actions">
                        <div class="actions" *ngIf="logedUserRole == 'master'">
                            <button mat-icon-button [matMenuTriggerFor]="optionsModule" aria-label="Example icon-button with a menu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #optionsModule="matMenu" class="menu">
                                <button mat-menu-item class="action" (click)="editModule(module)">
                                    <i class="fa-regular fa-pen-to-square icon"></i>
                                    <span class="texto">{{ "register.resources.editModule" | translate }}</span>
                                </button>
                                <button mat-menu-item class="action" (click)="openDialog(module.moduleId)">
                                    <i class="fa-regular fa-trash"></i>
                                    <span class="texto">{{ "register.resources.removeModule" | translate }}</span>
                                </button>
                            </mat-menu>
                        </div>
                    </td>
                </ng-container>
    
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                <tr mat-row *matRowDef="let row; columns: columnsToDisplay;" class="example-element-row"></tr>
    
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">{{ "activity.filterReturn" | translate }} "{{input.value}}"</td>
                </tr>
            </table>
    
            <mat-paginator #paginatorUsers [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="10"
                aria-label="Items por pagina" showFirstLastButtons class="border">
            </mat-paginator>
        </div>
    </div>
</div>