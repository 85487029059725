<div class="dashboards">
  <div class="dashboards-header">
    <div class="header-left">
      <h2>{{ 'analysisdashboards.page.title' | translate }}</h2>
      <p>{{ 'analysisdashboards.page.description' | translate }}</p>
    </div>
    <div class="header-right">
      <a [routerLink]="['/analysis/dashboards/create']" class="create-dashboard-button">{{
        'analysisdashboards.page.create_new' | translate
      }}</a>
    </div>
  </div>

  <div class="main-container">
    <div class="toolbar">
      <div class="search-input">
        <mat-form-field class="search" appearance="outline">
          <mat-icon class="icon" matPrefix>search</mat-icon>
          <mat-label>{{ 'analysisdashboards.toolbar.label' | translate }}</mat-label>
          <input
            matInput
            id="input-search-default"
            [(ngModel)]="inputValue"
            placeholder="{{ 'analysisdashboards.toolbar.placeholder_bar' | translate }}"
            #input
            (input)="filterDashboards(input.value)"
            style="width: 80%; margin-left: 30px"
          />
          <mat-icon
            matSuffix
            class="icon"
            *ngIf="inputValue.length > 0"
            (click)="clearInput(input)"
            style="left: -30px"
            >close</mat-icon
          >
        </mat-form-field>
      </div>
      <div class="sort-selection">
        <div class="sort-label">
          <label class="sort-label-text">{{ 'analysisdashboards.toolbar.sort_label' | translate }}:</label>
        </div>
        <mat-select
          [(value)]="modeSelect"
          placeholder="{{ 'analysisdashboards.toolbar.option_1' | translate }}"
          (selectionChange)="sortDashboards()"
        >
          <mat-option value="ultimo-editado">{{
            'analysisdashboards.toolbar.option_1' | translate
          }}</mat-option>
          <mat-option value="nome-dashboard">{{
            'analysisdashboards.toolbar.option_2' | translate
          }}</mat-option>
          <mat-option value="nome-editor">{{ 'analysisdashboards.toolbar.option_3' | translate }}</mat-option>
        </mat-select>
      </div>
    </div>

    <div class="container">
      <div class="card-container">
        <mat-card class="unit-dashboard-card" *ngFor="let card of filteredCards">
          <mat-card-header>
            <div class="header-top">
              <app-card-icon type="{{ card.iconType }}"></app-card-icon>
            </div>
            <div class="header-botton">
              <mat-card-title class="card-title">{{ card.title }}</mat-card-title>
              <mat-card-subtitle class="card-subtitle">{{ card.description }}</mat-card-subtitle>
              <button mat-icon-button [matMenuTriggerFor]="menu" class="menu-button">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <a mat-menu-item [routerLink]="['/analysis/dashboards/edit', card!.dashboardId!]">
                  <i class="fa-regular fa-edit"></i>
                  {{ 'analysisdashboards.card.menu.option_edit' | translate }}
                </a>
                <button mat-menu-item (click)="duplicateCard(card!.dashboardId!)">
                  <i class="fa-regular fa-copy"></i>
                  {{ 'analysisdashboards.card.menu.option_duplicate' | translate }}
                </button>
                <button mat-menu-item (click)="removeCard(card!.dashboardId!)">
                  <i class="fa-regular fa-trash"></i>
                  {{ 'analysisdashboards.card.menu.option_remove' | translate }}
                </button>
              </mat-menu>
            </div>
          </mat-card-header>
          <mat-card-content
            class="dashboard-content"
            *ngIf="card.lastEdited !== null && card.lastEdited !== undefined"
          >
            <p class="dashboard-p">
              <i class="fa-regular fa-calendar description-icon"></i>
              <span class="description-label"
                >{{ 'analysisdashboards.card.footer.user_name' | translate }}:</span
              >
              {{ card.updatedAt!.toLocaleDateString('pt-BR') }}
            </p>
            <p class="dashboard-p">
              <i class="fa-regular fa-user description-icon"></i>
              <span class="description-label"
                >{{ 'analysisdashboards.card.footer.updated_at' | translate }}:</span
              >
              {{ card!.lastEdited!.name }}
            </p>
          </mat-card-content>
        </mat-card>
      </div>
      <div *ngIf="filteredCards.length === 0" class="no-dashboard">
        <div class="not-dashboard">
          <app-tag-icon [icon]="'error'" [type]="'default'" [size]="18"> </app-tag-icon>
          <div class="info">
            <h2>{{ 'analysisdashboards.not_dashboard.info' | translate }}</h2>
            <hr />
            <p>
              <span class="support">
                <a [routerLink]="['/analysis/dashboards/create']" class="create-dashboard-link">{{
                  'analysisdashboards.not_dashboard.message_1' | translate
                }}</a></span
              >
              {{ 'analysisdashboards.not_dashboard.message_2' | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
