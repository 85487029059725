import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { UserRegisterRequest } from '../../models/request/user-register-request.model';
import { AuthUserDataViewModel, AuthUserViewModel } from '../../models/interfaces/auth-user.model';

@Injectable({
  providedIn: 'root',
})
export class UserServiceService extends BaseService {
  constructor() {
    super();
  }

  async getAll(currentPage: number = 1, totalPage: number = 10): Promise<UserRegisterRequest[]> {
    return await this.get(`/users?currentPage=${currentPage}&totalPage=${totalPage}`);
  }

  async getAllUsers(): Promise<UserRegisterRequest[]> {
    return await this.get(`/users`).then((resp) => {
      return resp.data;
    });
  }

  async getUser(): Promise<AuthUserViewModel | undefined> {
    return await this.getUserData();
  }

  async sendInvitationToUser(users: UserRegisterRequest[]): Promise<number | any> {
    return await this.post(`/users`, users)
      .then((resp) => {
        return resp.status;
      })
      .catch((resp) => {
        return resp.status;
      });
  }

  async changeUserRole(user: UserRegisterRequest): Promise<number> {
    return await this.patch(`/users/${user.userId}`, user)
      .then((resp) => {
        return resp.status;
      })
      .catch((resp) => {
        return resp.status;
      });
  }

  async deleteUser(id: number) {
    return await this.delete(`/users/${id}`).then((resp) => {
      return resp.status;
    });
  }


  async getLocalUser() {
    const user = this.getUserData();
    return {
      name: user?.name,
      email: user?.sub
    } as AuthUserDataViewModel;
  }
}
