import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Subject } from 'rxjs';
import { DialogViewModel } from 'src/app/core/models/interfaces/dialog.model';

@Component({
  selector: 'app-modal-dialog',
  templateUrl: './modal-dialog.component.html',
  styleUrls: ['./modal-dialog.component.scss'],
})
export class ModalDialogComponent {
  dialogModel: DialogViewModel = {} as DialogViewModel;
  confirm: Subject<boolean> = new Subject();
  close: Subject<boolean> = new Subject();
  moduleStatus: string = '';
  @Output() supportRequestAction = new EventEmitter<void>();

  constructor(
    public dialogRef: MatDialogRef<ModalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogViewModel,
  ) {
    this.dialogModel.icon = data.icon;
    this.dialogModel.iconType = data.iconType;
    this.dialogModel.title = data.title;
    this.dialogModel.message = data.message;
    this.dialogModel.defaultMessage = data.defaultMessage;
    this.dialogModel.showButtons = data.showButtons;
    this.dialogModel.btnError = data.btnError;
    this.dialogModel.template = data.template;
    this.dialogModel.resource = data.resource;
    this.dialogModel.controlMode = data.controlMode;
    this.dialogModel.actionSelectedInModule = data.actionSelectedInModule;
    this.dialogModel.isControllerModule = data.isControllerModule;
    this.dialogModel.isFailDialog = data.isFailDialog;
  }

  ngOnInit(): void {
    if (this.dialogModel.actionSelectedInModule == 2 || this.dialogModel.actionSelectedInModule == 5) {
      this.moduleStatus = 'module.statusLoadOn';
    } else {
      this.moduleStatus = 'module.statusLoadOff';
    }

    if (this.dialogModel.actionSelectedInModule == 3) {
      this.moduleStatus = 'module.actionStandBy';
    }
  }

  onConfirm(): void {
    this.confirm.next(true);
  }

  onDismiss(): void {
    this.close.next(true);
    this.dialogRef.close(false);
  }

  showSupportRequest(): void {
    this.supportRequestAction.emit();
  }
}
