import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { EnergyDistributionViewModel } from '../../models/interfaces/energy-distribution.model';
import { NewModuleRequest } from '../../models/request/new-module-request.model';

@Injectable({
  providedIn: 'root',
})
export class DistributionGridService extends BaseService {
  constructor() {
    super();
  }

  async getAllEnergyDistribution(): Promise<EnergyDistributionViewModel[]> {
    return await this.get('/energy-distributor');
  }

  async createDistributionGrid(form: NewModuleRequest) {
    const userData = this.getUserData();

    if (!userData || userData.org.ugridIds.length === 0) return [];

    const ugridId = userData.org.ugridIds[0];
    form.uGridId = ugridId;

    return this.post('/modules', form)
      .then((res) => {
        return res.status;
      })
      .catch((res) => {
        return res.status;
      });
  }

  async getDistributionGridModule(ugridId: number = 1): Promise<NewModuleRequest> {
    return await this.get(`/modules/${ugridId}/type?type=2&subtype=6`);
  }

  async updateDistributionGridModule(form: NewModuleRequest, moduleId: number) {
    return await this.put(`/modules/${moduleId}`, form).then((res) => {
      return res.status;
    });
  }
}
