
<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <mat-dialog-content class="mat-typography">
    <h1>{{ "dashboard_analisys.graphic.modal_personalize_dashboard.title_modal" | translate }}</h1>
    <h3>{{ "dashboard_analisys.graphic.modal_personalize_dashboard.description_modal" | translate }}</h3>

    <div class="form-group">
      <label for="" class="label">{{ "dashboard_analisys.graphic.modal_personalize_dashboard.title" | translate }} <span>*</span> <i class="fa-solid fa-circle-info"></i></label>
      <mat-form-field appearance="outline" class="full-width">
        <input matInput formControlName="title" placeholder="{{ 'dashboard_analisys.graphic.modal_personalize_dashboard.title_placeholder' | translate }}">
        <mat-hint>{{ "dashboard_analisys.graphic.modal_personalize_dashboard.title_validation_max_lenght" | translate }}</mat-hint>
        <mat-error *ngIf="formGroup.get('title')?.hasError('required') && formGroup.get('title')?.touched">
          <small>{{ "dashboard_analisys.graphic.modal_personalize_dashboard.title_placeholder_required" | translate }}</small>
        </mat-error>
        <mat-error *ngIf="formGroup.get('title')?.hasError('maxlength') && formGroup.get('title')?.touched && !formGroup.get('title')?.hasError('required')">
          <small>{{ "dashboard_analisys.graphic.modal_personalize_dashboard.title_validation_max_lenght" | translate }}</small>
        </mat-error>
        <mat-error *ngIf="formGroup.get('title')?.hasError('titleTaken')">
          <small>{{ "dashboard_analisys.graphic.modal_personalize_dashboard.title_is_not_unique" | translate }}</small>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="form-group">
      <label for="" class="label">{{ "dashboard_analisys.graphic.modal_personalize_dashboard.description" | translate }}</label>
      <mat-form-field appearance="outline" class="full-width" required>
        <textarea matInput formControlName="description" placeholder="{{ 'dashboard_analisys.graphic.modal_personalize_dashboard.description_placeholder' | translate }}" rows="4"></textarea>
        <mat-hint>{{ "dashboard_analisys.graphic.modal_personalize_dashboard.description_validation_max_lenght" | translate }}</mat-hint>
        <mat-error *ngIf="formGroup.get('description')?.hasError('required') && formGroup.get('description')?.touched">
          <small>{{ "dashboard_analisys.graphic.modal_personalize_dashboard.description_validation_required" | translate }}</small>
        </mat-error>
        <mat-error *ngIf="formGroup.get('description')?.hasError('maxlength') && formGroup.get('description')?.touched && !formGroup.get('description')?.hasError('required')">
          <small>{{ "dashboard_analisys.graphic.modal_personalize_dashboard.description_validation_max_lenght" | translate }}</small>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="form-group">
      <h4>{{ "dashboard_analisys.graphic.modal_personalize_dashboard.icon_message" | translate }}</h4>
      <ul class="icon-list">
        <li class="dashboard-icon dashboard-icon-wrench"
          [ngClass]="{'selected': selectedIcon === 'wrench'}"
          (click)="selectIcon('wrench')">
          <i class="fa-regular fa-wrench"></i>
        </li>
        <li class="dashboard-icon dashboard-icon-enterprise"
          [ngClass]="{'selected': selectedIcon === 'enterprise'}"
          (click)="selectIcon('enterprise')">
          <i class="fa-regular fa-building"></i>
        </li>
        <li class="dashboard-icon dashboard-icon-graphWithZoom"
          [ngClass]="{'selected': selectedIcon === 'graphWithZoom'}"
          (click)="selectIcon('graphWithZoom')">
          <i class="fa-regular fa-chart-area"></i>
        </li>
        <li class="dashboard-icon dashboard-icon-money"
          [ngClass]="{'selected': selectedIcon === 'money'}"
          (click)="selectIcon('money')">
          <i class="fa-regular fa-coins"></i>
        </li>
        <li class="dashboard-icon dashboard-icon-target"
          [ngClass]="{'selected': selectedIcon === 'target'}"
          (click)="selectIcon('target')">
          <i class="fa-regular fa-bullseye"></i>
        </li>
        <li class="dashboard-icon dashboard-icon-energy"
          [ngClass]="{'selected': selectedIcon === 'energy'}"
          (click)="selectIcon('energy')">
          <i class="fa-regular fa-bolt"></i>
        </li>
      </ul>
      <mat-error *ngIf="formGroup.get('icon')?.invalid && formGroup.get('icon')?.touched">
        {{ "dashboard_analisys.graphic.modal_personalize_dashboard.icon_message_validation" | translate }}
      </mat-error>
    </div>

    <div class="form-group">
      <p class="footer-modal">
        {{ "dashboard_analisys.graphic.modal_personalize_dashboard.message" | translate }}
      </p>
    </div>

    <div>
      <mat-divider></mat-divider>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close class="cancel-button">{{ "dashboard_analisys.graphic.modal_personalize_dashboard.btn_cancel" | translate }}</button>
    <button mat-button [mat-dialog-close]="true" class="save-button" type="submit" [disabled]="formGroup.invalid" cdkFocusInitial>{{ "dashboard_analisys.graphic.modal_personalize_dashboard.btn_save" | translate }}</button>
  </mat-dialog-actions>
</form>
