import { BaseService } from 'src/app/core/services/base.service';
import { State } from '../../models/interfaces/state.model';
import { City } from '../../models/interfaces/city.model';

export class LocationService extends BaseService {
  constructor() {
    super();
  }

  async listStates(): Promise<State[]> {
    return await this.get('/location/states')
      .then((res) => res.data)
      .catch((err) => {
        console.error(err);
        return [];
      });
  }

  async listCitiesByState(state: string): Promise<City[]> {
    return this.get(`/location/state/${state}/cities`)
      .then((res) => res.data)
      .catch((err) => {
        console.error(err);
        return [];
      });
  }
}
