import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TypeModuleEnum } from 'src/app/core/models/enums/typeModuleEnum';
import IndicatorResquest from 'src/app/core/models/request/indicator-request.model';
import ModuleRequest from 'src/app/core/models/request/module-request.model';
import { IndicatorService } from 'src/app/core/services/indicatorServices/indicator.service';
import { ModuleService } from 'src/app/core/services/moduleServices/module.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  modules: ModuleRequest[] = [];
  indicators: IndicatorResquest[] = [];

  constructor(
    private moduleService: ModuleService,
    private indicatorService: IndicatorService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.modules = await this.getAllModules();
    this.indicators = await this.indicatorService.getAll();
  }

  getAllModules(): Promise<ModuleRequest[]> {
    return this.moduleService.getAll().then((resp) => {
      resp.forEach((module) => {
        switch (module.type) {
          case TypeModuleEnum.grid:
            module.index = 0;
            break;
          case TypeModuleEnum.storage:
            module.index = 1;
            break;
          case TypeModuleEnum.generator:
            module.index = 2;
            break;
          case TypeModuleEnum.load:
            module.index = 3;
            break;
        }
      });
      return resp.sort((x, y) => (x.index > y.index ? 1 : -1));
    });
  }
}
