<div class="energy-contracts" *ngIf="showTable == true">
    <div class="energy-contracts__header">
        <div class="energy-contracts__header-info">
            <h2>{{ "analysisTimeSeries.page.title" | translate }}</h2>
            <p>{{ "analysisTimeSeries.page.description" | translate }}</p>
        </div>

        <button class="energy-contracts__header-btn" [matMenuTriggerFor]="optionsTimeSeries">
            <span>{{ "analysisTimeSeries.page.create_new" | translate }}</span>
        </button>
        <mat-menu #optionsTimeSeries="matMenu" xPosition="after" class="menu">
            <button mat-menu-item class="action" (click)="dataUploadSet()">
                <i class="fa-regular fa-cloud-arrow-up"></i>
                <span>{{ "analysisTimeSeries.page.optionDataUpload" | translate }}</span>
                <p>{{ "analysisTimeSeries.page.descDataUpload" | translate }}</p>
            </button>
            <button mat-menu-item class="action" (click)="constantValueSet()">
                <i class="fa-regular fa-hashtag"></i>
                <span>{{ "analysisTimeSeries.page.optionConstValue" | translate }}</span>
                <p>{{ "analysisTimeSeries.page.descConstValue" | translate }}</p>
            </button>
            <button mat-menu-item class="action" (click)="dataCrossingSet()">
                <i class="fa-regular fa-function"></i>
                <span>{{ "analysisTimeSeries.page.optionDataCross" | translate }}</span>
                <p>{{ "analysisTimeSeries.page.descDataCross" | translate }}</p>
            </button>
        </mat-menu>
    </div>

    <div class="energy-contracts__container" *ngIf="timeSeriesNumber == 0">
        <app-resource-not-found [icon]="icon" [type]="type" [message]="message" [resource]="resource"
            [contractMessage]="contractMessage" [noHeader]="noHeader">
        </app-resource-not-found>
    </div>
    <br>

    <div class="energy-contracts__container-list" *ngIf="timeSeriesNumber > 0">
        <div class="energy-contracts__container-list__header">
            <div class="energy-contracts__container-list__header-title">
                {{ "analysisTimeSeries.page.title" | translate }}
                <div class="users-number">{{ timeSeriesNumber }} {{ "register.items" | translate }}</div>
            </div>
            <mat-form-field appearance="outline">
                <mat-label>{{ "activity.filter" | translate }}</mat-label>
                <input matInput (keyup)="applyFilter($event)" #input>
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
        </div>

        <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "analysisTimeSeries.table.name" | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>
            <ng-container matColumnDef="unit">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "analysisTimeSeries.table.unity" | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.unit}} </td>
            </ng-container>
            <ng-container matColumnDef="dataSource">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "analysisTimeSeries.table.dataSource" | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.dataSource}} </td>
            </ng-container>
            <ng-container matColumnDef="indicator">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "analysisTimeSeries.table.indicator" | translate }} </th>
                <td mat-cell *matCellDef="let element"> 
                    <div *ngIf="element.indicatorType !== '-'"> {{element.indicatorValue}} {{element.unit}} <br> <p class="underLine">{{element.indicatorType}}</p> </div>
                    <div *ngIf="element.indicatorType == '-'"> {{element.indicatorType}} </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="createdBy">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "analysisTimeSeries.table.createdBy" | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.user.name}} <br> <p class="underLine">{{element.user.email}}</p> </td>
            </ng-container>
            <ng-container matColumnDef="firstData">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "analysisTimeSeries.table.firstData" | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.dateStart}} </td>
            </ng-container>
            <ng-container matColumnDef="lastData">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "analysisTimeSeries.table.lastData" | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.dateEnd}} </td>
            </ng-container>
            <ng-container matColumnDef="modal">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" class="resource-actions">
                    <div class="actions" *ngIf="element.flag == true">
                        <button mat-icon-button [matMenuTriggerFor]="optionsModule" aria-label="Example icon-button with a menu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #optionsModule="matMenu" class="menu">
                            <button mat-menu-item class="action" *ngIf="element.indicatorType !== '-'" (click)="openDialogEditIndicator(element.timeSeriesId, false, 1)">
                                <i class="fa-regular fa-pen-to-square"></i>
                                <span class="texto">{{ "analysisTimeSeries.table.editIndicator" | translate }}</span>
                            </button>
                            <button mat-menu-item class="action" *ngIf="element.indicatorType == '-'" (click)="openDialogRegisterIndicator(element.timeSeriesId, false)">
                                <i class="fa-regular fa-pen-to-square"></i>
                                <span class="texto">{{ "analysisTimeSeries.table.registerIndicator" | translate }}</span>
                            </button>
                            <button mat-menu-item class="action" (click)="editTimeSerie(element)">
                                <i class="fa-regular fa-pen-to-square"></i>
                                <span class="texto">{{ "analysisTimeSeries.table.editSerie" | translate }}</span>
                            </button>
                            <button mat-menu-item class="action" (click)="openialogDeleteTimeSeries(element.timeSeriesId)">
                                <i class="fa-regular fa-trash"></i>
                                <span class="texto">{{ "analysisTimeSeries.table.remove" | translate }}</span>
                            </button>
                        </mat-menu>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let row; columns: columnsToDisplay" class="example-element-row"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">{{ "register.energyContracts.filterReturn" | translate }} "{{input.value}}"</td>
            </tr>
        </table>
        
        <mat-paginator #paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="10"
            aria-label="Items por pagina" showFirstLastButtons class="border">
        </mat-paginator>
    </div>
</div>
<app-constant-value 
    *ngIf="constantValue == true" 
    (timeSeriesSaved)="timeSeriesSaved($event)" 
    (timeSeriesCanceled)="timeSeriesCanceled($event)"
    dataToEdit="{{toEdit}}"
    >
</app-constant-value>

<app-data-upload 
    *ngIf="dataUpload == true"
    (uploadDataTsSaved)="uploadDataTsSaved($event)"
    (timeSeriesCanceled)="timeSeriesCanceled($event)"
    dataToEdit="{{toEdit}}"
    >
</app-data-upload>

<app-data-crossing 
    *ngIf="dataCrossing" 
    (dataCrossingSaved)="dataCrossingSaved($event)"
    (crossingDataCanceled)="timeSeriesCanceled($event)"
    >
</app-data-crossing>
