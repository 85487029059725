<div class="form-content">
  <div *ngIf="status == 0">
    <h2>{{ "login.forgotPassword.title" | translate }}</h2>
    <h4>{{ "login.forgotPassword.message" | translate }}</h4>

    <form [formGroup]="retrieveForm" (ngSubmit)="onSubmit()" autocomplete="off">
      <div class="form-action">

        <div class="form-group">
          <label for="email" class="form-label">{{ "login.email" | translate }}</label>
          <mat-form-field appearance="outline" class="login-access__form-input" >
            <input matInput
                  placeholder='{{ "login.formPlaceholder.email" | translate }}'
                  formControlName="email" />

            <mat-icon matSuffix svgIcon="error" *ngIf="isError" class="text-color-red"></mat-icon>

            <mat-hint class="mat-padding-null" *ngIf="(retrieveForm.controls['email'].invalid && (retrieveForm.controls['email'].dirty || retrieveForm.controls['email'].touched) && retrieveForm.controls['email']?.errors?.['required']) && !isError">
              <span class="message-error">{{ "login.validate.email.required" | translate }}</span>
            </mat-hint>

            <mat-hint class="mat-padding-null" *ngIf="(retrieveForm.controls['email'].invalid && (retrieveForm.controls['email'].dirty || retrieveForm.controls['email'].touched) && retrieveForm.controls['email']?.errors?.['email']) && !isError">
              <span class="message-error">{{ "login.validate.email.invalid" | translate }}</span>
            </mat-hint>
          </mat-form-field>

        </div>

        <br />
        <button type="submit"
                class="submit-button"
                [disabled]="retrieveForm.invalid">
          {{ "login.forgotPassword.button" | translate }}
        </button>
      </div>

      <div class="forgotPasswordArea">
        <a [routerLink]="['/auth/login']"><span class="underline text-color-green"> {{ "login.backToLoginLabel" | translate }}</span></a>
      </div>
    </form>
  </div>

  <ng-container *ngIf="status == 200">
    <div class="disclaimer">
      <h3>{{ "login.forgotPassword.title" | translate }}</h3>
      <br />
      <p>{{ 'login.forgotPassword.instructions' | translate }}</p>
      <br />
      <p>
        <button [routerLink]="['/auth/login']" class="submit-button">
          {{ "login.feedbacks.login.buttonClose" | translate }}
        </button>
      </p>
    </div>
  </ng-container>

  <ng-container *ngIf="status == 403">
    <div class="disclaimer">
      <h3>{{ "login.feedbacks.login.title" | translate }}</h3>
      <br />
      <p>{{ 'login.feedbacks.fogoutPassword.activeMessage' | translate }}</p>
      <br />
      <p [innerHTML]="complement"></p>
      <br />
      <p>
        <button [routerLink]="['/auth/login']" class="submit-button">
          {{ "login.feedbacks.login.buttonClose" | translate }}
        </button>
      </p>
    </div>
  </ng-container>
</div>
