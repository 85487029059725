<div class="activity">
	<div class="activity-header">
		<h2>{{ "controlPanel.activityFeed.title" | translate }}</h2>
		<p>{{ "controlPanel.activityFeed.info" | translate }}</p>
	</div>

	<div class="background">
		<div class="sub-header">
			<div class="title">
				{{ "activity.subtitle" | translate }}
				<div class="operation-number">{{this.operationNumber}} {{ "activity.operations" | translate }}</div>
			</div>
			<mat-form-field appearance="outline">
				<mat-label>{{ "activity.filter" | translate }}</mat-label>
				<input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
				<mat-icon matSuffix>search</mat-icon>
			</mat-form-field>
		</div>

		<table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>

			<ng-container matColumnDef="moduleName">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "activity.column.name" | translate }} </th>
				<td mat-cell *matCellDef="let module"> {{module.moduleName}} </td>
			</ng-container>
			<ng-container matColumnDef="ctrl">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "activity.column.action" | translate }} </th>
				<td mat-cell *matCellDef="let module"> {{module.ctrl}} </td>
			</ng-container>
            <ng-container matColumnDef="time">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "activity.column.time" | translate }} </th>
				<td mat-cell *matCellDef="let module"> {{module.dateTime}} <br> {{module.time}} </td>
			</ng-container>
            <ng-container matColumnDef="ctrlType">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "activity.column.type" | translate }} </th>
				<td mat-cell *matCellDef="let module"> {{module.ctrlType}} </td>
			</ng-container>
            <ng-container matColumnDef="userName">
				<th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "activity.column.author" | translate }} </th>
				<td mat-cell *matCellDef="let module"> {{module.userName}} <br> {{module.userEmail}} </td>
			</ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let row; columns: columnsToDisplay;" class="example-element-row"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">{{ "activity.filterReturn" | translate }} "{{input.value}}"</td>
            </tr>
		</table>

		<mat-paginator #paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="10"
			aria-label="Items por pagina" showFirstLastButtons class="border">
        </mat-paginator>
	</div>
</div>