<div class="card-module">
    <div class="card-module-icon"
        [class.type-fixed-load]="module.type == 0 && module.subtype == 0"
        [class.type-renewable-generation]="module.type == 1"
        [class.type-distribution-network]="module.type == 2"
        [class.type-storage]="module.type == 3"
        [class.type-controllable-load]="module.type == 0 && module.subtype == 1"
        [class.type-dispatchable-generation]="module.type == 1 && module.subtype == 2"
        >
        <i class="fa-regular fa-{{ icon }}"></i>
    </div>
    <p class="card-module-title">
        {{ module.name }}
        <img
            src="assets/icons/circle-info.svg" 
            alt="Icon Info"
            matTooltip="{{ module.tooltipInfo ? module.tooltipInfo : notRecentUpdates }}"
            matTooltipPosition="above"
            matTooltipClass="module {{ module.tooltipInfo ? 'recent-updates' : '' }}">
    </p>
    <h3 class="card-module-value">{{ module.value }} {{ module.valueUnit }} </h3>
    <div class="card-module-details">
        <span
            [class.status-fixed-load]="module.type == 0 && module.subtype == 0"
            [class.status-renewable-generation]="module.type == 1"
            [class.status-distribution-network]="module.type == 2"
            [class.status-storage]="module.type == 3"
            [class.status-controllable-load]="module.type == 0 && module.subtype == 1"
            [class.status-dispatchable-generation]="module.type == 1 && module.subtype == 2"
        >
            {{ module.statusName }}
        </span>
        <a href="" *ngIf="module.type != 2">{{ "module.seeDetails" | translate }}</a>
    </div>
</div>