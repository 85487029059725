<div class="card" *ngIf="isExpansionPanel" 
    [style.width]="width"
    [style.margin-bottom]="marginBottom"> 
    <mat-accordion class="indicator">
        <mat-expansion-panel
        class="indicator-panel"
        (opened)="panelOpen = true"
        (closed)="panelOpen = false">
            <mat-expansion-panel-header class="indicator-panel_header">
                <mat-panel-description  class="indicator-panel_header-description">
                    <div>
                        <p> 
                            {{ title | translate }}
                            <i class="fa-solid fa-circle-info"
                                *ngIf="tooltipInfo"
                                matTooltip="{{ tooltipInfo | translate }}"
                                matTooltipPosition="above"
                                matTooltipClass="indicator-tooltip"
                            ></i>
                        </p>
                        <div>
                            <span>{{ value }} {{ valueUnit | translate }}</span>
                        </div>
                    </div>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="indicator-panel_content">
                <hr class="indicator-panel_content-separator">
                <div class="indicator-panel_content-info">
                    <div>
                        <span>{{ "indicator.inPeakTime" | translate }}</span>
                        <p> {{ inPeakTimeValue }} {{ inPeakTimeValueUnit | translate }}</p>
                    </div>
                    <div>
                        <span>{{ "indicator.outPeakTime" | translate }}</span>
                        <p> {{ outPeakTimeValue }} {{ outPeakTimeValueUnit | translate }}</p>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>

<div class="card-not-expansion" *ngIf="!isExpansionPanel" [style.width]="width">
    <div>
        <p>
            {{ title | translate }} 
            <i class="fa-solid fa-circle-info"
                *ngIf="tooltipInfo"
                matTooltip="{{ tooltipInfo | translate }}"
                matTooltipPosition="above"
                matTooltipClass="indicator-tooltip-not-expansion"
            ></i>
        </p>
        <div>
            <span>{{ value }} {{ valueUnit | translate }}</span>
        </div>
    </div>
</div>
