import { _isNumberValue } from '@angular/cdk/coercion';
import { HttpStatusCode } from '@angular/common/http';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ContractMetadataTypeEnum } from 'src/app/core/models/enums/contract-metadata-type.enum';
import { ContractMetadataValuesRequest, ContractRequest } from 'src/app/core/models/request/contract-request.model';
import { ContractRuleRequest } from 'src/app/core/models/request/contract-rule-request.model';
import { EnergyContractsService } from 'src/app/core/services/energyContracts/energy-contracts.service';
import { CustomSnackbarErrorComponent } from 'src/app/shared/components/custom-snackbar-error/custom-snackbar-error.component';
import { CustomSnackbarSuccessComponent } from 'src/app/shared/components/custom-snackbar-success/custom-snackbar-success.component';
import { AppConstants } from 'src/app/shared/constants/app.constants';

@Component({
  selector: 'app-new-energy-contracts-form',
  templateUrl: './new-energy-contracts-form.component.html',
  styleUrls: ['./new-energy-contracts-form.component.scss']
})
export class NewEnergyContractsFormComponent {

  contractRuleId?: number;
  contractRule: ContractRuleRequest = {} as ContractRuleRequest;
  contract: ContractRequest = {} as ContractRequest;
  isEditing: boolean = false;
  isDuplicating: boolean = false;

  private storage: Storage;
  nameTip = this.translate.instant('register.energyContracts.form.toolTip.nameTip');
  namePlaceholder = this.translate.instant('register.energyContracts.form.name_placeholder');
  descriptionPlaceholder = this.translate.instant('register.energyContracts.form.description_placeholder');
  validityPeriodPlaceholder = this.translate.instant('register.energyContracts.form.validityPeriod_placeholder');
  @Output() contractSaved = new EventEmitter<boolean>();

  constructor(private fb: FormBuilder, 
    private translate: TranslateService, 
    private energyContractsService: EnergyContractsService, 
    private snackBar: MatSnackBar) {
    this.storage = window.localStorage;

    this.isEditing = this.storage.getItem('editing') ? true : false;
    this.isDuplicating = this.storage.getItem('duplicating') ? true : false;
  }

  async ngOnInit(): Promise<void> {
    if (this.isEditing || this.isDuplicating) {
      const contractId = this.storage.getItem('contractId') ? Number(this.storage.getItem('contractId')) : undefined;
      await this.energyContractsService.getEnergyContractById(contractId!).then((res) => {
        this.contract = res;
        this.contractRuleId = res.contractRuleId;
        this.contract.contractId = this.isDuplicating ? null : res.contractId!;
        this.contract.startValidityPeriod = this.isDuplicating ? null : res.startValidityPeriod;
        this.contract.endValidityPeriod = this.isDuplicating ? null : res.endValidityPeriod;
      });
    } else {
      this.contractRuleId = this.storage.getItem('contractRuleId') ? Number(this.storage.getItem('contractRuleId')) : undefined;
    }

    await this.loadContractRule();
  }

  async loadContractRule(): Promise<void> {
    if (this.contractRuleId) {
      this.contractRule = await this.energyContractsService.getContractRuleById(this.contractRuleId!);
      if (!this.contractRule) {
        this.openSnackBarError(this.translate.instant('register.energyContracts.contractRuleNotFound'));
      }

      this.setupMetadataInfo();
      
      if (this.contractRule.energyCompany == undefined || this.contractRule?.energyCompany == '') {
        this.contractRule.energyCompany = this.translate.instant('register.energyContracts.form.energyCompanyNotApplicableValue');
      }
    } else {
      this.openSnackBarError(this.translate.instant('register.energyContracts.contractRuleNotFound'));
    }
  }

  async saveForm(isValid: boolean): Promise<void> {
    if (isValid) {
      
      // check invalid data
      if (!this.validateMetadataValues()) {
        return;
      }

      if (!await this.validatePeriod()) {
        this.openSnackBarError(this.translate.instant('register.energyContracts.invalidPeriod'));
        return;
      }

      this.contract.contractRuleId = this.contractRule.contractRuleId;
      this.contract.contractMetadataValues = [] as ContractMetadataValuesRequest[];
      this.contractRule.metadata.forEach((x) => {
        this.contract.contractMetadataValues.push({
          contractMetadataId: x.contractMetadataId,
          contractMetadataValue: x.metadataValue
        });
      });

      if (this.isEditing) {
        await this.energyContractsService.updateContract(this.contract).then((res) => {
          if (res.status == HttpStatusCode.Ok) {
            this.openSnackBarSaveSuccess(res.status);
            this.clearStorage();
            this.contractSaved.emit(true);
          }
          else
            this.openSnackBarSaveError(res.status);
        });
        
      } else {
        await this.energyContractsService.createContract(this.contract).then((res) => {
          if (res.status == HttpStatusCode.Created) {
            this.openSnackBarSaveSuccess(res.status);
            this.clearStorage();
            this.contractSaved.emit(true);
          }
          else
            this.openSnackBarSaveError(res.status);
        });
      }
    }
  }

  async validatePeriod(): Promise<boolean> {
    let isValid: boolean = true;
    if (this.contract.startValidityPeriod && this.contract.endValidityPeriod) {
      await this.energyContractsService.getAllEnergyContracts().then((contracts) => {
        contracts.forEach((c: ContractRequest) => {
          if (c.contractId != this.contract.contractId) {
            if (moment(this.contract.startValidityPeriod).isBetween(c.startValidityPeriod, c.endValidityPeriod, 'days', '[]') || 
                moment(this.contract.endValidityPeriod).isBetween(c.startValidityPeriod, c.endValidityPeriod, 'days', '[]')) {
              isValid = false;
              return;
            }
          }
        });
      });
    }
    return isValid;
  }

  validateMetadataValues(): boolean {
    let isValid: boolean = true;
    let dataIsValid: boolean = true;
    this.contractRule.metadata.forEach((meta) => {
      switch (meta.type) {
        case ContractMetadataTypeEnum.Number:
        case ContractMetadataTypeEnum.CurrencyNumber:
        case ContractMetadataTypeEnum.PeakDemandNumber:
        case ContractMetadataTypeEnum.OffPeakDemandNumber:
        case ContractMetadataTypeEnum.PercentNumber:
        case ContractMetadataTypeEnum.EnergyFee:
        case ContractMetadataTypeEnum.EnergyFeePeak:
        case ContractMetadataTypeEnum.EnergyFeeOffPeak:
        case ContractMetadataTypeEnum.EnergyTariff:
        case ContractMetadataTypeEnum.TUSD:
        case ContractMetadataTypeEnum.PIS:
        case ContractMetadataTypeEnum.COFINS:
          dataIsValid = _isNumberValue(meta.metadataValue.replace(',', '.'));
          isValid = isValid ? dataIsValid : false;
          meta.metadataErrorMessage = !dataIsValid ? this.translate.instant('register.energyContracts.invalidNumber') : ''; 
          break;
        case ContractMetadataTypeEnum.StartPeakTime:
        case ContractMetadataTypeEnum.EndPeakTime:
          dataIsValid = moment(meta.metadataValue, "HH:mm", true).isValid();
          isValid = isValid ? dataIsValid : false;
          meta.metadataErrorMessage = !dataIsValid ? this.translate.instant('register.energyContracts.invalidTime') : '';
          break;
        case ContractMetadataTypeEnum.DateTime:
          dataIsValid = moment(meta.metadataValue, "DD/MM/YYYY HH:mm:ss", true).isValid()
          isValid = isValid ? dataIsValid : false;
          meta.metadataErrorMessage = !dataIsValid ? this.translate.instant('register.energyContracts.invalidDate') : '';
          break;
        default:
          break;
      }
    });
    return isValid;
  }

  cancelForm(): void {
    this.contractRule = {} as ContractRuleRequest;
    this.clearStorage();
    this.contractSaved.emit(false);
  }

  clearStorage(): void {
    this.storage.removeItem('editing');
    this.storage.removeItem('contractId');
    this.storage.removeItem('contractRuleId');
    this.storage.removeItem('duplicating');
  }

  setupMetadataInfo(): void {
    
    this.contractRule?.metadata.forEach((x) => {
      
      if (this.isEditing || this.isDuplicating) {
        x.metadataValue = this.contract?.contractMetadataValues.find((m) => m.contractMetadataId == x.contractMetadataId)?.contractMetadataValue!
      } else if (x.options?.length == 0) {
        x.metadataValue = x.defaultValue!;
      }

      switch (x.type) {
        case ContractMetadataTypeEnum.PeakDemandNumber:
        case ContractMetadataTypeEnum.OffPeakDemandNumber:
          x.typeUnity = this.translate.instant('indicator.energyKiloWattHour');
          x.hasUnity = true;
          break;
        case ContractMetadataTypeEnum.StartPeakTime:
        case ContractMetadataTypeEnum.EndPeakTime:
          x.hasUnity = false;
          break;
        case ContractMetadataTypeEnum.EnergyFee:
        case ContractMetadataTypeEnum.EnergyFeeOffPeak:
        case ContractMetadataTypeEnum.EnergyFeePeak:
            x.typeUnity = this.translate.instant('indicator.monetary');
            x.hasUnity = true;
            break;
        case ContractMetadataTypeEnum.EnergyTariff:
        case ContractMetadataTypeEnum.TUSD:
            x.typeUnity = this.translate.instant('indicator.spentMonetary');
            x.hasUnity = true;
            break;
        case ContractMetadataTypeEnum.PIS:
        case ContractMetadataTypeEnum.COFINS:
            x.typeUnity = '%';
            x.hasUnity = true;
            break;
        default:
          break;
      }
    });
  }

  openSnackBarSaveSuccess(status: number): void {
    let msg = this.translate.instant('register.energyContracts.contractCreated');
    switch (status) {
      case HttpStatusCode.Created:
        msg = this.translate.instant('register.energyContracts.contractCreated');
        break;
      case HttpStatusCode.Ok:
        msg = this.translate.instant('register.energyContracts.contractEdited');
        break;
      case HttpStatusCode.NoContent:
        msg = this.translate.instant('register.energyContracts.contractRemoved');
        break;
      default:
        msg = this.translate.instant('register.energyContracts.contractDuplicated');
        break;
    }

    this.snackBar.openFromComponent(CustomSnackbarSuccessComponent, {
      data: {
        message: this.translate.instant('register.energyContracts.contractCreated'),
        snackBar: this.snackBar,
      },
      horizontalPosition: 'right',
      verticalPosition: 'top',
      duration: AppConstants.TREE_SECOND_WAIT,
    });
  }

  openSnackBarSaveError(status: number): void {
    let msg = this.translate.instant('register.energyContracts.contractSaveErro');
    switch (status) {
      case HttpStatusCode.BadRequest:
      case HttpStatusCode.UnprocessableEntity:
      case HttpStatusCode.NotFound:
        msg = this.translate.instant('register.energyContracts.contractSaveErro');
        break;
      default:
        msg = this.translate.instant('register.energyContracts.contractRemoveError');
        break;
    }

    this.openSnackBarError(msg);
  }

  openSnackBarError(msg: string): void {
    this.snackBar.openFromComponent(CustomSnackbarErrorComponent, {
      data: {
        message: msg,
        snackBar: this.snackBar,
      },
      horizontalPosition: 'right',
      verticalPosition: 'top',
      duration: AppConstants.TREE_SECOND_WAIT,
    });
  }

}
