import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DialogYesOrNoComponent,
  DialogYesOrNoSelected,
} from 'src/app/shared/components/dialog-yes-or-no/dialog-yes-or-no.component';
import { DashboardConfigurationDialog } from './dashboard-configuration-modal/dashboard-configuration-dialog.component';
import { DashboardService } from 'src/app/core/services/dashboards/dashboard.service';
import { getCardIconValue } from 'src/app/core/utils/card-icon.util';
import { DashboardConfigurationGraph } from './dashboard-configuration-graph-modal/dashboard-configuration-graph.component';
import { CustomSnackbarSuccessComponent } from 'src/app/shared/components/custom-snackbar-success/custom-snackbar-success.component';
import { CustomSnackbarErrorComponent } from 'src/app/shared/components/custom-snackbar-error/custom-snackbar-error.component';
import { AppConstants } from 'src/app/shared/constants/app.constants';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  DashboardAnalysisPeriod,
  DashboardComparisonPeriod,
  DashboardGraph,
  DashboardModel,
  DashboardParameter,
  DashboardTimesSeries,
  getAnalysisPeriodString,
  getComparisonPeriodString,
} from 'src/app/core/models/interfaces/dashboard.model';
import {
  formatDateFromDashboard,
  getDateRangesFromDashboard,
  getPreviousYearDate,
} from 'src/app/core/utils/date.util';
import { IconNameEnum } from 'src/app/core/models/enums/icon-name.enum';
import { DashboardTimeSeriesRequest } from 'src/app/core/models/interfaces/dashboard-time-series-request.model';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { group } from '@angular/animations';
import { HttpStatusCode } from '@angular/common/http';
import { LocaleService } from 'src/app/core/services/localeServices/locale.service';

@Component({
  selector: 'app-dashboard-create',
  templateUrl: './dashboard-create.component.html',
  providers: [
    {
      provide: MAT_DATE_LOCALE,
      deps: [LocaleService],
      useFactory: (localeService: LocaleService) => localeService.getLocale()
    },
    provideMomentDateAdapter()
  ],
  styleUrls: ['./dashboard-create.component.scss'],
})
export class DashboardCreateComponent implements OnInit {
  DashboardAnalysisPeriod = DashboardAnalysisPeriod;
  DashboardComparisonPeriod = DashboardComparisonPeriod;
  icon: string = 'none';
  title: string = '';
  description: string = '';
  isEditMode: boolean = false;
  dashboardForm: FormGroup;
  graphs: DashboardGraph[] = [];
  comparePeriodSelected?: DashboardComparisonPeriod;
  analisyPeriodSelected?: DashboardAnalysisPeriod;
  dashboardAnalysisPeriodList: { value: DashboardAnalysisPeriod; label: string }[] = [];
  dashboardComparisonPeriodList: { value: DashboardComparisonPeriod; label: string }[] = [];
  analysisPeriodStartDate?: Date;
  analysisPeriodEndDate?: Date;
  comparisonPeriodStartDate?: Date;
  comparisonPeriodEndDate?: Date;
  dateAnalisyPeriodSelectedString: string = '';
  dateComparePeriodSelectedString: string = '';
  hasError: boolean = false;

  constructor(
    private router: Router,
    private translate: TranslateService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private dashboardService: DashboardService,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
  ) {
    this.dashboardForm = this.formBuilder.group({
      title: ['', [Validators.required]],
      description: ['', [Validators.required]],
      icon: ['', [Validators.required]],
      comparePeriod: ['', [Validators.required]],
      analisyPeriod: ['', [Validators.required]],
      parameters: ['', [Validators.required]],
      graphs: [[], [Validators.required]],
      analysisPeriodStartDate: ['', [Validators.required]],
      analysisPeriodEndDate: ['', [Validators.required]],
      comparisonPeriodStartDate: ['', [Validators.required]],
      comparisonPeriodEndDate: ['', [Validators.required]],
    });
  }

  async ngOnInit() {

    this.activatedRoute.params.subscribe((params) => {
      this.dashboardAnalysisPeriodList = this.getDashboardAnalysisPeriodList();
      this.dashboardComparisonPeriodList = this.getDashboardComparisonPeriodList();

      const dashboardId = params['dashboardId'];

      if (dashboardId !== null && dashboardId !== undefined) {
        this.dashboardService
          .getById(dashboardId)
          .then((response) => {
            if (response.status === HttpStatusCode.Ok) {
              const dashboard = response.data;
              this.isEditMode = true;
              this.title = dashboard.title;
              this.description = dashboard.description;
              this.icon = IconNameEnum[dashboard.iconType] ?? 'none';
              this.graphs = dashboard.graphics.map((g: DashboardGraph) => {
                return {
                  index: g.index,
                  title: g.title,
                  description: g.description,
                  data: {
                    includeForecast: 0,
                    analysisPeriod: dashboard.parameters?.analysisPeriod ?? DashboardAnalysisPeriod.Today,
                    comparisonPeriod: dashboard.parameters?.comparisonPeriod ?? DashboardComparisonPeriod.Do_Not_Compare,
                    dataGranularity: dashboard.parameters?.dataGranularity,
                    dataAggregation: dashboard.parameters?.dataAggregation,
                    analysisCustomStartDate: dashboard.parameters?.analysisPeriodStartDate,
                    analysisCustomEndDate: dashboard.parameters?.analysisPeriodEndDate,
                    comparisonStartDate: dashboard.parameters?.comparisonPeriodStartDate,
                    comparisonEndDate: dashboard.parameters?.comparisonPeriodEndDate,
                  } as DashboardTimeSeriesRequest,
                  timesSeries: g.timesSeries,
                  dataGranularity: g.dataGranularity,
                  dataAggregation: g.dataAggregation,
                } as DashboardGraph
              });

              this.dashboardForm.patchValue({
                title: dashboard.title,
                description: dashboard.description,
                comparePeriod: dashboard?.parameters?.comparisonPeriod,
                analisyPeriod: dashboard?.parameters?.analysisPeriod,
                parameters: dashboard?.parameters,
                graphs:this.graphs,
              });
              this.setComparePeriodSelected(dashboard.parameters?.comparisonPeriod ?? DashboardComparisonPeriod.Do_Not_Compare);
              this.setAnalisyPeriodSelected(dashboard.parameters?.analysisPeriod ?? DashboardAnalysisPeriod.Today);
            }
            else if (response.status === HttpStatusCode.Gone) {
              this.isEditMode = false;
            }
            else {
              this.isEditMode = false;
            }
          })
          .catch((err) => {

            this.isEditMode = false;

            const dialogRef = this.snackBar.openFromComponent(CustomSnackbarErrorComponent, {
              data: {
                message: this.translate.instant('dashboard_analisys.snackbar.not_found.message'),
                snackBar: this.snackBar,
              },
              horizontalPosition: 'right',
              verticalPosition: 'top',
              duration: AppConstants.TREE_SECOND_WAIT,
            });

            dialogRef.afterDismissed().subscribe((result) => {
              this.router.navigate(['analysis/dashboards']);
            });
          });
      } else {
        this.isEditMode = true;
      }
    });
  }

  getHeaderTitle(): string {
    if (this.title == null || this.title === '') {
      return this.translate.instant('dashboard_analisys.metadata.title_modal');
    } else {
      return this.title;
    }
  }

  getHeaderDescription(): string {
    if (this.description == null || this.description === '') {
      return this.translate.instant('dashboard_analisys.metadata.description_modal');
    } else {
      return this.description;
    }
  }

  setDateAnalisyPeriodSelectedLabel(startDate: any, endDate: any) {
    if (startDate !== null && endDate !== null) {
      this.dateAnalisyPeriodSelectedString = `${formatDateFromDashboard(
        startDate,
      )} - ${formatDateFromDashboard(endDate)}`;
    } else if (startDate !== null && endDate === null) {
      this.dateAnalisyPeriodSelectedString = `${formatDateFromDashboard(startDate)}`;
    } else {
      this.dateAnalisyPeriodSelectedString = '';
    }
  }

  setDateComparePeriodSelectedLabel(startDate: any, endDate: any) {
    if (startDate !== null && endDate !== null) {
      this.dateComparePeriodSelectedString = `${formatDateFromDashboard(
        startDate,
      )} - ${formatDateFromDashboard(endDate)}`;
    } else if (startDate !== null && endDate === null) {
      this.dateComparePeriodSelectedString = `${formatDateFromDashboard(startDate)}`;
    } else {
      this.dateComparePeriodSelectedString = '';
    }
  }

  filledComparePeriodSelected(): boolean {
    return (
      this.comparePeriodSelected !== DashboardComparisonPeriod.Do_Not_Compare &&
      this.comparePeriodSelected !== null &&
      this.comparePeriodSelected !== undefined &&
      this.dateAnalisyPeriodSelectedString !== '' &&
      this.dateAnalisyPeriodSelectedString !== null
    );
  }

  filledAnalisyPeriodSelected(): boolean {
    return this.analisyPeriodSelected !== null
      && this.analisyPeriodSelected !== undefined
      && this.dateComparePeriodSelectedString !== null
      && this.dateComparePeriodSelectedString !== '';
  }

  getComparePeriodSelected(): string {
    const label = getComparisonPeriodString(this.comparePeriodSelected!);
    return this.translate.instant(label);
  }

  getAnalisyPeriodSelected(): string {
    const label = getAnalysisPeriodString(this.analisyPeriodSelected!);
    return this.translate.instant(label);
  }

  setComparePeriodSelected(value: DashboardComparisonPeriod): void {
    this.comparePeriodSelected = value;
  }

  setAnalisyPeriodSelected(value: DashboardAnalysisPeriod): void {
    this.analisyPeriodSelected = value;
  }

  setParameters() {
    const listDate = getDateRangesFromDashboard();
    let analisyPeriodSelectedStartDate: Date | null;
    let analisyPeriodSelectedEndDate: Date | null;
    let comparisonPeriodSelectedStartDate: Date | null;
    let comparisonPeriodSelectedEndDate: Date | null;

    // Definir Primeiro Parâmetro
    if (this.analisyPeriodSelected == DashboardAnalysisPeriod.Custom_Period) {
      analisyPeriodSelectedStartDate = this.dashboardForm.get('analysisPeriodStartDate')?.value;
      analisyPeriodSelectedEndDate = this.dashboardForm.get('analysisPeriodEndDate')?.value;
    }
    else {
      const tmp = listDate.find((x) => x.label === this.analisyPeriodSelected!);

      analisyPeriodSelectedStartDate = tmp?.startDate ?? null;
      if (this.analisyPeriodSelected == DashboardAnalysisPeriod.Today){
        analisyPeriodSelectedEndDate = null;
      } else {
        analisyPeriodSelectedEndDate = tmp?.endDate ?? null;
      }

      this.dashboardForm.patchValue({
        analysisPeriodStartDate: analisyPeriodSelectedStartDate,
        analysisPeriodEndDate: analisyPeriodSelectedEndDate,
      });
    }

    // Definir Segundo Parâmetro
    if (this.comparePeriodSelected == DashboardComparisonPeriod.Same_Period_Year_Previous_Year) {
      comparisonPeriodSelectedStartDate = getPreviousYearDate(analisyPeriodSelectedStartDate!);

      if (analisyPeriodSelectedEndDate !== null) {
        comparisonPeriodSelectedEndDate = getPreviousYearDate(analisyPeriodSelectedEndDate!);
      } else {
        comparisonPeriodSelectedEndDate = null;
      }

      this.dashboardForm.patchValue({
        comparisonPeriodStartDate: comparisonPeriodSelectedStartDate,
        comparisonPeriodEndDate: comparisonPeriodSelectedEndDate,
      });
    } else if (this.comparePeriodSelected == DashboardComparisonPeriod.Custom_Period) {
      comparisonPeriodSelectedStartDate = this.dashboardForm.get('comparisonPeriodStartDate')?.value ?? null;
      comparisonPeriodSelectedEndDate = this.dashboardForm.get('comparisonPeriodEndDate')?.value ?? null;

      this.dashboardForm.patchValue({
        comparisonPeriodStartDate: comparisonPeriodSelectedStartDate,
        comparisonPeriodEndDate: comparisonPeriodSelectedEndDate,
      });
    } else {
      comparisonPeriodSelectedStartDate = null;
      comparisonPeriodSelectedEndDate = null;

      this.dashboardForm.patchValue({
        comparisonPeriodStartDate: comparisonPeriodSelectedStartDate,
        comparisonPeriodEndDate: comparisonPeriodSelectedEndDate,
      });
    }

    this.setDateAnalisyPeriodSelectedLabel(
      analisyPeriodSelectedStartDate,
      analisyPeriodSelectedEndDate
    );

    this.setDateComparePeriodSelectedLabel(
      comparisonPeriodSelectedStartDate,
      comparisonPeriodSelectedEndDate,
    );

    this.updateGraphs();
  }

  updateGraphs() {

    const changedGraphs: DashboardGraph[] = [];

    this.graphs.forEach((graph) => {
      const params = {
        includeForecast: 1,
        analysisPeriod: this.analisyPeriodSelected!,
        comparisonPeriod: this.comparePeriodSelected!,
        analysisCustomStartDate: this.dashboardForm.get('analysisPeriodStartDate')?.value ?? null,
        analysisCustomEndDate: this.dashboardForm.get('analysisPeriodEndDate')?.value ?? null,
        comparisonStartDate: this.dashboardForm.get('comparisonPeriodStartDate')?.value ?? null,
        comparisonEndDate: this.dashboardForm.get('comparisonPeriodEndDate')?.value ?? null,
      } as DashboardTimeSeriesRequest;
      graph.data = params;
      changedGraphs.push(graph);
    });

    this.graphs = changedGraphs;
    this.cdr.detectChanges();
  }

  save() {
    const newDashboard = {} as DashboardModel;

    newDashboard.iconType = getCardIconValue(this.icon);
    newDashboard.title = this.title;
    newDashboard.description = this.description;
    newDashboard.graphics = this.graphs;
    newDashboard.parameters = {
      analysisPeriod: this.analisyPeriodSelected!,
      comparisonPeriod: this.comparePeriodSelected!,
      analysisPeriodStartDate: this.dashboardForm.get('analysisPeriodStartDate')?.value ?? null,
      analysisPeriodEndDate: this.dashboardForm.get('analysisPeriodEndDate')?.value ?? null,
      comparisonPeriodStartDate: this.dashboardForm.get('comparisonPeriodStartDate')?.value ?? null,
      comparisonPeriodEndDate: this.dashboardForm.get('comparisonPeriodEndDate')?.value ?? null,
    } as DashboardParameter;

    const result = this.dashboardService.saveDashboard(newDashboard);
    if (result !== undefined) {
      const snackBarRef: MatSnackBarRef<CustomSnackbarSuccessComponent> = this.snackBar.openFromComponent(
        CustomSnackbarSuccessComponent,
        {
          data: {
            message: this.translate.instant('dashboard_analisys.snackbar.save.message'),
            snackBar: this.snackBar,
          },
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: AppConstants.TREE_SECOND_WAIT,
        },
      );

      snackBarRef.afterDismissed().subscribe(() => {
        this.router.navigate(['analysis/dashboards']);
      });
    } else {
      this.snackBar.openFromComponent(CustomSnackbarErrorComponent, {
        data: {
          message: this.translate.instant('dashboard_analisys.snackbar.save.error_message'),
          snackBar: this.snackBar,
        },
        horizontalPosition: 'right',
        verticalPosition: 'top',
        duration: AppConstants.TREE_SECOND_WAIT,
      });
    }
  }

  openConfigurationGraphDialog() {
    const dialogRef = this.dialog.open(DashboardConfigurationGraph, {
      width: '660px',
      height: '700px',
      data: {
        id: 1 + this.graphs.length,
        title: '',
        description: '',
        timesSeries: undefined,
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        const params = {
          includeForecast: 0,
          analysisPeriod: this.analisyPeriodSelected!,
          comparisonPeriod: this.comparePeriodSelected!,
          dataGranularity: result.dataGranularity,
          dataAggregation: result.dataAggregation,
          analysisCustomStartDate: this.dashboardForm.get('analysisPeriodStartDate')?.value ?? null,
          analysisCustomEndDate: this.dashboardForm.get('analysisPeriodEndDate')?.value ?? null,
          comparisonStartDate: this.dashboardForm.get('comparisonPeriodStartDate')?.value ?? null,
          comparisonEndDate: this.dashboardForm.get('comparisonPeriodEndDate')?.value ?? null,
        } as DashboardTimeSeriesRequest;

        this.graphs.push({
          index: 1 + this.graphs.length,
          title: result.title,
          description: result.description,
          data: params,
          timesSeries: {
            ...result.timesSeries,
          } as DashboardTimesSeries,
          dataGranularity: result.dataGranularity,
          dataAggregation: result.dataAggregation,
        } as DashboardGraph);

        this.dashboardForm.patchValue({
          title: result.title,
          description: result.description,
          icon: result.icon,
        });
      }
    });
  }

  openConfigurationDialog() {
    const dialogRef = this.dialog.open(DashboardConfigurationDialog, {
      width: '576px',
      data: {
        title: this.title,
        description: this.description,
        icon: this.icon,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.icon = result.icon;
        this.title = result.title;
        this.description = result.description;
      }
    });
  }

  deleteGraph(graphId: number) {
    this.graphs = this.graphs.filter((graph) => graph.index !== graphId);
    this.snackBar.openFromComponent(CustomSnackbarSuccessComponent, {
      data: {
        message: this.translate.instant('dashboard_analisys.snackbar.delete_graphic.message'),
        snackBar: this.snackBar,
      },
      horizontalPosition: 'right',
      verticalPosition: 'top',
      duration: AppConstants.TREE_SECOND_WAIT,
    });
  }

  editGraph(graphId: number) {
    const selected = this.graphs.find((x) => x.index == graphId);
    if (selected) {
      const dialogRef = this.dialog.open(DashboardConfigurationGraph, {
        width: '660px',
        height: '700px',
        data: {
          id: selected.index,
          title: selected.title,
          description: selected.description,
          timesSeries: selected.timesSeries,
          dataAggregation: selected.dataAggregation,
          dataGranularity: selected.dataGranularity,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.graphs = this.graphs.filter((graph) => graph.index !== graphId);

          const params = {
            includeForecast: 0,
            analysisPeriod: this.comparePeriodSelected!,
            comparisonPeriod: this.analisyPeriodSelected!,
            dataGranularity: result.dataGranularity,
            dataAggregation: result.dataAggregation,
            analysisCustomStartDate: this.dashboardForm.get('analysisPeriodStartDate')?.value ?? null,
            analysisCustomEndDate: this.dashboardForm.get('analysisPeriodEndDate')?.value ?? null,
            comparisonStartDate: this.dashboardForm.get('comparisonPeriodStartDate')?.value ?? null,
            comparisonEndDate: this.dashboardForm.get('comparisonPeriodEndDate')?.value ?? null,
          } as DashboardTimeSeriesRequest;

          this.graphs.push({
            index: graphId,
            title: result.title,
            description: result.description,
            data: params,
            timesSeries: {
              ...result.timesSeries,
            } as DashboardTimesSeries,
            dataGranularity: result.dataGranularity,
            dataAggregation: result.dataAggregation,
          } as DashboardGraph);

          this.dashboardForm.patchValue({
            title: result.title,
            description: result.description,
            icon: result.icon,
          });

          this.graphs = this.graphs.map((graph) =>
            graph.index === result.index ? { ...graph, ...result } : graph,
          );

          this.snackBar.openFromComponent(CustomSnackbarSuccessComponent, {
            data: {
              message: this.translate.instant('dashboard_analisys.snackbar.edit.message'),
              snackBar: this.snackBar,
            },
            horizontalPosition: 'right',
            verticalPosition: 'top',
            duration: AppConstants.TREE_SECOND_WAIT,
          });
        } else {
          this.snackBar.openFromComponent(CustomSnackbarErrorComponent, {
            data: {
              message: this.translate.instant('dashboard_analisys.snackbar.edit.error_message'),
              snackBar: this.snackBar,
            },
            horizontalPosition: 'right',
            verticalPosition: 'top',
            duration: AppConstants.TREE_SECOND_WAIT,
          });
        }
      });
    } else {
      this.snackBar.openFromComponent(CustomSnackbarErrorComponent, {
        data: {
          message: this.translate.instant('dashboard_analisys.snackbar.edit.error_message'),
          snackBar: this.snackBar,
        },
        horizontalPosition: 'right',
        verticalPosition: 'top',
        duration: AppConstants.TREE_SECOND_WAIT,
      });
    }
  }

  isCardMaxHeight(): boolean {
    return (
      this.analisyPeriodSelected === DashboardAnalysisPeriod.Custom_Period ||
      this.comparePeriodSelected === DashboardComparisonPeriod.Custom_Period
    );
  }

  cancelEdit() {
    const dialogRef = this.dialog.open(DialogYesOrNoComponent, {
      width: '420px',
      data: {
        title: this.translate.instant('dashboard_analisys.snackbar.cancel.title'),
        description: this.translate.instant('dashboard_analisys.snackbar.cancel.description'),
        labelOkButton: this.translate.instant('dashboard_analisys.snackbar.cancel.label_ok_button'),
        labelNoButton: this.translate.instant('dashboard_analisys.snackbar.cancel.label_no_button'),
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === DialogYesOrNoSelected.NO) {
        dialogRef.close();
        this.router.navigate(['analysis/dashboards']);
      }
      dialogRef.close();
    });
  }

  comparePeriodSelectedIsCustomPeriod() {
    return this.comparePeriodSelected === DashboardComparisonPeriod.Custom_Period;
  }

  comparePeriodSelectedSamePeriodYearPreviousYear() {
    return this.comparePeriodSelected === DashboardComparisonPeriod.Same_Period_Year_Previous_Year;
  }

  isAnalisyPeriodSelected() {
    return this.analisyPeriodSelected === DashboardAnalysisPeriod.Custom_Period;
  }

  private getDashboardAnalysisPeriodList() {
    const data = [
      { value: DashboardAnalysisPeriod.Today, label: getAnalysisPeriodString(DashboardAnalysisPeriod.Today) },
      {
        value: DashboardAnalysisPeriod.Last_7_Days,
        label: getAnalysisPeriodString(DashboardAnalysisPeriod.Last_7_Days),
      },
      {
        value: DashboardAnalysisPeriod.Last_15_Days,
        label: getAnalysisPeriodString(DashboardAnalysisPeriod.Last_15_Days),
      },
      {
        value: DashboardAnalysisPeriod.Last_30_Days,
        label: getAnalysisPeriodString(DashboardAnalysisPeriod.Last_30_Days),
      },
      {
        value: DashboardAnalysisPeriod.Last_Week,
        label: getAnalysisPeriodString(DashboardAnalysisPeriod.Last_Week),
      },
      {
        value: DashboardAnalysisPeriod.Last_Month,
        label: getAnalysisPeriodString(DashboardAnalysisPeriod.Last_Month),
      },
      {
        value: DashboardAnalysisPeriod.Last_Quarter,
        label: getAnalysisPeriodString(DashboardAnalysisPeriod.Last_Quarter),
      },
      {
        value: DashboardAnalysisPeriod.Custom_Period,
        label: getAnalysisPeriodString(DashboardAnalysisPeriod.Custom_Period),
      },
    ];

    return data;
  }

  private getDashboardComparisonPeriodList() {
    const data = [
      {
        value: DashboardComparisonPeriod.Do_Not_Compare,
        label: getComparisonPeriodString(DashboardComparisonPeriod.Do_Not_Compare),
      },
      {
        value: DashboardComparisonPeriod.Custom_Period,
        label: getComparisonPeriodString(DashboardComparisonPeriod.Custom_Period),
      },
      {
        value: DashboardComparisonPeriod.Same_Period_Year_Previous_Year,
        label: getComparisonPeriodString(DashboardComparisonPeriod.Same_Period_Year_Previous_Year),
      },
    ];
    return data;
  }
}
