import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AxiosError } from 'axios';
import { LoadingService } from '../loading.service';
import { AccessActiveAccountViewModel } from '../../../../app/core/models/interfaces/access-active-account.model';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-retrieve-account',
  templateUrl: './retrieve-account.component.html',
  styleUrls: ['./retrieve-account.component.scss'],
})
export class RetrieveAccountComponent implements OnInit {
  enableSubmitButton: boolean = false;
  navigateTo: string | undefined;
  access: AccessActiveAccountViewModel = { email: '', password: '', passwordConfirmation: '', token: '' };
  retrieveForm: FormGroup;
  erroChangePassword: boolean;
  erroTokenInvalid: boolean;
  activedAccount: boolean;
  hide: boolean;
  tokenRecovery: string;
  minLenght: boolean = false;
  uppercaseLetter: boolean = false;
  lowercaseLetter: boolean = false;
  specialCaracter: boolean = false;
  showCondition: boolean = false;

  constructor(
    private translate: TranslateService,
    private matIconRegistry: MatIconRegistry,
    private authService: AuthService,
    private domSanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private loadingService: LoadingService,
  ) {
    this.translate.use('pt');

    this.retrieveForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      passwordConfirmation: ['', Validators.required],
    });

    this.erroTokenInvalid = false;
    this.erroChangePassword = false;
    this.activedAccount = false;
    this.hide = true;
    this.tokenRecovery = '';

    this.matIconRegistry.addSvgIcon(
      'eye',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/eye.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'eye-slash',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/eye-slash.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'error',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/error.svg'),
    );
  }

  ngOnInit(): void {
    this.navigateTo = this.activatedRoute.snapshot.queryParams['to'] || '/';

    this.retrieveForm.valueChanges.subscribe(() => {
      this.fillData();
      this.enableSubmitButton = this.retrieveForm.valid;
    });

    this.activatedRoute.params.subscribe((params) => {
      this.access.token = params['token'];
    });
  }

  async activeAccount() {
    if (this.retrieveForm.valid) {
      this.loadingService.setLoadingStatus(true);
      try {
        const result = await this.authService.retrieveAccount(this.access);

        if (!result) {
          throw 'Erro login.';
        }

        this.erroTokenInvalid = this.erroChangePassword = false;
        this.activedAccount = true;

        return result;
      } catch (error: AxiosError | any) {
        this.erroChangePassword = true;
        this.inputValidate(true);
        return undefined;
      } finally {
        this.loadingService.setLoadingStatus(false);
      }
    }

    return undefined;
  }

  validateForm(): boolean {
    this.enableSubmitButton =
      this.access.password !== '' &&
      this.access.password !== null &&
      this.access.passwordConfirmation !== '' &&
      this.access.passwordConfirmation !== null;
    return this.enableSubmitButton;
  }

  inputValidate(flag: boolean) {
    this.retrieveForm.controls['passwordConfirmation'].setErrors({ incorrect: flag });
    this.retrieveForm.controls['password'].setErrors({ incorrect: flag });
  }

  fillData() {
    this.access.email = this.retrieveForm?.get('email')?.value ?? '';
    this.access.password = this.retrieveForm?.get('password')?.value ?? '';
    this.access.passwordConfirmation = this.retrieveForm?.get('passwordConfirmation')?.value ?? '';
  }

  validatePassword() {
    this.showCondition = this.access.password.length > 0 || this.access.passwordConfirmation.length > 0;

    this.minLenght = this.access.password.length >= 10;
    this.uppercaseLetter = /[A-Z]/.test(this.access.password);
    this.lowercaseLetter = /[a-z]/.test(this.access.password);
    this.specialCaracter = /[$&+,:;=?@#|'<>.^*()%!-]/.test(this.access.password);

    this.enableSubmitButton =
      this.minLenght &&
      this.uppercaseLetter &&
      this.lowercaseLetter &&
      this.specialCaracter &&
      this.validateIfPasswordIsEqual();
  }

  validateIfPasswordIsEqual(): boolean {
    return (
      (this.access.password !== '' || this.access.password !== null) &&
      this.access.password === this.access.passwordConfirmation
    );
  }

  showLoading() {
    this.loadingService.showLoading();
  }

  hideLoading() {
    this.loadingService.hideLoading();
  }
}
