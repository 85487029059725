<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.4.1/dist/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous">

<div class="resource__header" *ngIf="editing == false">
    <div class="resource__header-info">
        <h2>{{ "register.resources.newResource.title" | translate }}</h2>
        <p>{{ "register.resources.newResource.info" | translate }}</p>
    </div>
</div>

<div class="resource__header" *ngIf="editing == true">
    <div class="resource__header-info">
        <h2>{{ "register.resources.newResource.editTitle" | translate }}</h2>
        <p>{{ "register.resources.newResource.editInfo" | translate }}</p>
    </div>
</div>

<div class="forms">
    <div class="forms-header">
        <label>{{ "register.resources.newResource.formsHeaderInfo" | translate }}</label>
    </div>
    <form #resourceForm="ngForm">
        <div class="form-row">
            <div class="form-group forms-form">
                <mat-panel-description>
                <div>
                    <label for="name">
                        {{ "register.resources.newResource.name" | translate }}
                    </label>
                    <img 
                        src="assets/icons/circle-info.svg" 
                        alt="Icon Info"
                        matTooltip="{{nameTip}}"
                        matTooltipPosition="above"
                        matTooltipClass="indicator-tooltip"
                        >
                </div>
                </mat-panel-description>
                <input 
                    [(ngModel)]="form.name" 
                    #name="ngModel" 
                    name="name" 
                    type="text" 
                    class="input-field" 
                    placeholder="{{insertResourceName}}" 
                    required 
                    maxlength="27" 
                    [class.invalid]="name.invalid && (name.dirty || name.touched)"
                    >
            </div>
            <div class="form-group forms-form">
                <label for="type">{{ "register.resources.newResource.type" | translate }}</label>
                <mat-form-field class="card-module-select" appearance="outline" [class.editing]="editing">
                    <mat-select [(ngModel)]="form.type" name="type" placeholder="{{select}}" required [disabled]="editing">
                        <mat-option *ngFor="let type of typesForm" [value]="type.type" (click)="selectingType(type.type)">
                            <p>{{ type.title }}</p>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="form-group forms-form" *ngIf="selectedType !== 3">
                <label for="subType">{{ "register.resources.newResource.subType" | translate }}</label>
                <mat-form-field class="card-module-select" appearance="outline" [class.editing]="editing">
                    <mat-select [(ngModel)]="form.subtype" name="subType" placeholder="{{select}}" required [disabled]="editing">
                        <mat-select-trigger> {{selectedSubType}} </mat-select-trigger>
                        <mat-option *ngFor="let subtype of subTypes" [value]="subtype.type" class="options" (click)="subTypeSelected(subtype.title)">
                            <p class="title">{{ subtype.title }}</p>
                            <span class="description">{{ subtype.description }}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <p class="forms-line"></p>

        <div class="forms-header">
            <label> {{ "register.resources.newResource.dataSource" | translate }} </label>
        </div>
        <div class="form-row">
            <div class="form-group forms-form">
                <label for="connection"> {{ "register.resources.newResource.dataSource" | translate }} </label>
                <mat-form-field class="card-module-select" appearance="outline" [class.invalid]="!checkConnectionFormValid" [class.editing]="editing">
                    <mat-select [(ngModel)]="connectionForm" name="connection" placeholder="{{select}}" required [disabled]="editing">
                        <mat-option *ngFor="let type of connectionBuilder" [value]="type" (click)="selectingConnection()">
                            <p>{{ type.name }}</p>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="form-group forms-form" *ngFor="let recipient of connectionFormTags | keyvalue; trackBy: trackByFn">
                <label class="tag-label" for="tag">{{recipient.key}}</label>
                <input 
                    [ngModel]="recipient.value" 
                    (ngModelChange)="connectionFormTags.set(recipient.key, $event)" 
                    name="tag" 
                    type="number"
                    class="input-field" 
                    placeholder="{{value}}" 
                    (keyup)="tagsConstructor()" 
                    required
                    [class.invalid]="!checkConnectionFormTagsValid"
                    [class.editing]="editing"
                    [disabled]="editing"
                    >
            </div>
        </div>

        <p class="forms-line"></p>

        <app-storage-form *ngIf="selectedType == 3" (formStorage)="recievingFormFromChild($event)"></app-storage-form>
        <app-load-form *ngIf="selectedType == 0" (formLoad)="recievingFormFromChild($event)"></app-load-form>
        <app-generation-form *ngIf="selectedType == 1" (formGeneration)="recievingFormFromChild($event)"></app-generation-form>

        <div class="forms-header">
            <label>{{ "register.resources.newResource.othersEspecifications" | translate }}</label>
            <img 
                src="assets/icons/circle-info.svg" 
                alt="Icon Info"
                matTooltip="{{othersTip}}"
                matTooltipPosition="above"
                matTooltipClass="indicator-tooltip"
                >
        </div>

        <form [formGroup]="attributesForm">
            <div class="form-row">
                <div class="form-group forms-form">
                    <p class="label" for="resourceName">{{ "register.resources.newResource.attributeName" | translate }}</p>
                    <input 
                        [(ngModel)]="otherAttributesForm.name" 
                        [ngModelOptions]="{standalone: true}" 
                        class="input-field" 
                        placeholder="{{insertAttributeName}}" 
                        (keyup)="builderOttherAttributeObject()"
                        >
                </div>
                <div class="form-group forms-form">
                    <p class="label" for="unity">{{ "register.resources.newResource.unity" | translate }}</p>
                    <input 
                        [(ngModel)]="otherAttributesForm.unity" 
                        [ngModelOptions]="{standalone: true}" 
                        class="input-field" 
                        placeholder="{{value}}" 
                        (keyup)="builderOttherAttributeObject()"
                        >
                </div>
                <div class="form-group forms-form">
                    <p class="label" for="value">{{ "register.resources.newResource.value" | translate }}</p>
                    <input 
                        [(ngModel)]="otherAttributesForm.value" 
                        [ngModelOptions]="{standalone: true}" 
                        class="input-field" 
                        type="number"
                        placeholder="{{value}}" 
                        (keyup)="builderOttherAttributeObject()"
                        >
                </div>
            </div>

            <div formArrayName="otherAttributes">
                <div *ngFor="let user of otherAttributes().controls; let i = index" 
                    [formGroupName]="i"
                    style="margin-top: 24px;"
                    class="form-row">
                    <div class="form-group forms-form">
                        <p class="label" for="name">{{ "register.resources.newResource.attributeName" | translate }}</p>
                        <input 
                            formControlName="name" 
                            class="input-field" 
                            placeholder="{{insertAttributeName}}" 
                            (keyup)="builderOttherAttributeObject()"
                            >
                    </div>
                    <div class="form-group forms-form">
                        <p class="label" for="unity">{{ "register.resources.newResource.unity" | translate }}</p>
                        <input 
                            formControlName="unity" 
                            class="input-field" 
                            placeholder="{{value}}" 
                            (keyup)="builderOttherAttributeObject()"
                            >
                    </div>
                    <div class="form-group forms-form">
                        <p class="label" for="value">{{ "register.resources.newResource.value" | translate }}</p>
                        <input 
                            formControlName="value" 
                            class="input-field" 
                            placeholder="{{value}}" 
                            type="number"
                            (keyup)="builderOttherAttributeObject()"
                            >
                    </div>
                    <div>
                        <button class="forms-form-remove" (click)="removeOthersAttributes(i)">
                            <i class="fa-regular fa-trash"></i>
                        </button>
                    </div>
                </div>
            </div>
            <button class="forms-form-add" (click)="addOtherAttributes()" style="margin-top: 24px;"> 
                {{ "register.resources.newResource.buttonAddAttribute" | translate }} 
            </button>
        </form>

        <p class="forms-line"></p>

        <div class="resource-buttons">
            <button class="resource-buttons-cancel"
                (click)="cancelForm()"
                >
                {{ "register.resources.newResource.buttonCancel" | translate }}
            </button>
            <button class="resource-buttons-save" 
                [disabled]="(!checkConnectionFormValid) || (!checkConnectionFormTagsValid) || (!resourceForm.form.valid) || (!typeFormValid)"
                (click)="saveForm(resourceForm.form.valid)"
                >
                {{ "register.resources.newResource.buttonSave" | translate }}
            </button>
        </div>
    </form>
</div>
