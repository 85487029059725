import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tag-icon',
  templateUrl: './tag-icon.component.html',
  styleUrls: ['./tag-icon.component.scss'],
})
export class TagIconComponent {
  @Input() type = 'default';
  @Input() icon = 'info';
  @Input() size = 20;

  get selectIcon() {
    switch (this.icon) {
      case 'upload':
        return 'fa-cloud-arrow-up';
      case 'change':
        return 'fa-arrows-repeat';
      case 'play-pause':
        return 'fa-play-pause';
      case 'success':
        return 'fa-circle-check';
      case 'info':
        return 'fa-circle-question';
      case 'warning':
        return 'fa-triangle-exclamation';
      case 'error':
        return 'fa-circle-exclamation';
      case 'error-warning':
        return 'fa-circle-exclamation';
      case 'notification':
        return 'fa-bell';
      case 'wifi':
        return 'fa-wifi';
      default:
        return 'fa-circle-question';
    }
  }
}
