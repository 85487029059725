<div class="constantValue__header" *ngIf="!editing">
    <div class="constantValue__header-info">
        <h2>{{ "analysisTimeSeries.constantValue.title" | translate }}</h2>
        <p>{{ "analysisTimeSeries.constantValue.info" | translate }}</p>
    </div>
</div>
<div class="constantValue__header" *ngIf="editing">
    <div class="constantValue__header-info">
        <h2>{{ "analysisTimeSeries.constantValue.titleEditing" | translate }}</h2>
        <p>{{ "analysisTimeSeries.constantValue.infoEditing" | translate }}</p>
    </div>
</div>

<div class="forms">
    <div class="forms__header">
        <p>{{ "analysisTimeSeries.constantValue.formsHeaderInfo" | translate }}</p>
    </div>
    <form #constForm="ngForm">
        <div class="form-row">
            <div class="form-group form-name">
                <div class="section">
                    <div class="row">
                        <label for="name" class="info">
                            {{ "analysisTimeSeries.constantValue.stName" | translate }}
                        </label>
                        <img 
                            src="assets/icons/circle-info.svg" 
                            alt="Icon Info"
                            matTooltip="{{nameTip}}"
                            matTooltipPosition="above"
                            matTooltipClass="indicator-tooltip"
                            >
                    </div>
                    <input 
                        [(ngModel)]="form.name" 
                        #name="ngModel" 
                        name="name" 
                        type="text" 
                        class="input-field large-input form-control" 
                        placeholder="{{insertName}}" 
                        required 
                        maxlength="26" 
                        [class.invalid]="(name.invalid && (name.dirty || name.touched)) || nameInvalid"
                        (keyup)="attName()"
                        >
                    <mat-hint class="hint" *ngIf="!nameInvalid">{{ "analysisTimeSeries.constantValue.nameHint" | translate }}</mat-hint>
                    <mat-hint class="hintInvalid" *ngIf="nameInvalid">{{ "analysisTimeSeries.constantValue.nameHintInvalid" | translate }}</mat-hint>
                </div>
            </div>
            <div class="form-group forms-form">
                <div class="row">
                    <label for="dataGrouping" class="info">
                        {{ "analysisTimeSeries.dataUpload.dataAgregation" | translate }}
                    </label>
                    <img 
                        src="assets/icons/circle-info.svg" 
                        alt="Icon Info"
                        matTooltip="{{dataAgregationTip}}"
                        matTooltipPosition="above"
                        matTooltipClass="indicator-tooltip"
                        >
                </div>
                
                <mat-form-field class="card-module-select" appearance="outline" [class.editing-select]="editing">
                    <mat-select [(ngModel)]="form.dataGrouping" name="dataGrouping" placeholder="{{select}}" required [disabled]="editing">
                        <mat-select-trigger> {{selectedDataGrouping}} </mat-select-trigger>
                        <mat-option *ngFor="let data of dataGrouping" [value]="data.type" class="options" (click)="dataGroupingSelected(data.title)">
                            <p class="title">{{ data.title }}</p>
                            <span class="description">{{ data.desc }}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group forms-form">
                <div class="row" style="padding-bottom: 10px;">
                    <label for="unit" class="info">
                        {{ "analysisTimeSeries.constantValue.mesureUnity" | translate }}
                    </label>
                    <img 
                        src="assets/icons/circle-info.svg" 
                        alt="Icon Info"
                        matTooltip="{{unityTip}}"
                        matTooltipPosition="above"
                        matTooltipClass="indicator-tooltip"
                        >
                </div>
                <input type="text"
                    placeholder="{{insertUnity}}"
                    matInput
                    [(ngModel)]="form.unit"
                    [matAutocomplete]="auto"
                    [class.creating]="!editing"
                    [class.editing]="editing"
                    required 
                    name="unit"
                    #unit="ngModel"
                    [class.invalid]="unit.invalid && (unit.dirty || unit.touched)"
                    [disabled]="editing"
                    >
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                        {{option}}
                    </mat-option>
                </mat-autocomplete>
                
            </div>
            <div class="form-group forms-form">
                <div class="row">
                    <label for="value" class="info">
                        {{ "analysisTimeSeries.constantValue.stValue" | translate }}
                    </label>
                    <img 
                        *ngIf="editing"
                        src="assets/icons/circle-info.svg" 
                        alt="Icon Info"
                        matTooltip="{{valueTip}}"
                        matTooltipPosition="above"
                        matTooltipClass="indicator-tooltip"
                        >
                </div>
                <input 
                    [(ngModel)]="form.value" 
                    [class.creating]="!editing"
                    [class.editing]="editing"
                    placeholder="{{stValue}}" 
                    type="number"
                    required 
                    name="value"
                    #value="ngModel"
                    [class.invalid]="value.invalid && (value.dirty || value.touched)"
                    >
            </div>
            <div class="form-group forms-form">
                <label for="startOfValidity" class="info">
                    {{ "analysisTimeSeries.constantValue.validityStart" | translate }}
                </label>
                <div [class.date-landing]="!editing" [class.date-landing-editing]="editing">
                    <mat-form-field appearance="outline">
                        <input 
                            matInput 
                            [(ngModel)]="form.startOfValidity"
                            [matDatepicker]="picker"
                            placeholder="{{insertValidity}}"
                            required 
                            class="form-control"
                            name="startOfValidity"
                            #startOfValidity="ngModel"
                            >
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <div class="form-group forms-form" *ngIf="editing == true">
                <p for="endOfValidity" class="info">
                    {{ "analysisTimeSeries.constantValue.validityEnd" | translate }}
                </p>
                <div [class.date-landing-editing]="editing">
                    <mat-form-field appearance="outline">
                        <input 
                            matInput 
                            [(ngModel)]="form.endOfValidity"
                            [matDatepicker]="picker"
                            placeholder="{{insertValidity}}"
                            class="form-control"
                            name="endOfValidity"
                            #endOfValidity="ngModel"
                            >
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </form>
    <div class="tip">
        <span>{{ "analysisTimeSeries.constantValue.tip" | translate }}</span>
        {{ "analysisTimeSeries.constantValue.tipDetail" | translate }}
    </div>
    <p class="forms-line"></p>

    <div class="const-buttons">
        <button class="const-buttons-cancel"
            (click)="cancelForm()"
            >
            {{ "analysisTimeSeries.constantValue.buttonCancel" | translate }}
        </button>
        <button *ngIf="!editing"
            class="const-buttons-save"
            [disabled]="!constForm.form.valid"
            (click)="saveForm(constForm.form.valid)"
            >
            {{ "analysisTimeSeries.constantValue.buttonSave" | translate }}
        </button>
        <button *ngIf="editing"
            class="const-buttons-save"
            [disabled]="!constForm.form.valid"
            (click)="saveFormEdit(constForm.form.valid)"
            >
            {{ "analysisTimeSeries.constantValue.buttonSave" | translate }}
        </button>
    </div>
</div>
