import { Component, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  GenerationFormRequest,
  NewModuleRequest,
} from 'src/app/core/models/request/new-module-request.model';

@Component({
  selector: 'app-generation-form',
  templateUrl: './generation-form.component.html',
  styleUrls: ['./generation-form.component.scss'],
})
export class GenerationFormComponent {
  basicForm: NewModuleRequest = {} as NewModuleRequest;
  generationForm: GenerationFormRequest = {} as GenerationFormRequest;
  insertModel = this.translate.instant('register.resources.newResource.insertModel');
  insertManufacturer = this.translate.instant('register.resources.newResource.insertManufacturer');
  insertType = this.translate.instant('register.resources.newResource.insertType');
  value = this.translate.instant('register.resources.newResource.value');
  select = this.translate.instant('register.resources.newResource.select');
  specificationsTip = this.translate.instant('register.resources.newResource.toolTip.specificationsTip');
  generationTypeTip = this.translate.instant('register.resources.newResource.toolTip.generationTypeTip');
  generationEfficiencyTip = this.translate.instant(
    'register.resources.newResource.toolTip.generationEfficiencyTip',
  );
  private storage: Storage;
  editing: boolean = false;

  @Output()
  formGeneration: EventEmitter<{ form: NewModuleRequest; valid: boolean }> = new EventEmitter();

  constructor(private translate: TranslateService) {
    this.storage = window.localStorage;
  }

  ngOnInit(): void {
    this.editing = JSON.parse(this.storage.getItem('editing')!);
    if (this.storage.getItem('form')) {
      this.basicForm = JSON.parse(this.storage.getItem('form') || '[]');
      this.generationForm = this.basicForm.generationAttributes!;
      this.storage.removeItem('form');
      this.storage.removeItem('editing');
      this.sendForm(true);
    }
  }

  sendForm(valid: boolean) {
    this.basicForm.generationAttributes = this.generationForm;
    const form: NewModuleRequest = this.basicForm;
    this.formGeneration.emit({ form, valid });
  }
}
