<div class="register-user">
    <div class="register-user__header">
        <div class="register-user__header-info" *ngIf="isListUsers">
            <h2>{{ "register.users.title" | translate }}</h2>
            <p>{{ "register.users.info" | translate }}</p>
        </div>

        <div class="register-user__header-info" *ngIf="isUserRegister">
            <h2>{{ "register.users.newUser" | translate }}</h2>
            <p>{{ "register.users.newUserText" | translate }}</p>
        </div>

        <div class="register-user__header-info" *ngIf="isUserEdit">
            <h2>{{ "register.users.editUser" | translate }}</h2>
            <p>{{ "register.users.editUserText" | translate }}</p>
        </div>


        <button class="register-user__header-btn" (click)="goToRegisterUser()" *ngIf="isListUsers">
            <i class="fa-regular fa-user"></i>
            <span>{{ "register.users.createUser" | translate }}</span>
        </button>
    </div>

    <div class="register-user__container">
        <app-resource-not-found *ngIf="users.length == 0  && !isUserRegister"
            [title]="title"
            [icon]="'error'"
            [type]="'default'"
            [message]="message"
            [resource]="resource">
        </app-resource-not-found>

        <div class="register-user__container-register" *ngIf="isUserRegister">
            <h2 class="register-user__container-register__header">{{ "register.basicInfo" | translate }}</h2>
            <form action=""  class="register-user__container-register__fields" [formGroup]="userForm">
                <div>
                    <label for="name">{{ "register.users.name" | translate }}</label>
                    <input formControlName="name" required type="text" class="input-field">
                </div>
                <div>
                    <label for="email">{{ "register.users.email" | translate }}</label>
                    <input formControlName="email" required type="text" class="input-field">
                </div>
                <div class="select">
                    <label for="type">{{ "register.users.typeUser" | translate }}</label>
                    <mat-form-field appearance="outline" class="select-field">
                        <mat-select placeholder="{{ select | translate }}" formControlName="role" required>
                            <mat-select-trigger>
                                {{ userForm.controls['role'].value | translate }}
                            </mat-select-trigger>
                            <mat-option *ngFor="let type of typeUser" [value]="type.value" class="options">
                                <p class="title">{{ type.title | translate}}</p>
                                <span class="description">{{ type.description | translate}}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </form>

            <form [formGroup]="usersForm" #formDir="ngForm">
                <div formArrayName="userFields">
                    <div *ngFor="let user of userFields().controls; let i = index"
                        [formGroupName]="i"
                        class="register-user__container-register__fields">
                        <div>
                            <label for="name">{{ "register.users.name" | translate }}</label>
                            <input type="text" id="name" class="input-field" formControlName="name" required>
                        </div>
                        <div>
                            <label for="email">{{ "register.users.email" | translate }}</label>
                            <input type="text" id="email" class="input-field" formControlName="email" required>
                        </div>
                        <div class="select">
                            <label for="type">{{ "register.users.typeUser" | translate }}</label>
                            <mat-form-field appearance="outline" class="select-field">
                                <mat-select placeholder="{{ select | translate }}" formControlName="role" required>
                                    <mat-select-trigger>
                                        {{ usersForm.value.userFields[i].role | translate }}
                                    </mat-select-trigger>
                                    <mat-option *ngFor="let type of typeUser" [value]="type.value" class="options">
                                        <p class="title">{{ type.title | translate}}</p>
                                        <span class="description">{{ type.description | translate}}</span>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div>
                            <button class="register-user__container-register__fields-remove" (click)="removeUserField(i)">
                                <i class="fa-regular fa-trash"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <button class="register-user__container-register__fields-add" (click)="addUserField()">{{ "register.users.addUser" | translate }}</button>
            </form>

            <hr>

            <div class="register-user__container-register__btns">
                <button class="register-user__container-register__btns-cancel" (click)="goToListUsers()">{{ "modal.cancel" | translate }}</button>
                <button
                    class="register-user__container-register__btns-save"
                    [disabled]="!generalFormValidation()"
                    [class.disabled]="!generalFormValidation()"
                    [class.enabled]="generalFormValidation()"
                    (click)="openDialog(modelConfirm)"
                >
                    {{ "modal.toInvite" | translate }}
                </button>
            </div>

            <br>
            <br>
        </div>

        <div class="register-user__container-register edit" *ngIf="isUserEdit">
            <h2 class="register-user__container-register__header">{{ "register.basicInfo" | translate }}</h2>
            <div action=""  class="register-user__container-register__fields">
                <div>
                    <label for="name">{{ "register.users.name" | translate }}</label>
                    <input [(ngModel)]="userEdit.name" type="text" class="input-field" disabled required>
                </div>
                <div>
                    <label for="email">{{ "register.users.email" | translate }}</label>
                    <input [(ngModel)]="userEdit.email" type="text" class="input-field" disabled required>
                </div>
                <div class="select">
                    <label for="type">{{ "register.users.typeUser" | translate }}</label>
                    <mat-form-field appearance="outline" class="select-field">
                        <mat-select required [(ngModel)]="userEdit.role" placeholder="{{ placeholderTypeUserEdit | translate }}">
                            <mat-select-trigger>
                                {{ userEdit.role | translate }}
                            </mat-select-trigger>
                            <mat-option *ngFor="let type of typeUser" [value]="type.value" class="options">
                                <p class="title">{{ type.title | translate}}</p>
                                <span class="description">{{ type.description | translate}}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <hr>

            <div class="register-user__container-register__btns">
                <button class="register-user__container-register__btns-cancel" (click)="goToListUsers()">{{ "modal.cancel" | translate }}</button>
                <button
                    class="register-user__container-register__btns-save enabled"

                    (click)="editUserRole()"
                >
                {{ "register.users.save" | translate }}
                </button>
            </div>
        </div>

        <div class="register-user__container-list" *ngIf="isListUsers && users.length > 0">
            <div class="register-user__container-list__header">
                <div class="register-user__container-list__header-title">
                    {{ "register.registerList" | translate }}
                    <div class="users-number">{{ usersNumber }} {{ "register.items" | translate }}</div>
                </div>
                <mat-form-field appearance="outline">
                    <mat-label>{{ "activity.filter" | translate }}</mat-label>
                    <input matInput (keyup)="applyFilter($event)" #input>
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
            </div>

            <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="list-user"> {{ "register.users.name" | translate }} </th>
                    <td mat-cell *matCellDef="let user" class="list-user"> {{user.name}} </td>
                </ng-container>
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="list-user"> {{ "register.users.email" | translate }} </th>
                    <td mat-cell *matCellDef="let user" class="list-user"> {{user.email}} </td>
                </ng-container>
                <ng-container matColumnDef="role">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="list-user"> {{ "register.users.type" | translate }} </th>
                    <td mat-cell *matCellDef="let user" class="list-user"> {{ user.role | translate }}</td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="list-user"> {{ "register.users.status" | translate }} </th>
                    <td mat-cell *matCellDef="let user" class="user-status list-user">
                        <div class="info">
                            <i class="fa-solid fa-circle-check active" *ngIf="user.status == 'Ativo'"></i>
                            <i class="fa-solid fa-solid fa-clock invitation" *ngIf="user.status != 'Ativo'"></i>
                            {{ user.status | translate }}
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let user" class="user-actions">
                        <div class="actions">
                            <button mat-icon-button [matMenuTriggerFor]="optionsUser" aria-label="Example icon-button with a menu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #optionsUser="matMenu" class="menu">
                                <button mat-menu-item (click)="editTypeUser(user)" class="action">
                                    <i class="fa-regular fa-pen-to-square icon"></i>
                                    <span class="texto">{{ "register.users.editUserType" | translate }}</span>
                                </button>
                                <button mat-menu-item class="action" (click)="openDialogRemoveUser(user.userId)">
                                    <i class="fa-regular fa-trash"></i>
                                    <span class="texto">{{ "register.users.removeUser" | translate }}</span>
                                </button>
                            </mat-menu>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                <tr mat-row *matRowDef="let row; columns: columnsToDisplay;" class="example-element-row"></tr>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">{{ "activity.filterReturn" | translate }} "{{input.value}}"</td>
                </tr>
            </table>

            <mat-paginator #paginatorUsers [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="5"
                aria-label="Items por pagina" showFirstLastButtons class="border">
            </mat-paginator>
        </div>
    </div>
</div>
