import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ActionModuleControllerEnum } from 'src/app/core/models/enums/actionModuleControllerEnum';
import { SchedulesControlMode } from 'src/app/core/models/enums/schedules-control-mode.enum';
import ActivityFeedRequest from 'src/app/core/models/request/activity-feed-request.model';
import { ActivityFeedService } from 'src/app/core/services/activityFeedServices/activity-feed.service';

@Component({
  selector: 'app-activity-feed',
  templateUrl: './activity-feed.component.html',
  styleUrls: ['./activity-feed.component.scss'],
})
export class ActivityFeedComponent implements AfterViewInit {
  @ViewChild('paginator') paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  activities: ActivityFeedRequest[] = [];
  dataSource = new MatTableDataSource();
  columnsToDisplay: string[] = ['moduleName', 'ctrl', 'time', 'ctrlType', 'userName'];
  operationNumber: number = 0;

  constructor(
    private activityFeedService: ActivityFeedService,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.getAllActivities();
  }

  ngAfterViewInit() {
    this.translateMatPaginator(this.paginator);
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getAllActivities() {
    this.activityFeedService.getActivities().then((resp) => {
      this.activities = resp;
      this.operationNumber = this.activities?.length;
      this.convertData(this.activities);
    });
  }

  translateMatPaginator(paginator: MatPaginator) {
    this.translate.get('activity.paginator').subscribe(() => {
      paginator._intl.itemsPerPageLabel = this.translate.instant('activity.paginator.itemsPerPage');
      paginator._intl.firstPageLabel = this.translate.instant('activity.paginator.firstPageLabel');
      paginator._intl.lastPageLabel = this.translate.instant('activity.paginator.lastPageLabel');
      paginator._intl.nextPageLabel = this.translate.instant('activity.paginator.nextPageLabel');
      paginator._intl.previousPageLabel = this.translate.instant('activity.paginator.previousPageLabel');
      paginator._intl.getRangeLabel = this.rangeLabel;
    });
  }

  rangeLabel = (page: number, pageSize: number, length: number) => {
    if (length == 0 || pageSize == 0) {
      return `0 ${this.translate.instant('scheduling.paginator.of')} ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} ${this.translate.instant('scheduling.paginator.of')} ${length}`;
  };

  convertData(modules: ActivityFeedRequest[]) {
    modules.forEach((element) => {
      const date = new Date(element.dateTime);
      date.getTime();
      element.dateTime = moment(date).format('DD/MM/YYYY');
      element.time = moment(date).format('HH:mm:ss');

      switch (element.ctrl) {
        case ActionModuleControllerEnum.turnOn:
          element.ctrl = this.translate.instant('activity.column.ctrl.on');
          break;
        case ActionModuleControllerEnum.turnOff:
          element.ctrl = this.translate.instant('activity.column.ctrl.off');
          break;
        case ActionModuleControllerEnum.standBy:
          element.ctrl = this.translate.instant('activity.column.ctrl.standby');
          break;
        case ActionModuleControllerEnum.uncharge:
          element.ctrl = this.translate.instant('activity.column.ctrl.uncharge');
          break;
        case ActionModuleControllerEnum.charge:
          element.ctrl = this.translate.instant('activity.column.ctrl.charge');
          break;
        case ActionModuleControllerEnum.consuming:
          element.ctrl = this.translate.instant('activity.column.ctrl.consuming');
          break;
        case ActionModuleControllerEnum.injecting:
          element.ctrl = this.translate.instant('activity.column.ctrl.injecting');
          break;
        case ActionModuleControllerEnum.changeControlMode:
          element.ctrl = this.translate.instant('activity.column.ctrl.changeControlMode');
          break;
        default:
          element.ctrl = this.translate.instant('activity.column.ctrl.unknown');
          break;
      }

      if (element.ctrlType === SchedulesControlMode.Manual) {
        element.ctrlType = this.translate.instant('activity.column.ctrlTypes.manual');
      } else if (element.ctrlType === SchedulesControlMode.Optimizer) {
        element.ctrlType = this.translate.instant('activity.column.ctrlTypes.optimizer');
      } else if (element.ctrlType === SchedulesControlMode.Scheduler) {
        element.ctrlType = this.translate.instant('activity.column.ctrlTypes.scheduler');
      }

      if (element.userEmail === '') {
        element.userName = this.translate.instant('activity.column.userControler');
      }
    });
    this.dataSource.data = modules;
    this.dataSource.filteredData = modules;
    this.dataSource.sort = this.sort;
  }
}
