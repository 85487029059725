import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Observable, map, startWith } from 'rxjs';
import { TariffSubgroupEnum, TypeSupplyEnum } from 'src/app/core/models/enums/distribution-grid.enum';
import { NewModuleRequest } from 'src/app/core/models/request/new-module-request.model';
import { DistributionGridService } from 'src/app/core/services/distributionGridServices/distribution-grid.service';
import { CustomSnackbarErrorComponent } from 'src/app/shared/components/custom-snackbar-error/custom-snackbar-error.component';
import { CustomSnackbarSuccessComponent } from 'src/app/shared/components/custom-snackbar-success/custom-snackbar-success.component';
import { AppConstants } from 'src/app/shared/constants/app.constants';

@Component({
  selector: 'app-distribution-grid',
  templateUrl: './distribution-grid.component.html',
  styleUrls: ['./distribution-grid.component.scss'],
})
export class DistributionGridComponent {
  gridForm: FormGroup;
  distributionGridRequest: NewModuleRequest = {} as NewModuleRequest;
  select: string = 'register.grid.select';
  value: string = 'register.grid.value';
  code: string = 'register.grid.enterCode';
  number: string = 'register.grid.enterNumber';
  enterTheDistributor: string = 'register.grid.enterTheDistributor';
  energyDistribution: string[] = [];
  filteredOptions!: Observable<string[]>;
  fieldDisable: boolean = false;
  showBtnCancel: boolean = false;

  tariffSubgroupOptions = [
    {
      title: 'register.grid.tariffSubgroupOptions.A1',
      value: TariffSubgroupEnum.A1,
    },
    {
      title: 'register.grid.tariffSubgroupOptions.A2',
      value: TariffSubgroupEnum.A2,
    },
    {
      title: 'register.grid.tariffSubgroupOptions.A3',
      value: TariffSubgroupEnum.A3,
    },
    {
      title: 'register.grid.tariffSubgroupOptions.A3a',
      value: TariffSubgroupEnum.A3a,
    },
    {
      title: 'register.grid.tariffSubgroupOptions.A4',
      value: TariffSubgroupEnum.A4,
    },
    {
      title: 'register.grid.tariffSubgroupOptions.AS',
      value: TariffSubgroupEnum.AS,
    },
    {
      title: 'register.grid.tariffSubgroupOptions.B1',
      value: TariffSubgroupEnum.B1,
    },
    {
      title: 'register.grid.tariffSubgroupOptions.B2',
      value: TariffSubgroupEnum.B2,
    },
    {
      title: 'register.grid.tariffSubgroupOptions.B3',
      value: TariffSubgroupEnum.B3,
    },
    {
      title: 'register.grid.tariffSubgroupOptions.B4',
      value: TariffSubgroupEnum.B4,
    },
  ];
  typeOfSupplyOptions = [
    {
      title: 'register.grid.typeSupplyOptions.singlePhase',
      value: TypeSupplyEnum.singlePhase,
    },
    {
      title: 'register.grid.typeSupplyOptions.biphasic',
      value: TypeSupplyEnum.biphasic,
    },
    {
      title: 'register.grid.typeSupplyOptions.threePhase',
      value: TypeSupplyEnum.threePhase,
    },
  ];

  constructor(
    private fb: FormBuilder,
    private distributionGridService: DistributionGridService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
  ) {
    this.gridForm = this.fb.group({
      energyDistributor: ['', [Validators.required, Validators.minLength(3)]],
      tariffSubgroup: ['', [Validators.required]],
      typeSupply: ['', Validators.required],
      installNumber: [''],
      customerCode: [''],
      supplyVoltage: ['', Validators.required],
    });
  }

  get formValidation(): boolean {
    return this.gridForm.valid && this.gridForm.dirty;
  }

  get disabled(): boolean {
    return this.fieldDisable;
  }

  ngOnInit() {
    this.getAllEnergyDistributionOptions();
    this.getModuleGrid();
    this.filterEnergyDistribution();
  }

  getAllEnergyDistributionOptions(): void{
    this.distributionGridService.getAllEnergyDistribution().then((resp: any) => {
      resp.data.forEach((element: any) => {
        this.energyDistribution.push(element.sigAgente);
      });
    });
  }

  getModuleGrid(): void {
    this.distributionGridService.getDistributionGridModule().then((resp: any) => {
      this.distributionGridRequest = resp.data;

      if (this.distributionGridRequest.moduleId) {
        this.gridForm.controls['energyDistributor'].setValue(this.distributionGridRequest.distributionGridAttributes?.energyDistributor);
        this.gridForm.controls['tariffSubgroup'].setValue(this.distributionGridRequest.distributionGridAttributes?.tariffSubgroup);
        this.gridForm.controls['typeSupply'].setValue(this.distributionGridRequest.distributionGridAttributes?.typeSupply);
        this.gridForm.controls['installNumber'].setValue(this.distributionGridRequest.distributionGridAttributes?.installNumber);
        this.gridForm.controls['customerCode'].setValue(this.distributionGridRequest.distributionGridAttributes?.customerCode);
        this.gridForm.controls['supplyVoltage'].setValue(this.distributionGridRequest.distributionGridAttributes?.supplyVoltage);
        this.disableEdtion();
      }
    })
  }

  filterEnergyDistribution() {
    this.filteredOptions = this.gridForm.controls['energyDistributor'].valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || '')),
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.energyDistribution.filter((option) => option.toLowerCase().includes(filterValue));
  }

  enableEdition(): void {
    this.fieldDisable = false;
    this.showBtnCancel = true;

    this.gridForm.controls['energyDistributor'].enable();
    this.gridForm.controls['tariffSubgroup'].enable();
    this.gridForm.controls['typeSupply'].enable();
    this.gridForm.controls['installNumber'].enable();
    this.gridForm.controls['customerCode'].enable();
    this.gridForm.controls['supplyVoltage'].enable();
  }

  disableEdtion(): void {    
    this.fieldDisable = true;
    this.showBtnCancel = false;
    this.gridForm.controls['energyDistributor'].disable();
    this.gridForm.controls['tariffSubgroup'].disable();
    this.gridForm.controls['typeSupply'].disable();
    this.gridForm.controls['installNumber'].disable();
    this.gridForm.controls['customerCode'].disable();
    this.gridForm.controls['supplyVoltage'].disable();

    this.gridForm.controls['energyDistributor'].setValue(this.distributionGridRequest.distributionGridAttributes?.energyDistributor);
    this.gridForm.controls['tariffSubgroup'].setValue(this.distributionGridRequest.distributionGridAttributes?.tariffSubgroup);
    this.gridForm.controls['typeSupply'].setValue(this.distributionGridRequest.distributionGridAttributes?.typeSupply);
    this.gridForm.controls['installNumber'].setValue(this.distributionGridRequest.distributionGridAttributes?.installNumber);
    this.gridForm.controls['customerCode'].setValue(this.distributionGridRequest.distributionGridAttributes?.customerCode);
    this.gridForm.controls['supplyVoltage'].setValue(this.distributionGridRequest.distributionGridAttributes?.supplyVoltage);
  }

  save(): void {
    if (this.distributionGridRequest.moduleId) {
      this.editDistributionGrid();
    } else {
      this.createDistributionGrid();
    }
  }

  createDistributionGrid(): void {
    this.distributionGridRequest.name = 'Rede de distribuição';
    this.distributionGridRequest.type = 2;
    this.distributionGridRequest.subtype = 6;
    this.distributionGridRequest.model = 'grid';
    this.distributionGridRequest.manufacturer = 'grid';
    this.distributionGridRequest.controlMode = 0;
    this.distributionGridRequest.distributionGridAttributes = this.gridForm.value;
    this.distributionGridService.createDistributionGrid(this.distributionGridRequest).then((result: number) => {
      this.openSnackbar(result);
    }).then(() => {
      this.getModuleGrid();
    });
  }

  editDistributionGrid(): void {
    this.distributionGridRequest.distributionGridAttributes = this.gridForm.value;    
    this.distributionGridService.updateDistributionGridModule(this.distributionGridRequest, this.distributionGridRequest.moduleId).then((result: number) => {
      this.openSnackbar(result);
    }).then(() => {
      this.getModuleGrid();
    })
  }

  openSnackbar(status: number): void {
    switch (status) {
      case AppConstants.CREATE_SUCESS:
        this.snackBar.openFromComponent(CustomSnackbarSuccessComponent, {
          data: {
            message: this.translate.instant('snackBar.newModule.moduleCreated'),
            snackBar: this.snackBar,
          },
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: AppConstants.TREE_SECOND_WAIT,
        });
        break;
      case AppConstants.OK:
        this.snackBar.openFromComponent(CustomSnackbarSuccessComponent, {
          data: {
            message: this.translate.instant('snackBar.newModule.moduleCreated'),
            snackBar: this.snackBar,
          },
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: AppConstants.TREE_SECOND_WAIT,
        });
        break;
      default:
        this.snackBar.openFromComponent(CustomSnackbarErrorComponent, {
          data: {
            message: this.translate.instant('snackBar.newModule.moduleErroCreating'),
            snackBar: this.snackBar,
          },
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: AppConstants.TREE_SECOND_WAIT,
        });
        break;
    }
  }
}
