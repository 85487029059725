<div class="dataUpload__header" *ngIf="!editing">
    <div class="dataUpload__header-info">
        <h2>{{ "analysisTimeSeries.dataUpload.title" | translate }}</h2>
        <p>{{ "analysisTimeSeries.dataUpload.info" | translate }}</p>
    </div>
</div>
<div class="dataUpload__header" *ngIf="editing">
    <div class="dataUpload__header-info">
        <h2>{{ "analysisTimeSeries.dataUpload.titleEditing" | translate }}</h2>
        <p>{{ "analysisTimeSeries.dataUpload.infoEditing" | translate }}</p>
    </div>
</div>

<div class="forms">
    <div class="forms__header">
        <p>{{ "analysisTimeSeries.dataUpload.formsHeaderInfo" | translate }}</p>
    </div>
    <div class="direction">
        <form #uploadForm="ngForm">
            <div class="form-row">
                <div class="form-group forms-form">
                    <div class="section">
                        <div class="row">
                            <label for="name" class="info">
                                {{ "analysisTimeSeries.dataUpload.stName" | translate }}
                            </label>
                            <img 
                                src="assets/icons/circle-info.svg" 
                                alt="Icon Info"
                                matTooltip="{{nameTip}}"
                                matTooltipPosition="above"
                                matTooltipClass="indicator-tooltip"
                                >
                        </div>
                        <input 
                            [(ngModel)]="form.name"
                            #name="ngModel" 
                            name="name" 
                            type="text" 
                            placeholder="{{insertName}}"
                            class="input-field large-input form-control" 
                            required 
                            maxlength="26" 
                            [class.invalid]="(name.invalid && (name.dirty || name.touched)) || nameInvalid"
                            (keyup)="checkName(form.name);"
                            >
                        <mat-hint class="hint" *ngIf="!nameInvalid">{{ "analysisTimeSeries.dataUpload.nameHint" | translate }}</mat-hint>
                        <mat-hint class="hintInvalid" *ngIf="nameInvalid">{{ "analysisTimeSeries.dataUpload.nameHintInvalid" | translate }}</mat-hint>
                    </div>
                </div>
                <div class="form-group forms-form">
                    <div class="section">
                        <div class="row">
                            <label for="unit" class="info">
                                {{ "analysisTimeSeries.dataUpload.mesureUnity" | translate }}
                            </label>
                            <img 
                                src="assets/icons/circle-info.svg" 
                                alt="Icon Info"
                                matTooltip="{{unityTip}}"
                                matTooltipPosition="above"
                                matTooltipClass="indicator-tooltip"
                                >
                        </div>
                        <input 
                            [(ngModel)]="form.unit"
                            #unit="ngModel"
                            name="unit"
                            type="text"
                            placeholder="{{insertUnity}}"
                            class="input-field small-input form-control"
                            style="margin-top: 10px;"
                            matInput
                            [matAutocomplete]="auto"
                            required 
                            [disabled]="editing"
                            [class.invalid]="(unit.invalid && (unit.dirty || unit.touched))"
                            >
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                {{option}}
                            </mat-option>
                        </mat-autocomplete>
                    </div>
                </div>

            </div>
            <div class="form-row">
                <div class="form-group forms-form">
                    <div class="section">
                        <div class="row">
                            <label for="samplingFrequency" class="info">
                                {{ "analysisTimeSeries.dataUpload.dataTimeInterval" | translate }}
                            </label>
                            <img 
                                src="assets/icons/circle-info.svg" 
                                alt="Icon Info"
                                matTooltip="{{dataTimeIntervalTip}}"
                                matTooltipPosition="above"
                                matTooltipClass="indicator-tooltip"
                                >
                        </div>
                        <div class="section-interval">
                            <input 
                                [(ngModel)]="form.samplingFrequency"
                                #samplingFrequency="ngModel" 
                                name="samplingFrequency"  
                                type="number"
                                placeholder="{{value}}"
                                class="input-field small-input form-control"
                                style="margin: 10px 24px 0 0;"
                                matInput
                                required 
                                [disabled]="editing"
                                [class.invalid]="(samplingFrequency.invalid && (samplingFrequency.dirty || samplingFrequency.touched))"
                                >

                            <mat-form-field class="card-module-select small-input" appearance="outline" [class.editing-select]="editing">
                                <mat-select [(ngModel)]="form.samplingFrequencyUnity" name="samplingFrequencyUnity" placeholder="{{select}}" required [disabled]="editing">
                                    <mat-option *ngFor="let sample of samplingOptions" [value]="sample.type">
                                        <p>{{ sample.title }}</p>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="form-group forms-form">
                    <div class="row">
                        <label for="dataGrouping" class="info">
                            {{ "analysisTimeSeries.dataUpload.dataAgregation" | translate }}
                        </label>
                        <img 
                            src="assets/icons/circle-info.svg" 
                            alt="Icon Info"
                            matTooltip="{{dataAgregationTip}}"
                            matTooltipPosition="above"
                            matTooltipClass="indicator-tooltip"
                            >
                    </div>
                    
                    <mat-form-field class="card-module-select medium-input" appearance="outline" [class.editing-select]="editing">
                        <mat-select [(ngModel)]="form.dataGrouping" name="dataGrouping" placeholder="{{select}}" required [disabled]="editing">
                            <mat-select-trigger> {{selectedDataGrouping}} </mat-select-trigger>
                            <mat-option *ngFor="let data of dataGrouping" [value]="data.type" class="options" (click)="dataGroupingSelected(data.title)">
                                <p class="title">{{ data.title }}</p>
                                <span class="description">{{ data.desc }}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <button class="dowload-button" (click)="dowloadTemplate()" [disabled]="!uploadForm.form.valid">
                {{"analysisTimeSeries.dataUpload.buttonDowload" | translate }}
            </button>
        </form>
        <p class="forms-line-vertical"></p>
        <div>
            <label *ngIf="!editing" for="upload" class="row">
                {{ "analysisTimeSeries.dataUpload.dataUpload" | translate }}
            </label>
            <p *ngIf="editing" for="upload" class="row label-upload">
                {{ "analysisTimeSeries.dataUpload.dataUpload" | translate }}
            </p>
            <div class="dropzone" appDragAndDrop (fileDropped)="onFileDroped($event)" [class.dropzone-disable]="fileUploaded" [class.dropzone-invalid]="invalid">
                <div class="drop-texts">
                    <app-tag-icon [icon]="'upload'" [type]="'default'"> </app-tag-icon>
                    <p style="font-size: 14px;">
                        <span class="click-upload" (click)="file.click()">{{ "analysisTimeSeries.dataUpload.click" | translate }}</span>
                        {{ "analysisTimeSeries.dataUpload.dropIn" | translate }}
                    </p>
                    <p class="file-rules">{{ "analysisTimeSeries.dataUpload.uploadHint" | translate }}</p>
                </div>
                <input 
                    #file 
                    #attachments
                    type="file" 
                    name="file" 
                    id="file" 
                    (change)="onFileChange($event)" 
                    [multiple]="false" 
                    hidden 
                    accept=".csvs, .csv"
                    data-maxFileSize="2"
                    required
                    [disabled]="fileUploaded"
                    >
            </div>
            <div *ngIf="fileUploaded == true" class="action">
                <i class="fa-regular fa-paperclip-vertical"></i>
                <span *ngFor="let file of fileToUpload">{{file.name}}</span>
                <p (click)="removeFile()">x</p>
            </div>
        </div>
    </div>

    <div class="tip" *ngIf="editing">
        <span>{{ "analysisTimeSeries.dataUpload.tip" | translate}}</span>
        <ul>
            <li>{{ "analysisTimeSeries.dataUpload.text01" | translate }}</li>
            <li>{{ "analysisTimeSeries.dataUpload.text02" | translate }}</li>
        </ul>
    </div>
    <p class="forms-line-horizontal"></p>

    <div class="data-upload-buttons">
        <button class="data-upload-buttons-cancel"
            (click)="cancelForm()"
            >
            {{ "analysisTimeSeries.dataUpload.buttonCancel" | translate }}
        </button>
        <button *ngIf="!editing"
            class="data-upload-buttons-save" 
            [disabled]="(!uploadForm.form.valid) || (!fileUploaded)"
            (click)="saveForm(uploadForm.form.valid)"
            >
            {{ "analysisTimeSeries.dataUpload.buttonSave" | translate }}
        </button>
        <button *ngIf="editing"
            class="data-upload-buttons-save" 
            [disabled]="!nameChanged && !fileUploaded"
            (click)="saveFormToEdit(uploadForm.form.valid)"
            >
            {{ "analysisTimeSeries.dataUpload.buttonSave" | translate }}
        </button>
    </div>
</div>

<div class="loading-screen" *ngIf="loading">
    <div class="modal">
        <div class="loading">
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
        </div>
        <h2>{{ "analysisTimeSeries.dataUpload.modal.title" | translate }}</h2>
        <p>{{ "analysisTimeSeries.dataUpload.modal.info" | translate }}</p>
        <p class="bottom">{{ "analysisTimeSeries.dataUpload.modal.info2" | translate }}</p>
    </div>
</div>