import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { SchedulePolicyStatusEnum } from 'src/app/core/models/enums/schedules-policy-status.enum';
import { SelectStatusEnum } from 'src/app/core/models/enums/select-status.enum';
import { SubTypeModuleEnum } from 'src/app/core/models/enums/subtype-module.enum';
import { OptionDayViewModel } from 'src/app/core/models/interfaces/options-day.model';
import { StatusSelectViewModel } from 'src/app/core/models/interfaces/status-select.model';
import { TimePickerHoursViewModel } from 'src/app/core/models/interfaces/time-picker-hours.model';
import { ScheduleStatus } from 'src/app/core/models/request/schedules-request.model';

@Component({
  selector: 'app-modal-new-status',
  templateUrl: './modal-new-status.component.html',
  styleUrls: ['./modal-new-status.component.scss']
})
export class ModalNewStatusComponent {
  form: ScheduleStatus = {} as ScheduleStatus;
  confirm: Subject<ScheduleStatus> = new Subject();
  close: Subject<boolean> = new Subject();
  status: StatusSelectViewModel[] = []
  select: string = 'register.users.select';
  hours: string[] = [];
  days: OptionDayViewModel[] = [
    {name:'controlPanel.schedules.container.days.allDays', id: -1},
    {name:'controlPanel.schedules.container.days.Sunday', id: 6},
    {name:'controlPanel.schedules.container.days.Monday', id: 0},
    {name:'controlPanel.schedules.container.days.Tuesday', id: 1},
    {name:'controlPanel.schedules.container.days.Wednesday', id: 2},
    {name:'controlPanel.schedules.container.days.Thursday', id: 3},
    {name:'controlPanel.schedules.container.days.Friday', id: 4},
    {name:'controlPanel.schedules.container.days.Saturday', id: 5},
  ];
  enabled: boolean = false;
  subType!: SubTypeModuleEnum;
  timeRange: string[] = [];
  end: string = "";
  start: string = "";
  @Output() scheuleStatusEmitte = new EventEmitter<ScheduleStatus>();

  constructor (
    private translate: TranslateService,
    public dialogRef: MatDialogRef<ModalNewStatusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { 
    this.subType = data.subType
  }

  get formValid(): boolean {
    if ((this.form.endTime == '00:00' || this.form.endTime == undefined) && (this.form.startTime == '00:00' || this.form.startTime == null)) {
      return false;
    } else {
      return true;
    }
  }

  ngOnInit(): void {
    this.setOptionStatus(this.subType);
  }

  setOptionStatus(type: SubTypeModuleEnum): void {
    switch (type) {
      case SubTypeModuleEnum.dispatchable:
        this.status = [
          {name: 'module.statusLoadOn', statusType: SchedulePolicyStatusEnum.on}, 
          {name: 'module.actionStandBy', statusType: SchedulePolicyStatusEnum.standBy}
        ]
        break;

        case SubTypeModuleEnum.controllable:
        this.status = [
          {name: 'module.statusLoadOn', statusType: SchedulePolicyStatusEnum.on}, 
          {name: 'module.statusLoadOff', statusType: SchedulePolicyStatusEnum.off}
        ]
        break;
    
      default:
        this.status = [
          {name: 'module.statusCharge', statusType: SchedulePolicyStatusEnum.charge}, 
          {name: 'module.statusUncharge', statusType: SchedulePolicyStatusEnum.uncharge},
          {name: 'module.actionStandBy', statusType: SchedulePolicyStatusEnum.standBy},
        ]
        break;
    }
  }

  onConfirm(): void {
    this.confirm.next(this.form);
  }

  onTimeChanged(event: TimePickerHoursViewModel) {
    this.form.endTime = event.end;
    this.form.startTime = event.start
  }

  onDismiss(): void {
    this.close.next(true);
    this.dialogRef.close(false);
  }
}
