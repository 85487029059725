<div class="control-panel">
  <div class="header">
    <app-header></app-header>
  </div>

  <div class="monitoring">
    <div class="control-panel__nav" [class.collapsed-menu]="menuIsCollapsed">
      <div class="control-panel__nav__header" *ngIf="!menuIsCollapsed">
        <h2>
          {{ "monitoring.title" | translate }}
          <i 
           class="fa-regular fa-chevrons-left" 
           (click)="collapseMenu()"
           matTooltip="{{ hideMenu | translate }}"
           matTooltipPosition="right"
           matTooltipClass="tooltip hide"></i>
        </h2>
        <p>
          {{ "monitoring.info" | translate }}
        </p>
      </div>

      <div *ngIf="menuIsCollapsed" class="collapsed-menu" 
        matTooltip="{{ expandMenu | translate }}"
        matTooltipPosition="right"
        matTooltipClass="tooltip">
        <i class="fa-regular fa-chevrons-right icon" (click)="collapseMenu()"></i>
      </div>

      <div class="control-panel__nav__search">
        <mat-form-field appearance="outline" class="monitoring-search" *ngIf="!menuIsCollapsed">
          <mat-label>{{ "activity.filter" | translate }}</mat-label>
          <input matInput #input>
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
        
        <div *ngIf="menuIsCollapsed" class="collapsed-menu">
          <i class="fa-solid fa-magnifying-glass icon"></i>
        </div>
      </div>
  
      <a routerLink="/monitoring/dashboard-monitoring" routerLinkActive="active" class="microgrid" [class.collapsed-menu]="menuIsCollapsed"
        matTooltip="{{ microgrid | translate }}"
        matTooltipPosition="right"
        matTooltipClass="tooltip microgrid"
        [matTooltipDisabled]="!menuIsCollapsed"
      >
        <i class="fa-regular fa-grid-2 teste" [class.collapsed-menu]="menuIsCollapsed"></i>
        <span [class.collapsed-menu]="menuIsCollapsed">{{ "monitoring.dashboard" | translate }}</span>
      </a>
  
      <mat-accordion class="nav" *ngIf="isOptionMenuStorage"
        matTooltip="{{ storage | translate }}"
        matTooltipPosition="right"
        matTooltipClass="tooltip storage"
        [matTooltipDisabled]="!menuIsCollapsed"
      >
        <mat-expansion-panel
          class="nav-panel"
          (opened)="panelOpen = true"
          (closed)="panelOpen = false">
          <mat-expansion-panel-header class="nav-panel__header">
          <mat-panel-title class="nav-panel__header-title">
            <i class="fa-regular fa-car-battery" [class.collapsed-menu]="menuIsCollapsed" (click)="collapseMenu()"></i>
            <span [class.collapsed-menu]="menuIsCollapsed">{{ "monitoring.storage" | translate }}</span>
          </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="nav-panel__content">
            <mat-vertical-stepper #stepper [linear]="false">
              <mat-step *ngFor="let item of optionMenuStorage" class="nav-stepper">
                <ng-template matStepLabel>
                  <a 
                    routerLink="/monitoring/module-monitoring"
                    routerLinkActive="router-link-active" 
                    class="nav-panel__content-option"
                    [class.collapsed-menu]="menuIsCollapsed"
                    >{{ item }}</a>
                </ng-template>
              </mat-step>
            </mat-vertical-stepper>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

      <mat-accordion class="nav" *ngIf="isOptionMenuRenewableGeneration"
        matTooltip="{{ renewableGeneration | translate }}"   
        matTooltipPosition="right"
        matTooltipClass="tooltip renewableGeneration"
        [matTooltipDisabled]="!menuIsCollapsed">
        <mat-expansion-panel
          class="nav-panel"
          (opened)="panelOpen = true"
          (closed)="panelOpen = false">
          <mat-expansion-panel-header class="nav-panel__header">
            <mat-panel-title class="nav-panel__header-title">
              <i class="fa-regular fa-circle-bolt" [class.collapsed-menu]="menuIsCollapsed" (click)="collapseMenu()"></i>
              <span [class.collapsed-menu]="menuIsCollapsed">{{ "monitoring.renewableGeneration" | translate }}</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="nav-panel__content">
            <mat-vertical-stepper #stepper [linear]="false">
              <mat-step *ngFor="let item of optionMenuRenewableGeneration" class="nav-stepper">
                <ng-template matStepLabel>
                  <a routerLink="/monitoring/module-monitoring" routerLinkActive="router-link-active"  class="nav-panel__content-option" [class.collapsed-menu]="menuIsCollapsed">{{ item }}</a>
                </ng-template>
              </mat-step>
            </mat-vertical-stepper>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

      <mat-accordion class="nav" *ngIf="isOptionMenuDispatchableGeneration"
        matTooltip="{{ dispatchableGeneration | translate }}"
        matTooltipPosition="right"
        matTooltipClass="tooltip dispatchableGeneration"
        [matTooltipDisabled]="!menuIsCollapsed">
        <mat-expansion-panel
          class="nav-panel"
          (opened)="panelOpen = true"
          (closed)="panelOpen = false">
          <mat-expansion-panel-header class="nav-panel__header">
            <mat-panel-title class="nav-panel__header-title">
              <i class="fa-regular fa-circle-bolt" [class.collapsed-menu]="menuIsCollapsed" (click)="collapseMenu()"></i>
              <span [class.collapsed-menu]="menuIsCollapsed">{{ "monitoring.dispatchableGeneration" | translate }}</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="nav-panel__content">
            <mat-vertical-stepper #stepper [linear]="false">
              <mat-step *ngFor="let item of optionMenuDispatchableGeneration" class="nav-stepper">
                <ng-template matStepLabel>
                  <a routerLink="/monitoring/module-monitoring" routerLinkActive="router-link-active"  class="nav-panel__content-option" [class.collapsed-menu]="menuIsCollapsed">{{ item }}</a>
                </ng-template>
              </mat-step>
            </mat-vertical-stepper>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

      <mat-accordion class="nav" *ngIf="isOptionMenuFixedLoad"
        matTooltip="{{ fixedLoad | translate }}"
        matTooltipPosition="right"
        matTooltipClass="tooltip fixedLoad"
        [matTooltipDisabled]="!menuIsCollapsed">
        <mat-expansion-panel
          class="nav-panel"
          (opened)="panelOpen = true"
          (closed)="panelOpen = false">
          <mat-expansion-panel-header class="nav-panel__header">
            <mat-panel-title class="nav-panel__header-title">
              <i class="fa-regular fa-plug-circle-bolt" [class.collapsed-menu]="menuIsCollapsed" (click)="collapseMenu()"></i>
              <span [class.collapsed-menu]="menuIsCollapsed">{{ "monitoring.fixedLoad" | translate }}</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="nav-panel__content">
            <mat-vertical-stepper #stepper [linear]="false">
              <mat-step *ngFor="let item of optionMenuFixedLoad" class="nav-stepper">
                <ng-template matStepLabel>
                  <a routerLink="/monitoring/module-monitoring" routerLinkActive="router-link-active"  class="nav-panel__content-option" [class.collapsed-menu]="menuIsCollapsed">{{ item }}</a>
                </ng-template>
              </mat-step>
            </mat-vertical-stepper>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

      <mat-accordion class="nav" *ngIf="isOptionMenuControllableLoad"
        matTooltip="{{ controllableLoad | translate }}"
        matTooltipPosition="right"
        matTooltipClass="tooltip controllableLoad"
        [matTooltipDisabled]="!menuIsCollapsed">
        <mat-expansion-panel
          class="nav-panel"
          (opened)="panelOpen = true"
          (closed)="panelOpen = false">
          <mat-expansion-panel-header class="nav-panel__header">
            <mat-panel-title class="nav-panel__header-title">
              <i class="fa-regular fa-plug-circle-bolt" [class.collapsed-menu]="menuIsCollapsed" (click)="collapseMenu()"></i>
              <span [class.collapsed-menu]="menuIsCollapsed">{{ "monitoring.controllableLoad" | translate }}</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="nav-panel__content">
            <mat-vertical-stepper #stepper [linear]="false">
              <mat-step *ngFor="let item of optionMenuControllableLoad" class="nav-stepper">
                <ng-template matStepLabel>
                  <a routerLink="/monitoring/module-monitoring" routerLinkActive="router-link-active"  class="nav-panel__content-option" [class.collapsed-menu]="menuIsCollapsed">{{ item }}</a>
                </ng-template>
              </mat-step>
            </mat-vertical-stepper>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    
    <div class="control-panel__container">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>