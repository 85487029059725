<div class="form-content">
  <div *ngIf="!erroChangePassword && !erroTokenInvalid && !activedAccount">
    <h2>{{ "login.retrieve.greeting" | translate }}</h2>
    <h4>{{ "login.retrieve.message" | translate }}</h4>

    <form [formGroup]="retrieveForm" (ngSubmit)="activeAccount()" >
      <div class="form-action">

        <div class="form-group">
          <label for="email" class="form-label">{{ "login.email" | translate }}</label>
          <mat-form-field appearance="outline" class="login-access__form-input" >
            <input matInput
                  placeholder='{{ "login.formPlaceholder.email" | translate }}'
                  formControlName="email" />

          </mat-form-field>

        </div>

        <div class="form-group">
          <label for="password" class="form-label">{{ "login.password" | translate }}</label>
          <mat-form-field appearance="outline" class="login-access__form-input custom-mat-mdc-form-field-infix" [class.error]="erroChangePassword">
            <input matInput
                  type="{{ hide ? 'password' : 'text' }}"
                  (focus)="erroChangePassword = false"
                  (keyup)="validatePassword()"
                  (blur)="validatePassword()"
                  placeholder='{{ "login.formPlaceholder.password" | translate }}'
                  formControlName="password" />

            <mat-icon matSuffix *ngIf="!erroChangePassword" svgIcon="{{ hide ? 'eye' : 'eye-slash'}}" (click)="hide = !hide"></mat-icon>
            <mat-icon matSuffix svgIcon="error" *ngIf="erroChangePassword" class="text-color-red"></mat-icon>

            <mat-hint class="mat-padding-null" class="message-error" *ngIf="(retrieveForm.controls['password'].invalid && (retrieveForm.controls['password'].dirty || retrieveForm.controls['password'].touched)) && retrieveForm.controls['password']?.errors?.['required']">
              <span class="message-error">{{ "login.validate.password.required" | translate }}</span>
            </mat-hint>

            <mat-hint class="mat-padding-null" class="message-error" *ngIf="erroChangePassword">
              <span class="message-error">{{ "login.activeAccount.403" | translate }}</span>
            </mat-hint>
            <mat-hint class="mat-padding-null" class="message-error" *ngIf="erroChangePassword">
              <span class="message-error">{{ "login.activeAccount.404" | translate }}</span>
            </mat-hint>
            <mat-hint class="mat-padding-null" class="message-error" *ngIf="erroChangePassword">
              <span class="message-error">{{ "login.activeAccount.500" | translate }}</span>
            </mat-hint>

          </mat-form-field>

          <div style="text-align: left;font-size: .75em;padding-top:5px;padding-bottom: 30px;" *ngIf="showCondition">
            <div class="condition">
              <span class="condition_result">
                <span  *ngIf="minLenght"  class="material-icons md-12 check_cicle">check_circle</span>
                <span  *ngIf="!minLenght" class="material-icons md-12 cancel">cancel</span>
              </span>
              <span class="text">{{ "login.activeAccountCondition.minLenght" | translate }}</span>
            </div>
            <div class="condition">
              <span class="condition_result">
                <span *ngIf="uppercaseLetter"  class="material-icons md-12 check_cicle">check_circle</span>
                <span *ngIf="!uppercaseLetter" class="material-icons md-12 cancel">cancel</span>
              </span>
              <span class="text">{{ "login.activeAccountCondition.uppercaseLetter" | translate }}</span>
            </div>
            <div class="condition">
              <span class="condition_result">
                <span *ngIf="lowercaseLetter"  class="material-icons md-12 check_cicle">check_circle</span>
                <span *ngIf="!lowercaseLetter" class="material-icons md-12 cancel">cancel</span>
              </span>
              <span class="text">{{ "login.activeAccountCondition.lowercaseLetter" | translate }}</span>
            </div>
            <div class="condition">
              <span class="condition_result">
                <span *ngIf="specialCaracter"  class="material-icons md-12 check_cicle">check_circle</span>
                <span *ngIf="!specialCaracter" class="material-icons md-12 cancel">cancel</span>
              </span>
              <span class="text">{{ "login.activeAccountCondition.specialCaracter" | translate }}</span>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="password" class="form-label">{{ "login.password_confirmation" | translate }}</label>
          <mat-form-field appearance="outline" class="login-access__form-input custom-mat-mdc-form-field-infix" [class.error]="erroChangePassword">
            <input matInput
                  type="{{ hide ? 'password' : 'text' }}"
                  (focus)="erroChangePassword = false"
                  (keyup)="validatePassword()"
                  (blur)="validatePassword()"
                  placeholder='{{ "login.formPlaceholder.confirmPassword" | translate }}'
                  formControlName="passwordConfirmation" />

            <mat-icon matSuffix *ngIf="!erroChangePassword" svgIcon="{{ hide ? 'eye' : 'eye-slash'}}" (click)="hide = !hide"></mat-icon>
            <mat-icon matSuffix svgIcon="error" *ngIf="erroChangePassword" class="text-color-red"></mat-icon>

            <mat-hint class="mat-padding-null" class="message-error" *ngIf="(retrieveForm.controls['passwordConfirmation'].invalid && (retrieveForm.controls['passwordConfirmation'].dirty || retrieveForm.controls['passwordConfirmation'].touched)) && retrieveForm.controls['passwordConfirmation']?.errors?.['required']">
              <span class="message-error">{{ "login.validate.password.required" | translate }}</span>
            </mat-hint>

            <mat-hint class="mat-padding-null" class="message-error" *ngIf="!validateIfPasswordIsEqual()">
              <span class="message-error">{{ "login.activeAccountCondition.notEqual" | translate }}</span>
            </mat-hint>

            <mat-hint class="mat-padding-null" class="message-error" *ngIf="erroChangePassword">
              <span class="message-error">{{ "login.activeAccount.403" | translate }}</span>
            </mat-hint>
            <mat-hint class="mat-padding-null" class="message-error" *ngIf="erroChangePassword">
              <span class="message-error">{{ "login.activeAccount.404" | translate }}</span>
            </mat-hint>
            <mat-hint class="mat-padding-null" class="message-error" *ngIf="erroChangePassword">
              <span class="message-error">{{ "login.activeAccount.422" | translate }}</span>
            </mat-hint>
            <mat-hint class="mat-padding-null" class="message-error" *ngIf="erroChangePassword">
              <span class="message-error">{{ "login.activeAccount.500" | translate }}</span>
            </mat-hint>

          </mat-form-field>
        </div>

        <button type="submit"
                class="submit-button"
                [class.disabled]="retrieveForm.invalid && !erroChangePassword"
                [class.enable]="!retrieveForm.invalid && !erroChangePassword"
                [disabled]="retrieveForm.pristine || retrieveForm.invalid  || erroChangePassword">
          {{ "login.buttonFirstNewPassword" | translate }}
        </button>
      </div>
    </form>
  </div>

  <ng-container *ngIf="activedAccount">
    <div class="disclaimer">
      <h3>Recuperação de Acesso</h3>
      <br />
      <p>Senha salva com sucesso.</p>
      <br />
      <p>
        <button routerLink="/login" class="submit-button">
          {{ "login.feedbacks.activedAccount.buttonClose" | translate }}
        </button>
      </p>
    </div>
  </ng-container>

  <ng-container *ngIf="erroChangePassword">
    <div class="disclaimer">
      <h3>{{ "login.feedbacks.erroChangePassword.title" | translate }}</h3>
      <br />
      <p [innerHTML]="'login.feedbacks.erroChangePassword.activeMessage' | translate"></p>
      <br />
      <p>
        <button routerLink="/login" class="submit-button">
          {{ "login.feedbacks.erroChangePassword.buttonClose" | translate }}
        </button>
      </p>
    </div>
  </ng-container>

  <ng-container *ngIf="erroTokenInvalid">
    <div class="disclaimer">
      <h3>{{ "login.feedbacks.erroTokenInvalid.title" | translate }}</h3>
      <br />
      <p [innerHTML]="'login.feedbacks.erroTokenInvalid.activeMessage' | translate"></p>
      <br />
      <p>
        <button routerLink="/login" class="submit-button">
          {{ "login.feedbacks.erroTokenInvalid.buttonClose" | translate }}
        </button>
      </p>
    </div>
  </ng-container>
</div>
