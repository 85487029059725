import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AxiosError } from 'axios';
import { AccountActivatorViewModel } from '../../../app/core/models/interfaces/account-activator.model';
import { ActiveAccountModel } from '../../../app/core/models/interfaces/active-account.model';
import { UserViewModel } from '../../../app/core/models/interfaces/user.model';
import { BaseService } from '../../../app/core/services/base.service';
import { AppConstants } from '../../../app/shared/constants/app.constants';
import { ForgotPasswordResult } from '../../../app/core/models/interfaces/forgot-password-result.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {
  constructor(private router: Router) {
    super();
  }

  isLoggedIn(): boolean {
    return this.getLoggedUser() !== undefined;
  }

  getLoggedUser(): UserViewModel | undefined {
    if (this.checkTokenExpired()) return undefined;

    const storedUser = this.storage.getItem(AppConstants.STORED_USER_KEY);
    if (storedUser && storedUser !== undefined) {
      return JSON.parse(storedUser);
    }
    return undefined;
  }

  removeStoredUser(): void {
    this.storage.removeItem(AppConstants.STORED_USER_KEY);
  }

  checkAuthentication(path?: string): boolean {
    if (this.isLoggedIn()) {
      return true;
    }

    this.handleLogin(path);
    return false;
  }

  async forgotPassword(email: string): Promise<ForgotPasswordResult | undefined> {
    let result: ForgotPasswordResult | undefined;

    await this.post('/auth/retrieve-account', {
      email: email,
    })
      .then((response) => {
        result = { status: +response.status };
      })
      .catch((res: AxiosError | any) => {
        result = { status: 403 };
      });
    return result;
  }

  async login(email: string, password: string): Promise<UserViewModel | undefined> {
    let user: UserViewModel | undefined;

    await this.post('/auth/login', {
      email: email,
      password: password,
    })
      .then((response) => {
        if (response && (response.status === 200 || response.status == 201)) {
          user = response.data;
          this.storage.setItem(AppConstants.STORED_USER_KEY, JSON.stringify(user));
        }
      })
      .catch((response: AxiosError) => {
        throw response;
      });

    return user;
  }

  async getDataActiveAccount(token: string): Promise<AccountActivatorViewModel | undefined> {
    let target: AccountActivatorViewModel | undefined;

    await this.getWithPromise(`/users/active-account/${token}`)
      .then((response) => {
        if (response && (response.status === 200 || response.status == 201)) {
          target = response.data;
        }
      })
      .catch((response: AxiosError | any) => {
        throw response;
      });

    return target;
  }

  async activeAccount(data: ActiveAccountModel): Promise<boolean> {
    return this.post('/users/account/active', data)
      .then((response) => {
        return response.status === 200 || response.status === 201 || response.status === 204;
      })
      .catch(() => {
        return false;
      });
  }

  async retrieveAccount(data: ActiveAccountModel): Promise<boolean> {
    return this.post('/users/account/retrieve', data)
      .then((response) => {
        return response.status === 200 || response.status === 201 || response.status === 204;
      })
      .catch(() => {
        return false;
      });
  }

  handleLogin(path?: string) {
    this.router.navigate(['/auth/login'], { queryParams: { to: path } });
  }
}
