<div class="forms">
    <div class="forms-header">
        <label>{{ "register.resources.newResource.formsHeaderSpecifications" | translate }}</label>
        <img 
            src="assets/icons/circle-info.svg" 
            alt="Icon Info"
            matTooltip="{{specificationsTip}}"
            matTooltipPosition="above"
            matTooltipClass="indicator-tooltip"
            >
    </div>
    <form #form="ngForm">
        <div class="form-row">
            <div class="form-group forms-form">
                <label for="model">{{ "register.resources.newResource.model" | translate }}</label>
                <input 
                    [(ngModel)]="basicForm.model" 
                    name="model" 
                    #model="ngModel"
                    type="text" 
                    class="form-control input-field" 
                    placeholder="{{insertModel}}" 
                    (keyup)="sendForm(form.form.valid)"
                    required
                    [class.invalid]="model.invalid && (model.dirty || model.touched)"
                    >
            </div>
            <div class="form-group forms-form">
                <label for="manufacturer">{{ "register.resources.newResource.manufacturer" | translate }}</label>
                <input 
                    [(ngModel)]="basicForm.manufacturer" 
                    name="manufacturer" 
                    #manufacturer="ngModel"
                    type="text" 
                    class="form-control input-field" 
                    placeholder="{{insertManufacturer}}" 
                    (keyup)="sendForm(form.form.valid)"
                    required
                    [class.invalid]="manufacturer.invalid && (manufacturer.dirty || manufacturer.touched)"
                    >
            </div>
            <div class="form-group forms-form">
                <div>
                    <label for="generationType">{{ "register.resources.newResource.generation.generationType" | translate }}</label>
                    <img 
                        src="assets/icons/circle-info.svg" 
                        alt="Icon Info"
                        matTooltip="{{generationTypeTip}}"
                        matTooltipPosition="above"
                        matTooltipClass="indicator-tooltip-generation"
                        >
                </div>
                <input 
                    [(ngModel)]="generationForm.generationType" 
                    name="generationType" 
                    #generationType="ngModel"
                    type="text" 
                    class="form-control input-field" 
                    placeholder="{{insertType}}" 
                    (keyup)="sendForm(form.form.valid)"
                    required
                    [class.invalid]="generationType.invalid && (generationType.dirty || generationType.touched)"
                    >
            </div>
            <div class="form-group form-div">
                <label for="nominalLineVoltage">{{ "register.resources.newResource.generation.nominalLineVoltage" | translate }}</label>
                <div class="input-group input-div">
                    <input 
                        [(ngModel)]="generationForm.nominalLineVoltage" 
                        name="nominalLineVoltage" 
                        #nominalLineVoltage="ngModel"
                        type="number" 
                        class="form-control input-unit" 
                        placeholder="{{value}}" 
                        (keyup)="sendForm(form.form.valid)"
                        required
                        [class.invalid]="nominalLineVoltage.invalid && (nominalLineVoltage.dirty || nominalLineVoltage.touched)"
                        >
                    <div class="input-group-append">
                        <span class="unit input-group-text"> V </span>
                    </div>
                </div>
            </div>
            <div class="form-group form-div">
                <label for="nominalVoltage">{{ "register.resources.newResource.generation.nominalVoltage" | translate }}</label>
                <div class="input-group input-div">
                    <input 
                        [(ngModel)]="generationForm.nominalVoltage" 
                        name="nominalVoltage" 
                        #nominalVoltage="ngModel"
                        type="number" 
                        class="form-control input-unit" 
                        placeholder="{{value}}" 
                        (keyup)="sendForm(form.form.valid)"
                        required
                        [class.invalid]="nominalVoltage.invalid && (nominalVoltage.dirty || nominalVoltage.touched)"
                        >
                    <div class="input-group-append">
                        <span class="unit input-group-text"> V </span>
                    </div>
                </div>
            </div>
            <div class="form-group forms-form">
                <label for="nominalPowerPlant">{{ "register.resources.newResource.generation.nominalPowerPlant" | translate }}</label>
                <input 
                    [(ngModel)]="generationForm.nominalPowerPlant" 
                    name="nominalPowerPlant" 
                    #nominalPowerPlant="ngModel"
                    type="number" 
                    class="form-control input-field" 
                    placeholder="{{value}}" 
                    (keyup)="sendForm(form.form.valid)"
                    required
                    [class.invalid]="nominalPowerPlant.invalid && (nominalPowerPlant.dirty || nominalPowerPlant.touched)"
                    >
            </div>
            <div class="form-group forms-form">
                <label for="operatingTemperature">{{ "register.resources.newResource.generation.operatingTemperature" | translate }}</label>
                <div class="double-input">
                    <div class="input-group input-div">
                        <input 
                            [(ngModel)]="generationForm.operatingTemperatureMin" 
                            name="operatingTemperatureMin" 
                            #operatingTemperatureMin="ngModel"
                            type="number" 
                            class="form-control input-unit" 
                            placeholder="{{value}}" 
                            (keyup)="sendForm(form.form.valid)"
                            required
                            [class.invalid]="operatingTemperatureMin.invalid && (operatingTemperatureMin.dirty || operatingTemperatureMin.touched)"
                            >
                        <div class="input-group-append">
                            <span class="unit input-group-text"> °C </span>
                        </div>
                    </div>
                    <p style="font-size: 14px;">{{ "register.resources.newResource.to" | translate }}</p>
                    <div class="input-group input-div">
                        <input 
                            [(ngModel)]="generationForm.operatingTemperatureMax" 
                            name="operatingTemperatureMax" 
                            #operatingTemperatureMax="ngModel"
                            type="number" 
                            class="form-control input-unit" 
                            placeholder="{{value}}" 
                            (keyup)="sendForm(form.form.valid)"
                            required
                            [class.invalid]="operatingTemperatureMax.invalid && (operatingTemperatureMax.dirty || operatingTemperatureMax.touched)"
                            >
                        <div class="input-group-append">
                            <span class="unit input-group-text"> °C </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group form-div">
                <label for="protectionIndex">{{ "register.resources.newResource.generation.protectionIndex" | translate }}</label>
                <div class="input-group input-div">
                    <input 
                        [(ngModel)]="generationForm.protectionIndex" 
                        name="protectionIndex" 
                        #protectionIndex="ngModel"
                        type="number" 
                        class="form-control input-unit" 
                        placeholder="{{value}}" 
                        (keyup)="sendForm(form.form.valid)"
                        required
                        [class.invalid]="protectionIndex.invalid && (protectionIndex.dirty || protectionIndex.touched)"
                        >
                    <div class="input-group-append">
                        <span class="unit input-group-text"> IP </span>
                    </div>
                </div>
            </div>
            <div class="form-group forms-form">
                <label for="numberOfAssets">{{ "register.resources.newResource.generation.numberOfAssets" | translate }}</label>
                <input 
                    [(ngModel)]="generationForm.numberOfAssets" 
                    name="numberOfAssets" 
                    #numberOfAssets="ngModel"
                    type="number" 
                    class="form-control input-field" 
                    placeholder="{{value}}" 
                    (keyup)="sendForm(form.form.valid)"
                    required
                    [class.invalid]="numberOfAssets.invalid && (numberOfAssets.dirty || numberOfAssets.touched)"
                    >
            </div>
            <div class="form-group form-div">
                <div>
                    <label for="generationEfficiency">{{ "register.resources.newResource.generation.generationEfficiency" | translate }}</label>
                    <img 
                        src="assets/icons/circle-info.svg" 
                        alt="Icon Info"
                        matTooltip="{{generationEfficiencyTip}}"
                        matTooltipPosition="above"
                        matTooltipClass="indicator-tooltip"
                        >
                </div>
                <div class="input-group input-div">
                    <input 
                        [(ngModel)]="generationForm.generationEfficiency" 
                        name="generationEfficiency" 
                        #generationEfficiency="ngModel"
                        type="number" 
                        class="form-control input-unit" 
                        placeholder="{{value}}" 
                        (keyup)="sendForm(form.form.valid)"
                        required
                        [class.invalid]="generationEfficiency.invalid && (generationEfficiency.dirty || generationEfficiency.touched)"
                        >
                    <div class="input-group-append">
                        <span class="unit input-group-text"> {{ "register.resources.newResource.label.fuel" | translate }} </span>
                    </div>
                </div>
            </div>
            <div class="form-group form-div">
                <label for="lifespan">{{ "register.resources.newResource.generation.lifespan" | translate }}</label>
                <div class="input-group input-div">
                    <input 
                        [(ngModel)]="generationForm.lifespan" 
                        name="lifespan" 
                        #lifespan="ngModel"
                        type="number" 
                        class="form-control input-unit" 
                        placeholder="{{value}}" 
                        (keyup)="sendForm(form.form.valid)"
                        required
                        [class.invalid]="lifespan.invalid && (lifespan.dirty || lifespan.touched)"
                        >
                    <div class="input-group-append">
                        <span class="unit input-group-text"> {{ "register.resources.newResource.label.years" | translate }} </span>
                    </div>
                </div>
            </div>
            <div class="form-group form-div">
                <label for="purchaseCost">{{ "register.resources.newResource.generation.purchaseCost" | translate }}</label>
                <div class="input-group input-div">
                    <div class="input-group-prepend">
                        <span class="unit input-group-text"> {{ "register.resources.newResource.label.currency" | translate }} </span>
                    </div>
                    <input 
                        [(ngModel)]="generationForm.purchaseCost" 
                        name="purchaseCost" 
                        #purchaseCost="ngModel"
                        type="number" 
                        class="form-control input-unit" 
                        placeholder="Valor" 
                        (keyup)="sendForm(form.form.valid)"
                        required
                        [class.invalid]="purchaseCost.invalid && (purchaseCost.dirty || purchaseCost.touched)"
                        >
                </div>
            </div>
        </div>
        <p class="forms-line"></p>
    </form>
</div>