<div class="register">
    <div class="header">
        <app-header></app-header>
    </div>

    <div class="content">
        <div class="register__nav" [class.collapsed-menu]="menuIsCollapsed">
            <div class="register__nav-header" *ngIf="!menuIsCollapsed">
                <h2>
                  {{ "register.title" | translate }}
                  <i class="fa-regular fa-chevrons-left" (click)="collapseMenu()"
                    matTooltip="{{ hideMenu | translate }}"
                    matTooltipPosition="right"
                    matTooltipClass="tooltip hide"
                  ></i>
                </h2>
                <p>
                  {{ "register.info" | translate }}
                </p>
            </div>

            <a *ngIf="menuIsCollapsed" class="collapsed-menu"
                matTooltip="{{ expandMenu | translate }}"
                matTooltipPosition="right"
                matTooltipClass="tooltip">
                <i class="fa-regular fa-chevrons-right icon" (click)="collapseMenu()"></i>
            </a>
    
            <div class="register__nav-link">
                <a routerLink="/register/general-information" routerLinkActive="active" [class.collapsed-menu]="menuIsCollapsed"
                    matTooltip="{{ generalInformation | translate }}"
                    matTooltipPosition="right"
                    matTooltipClass="tooltip energyResources"
                    [matTooltipDisabled]="!menuIsCollapsed"
                >
                    <i class="fa-regular fa-location-dot" [class.collapsed-menu]="menuIsCollapsed"></i>
                    <span [class.collapsed-menu]="menuIsCollapsed">{{ "register.general_information.link" | translate }}</span>
                </a>
                <a routerLink="/register/distribution-grid" routerLinkActive="active" [class.collapsed-menu]="menuIsCollapsed"
                    matTooltip="{{ distributionNetwork | translate }}"
                    matTooltipPosition="right"
                    matTooltipClass="tooltip energyResources"
                    [matTooltipDisabled]="!menuIsCollapsed"
                >
                    <i class="fa-regular fa-utility-pole" [class.collapsed-menu]="menuIsCollapsed"></i>
                    <span [class.collapsed-menu]="menuIsCollapsed">{{ "register.grid.title" | translate }}</span>
                </a>
                <a routerLink="/register/energy-contracts"  routerLinkActive="active" [class.collapsed-menu]="menuIsCollapsed"
                    matTooltip="{{ energyContracts | translate }}"
                    matTooltipPosition="right"
                    matTooltipClass="tooltip energyResources"
                    [matTooltipDisabled]="!menuIsCollapsed"
                >
                    <i class="fa-regular fa-file-invoice-dollar" [class.collapsed-menu]="menuIsCollapsed"></i>
                    <span [class.collapsed-menu]="menuIsCollapsed">{{ "register.energyContracts.title" | translate }}</span>
                </a>
                <a routerLink="/register/energy-resources" routerLinkActive="active" [class.collapsed-menu]="menuIsCollapsed"
                    matTooltip="{{ energyResources | translate }}"
                    matTooltipPosition="right"
                    matTooltipClass="tooltip energyResources"
                    [matTooltipDisabled]="!menuIsCollapsed"
                >
                    <i class="fa-regular fa-circle-bolt" [class.collapsed-menu]="menuIsCollapsed"></i>
                    <span [class.collapsed-menu]="menuIsCollapsed">{{ "register.resources.title" | translate }}</span>
                </a>
                <a routerLink="/register/user" routerLinkActive="active" [class.collapsed-menu]="menuIsCollapsed"
                    matTooltip="{{ users | translate }}"
                    matTooltipPosition="right"
                    matTooltipClass="tooltip users"
                    [matTooltipDisabled]="!menuIsCollapsed"
                >
                    <i class="fa-regular fa-user" [class.collapsed-menu]="menuIsCollapsed"></i>
                    <span [class.collapsed-menu]="menuIsCollapsed">{{ "register.users.title" | translate }}</span>
                </a>
            </div>
        </div>
    
        <div class="register__container">
            <router-outlet></router-outlet>
        </div>
    </div>

</div>
