import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
})
export class SnackbarComponent {
  @Input() type: 'success' | 'error' | 'danger' | 'warning' | 'info' | 'default' = 'default';
  @Input() message: string = '';
  @Input() closable: boolean = true;
  @Output() closed: EventEmitter<void> = new EventEmitter<void>();

  get alertClass(): string {
    return 'alert-' + this.type;
  }

  close(): void {
    this.closed.emit();
  }
}
