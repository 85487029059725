<div class="home-page">
  <div class="header">
    <app-header></app-header>
  </div>

  <section class="dashboard">
    <app-dashboard-home></app-dashboard-home>
  </section>

  <section class="indicators">
    <h2 class="indicators-title">{{ "indicator.title" | translate }}</h2>

    <div class="indicators-card" [style.overflow-y]="indicators.length > 7 ? 'scroll': 'hidden'">
      <app-card-indicator *ngFor="let indicator of indicators"
        [width]="'250px'"
        [marginBottom]="'24px'"
        [title]="indicator.title"
        [isExpansionPanel]="indicator.isExpansionPanel"
        [value]="indicator.value"
        [valueUnit]="indicator.valueUnit"
        [inPeakTimeValueUnit]="indicator.inPeakTimeValueUnit"
        [outPeakTimeValueUnit]="indicator.outPeakTimeValueUnit"
        [tooltipInfo]="indicator.tooltipInfo"
        [inPeakTimeValue]="indicator.inPeakTimeValue"
        [outPeakTimeValue]="indicator.outPeakTimeValue"
        [type]="indicator.type">
      </app-card-indicator>
    </div>
  </section>

  <section class="state-module">
    <app-card-module *ngFor="let module of modules" [module]="module"></app-card-module>
  </section>
</div>
