import { ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker, MatDatepickerModule} from '@angular/material/datepicker';
import { FormBuilder, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import ModuleRequest, { ModuleRequestViewAttribute } from 'src/app/core/models/request/module-request.model';
import { MatTableDataSource } from '@angular/material/table';
import { TypeModuleEnum } from 'src/app/core/models/enums/typeModuleEnum';
import { ModuleService } from 'src/app/core/services/moduleServices/module.service';
import { LocaleService } from 'src/app/core/services/localeServices/locale.service';

export const INTERNAL_DATE_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-module-monitoring',
  templateUrl: './module-monitoring.component.html',
  styleUrls: ['./module-monitoring.component.scss'],
  providers: [
    {
      provide: MAT_DATE_LOCALE,
      deps: [LocaleService],
      useFactory: (localeService: LocaleService) => localeService.getLocale()
    },
    provideMomentDateAdapter(),
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: INTERNAL_DATE_FORMATS
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ModuleMonitoringComponent {
  private moduleId?: number;
  request: ModuleRequest = {} as ModuleRequest;
  private loaded: boolean = false;
  private otherAttributesDataSource?: MatTableDataSource<ModuleRequestViewAttribute>;
  private storageAttributesDataSource?: MatTableDataSource<ModuleRequestViewAttribute>;

  date: Date;
  data1 = [];
  data2 = [];

  constructor(
    private router: Router,
    private translate: TranslateService,
    private moduleService: ModuleService,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,
  ) {
    this.date = moment().toDate();
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(async (params) => {
      this.moduleId = parseInt(params['module']);
      await this.loadModule(this.moduleId!);
    });
  }

  getOtherAttributeColumns(): string[] {
    return ['name', 'value', 'unity',];
  }

  getStorageColumns(): string[] {
    return ['name', 'value', 'unity'];
  }

  getColumns(): string[] {
    return ['name', 'value', 'unity'];
  }

  getOtherAttributesDataSource(): MatTableDataSource<ModuleRequestViewAttribute>  {
    return this.otherAttributesDataSource!;
  }

  getStorageAttributesDataSource(): MatTableDataSource<ModuleRequestViewAttribute> {
    return this.storageAttributesDataSource!;
  }

  status(): boolean {
    return this.loaded;
  }

  private async loadModule(moduleId: number): Promise<void> {
    this.request = await this.moduleService.getById(moduleId);

    this.otherAttributesDataSource = new MatTableDataSource<ModuleRequestViewAttribute>(this.request.otherAttributes);

    let translatePath;
    let storageAttributesPlain;
    switch (this.request.type) {
      case TypeModuleEnum.storage:
        storageAttributesPlain = Object.entries(this.request.storageAttributes ?? new Object());
        translatePath = 'register.resources.newResource.storage';
        break;
      case TypeModuleEnum.load:
        storageAttributesPlain = Object.entries(this.request.loadAttributes ?? new Object());
        translatePath = 'register.resources.newResource.load';
        break;
      case TypeModuleEnum.generator:
        storageAttributesPlain = Object.entries(this.request.generationAttributes ?? new Object());
        translatePath = 'register.resources.newResource.generation';
        break;
      default: break;
    }

    const storageAttributes = this.mapAttributes(storageAttributesPlain!, translatePath!); 

    this.storageAttributesDataSource = new MatTableDataSource<ModuleRequestViewAttribute>(storageAttributes);
    if (this.request) {
      this.loaded = true;
    } else {
      this.loaded = false;
    }
  }

  private mapAttributes(storageAttributesPlain: [string, any][], translatePath: string): any[] {
    return storageAttributesPlain!.map((st, index) => {
      return {
        name:  this.translate.instant(`${translatePath}.${st[0]}`),
        value: st[1],
        unity: this.translate.instant(`register.resources.newResource.unities.${st[0]}Unity`),
      };
    });
  }

  get module() : number {
    return this.moduleId ?? 0;
  }

  setMonthAndYear(normalizedMonthAndYear: moment.Moment, datepicker: MatDatepicker<moment.Moment>) {
    const ctrlValue = this.date;
    console.log(ctrlValue, normalizedMonthAndYear);
    ctrlValue.setMonth(normalizedMonthAndYear.month());
    ctrlValue.setFullYear(normalizedMonthAndYear.year());
    this.date = ctrlValue;
    datepicker.close();
  }
}
