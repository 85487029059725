<div class="general-information-container">
  <div class="general-information-header">
    <h2>{{ "register.general_information.title" | translate }}</h2>
    <p style="font-weight: 100;margin-top:10px;">{{ "register.general_information.subtitle" | translate }}</p>
  </div>
  <div class="general-information-body">
    <mat-card>
      <mat-card-content>
        <form (ngSubmit)="register()" class="general-information_register" [formGroup]="formToRegister">
          <h4 class="form-title">{{ "register.general_information.section_1" | translate }}</h4>
          <div class="form-row">
              <div class="form-group">
                  <label for="name">{{ "register.general_information.forms.labels.organization.name" | translate }}</label>
                  <input id="name"
                    formControlName="name"
                    class="input-field"
                    placeholder="{{ 'register.general_information.forms.placeholders.organization.name' | translate }}"
                    required
                    (focus)="removeInvalidClass($event)"
                    (blur)="addInvalidClass($event, 'name')"/>
                  <div>
                    <small class="text-error" *ngIf="validateName('required')">{{ "register.general_information.forms.validators.name.required" | translate }}</small>
                    <small class="text-error" *ngIf="validateName('minlength')">{{ "register.general_information.forms.validators.name.minlength" | translate }}</small>
                    <small class="text-error" *ngIf="validateName('maxlength')">{{ "register.general_information.forms.validators.name.maxlength" | translate }}</small>
                  </div>
              </div>
              <div class="form-group">
                  <label for="document">{{ "register.general_information.forms.labels.organization.document" | translate }}</label>
                  <input id="document"
                    formControlName="document"
                    class="input-field"
                    placeholder="{{ 'register.general_information.forms.placeholders.organization.document' | translate }}"
                    type="text"
                    required
                    (focus)="removeInvalidClass($event)"
                    (blur)="addInvalidClass($event, 'document')"/>
                  <div>
                    <small class="text-error" *ngIf="validateDocument('required')">{{ "register.general_information.forms.validators.document.required" | translate }}</small>
                  </div>
              </div>
          </div>

          <div class="divider"></div>

          <h4 class="form-title">{{ "register.general_information.section_2" | translate }}</h4>
          <div class="form-row">
              <div class="form-group">
                  <label for="ugridName">{{ "register.general_information.forms.labels.microgrid.name" | translate }}</label>
                  <input id="ugridName"
                    formControlName="ugridName"
                    class="input-field"
                    type="text"
                    placeholder="{{ 'register.general_information.forms.placeholders.microgrid.name' | translate }}"
                    (focus)="removeInvalidClass($event)"
                    (blur)="addInvalidClass($event, 'ugridName')"/>
                    <div>
                    <small class="text-error" *ngIf="validateUgridName('required')">{{ "register.general_information.forms.validators.ugridName.required" | translate }}</small>
                    <small class="text-error" *ngIf="validateUgridName('minlength')">{{ "register.general_information.forms.validators.ugridName.minlength" | translate }}</small>
                    <small class="text-error" *ngIf="validateUgridName('maxlength')">{{ "register.general_information.forms.validators.ugridName.maxlength" | translate }}</small>
                  </div>
              </div>
              <div class="form-group">
                  <label for="postcode">{{ "register.general_information.forms.labels.microgrid.postcode" | translate }}</label>
                  <input id="postcode"
                    mask="00000-000"
                    formControlName="postcode"
                    class="input-field"
                    type="text"
                    placeholder="{{ 'register.general_information.forms.placeholders.microgrid.postcode' | translate }}"
                    (focus)="removeInvalidClass($event)"
                    (blur)="addInvalidClass($event, 'postcode')" />
                    <div>
                    <small class="text-error" *ngIf="validatePostcode('required')">{{ "register.general_information.forms.validators.postcode.required" | translate }}</small>
                    <small class="text-error" *ngIf="validatePostcode('minlength')">{{ "register.general_information.forms.validators.postcode.minlength" | translate }}</small>
                    <small class="text-error" *ngIf="validatePostcode('maxlength')">{{ "register.general_information.forms.validators.postcode.maxlength" | translate }}</small>
                  </div>
              </div>
              <div class="form-group">
                  <label for="timeZone">{{ "register.general_information.forms.labels.microgrid.timezone" | translate }}</label>
                  <select id="timeZone"
                    formControlName="timeZone"
                    class="input-field"
                    (focus)="removeInvalidClass($event)"
                    (blur)="addInvalidClass($event, 'timeZone')">
                    <option value="" selected>{{ 'register.general_information.forms.placeholders.microgrid.timezone' | translate }}</option>
                    <option *ngFor="let timezone of timeszones" [value]="timezone.value">
                      {{ timezone.value }}
                    </option>
                  </select>
                  <div>
                  <small class="text-error" *ngIf="validateTimeZone('required')">{{ "register.general_information.forms.validators.timeZone.required" | translate }}</small>
                </div>
              </div>
          </div>
          <br />
          <div class="form-row">
              <div class="form-group">
                  <label for="country">{{ "register.general_information.forms.labels.microgrid.country" | translate }}</label>
                  <select id="country"
                    formControlName="country"
                    class="input-field"
                    (focus)="removeInvalidClass($event)"
                    (blur)="addInvalidClass($event, 'country')">
                    <option value="0">{{ 'register.general_information.forms.placeholders.microgrid.country' | translate }}</option>
                    <option *ngFor="let country of countries" [value]="country">
                      {{ country }}
                    </option>
                  </select>
                  <div>
                  <small class="text-error" *ngIf="validateCountry('required')">{{ "register.general_information.forms.validators.country.required" | translate }}</small>
                </div>
              </div>
              <div class="form-group">
                  <label for="state">{{ "register.general_information.forms.labels.microgrid.state" | translate }}</label>
                  <select id="state"
                    formControlName="state"
                    class="input-field"
                    (change)="getCitiesByState()"
                    (focus)="removeInvalidClass($event)"
                    (blur)="addInvalidClass($event, 'state')">
                    <option value="" selected>{{ 'register.general_information.forms.placeholders.microgrid.state' | translate }}</option>
                    <option *ngFor="let state of states" [value]="state.uf">
                      {{ state.name }} ({{ state.uf.toUpperCase() }})
                    </option>
                  </select>
                  <div>
                  <small class="text-error" *ngIf="validateState('required')">{{ "register.general_information.forms.validators.state.required" | translate }}</small>
                </div>
              </div>
              <div class="form-group">
                  <label for="city">{{ "register.general_information.forms.labels.microgrid.city" | translate }}</label>
                  <select id="city"
                    formControlName="city"
                    class="input-field"
                    (focus)="removeInvalidClass($event)"
                    (blur)="addInvalidClass($event, 'city')">
                    <option value="" selected>{{ 'register.general_information.forms.placeholders.microgrid.city' | translate }}</option>
                    <option *ngFor="let city of cities" [value]="city.name">
                      {{ city.name }}
                    </option>
                  </select>
                  <div>
                  <small class="text-error" *ngIf="validateCity('required')">{{ "register.general_information.forms.validators.city.required" | translate }}</small>
                </div>
              </div>
          </div>
          <br />
          <div class="form-row">
              <div class="form-group">
                  <label for="address">{{ "register.general_information.forms.labels.microgrid.address" | translate }}</label>
                  <input id="address"
                    formControlName="address"
                    class="input-field"
                    type="text"
                    placeholder="{{ 'register.general_information.forms.placeholders.microgrid.address' | translate }}"
                    (focus)="removeInvalidClass($event)"
                    (blur)="addInvalidClass($event, 'address')" />
                    <div>
                    <small class="text-error" *ngIf="validateAddress('required')">{{ "register.general_information.forms.validators.address.required" | translate }}</small>
                    <small class="text-error" *ngIf="validateAddress('minlength')">{{ "register.general_information.forms.validators.address.minlength" | translate }}</small>
                    <small class="text-error" *ngIf="validateAddress('maxlength')">{{ "register.general_information.forms.validators.address.maxlength" | translate }}</small>
                  </div>
              </div>
              <div class="form-group">
                  <label for="number">{{ "register.general_information.forms.labels.microgrid.address_number" | translate }}</label>
                  <input id="number"
                    formControlName="number"
                    class="input-field"
                    type="text"
                    placeholder="{{ 'register.general_information.forms.placeholders.microgrid.address_number' | translate }}"
                    (focus)="removeInvalidClass($event)"
                    (blur)="addInvalidClass($event, 'number')" />
                    <div>
                    <small class="text-error" *ngIf="validateNumber('required')">{{ "register.general_information.forms.validators.number.required" | translate }}</small>
                    <small class="text-error" *ngIf="validateNumber('minlength')">{{ "register.general_information.forms.validators.number.minlength" | translate }}</small>
                    <small class="text-error" *ngIf="validateNumber('maxlength')">{{ "register.general_information.forms.validators.number.maxlength" | translate }}</small>
                  </div>
              </div>
              <div class="form-group">
                  <label for="complement">{{ "register.general_information.forms.labels.microgrid.complement" | translate }}</label>
                  <input id="complement"
                    formControlName="complement"
                    class="input-field"
                    type="text"
                    placeholder="{{ 'register.general_information.forms.placeholders.microgrid.complement' | translate }}"
                    (focus)="removeInvalidClass($event)"
                    (blur)="addInvalidClass($event, 'complement')" />
                  <div>
                    <small class="text-error" *ngIf="validateComplement('required')">{{ "register.general_information.forms.validators.complement.required" | translate }}</small>
                    <small class="text-error" *ngIf="validateComplement('minlength')">{{ "register.general_information.forms.validators.complement.minlength" | translate }}</small>
                    <small class="text-error" *ngIf="validateComplement('maxlength')">{{ "register.general_information.forms.validators.complement.maxlength" | translate }}</small>
                  </div>
              </div>
          </div>

          <div class="divider"></div>

          <div>

            <div *ngIf="modeSave">
              <button type="submit"
                class="submit-button"
                (click)="register()"
                [class.disabled]="enableButton()"
                [class.enable]="!enableButton()"
                [disabled]="enableButton()">
                {{ "register.general_information.button_register"  | translate }}
              </button>
            </div>

            <div *ngIf="!modeSave" class="button-group">
              <button type="button"
                *ngIf="formToRegister.disabled"
                class="submit-button btn-ext"
                (click)="toggleInputs()">
                {{ "register.general_information.button_register_edt_cad"  | translate }}
              </button>
              <button type="button"
                *ngIf="!formToRegister.disabled"
                class="button-cancel btn-cancel"
                (click)="toggleInputs()">
                {{ "register.general_information.button_cancel"  | translate }}
              </button>
              <button type="submit"
                *ngIf="!formToRegister.disabled"
                class="submit-button"
                (click)="register()">
                {{ "register.general_information.button_register_edt"  | translate }}
              </button>
            </div>
          </div>

      </form>

      </mat-card-content>
    </mat-card>
  </div>
</div>

