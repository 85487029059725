<div class="dialog">
	<div *ngIf="dialogModel.icon" class="row tag-icon">
        <app-tag-icon
            [icon]="dialogModel.icon"
            [type]="dialogModel.iconType"
            [size]="18">        
        </app-tag-icon>		
	</div>
	<div *ngIf="dialogModel.title" class="panel-title" mat-dialog-title>
		<h4 class="modal-title">{{ dialogModel.title | translate }}</h4>
	</div>
	<div class="modal-body">
		<div mat-dialog-content class="desc-body">
			<div *ngIf="dialogModel.isFailDialog">
				<p>
					{{ "modal.dialogMessageControlFail1" | translate }}
					<span (click)="showSupportRequest()" class="modal-support-request-text" >{{ "modal.dialogMessageControlFail2" | translate }}</span>
					{{ "modal.dialogMessageControlFail3" | translate }}
				</p>
			</div>
			<div *ngIf="dialogModel.defaultMessage">
				<p>{{ dialogModel.message }}</p>
			</div>
			<div *ngIf="!dialogModel.isControllerModule && !dialogModel.defaultMessage; else elseModule">
				<p> 
					{{ "scheduling.modal.text1" | translate }}
					<span>"{{ dialogModel.resource }}"</span>
					{{ "scheduling.modal.text2" | translate }}
				</p>
			</div>
			<ng-template #elseModule>
				<div *ngIf="dialogModel.controlMode == 2 && !dialogModel.defaultMessage; else withoutScheduling">
					<p>
						{{ "modal.dialogMessageWithSchedule1" | translate }} 
						<span>"{{ dialogModel.resource }}"</span>
						{{ "modal.dialogMessageWithSchedule2" | translate }}
					</p>
				</div>
				<ng-template #withoutScheduling class="without-scheduling">
					<p *ngIf="dialogModel.actionSelectedInModule != 3 && !dialogModel.defaultMessage; else isStandBy">
						{{ "modal.dialogMessageWithoutSchedule1" | translate }} 
						<span>"{{ dialogModel.resource }}"</span>
						{{ "modal.willBe" | translate }}
						<span>{{ moduleStatus | translate }}</span>. 
						{{ "modal.doYouWishToContinue" | translate}}
					</p>
					<ng-template #isStandBy>
						<p *ngIf="!dialogModel.defaultMessage">
							{{ "modal.dialogMessageWithoutSchedule1" | translate }} 
							<span>"{{ dialogModel.resource }}"</span>
							{{ "modal.willGoInto"| translate }}
							<span>{{ moduleStatus | translate }}</span>. 
							{{ "modal.doYouWishToContinue" | translate}}
						</p>
					</ng-template>
					
				</ng-template>				
			</ng-template>
		</div>
		<div *ngIf="dialogModel.showButtons" mat-dialog-actions class="dialog-actions">
			<button
				type="button"
				class="modal-button cancel"
				style="margin-right: 5px"
				(click)="onDismiss()"
			>
				{{ "modal.cancel" | translate }}
			</button>
			<button
				type="submit"
				class="modal-button"
				[ngClass]="dialogModel.btnError ? 'remove' : 'send'"
				(click)="onConfirm()"
			>
				{{ "modal.continue" | translate }}
			</button>

		</div>
		<div *ngIf="dialogModel.isFailDialog" mat-dialog-actions class="dialog-actions">
			<button
				type="submit"
				class="modal-button-fail"
				[ngClass]="'send'"
				(click)="onDismiss()"
			>
				{{ "modal.closeTryAgain" | translate }}
			</button>

		</div>
		<div class="info" *ngIf="dialogModel.isControllerModule">
			<p *ngIf="dialogModel.controlMode == 2; else withoutSchedulingInfo">
				<span>{{ "modal.tip" | translate }}</span>
				{{ "modal.infoTip1" | translate }}
				<span>{{ "header.nav.schedules" | translate }},</span>
				{{ "modal.inYour"| translate }}
				<span>{{ "header.controlPanel" | translate }}</span>
				
			</p>
			<ng-template #withoutSchedulingInfo>
				<p>
					<span>{{ "modal.tip" | translate }}</span>
					{{ "modal.infoTip2" | translate }}
					<span>{{ "header.nav.schedules" | translate }},</span>
					{{ "modal.inYour"| translate }}
					<span>{{ "header.controlPanel" | translate }},</span>
					{{ "modal.infoTip3" | translate }}
				</p>
			</ng-template>
			
		</div>
	</div>
</div>