import { IconNameEnum } from "../models/enums/icon-name.enum";

export function getCardIconName(index: number): string | undefined {
    switch (index) {
        case IconNameEnum.wrench:
            return 'wrench';
        case IconNameEnum.enterprise:
            return 'enterprise';
        case IconNameEnum.graphWithZoom:
            return 'graphWithZoom';
        case IconNameEnum.money:
            return 'money';
        case IconNameEnum.target:
            return 'target';
        case IconNameEnum.energy:
            return 'energy';
        default:
            return '';
    }
}

export function getCardIconValue(index: string): number {
    switch (index) {
        case 'wrench':
            return IconNameEnum.wrench;
        case 'enterprise':
            return IconNameEnum.enterprise;
        case 'graphWithZoom':
            return IconNameEnum.graphWithZoom;
        case 'money':
            return IconNameEnum.money;
        case 'target':
            return IconNameEnum.target;
        case 'energy':
            return IconNameEnum.energy;
        default:
            return IconNameEnum.enterprise;
    }
}