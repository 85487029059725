import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root',
})
export class LocaleService extends BaseService {
  getLocale(): string {
    // TODO - Implementar a lógica para retornar o locale do usuário
    return 'pt-BR';
  }
}
