import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, OnInit, Inject } from '@angular/core';
import { ReactiveFormsModule, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  DashboardGraphDataAggregation,
  DashboardGraphDataGranularity,
  getGraphDataAggregationString,
  getGraphDataGranularityString,
} from 'src/app/core/models/interfaces/dashboard.model';
import { DashboardTimesSeries } from 'src/app/core/models/interfaces/dashboard-times-series.model';
import { TimeSeriesService } from 'src/app/core/services/timeSeries/time-series.service';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'dashboard-configuration-graph',
  templateUrl: './dashboard-configuration-graph.component.html',
  styleUrls: ['./dashboard-configuration-graph.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatSelectModule,
    MatIconModule,
    TranslateModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardConfigurationGraph implements OnInit {
  DashboardGraphDataGranularity = DashboardGraphDataGranularity;
  DashboardGraphDataAggregation = DashboardGraphDataAggregation;
  firstFormGroup: FormGroup;

  helpMessage: string = '';
  isLinear = true;
  timesSeries: DashboardTimesSeries[] = [];
  timesSeriesSelected?: DashboardTimesSeries;
  isEditMode: boolean = false;
  dataGranularitySelected?: DashboardGraphDataGranularity;
  dataAggregationSelected?: DashboardGraphDataAggregation;

  constructor(
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<DashboardConfigurationGraph>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private timeSeriesService: TimeSeriesService,
  ) {
    this.firstFormGroup = this.formBuilder.group({
      id: [this.data.id || 1, []],
      title: [this.data.title || '', [Validators.required, Validators.maxLength(32)]],
      description: [this.data.description || '', [Validators.required, Validators.maxLength(200)]],
      timesSeries: [this.data.timesSeries || '', [Validators.required]],
      dataGranularity: [this.data?.dataGranularity ?? '', [Validators.required]],
      dataAggregation: [this.data?.dataAggregation ?? '', [Validators.required]],
    });

    this.dataGranularitySelected = this.data?.dataGranularity ?? '';
    this.dataAggregationSelected = this.data?.dataAggregation ?? '';
    this.timesSeriesSelected = this.data?.timesSeries ?? null;

    this.helpMessage = this.translate.instant('reports.helpMessage');
  }

  setDataGranularity(value: DashboardGraphDataGranularity) {
    this.dataGranularitySelected = value;
  }

  setDataAggregation(value: DashboardGraphDataAggregation) {
    this.dataAggregationSelected = value;
  }

  getDataGranularitySelectedString(): string {
    const label = getGraphDataGranularityString(this.dataGranularitySelected!);
    return this.translate.instant(label);
  }

  getDataAggregationSelectedString(): string {
    const label = getGraphDataAggregationString(this.dataAggregationSelected!);
    return this.translate.instant(label);
  }

  getDataGranularityString(target: DashboardGraphDataGranularity): string {
    const label = getGraphDataGranularityString(target);
    return this.translate.instant(label);
  }

  getDataAggregationString(target: DashboardGraphDataAggregation): string {
    const label = getGraphDataAggregationString(target);
    return this.translate.instant(label);
  }

  getDataAggregationLabelString(target: DashboardGraphDataAggregation): string {
    const label = getGraphDataAggregationString(target);
    return this.translate.instant(`${label}_label`);
  }

  async ngOnInit() {
    this.timesSeries = await this.timeSeriesService.getAllTimeSeriesSingle();
    const ts = this.firstFormGroup.get('timesSeries')?.value ?? undefined;
    if (ts !== undefined && ts !== '' && ts !== null) {
      this.timesSeriesSelected = ts;
    }
  }

  registerNewGraph() {
    if (this.firstFormGroup.valid) {
      const resultToReturn = {
        title: this.firstFormGroup?.get('title')?.value ?? '',
        description: this.firstFormGroup?.get('description')?.value ?? '',
        timesSeries: this.timesSeriesSelected,
        dataGranularity: this.firstFormGroup?.get('dataGranularity')?.value ?? '',
        dataAggregation: this.firstFormGroup?.get('dataAggregation')?.value ?? '',
      };

      this.dialogRef.close(resultToReturn);
    }
  }

  selectTimesSeries(target: number) {
    if (this.timesSeries !== undefined) {
      this.timesSeriesSelected = this.timesSeries.find((ts) => ts.id === target);
    }
  }
}
