<div class="dashboards">
  <form [formGroup]="reportForm">
    <div class="dashboards-header">
      <div class="header-left">
        <div class="text-container">
          <h2>{{ getHeaderTitle() }}</h2>
          <p>{{ getHeaderDescription() }}</p>
        </div>
      </div>
    </div>

    <div class="main-container">
      <mat-card class="parameters-panel card-configuration card-height-max" appearance="outlined">
        <mat-card-content class="card-configuration">
          <div class="parameters">
            <div class="header-parameters">
              <div class="header-left-parameters">
                <h3></h3>
              </div>
            </div>
            <br />
            <div class="header-parameters">
              <div class="header-left-parameters">
                <div class="select">
                  <label class="label">{{ 'reports.parameters.label_dashboard' | translate }}</label>
                  <mat-form-field appearance="outline" class="select-analisy-period">
                    <mat-select
                      placeholder="{{
                        'dashboard_analisys.graphic.modal_configuration_graph.select' | translate
                      }}"
                    >
                      <mat-select-trigger>{{ dashboardSelected?.title }}</mat-select-trigger>
                      <mat-option
                        *ngFor="let ts of dashboards"
                        [value]="ts.dashboardId"
                        (click)="selectDashboard(ts)"
                      >
                        <p class="title">{{ ts.title }}</p>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="body-parameters">
              <div class="select">
                <label class="info" for="tariffSubgroup">{{
                  'dashboard_analisys.parameters.analysis_period.label' | translate
                }}</label>
                <mat-form-field appearance="outline" class="select-analisy-period">
                  <mat-select
                    placeholder="{{
                      'dashboard_analisys.graphic.modal_configuration_graph.select' | translate
                    }}"
                  >
                    <mat-select-trigger>{{ getAnalisyPeriodSelected() }}</mat-select-trigger>

                    <mat-option
                      class="options"
                      (click)="setAnalisyPeriodSelected(DashboardAnalysisPeriod.Today)"
                      [value]="DashboardAnalysisPeriod.Today"
                    >
                      <p class="title">
                        {{ 'dashboard_analisys.parameters.analysis_period.today' | translate }}
                      </p>
                    </mat-option>

                    <mat-option
                      class="options"
                      (click)="setAnalisyPeriodSelected(DashboardAnalysisPeriod.Last_7_Days)"
                      [value]="DashboardAnalysisPeriod.Last_7_Days"
                    >
                      <p class="title">
                        {{ 'dashboard_analisys.parameters.analysis_period.last_7_days' | translate }}
                      </p>
                    </mat-option>

                    <mat-option
                      class="options"
                      (click)="setAnalisyPeriodSelected(DashboardAnalysisPeriod.Last_15_Days)"
                      [value]="DashboardAnalysisPeriod.Last_15_Days"
                    >
                      <p class="title">
                        {{ 'dashboard_analisys.parameters.analysis_period.last_15_days' | translate }}
                      </p>
                    </mat-option>

                    <mat-option
                      class="options"
                      (click)="setAnalisyPeriodSelected(DashboardAnalysisPeriod.Last_30_Days)"
                      [value]="DashboardAnalysisPeriod.Last_30_Days"
                    >
                      <p class="title">
                        {{ 'dashboard_analisys.parameters.analysis_period.last_30_days' | translate }}
                      </p>
                    </mat-option>

                    <mat-option
                      class="options"
                      (click)="setAnalisyPeriodSelected(DashboardAnalysisPeriod.Last_Week)"
                      [value]="DashboardAnalysisPeriod.Last_Week"
                    >
                      <p class="title">
                        {{ 'dashboard_analisys.parameters.analysis_period.last_week' | translate }}
                      </p>
                    </mat-option>

                    <mat-option
                      class="options"
                      (click)="setAnalisyPeriodSelected(DashboardAnalysisPeriod.Last_Month)"
                      [value]="DashboardAnalysisPeriod.Last_Month"
                    >
                      <p class="title">
                        {{ 'dashboard_analisys.parameters.analysis_period.last_month' | translate }}
                      </p>
                    </mat-option>

                    <mat-option
                      class="options"
                      (click)="setAnalisyPeriodSelected(DashboardAnalysisPeriod.Last_Quarter)"
                      [value]="DashboardAnalysisPeriod.Last_Quarter"
                    >
                      <p class="title">
                        {{ 'dashboard_analisys.parameters.analysis_period.last_quarter' | translate }}
                      </p>
                    </mat-option>

                    <mat-option
                      class="options"
                      (click)="setAnalisyPeriodSelected(DashboardAnalysisPeriod.Custom_Period)"
                      [value]="DashboardAnalysisPeriod.Custom_Period"
                    >
                      <p class="title">
                        {{ 'dashboard_analisys.parameters.analysis_period.custom_period' | translate }}
                      </p>
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <div class="form-group" *ngIf="isAnalisyPeriodSelected()">
                  <div class="form-control">
                    <mat-label>{{
                      'dashboard_analisys.parameters.analysis_period_start_date' | translate
                    }}</mat-label>
                    <mat-form-field appearance="outline" class="custom-form-field" required>
                      <input
                        matInput
                        [matDatepicker]="pickerInicialDatePSDi"
                        formControlName="analysisPeriodStartDate"
                      />
                      <mat-datepicker-toggle matIconPrefix [for]="pickerInicialDatePSDi">
                        <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                      </mat-datepicker-toggle>
                      <mat-datepicker #pickerInicialDatePSDi></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div class="form-control">
                    <mat-label>{{
                      'dashboard_analisys.parameters.analysis_period_end_date' | translate
                    }}</mat-label>
                    <mat-form-field appearance="outline" class="custom-form-field" required>
                      <input
                        matInput
                        [matDatepicker]="pickerEndDatePSdf"
                        formControlName="analysisPeriodEndDate"
                      />
                      <mat-datepicker-toggle matIconPrefix [for]="pickerEndDatePSdf">
                        <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                      </mat-datepicker-toggle>
                      <mat-datepicker #pickerEndDatePSdf></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div class="select">
                <label class="info" for="tariffSubgroup">{{
                  'dashboard_analisys.parameters.comparison_period.label' | translate
                }}</label>
                <mat-form-field appearance="outline" class="select-compare-period">
                  <mat-select placeholder="Selecione" required>
                    <mat-select-trigger>{{ getComparePeriodSelected() }}</mat-select-trigger>

                    <mat-option
                      class="options"
                      (click)="setComparePeriodSelected(DashboardComparisonPeriod.Do_Not_Compare)"
                      [value]="DashboardComparisonPeriod.Do_Not_Compare"
                    >
                      <p class="title">
                        {{ 'dashboard_analisys.parameters.comparison_period.do_not_compare' | translate }}
                      </p>
                    </mat-option>

                    <mat-option
                      class="options"
                      (click)="
                        setComparePeriodSelected(DashboardComparisonPeriod.Same_Period_Year_Previous_Year)
                      "
                      [value]="DashboardComparisonPeriod.Same_Period_Year_Previous_Year"
                    >
                      <p class="title">
                        {{
                          'dashboard_analisys.parameters.comparison_period.same_period_year_previous_year'
                            | translate
                        }}
                      </p>
                    </mat-option>

                    <mat-option
                      class="options"
                      (click)="setComparePeriodSelected(DashboardComparisonPeriod.Custom_Period)"
                      [value]="DashboardComparisonPeriod.Custom_Period"
                    >
                      <p class="title">
                        {{ 'dashboard_analisys.parameters.comparison_period.custom_period' | translate }}
                      </p>
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <div class="form-group" *ngIf="comparePeriodSelectedIsCustomPeriod()">
                  <div class="form-control">
                    <mat-label>{{
                      'dashboard_analisys.parameters.comparison_period_start_date' | translate
                    }}</mat-label>
                    <mat-form-field appearance="outline" class="custom-form-field" required>
                      <input
                        matInput
                        [matDatepicker]="pickerInitialDateCPdi"
                        formControlName="comparisonPeriodStartDate"
                      />
                      <mat-datepicker-toggle matIconPrefix [for]="pickerInitialDateCPdi">
                        <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                      </mat-datepicker-toggle>
                      <mat-datepicker #pickerInitialDateCPdi></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div class="form-control">
                    <mat-label>{{
                      'dashboard_analisys.parameters.comparison_period_end_date' | translate
                    }}</mat-label>
                    <mat-form-field appearance="outline" class="custom-form-field" required>
                      <input
                        matInput
                        [matDatepicker]="pickerEndDateCPdf"
                        formControlName="comparisonPeriodEndDate"
                      />
                      <mat-datepicker-toggle matIconPrefix [for]="pickerEndDateCPdf">
                        <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                      </mat-datepicker-toggle>
                      <mat-datepicker #pickerEndDateCPdf></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div class="select"></div>

              <div class="form-group">
                <div class="select">
                  <mat-checkbox formControlName="displayForecast" color="#3CD4A0" [checked]="true"
                    >Exibir previsão a partir do período de análise</mat-checkbox
                  >
                </div>
              </div>
            </div>

            <mat-divider
              [ngClass]="{
                'divider-max': isCardMaxHeight(),
                'divider-min': !isCardMaxHeight()
              }"
            ></mat-divider>
            <div class="body-parameters">
              <div>
                <div class="form-group1">
                  <button type="button" id="btnDownload" (click)="generateReport()" class="btn btn-success">
                    {{ 'reports.buttons.download' | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </form>
</div>
