import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import IndicatorResquest from '../../models/request/indicator-request.model';
import { IndicatorTypeEnum } from '../../models/enums/indicator-type.enum';
import { EnergyUtil } from '../../utils/energy-util';

@Injectable({
  providedIn: 'root',
})
export class IndicatorService extends BaseService {
  constructor() {
    super();
  }

  async getById(timeSeriesId: number) {
    return await this.get(`/time-series/${timeSeriesId}/header`).then((res) => {
      return res.data;
    });
  }

  async setIndicator (id: number, body: any) {
    return await this.patch(`/time-series/indicator-type/${id}`, body)
      .then((res) => {
        return res;
      })
      .catch(() => {
        return false;
      });
  }

  async getAll(): Promise<IndicatorResquest[]> {
    const userData = this.getUserData();

    if (!userData || userData.org.ugridIds.length === 0) return [];

    const ugridId = userData.org.ugridIds[0]; // TODO: Get the first uGrid while the selected one isn't implemented

    const indicators = await this.get(`/indicators/${ugridId}`).then((res) => {
      return res.data as IndicatorResquest[];
    });

    this.formatIndicators(indicators);
    return indicators;
  }

  formatIndicators(indicators: IndicatorResquest[]) {
    indicators.forEach((i) => {
      switch (i.type) {
        case IndicatorTypeEnum.CostFreeMarket:
          i.title = 'indicator.energyCostCM';
          i.tooltipInfo = 'indicator.energyCostCMInfo';
          i.valueUnit = 'indicator.spentMonetary';
          i.inPeakTimeValueUnit = 'indicator.spentMonetary';
          i.outPeakTimeValueUnit = 'indicator.spentMonetary';
          i.value = EnergyUtil.toDecimalValue(+i.value);
          i.inPeakTimeValue = EnergyUtil.toDecimalValue(+i.inPeakTimeValue);
          i.outPeakTimeValue = EnergyUtil.toDecimalValue(+i.outPeakTimeValue);
          i.isExpansionPanel = false;
          break;
        case IndicatorTypeEnum.CostRegulatedMarket:
          i.title = 'indicator.energyCostCC';
          i.tooltipInfo = 'indicator.energyCostCCInfo';
          i.valueUnit = 'indicator.spentMonetary';
          i.inPeakTimeValueUnit = 'indicator.spentMonetary';
          i.outPeakTimeValueUnit = 'indicator.spentMonetary';
          i.value = EnergyUtil.toDecimalValue(+i.value);
          i.inPeakTimeValue = EnergyUtil.toDecimalValue(+i.inPeakTimeValue);
          i.outPeakTimeValue = EnergyUtil.toDecimalValue(+i.outPeakTimeValue);
          i.isExpansionPanel = true;
          break;
        case IndicatorTypeEnum.Consumption:
          i.title = 'indicator.totalConsumption';
          i.valueUnit = EnergyUtil.getUnitEnergyByHour(+i.value);
          i.inPeakTimeValueUnit = EnergyUtil.getUnitEnergyByHour(+i.inPeakTimeValue);
          i.outPeakTimeValueUnit = EnergyUtil.getUnitEnergyByHour(+i.outPeakTimeValue);
          this.convertEnergyIndicators(i);
          i.isExpansionPanel = true;
          break;
        case IndicatorTypeEnum.ConsumptionAndInjection:
          i.title = 'indicator.accumulatedEnergy';
          i.tooltipInfo = 'indicator.accumulatedEnergyInfo';
          i.valueUnit = EnergyUtil.getUnitEnergyByHour(+i.value);
          i.inPeakTimeValueUnit = EnergyUtil.getUnitEnergyByHour(+i.inPeakTimeValue);
          i.outPeakTimeValueUnit = EnergyUtil.getUnitEnergyByHour(+i.outPeakTimeValue);
          this.convertEnergyIndicators(i);
          i.isExpansionPanel = true;
          break;
        case IndicatorTypeEnum.Injection:
          i.title = 'indicator.injectedEnergy';
          i.valueUnit = EnergyUtil.getUnitEnergyByHour(+i.value);
          i.inPeakTimeValueUnit = EnergyUtil.getUnitEnergyByHour(+i.inPeakTimeValue);
          i.outPeakTimeValueUnit = EnergyUtil.getUnitEnergyByHour(+i.outPeakTimeValue);
          this.convertEnergyIndicators(i);
          i.isExpansionPanel = true;
          break;
        case IndicatorTypeEnum.MaxDemand:
          i.title = 'indicator.maximumDemand';
          i.valueUnit = EnergyUtil.getUnitEnergy(+i.value);
          i.inPeakTimeValueUnit = EnergyUtil.getUnitEnergy(+i.inPeakTimeValue);
          i.outPeakTimeValueUnit = EnergyUtil.getUnitEnergy(+i.outPeakTimeValue);
          this.convertEnergyIndicators(i);
          i.isExpansionPanel = true;
          break;
        case IndicatorTypeEnum.LoadFactor:
          i.title = 'indicator.energyFactor';
          i.tooltipInfo = 'indicator.energyFactorInfo';
          i.valueUnit = 'indicator.percent';
          i.value = EnergyUtil.toDecimalValue(+i.value);
          i.inPeakTimeValue = EnergyUtil.toDecimalValue(+i.inPeakTimeValue);
          i.outPeakTimeValue = EnergyUtil.toDecimalValue(+i.outPeakTimeValue);
          i.isExpansionPanel = false;
          break;
      }
    });
  }

  convertEnergyIndicators(i: IndicatorResquest) {
    i.value = EnergyUtil.convertEnergyUnit(+i.value);
    i.inPeakTimeValue = EnergyUtil.convertEnergyUnit(+i.inPeakTimeValue);
    i.outPeakTimeValue = EnergyUtil.convertEnergyUnit(+i.outPeakTimeValue);
  }
}
