<div class="container">
  <div class="left-side">

    <img class="bg-small"  src="../../../assets/imgs/BG_Login_720.svg"  alt="Autopilot By Greenant." />
    <img class="bg-medium" src="../../../assets/imgs/BG_Login_992.svg"  alt="Autopilot By Greenant." />
    <img class="bg-large"  src="../../../assets/imgs/BG_Login_1360.svg" alt="Autopilot By Greenant." />

    <div class="overlay-text">
      <div class="overlay-panel">
        <div class="text-right container-logo">
          <img class="logo-greenant" src="../../../assets/imgs/logo-greenant.svg" alt="Autopilot By Greenant." />
        </div>
        <div class="separator"></div>
        <div class="text-right container-phrase">
          <span class="text-strong">{{ "login.phraseLeftSide.slice1" | translate }}</span> <br />
          <span class="text-strong">{{ "login.phraseLeftSide.slice2" | translate }}</span> <span class="text-thin"> {{ "login.phraseLeftSide.slice3" | translate }}</span><br />
          <span class="text-thin">{{ "login.phraseLeftSide.slice4" | translate }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="right-side">
    <div class="login-form">
      <router-outlet></router-outlet>
    </div>
  </div>

  <div class="loading-screen" *ngIf="loading">
    <div class="loading">
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
      </div>
  </div>

</div>
