import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AccessViewModel } from 'src/app/core/models/interfaces/access.model';
import { UserViewModel } from 'src/app/core/models/interfaces/user.model';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '../auth.service';
import { AxiosError } from 'axios';
import { LoadingService } from '../loading.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
})
export class SigninComponent implements OnInit {
  enableSubmitButton: boolean = false;
  navigateTo: string | undefined;
  access: AccessViewModel = { email: '', password: '' };
  loginForm: FormGroup;
  errorLogin: boolean;
  hide: boolean;
  erro401: boolean;
  erro403: boolean;
  erroChangePassword: boolean;

  constructor(
    private translate: TranslateService,
    private matIconRegistry: MatIconRegistry,
    private authService: AuthService,
    private domSanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private loadingService: LoadingService,
  ) {
    this.translate.setDefaultLang('pt');

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });

    this.erroChangePassword = false;
    this.hide = true;
    this.erro401 = false;
    this.erro403 = false;
    this.errorLogin = false;

    this.matIconRegistry.addSvgIcon(
      'eye',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/eye.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'eye-slash',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/eye-slash.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'error',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/icons/error.svg'),
    );

    this.loadingService.setLoadingStatus(true);
  }

  ngAfterViewInit() {
    this.loginForm.reset();
    this.loginForm.controls['email'].setValue('');
    this.loginForm.controls['password'].setValue('');
  }

  resetForm() {
    this.loginForm.reset();
    this.loginForm.controls['email'].setValue('');
    this.loginForm.controls['password'].setValue('');
    this.erro401 = false;
    this.erro403 = false;
  }

  ngOnInit(): void {
    this.navigateTo = this.activatedRoute.snapshot.queryParams['to'] || '/';

    this.loginForm.valueChanges.subscribe(() => {
      this.access.email = this.loginForm?.get('email')?.value ?? '';
      this.access.password = this.loginForm?.get('password')?.value ?? '';
    });

    this.loadingService.setLoadingStatus(false);
  }

  async login(): Promise<UserViewModel | undefined> {
    this.loadingService.setLoadingStatus(true);
    try {
      const result = await this.authService.login(this.access.email, this.access.password);

      if (!result) throw 'Erro login.';

      this.router.navigate([this.navigateTo]);
      this.errorLogin = false;

      return result;
    } catch (error: AxiosError | any) {
      console.log(error);
      if (error instanceof AxiosError) {
        this.erro401 = error.response != null && error.response != undefined && error.response.status === 401;
        this.erro403 = error.response != null && error.response != undefined && error.response.status === 403;
      } else {
        this.erro401 = true;
        this.erro403 = false;
      }

      this.loginForm.controls['email'].reset();
      this.loginForm.controls['password'].reset();

      this.errorLogin = true;
      return undefined;
    } finally {
      this.loadingService.setLoadingStatus(false);
    }
  }

  shouldShowEmailError(): boolean {
    return (
      this.loginForm.controls['email'].invalid &&
      (this.loginForm.controls['email'].dirty || this.loginForm.controls['email'].touched) &&
      this.loginForm.controls['email']?.errors?.['required']
    );
  }

  shouldShowPasswordError(): boolean {
    return (
      this.loginForm.controls['password'].invalid &&
      (this.loginForm.controls['password'].dirty || this.loginForm.controls['password'].touched) &&
      this.loginForm.controls['password']?.errors?.['password']
    );
  }

  mustEnableButtonSubmit() {
    return !this.shouldShowEmailError() && !this.shouldShowPasswordError();
  }
}
