import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-icon',
  templateUrl: './card-icon.component.html',
  styleUrls: ['./card-icon.component.scss'],
})
export class CardIconComponent {
  @Input() type: string = '';
  
  getIconClass(): string {
    switch (this.type) {
      case 'wrench':
        return 'fa-wrench';
      case 'enterprise':
        return 'fa-building';
      case 'graphWithZoom':
        return 'fa-chart-area';
      case 'money':
        return 'fa-coins';
      case 'target':
        return 'fa-bullseye';
      case 'energy':
        return 'fa-bolt';
      default:
        return 'fa-question-circle';
    }
  }

  getIconStyle(): string {
    switch (this.type) {
      case 'wrench':
        return 'dashboard-icon-wrench';
      case 'enterprise':
        return 'dashboard-icon-enterprise';
      case 'graphWithZoom':
        return 'dashboard-icon-graphWithZoom';
      case 'money':
        return 'dashboard-icon-money';
      case 'target':
        return 'dashboard-icon-target';
      case 'energy':
        return 'dashboard-icon-energy';
      default:
        return '';
    }
  }
}
