<mat-dialog-content class="mat-graph-config">
  <h2>
    {{ 'dashboard_analisys.graphic.modal_configuration_graph.title_modal' | translate }}
  </h2>
  <p>
    {{ 'dashboard_analisys.graphic.modal_configuration_graph.description_modal' | translate }}
  </p>

  <form [formGroup]="firstFormGroup">
    <div class="form-group">
      <label for="title" class="label"
        >{{ 'dashboard_analisys.graphic.modal_configuration_graph.title' | translate }} <span>*</span></label
      >
      <mat-form-field appearance="outline" class="full-width">
        <input
          matInput
          id="title"
          formControlName="title"
          placeholder="{{
            'dashboard_analisys.graphic.modal_configuration_graph.title_placeholder' | translate
          }}"
        />
        <mat-hint>{{
          'dashboard_analisys.graphic.modal_configuration_graph.title_validation_max_caracter' | translate
        }}</mat-hint>
        <mat-error
          *ngIf="firstFormGroup.get('title')?.hasError('required') && firstFormGroup.get('title')?.touched"
        >
          <small>{{
            'dashboard_analisys.graphic.modal_configuration_graph.title_validation_required' | translate
          }}</small>
        </mat-error>
        <mat-error
          *ngIf="firstFormGroup.get('title')?.hasError('maxlength') && firstFormGroup.get('title')?.touched"
        >
          <small>{{
            'dashboard_analisys.graphic.modal_configuration_graph.title_validation_limit_caracter' | translate
          }}</small>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="form-group">
      <label for="description" class="label">{{
        'dashboard_analisys.graphic.modal_configuration_graph.description' | translate
      }}</label>
      <mat-form-field appearance="outline" class="full-width">
        <textarea
          matInput
          id="description"
          formControlName="description"
          placeholder="{{
            'dashboard_analisys.graphic.modal_configuration_graph.description_placeholder' | translate
          }}"
          rows="2"
        ></textarea>
        <mat-hint>{{
          'dashboard_analisys.graphic.modal_configuration_graph.description_placeholder' | translate
        }}</mat-hint>
        <mat-error
          *ngIf="
            firstFormGroup.get('description')?.hasError('required') &&
            firstFormGroup.get('description')?.touched
          "
        >
          <small>{{
            'dashboard_analisys.graphic.modal_configuration_graph.description_validation_required' | translate
          }}</small>
        </mat-error>
        <mat-error
          *ngIf="
            firstFormGroup.get('description')?.hasError('maxlength') &&
            firstFormGroup.get('description')?.touched
          "
        >
          <small>{{
            'dashboard_analisys.graphic.modal_configuration_graph.description_validation_max_caracter'
              | translate
          }}</small>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="form-group">
      <label for="timesSeries" class="label">{{
        'dashboard_analisys.graphic.modal_configuration_graph.timeseries' | translate
      }}</label>
      <mat-form-field appearance="outline" class="select-analisy-period full-width">
        <mat-select
          id="timesSeries"
          placeholder="{{ 'dashboard_analisys.graphic.modal_configuration_graph.select' | translate }}"
          formControlName="timesSeries"
        >
          <mat-select-trigger>{{ timesSeriesSelected?.title }}</mat-select-trigger>
          <mat-option *ngFor="let ts of timesSeries" [value]="ts.id" (click)="selectTimesSeries(ts.id)">
            <p class="title">{{ ts.title }}</p>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="form-container">
      <div class="form-group">
        <label for="dataGranularity" class="label"
          >{{ 'dashboard_analisys.graphic.modal_configuration_graph.data_granularity' | translate }} *
          <button class="round-button">?</button></label
        >
        <mat-form-field appearance="outline" class="select-analisy-period full-width">
          <mat-select
            id="dataGranularity"
            placeholder="{{ 'dashboard_analisys.graphic.modal_configuration_graph.select' | translate }}"
            formControlName="dataGranularity"
          >
            <mat-select-trigger>{{ getDataGranularitySelectedString() }}</mat-select-trigger>

            <mat-option
              (click)="setDataGranularity(DashboardGraphDataGranularity.Hourly)"
              [value]="DashboardGraphDataGranularity.Hourly"
            >
              <p class="title">{{ getDataGranularityString(DashboardGraphDataGranularity.Hourly) }}</p>
            </mat-option>

            <mat-option
              (click)="setDataGranularity(DashboardGraphDataGranularity.Daily)"
              [value]="DashboardGraphDataGranularity.Daily"
            >
              <p class="title">{{ getDataGranularityString(DashboardGraphDataGranularity.Daily) }}</p>
            </mat-option>

            <mat-option
              (click)="setDataGranularity(DashboardGraphDataGranularity.Weekly)"
              [value]="DashboardGraphDataGranularity.Weekly"
            >
              <p class="title">{{ getDataGranularityString(DashboardGraphDataGranularity.Weekly) }}</p>
            </mat-option>

            <mat-option
              (click)="setDataGranularity(DashboardGraphDataGranularity.Monthly)"
              [value]="DashboardGraphDataGranularity.Monthly"
            >
              <p class="title">{{ getDataGranularityString(DashboardGraphDataGranularity.Monthly) }}</p>
            </mat-option>

            <mat-option
              (click)="setDataGranularity(DashboardGraphDataGranularity.Quarterly)"
              [value]="DashboardGraphDataGranularity.Quarterly"
            >
              <p class="title">{{ getDataGranularityString(DashboardGraphDataGranularity.Quarterly) }}</p>
            </mat-option>

            <mat-option
              (click)="setDataGranularity(DashboardGraphDataGranularity.Yearly)"
              [value]="DashboardGraphDataGranularity.Yearly"
            >
              <p class="title">{{ getDataGranularityString(DashboardGraphDataGranularity.Yearly) }}</p>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="form-group">
        <label for="dataAggregation" class="label">{{
          'dashboard_analisys.graphic.modal_configuration_graph.data_aggregation' | translate
        }}</label>
        <mat-form-field appearance="outline" class="select-aggregated-period full-width">
          <mat-select
            id="dataAggregation"
            placeholder="{{ 'dashboard_analisys.graphic.modal_configuration_graph.select' | translate }}"
            formControlName="dataAggregation"
          >
            <mat-select-trigger>{{ getDataAggregationSelectedString() }}</mat-select-trigger>

            <mat-option
              (click)="setDataAggregation(DashboardGraphDataAggregation.Cumulative)"
              [value]="DashboardGraphDataAggregation.Cumulative"
            >
              <p class="title">
                {{ getDataAggregationString(DashboardGraphDataAggregation.Cumulative) }}
              </p>
              <span class="label-option">{{
                getDataAggregationLabelString(DashboardGraphDataAggregation.Cumulative)
              }}</span>
            </mat-option>

            <mat-option
              (click)="setDataAggregation(DashboardGraphDataAggregation.Average)"
              [value]="DashboardGraphDataAggregation.Average"
            >
              <p class="title">{{ getDataAggregationString(DashboardGraphDataAggregation.Average) }}</p>
              <span class="label-option">{{
                getDataAggregationLabelString(DashboardGraphDataAggregation.Average)
              }}</span>
            </mat-option>

            <mat-option
              (click)="setDataAggregation(DashboardGraphDataAggregation.FirstValue)"
              [value]="DashboardGraphDataAggregation.FirstValue"
            >
              <p class="title">
                {{ getDataAggregationString(DashboardGraphDataAggregation.FirstValue) }}
              </p>
              <span class="label-option">{{
                getDataAggregationLabelString(DashboardGraphDataAggregation.FirstValue)
              }}</span>
            </mat-option>

            <mat-option
              (click)="setDataAggregation(DashboardGraphDataAggregation.LastValue)"
              [value]="DashboardGraphDataAggregation.LastValue"
            >
              <p class="title">{{ getDataAggregationString(DashboardGraphDataAggregation.LastValue) }}</p>
              <span class="label-option">{{
                getDataAggregationLabelString(DashboardGraphDataAggregation.LastValue)
              }}</span>
            </mat-option>

            <mat-option
              (click)="setDataAggregation(DashboardGraphDataAggregation.MaximumValue)"
              [value]="DashboardGraphDataAggregation.MaximumValue"
            >
              <p class="title">
                {{ getDataAggregationString(DashboardGraphDataAggregation.MaximumValue) }}
              </p>
              <span class="label-option">{{
                getDataAggregationLabelString(DashboardGraphDataAggregation.MaximumValue)
              }}</span>
            </mat-option>

            <mat-option
              (click)="setDataAggregation(DashboardGraphDataAggregation.MinimumValue)"
              [value]="DashboardGraphDataAggregation.MinimumValue"
            >
              <p class="title">
                {{ getDataAggregationString(DashboardGraphDataAggregation.MinimumValue) }}
              </p>
              <span class="label-option">{{
                getDataAggregationLabelString(DashboardGraphDataAggregation.MinimumValue)
              }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div>
      <mat-divider></mat-divider>
    </div>

    <div class="button-area">
      <button
        mat-button
        [mat-dialog-close]="false"
        class="btn btn-success"
        [disabled]="!firstFormGroup.valid"
        (click)="registerNewGraph()"
        cdkFocusInitial
      >
        {{ 'dashboard_analisys.graphic.modal_configuration_graph.btn_save' | translate }}
      </button>
    </div>
  </form>
</mat-dialog-content>
