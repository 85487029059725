<div class="analysis">
    <div class="header">
      <app-header></app-header>
    </div>

    <div class="content">
      <div class="analysis__nav" [class.collapsed-menu]="menuIsCollapsed">
        <div class="analysis__nav__header" *ngIf="!menuIsCollapsed">
          <h2>
            {{ "analysis.title" | translate }}
            <i class="fa-regular fa-chevrons-left" (click)="collapseMenu()"
              matTooltip="{{ hideMenu | translate }}"
              matTooltipPosition="right"
              matTooltipClass="tooltip hide"
            ></i>
          </h2>
          <p>
            {{ "analysis.info" | translate }}
          </p>
        </div>

        <a *ngIf="menuIsCollapsed" class="collapsed-menu"
          matTooltip="{{ expandMenu | translate }}"
          matTooltipPosition="right"
          matTooltipClass="tooltip"
        >
          <i class="fa-regular fa-chevrons-right icon" (click)="collapseMenu()"></i>
        </a>
    
        <a routerLink="/analysis/dashboards" routerLinkActive="active" [class.collapsed-menu]="menuIsCollapsed"
          matTooltip="{{ dashboard | translate }}"
          matTooltipPosition="right"
          matTooltipClass="tooltip collapsed"
          [matTooltipDisabled]="!menuIsCollapsed"
        >
          <i class="fa-regular fa-table-columns" [class.collapsed-menu]="menuIsCollapsed"></i>
          <span [class.collapsed-menu]="menuIsCollapsed">{{ "analysis.dashboards.title" | translate }}</span>
        </a>
        <a routerLink="/analysis/reports" routerLinkActive="active" [class.collapsed-menu]="menuIsCollapsed"
          matTooltip="{{ reports | translate }}"
          matTooltipPosition="right"
          matTooltipClass="tooltip collapsed"
          [matTooltipDisabled]="!menuIsCollapsed"
        >
          <i class="fa-regular fa-file-chart-column" [class.collapsed-menu]="menuIsCollapsed"></i>
          <span [class.collapsed-menu]="menuIsCollapsed">{{ "analysis.reports.title" | translate }}</span>
        </a>
        <a routerLink="/analysis/time-series" routerLinkActive="active" [class.collapsed-menu]="menuIsCollapsed"
          matTooltip="{{ timeSeries | translate }}"
          matTooltipPosition="right"
          matTooltipClass="tooltip collapsed"
          [matTooltipDisabled]="!menuIsCollapsed"
        >
          <i class="fa-regular fa-chart-line" [class.collapsed-menu]="menuIsCollapsed"></i>
          <span [class.collapsed-menu]="menuIsCollapsed">{{ "analysis.timeSeries.title" | translate }}</span>
        </a>
      </div>
      
      <div class="analysis__container">
        <router-outlet></router-outlet>
      </div>
    </div>
</div>