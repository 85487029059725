import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { DashboardModel, DashboardToReport } from '../../models/interfaces/dashboard.model';
import { getCardIconName } from '../../utils/card-icon.util';
import { DashboardTimeSeriesRequest } from '../../models/interfaces/dashboard-time-series-request.model';
import { DashboardTimeSeriesRemovedResponse, DashboardTimeSeriesResponse } from '../../models/interfaces/dashboard-time-series-response.model';

@Injectable({
  providedIn: 'root',
})
export class DashboardService extends BaseService {
  constructor() {
    super();
  }

  async download(params: DashboardToReport) {
    await this.downloadPdf(`/reports/generate`, params);
  }

  async saveDashboard(target: DashboardModel): Promise<DashboardModel | undefined> {
    const userData = this.getUserData();

    if (!userData || userData.org.ugridIds.length === 0) return undefined;

    (target.orgId = userData.org.orgId), (target.ugridId = userData.org.ugridIds[0]); // TODO: Get the first uGrid while the selected one isn't implemented

    return await this.post(`/analysis-dashboard`, target)
      .then((res) =>
        res.data.map((item: any) => {
          return {
            orgId: item.orgId,
            ugridId: item.ugridId,
            ownerId: item.ownerId,
            dashboardId: item.dashboardId,
            iconType: getCardIconName(item.iconType),
            title: item.title,
            description: item.description,
            lastEdited: item.lastEdited,
            createdAt: new Date(item.createdAt),
            updatedAt: new Date(item.updatedAt),
          } as DashboardModel;
        }),
      )
      .catch((res) => undefined);
  }

  async getAll(): Promise<DashboardModel[]> {
    const userData = this.getUserData();

    if (!userData || userData.org.ugridIds.length === 0) return [];

    const ugridId = userData.org.ugridIds[0]; // TODO: Get the first uGrid while the selected one isn't implemented
    //const ugridId = 2;

    return await this.get(`/analysis-dashboard`).then((res) =>
      res.data.map((item: any) => {
        return {
          orgId: item.orgId,
          ugridId: item.ugridId,
          ownerId: item.ownerId,
          dashboardId: item.dashboardId,
          iconType: getCardIconName(item.iconType),
          title: item.title,
          description: item.description,
          lastEdited: item.lastEdited,
          createdAt: new Date(item.createdAt),
          updatedAt: new Date(item.updatedAt),
        } as DashboardModel;
      }),
    );
  }

  async duplicate(dashboardId: number): Promise<DashboardModel | undefined> {
    return await this.post(`/analysis-dashboard/duplicate/${dashboardId}`, {})
      .then((res) => {
        return {
          orgId: res.data.orgId,
          ugridId: res.data.ugridId,
          ownerId: res.data.ownerId,
          dashboardId: res.data.dashboardId,
          iconType: getCardIconName(res.data.iconType),
          title: res.data.title,
          description: res.data.description,
          lastEdited: res.data.lastEdited,
          createdAt: new Date(res.data.createdAt),
          updatedAt: new Date(res.data.updatedAt),
        } as DashboardModel;
      })
      .catch((res) => undefined);
  }

  async getById(dashboardId: number) {
    return await this.get(`/analysis-dashboard/${dashboardId}`);
  }

  async getTimeSeries(
    tsId: number,
    params: DashboardTimeSeriesRequest,
  ): Promise<DashboardTimeSeriesResponse | DashboardTimeSeriesRemovedResponse | undefined> {
    return this.getWithParams(`/time-series/${tsId}`, params)
      .then((res) => {
        if (res.status === 200) {
          const data = res.data as DashboardTimeSeriesResponse;
          data.status = 200;
          return data;
        }
        else {
          return undefined;
        }
      })
      .catch((res) => {
         let result = res?.response?.data as DashboardTimeSeriesRemovedResponse;
         result.status = 410;
         return result;
      });
  }

  async deleteDashboard(dashboardId: number) {
    return await this.delete(`/analysis-dashboard/${dashboardId}`)
      .then((res) => res.status === 200)
      .catch((res) => undefined);
  }

  async checkTitleUnique(title: string): Promise<boolean> {
    return await this.get(`/analysis-dashboard/verify/name-is-unique?title=${title}`)
      .then((res) => !(res.data.exists ?? false))
      .catch((res) => {
        console.error(res);
        return true;
      });
  }
}
