import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { DashboardTimesSeries } from '../../models/interfaces/dashboard.model';
import { ConstantValueRequest } from '../../models/request/constant-value-request.model';
import { DataUploadRequest } from '../../models/request/data-upload-request.model';
import { DataCrossingRequest } from '../../models/request/data-crossing.model';

@Injectable({
  providedIn: 'root',
})
export class TimeSeriesService extends BaseService {
  constructor() {
    super();
  }

  async getById(timeSeriesId: number) {
    return await this.get(`/time-series/${timeSeriesId}/header`).then((res) => {
      return res.data;
    });
  }

  async getLastData(timeSeriesId: number) {
    return await this.get(`/time-series/${timeSeriesId}/last-data`).then((res) => {
      return res.data;
    });
  }

  async getAllTimeSeries() {
    return await this.get(`/time-series`).then((res) => {
      return res.data;
    });
  }

  async getAllTimeSeriesSingle(): Promise<DashboardTimesSeries[]> {
    return await this.getAllTimeSeries()
      .then((res) => {
        return res.map(
          (ts: any) =>
            ({
              title: ts.name,
              id: ts.timeSeriesId,
              indicatorType: ts.indicatorType,
              indicatorValue: ts.indicatorValue,
            }) as DashboardTimesSeries,
        );
      })
      .catch((res) => []);
  }

  async saveTimeSeriesForm(form: ConstantValueRequest) {
    const userData = this.getUserData();

    if (!userData || userData.org.ugridIds.length === 0) return [];

    const ugridId = userData.org.ugridIds[0]; // TODO: Get the first uGrid while the selected one isn't implemented
    form.ugridId = ugridId;

    return this.post('/time-series', form)
      .then((res) => {
        return res;
      })
      .catch((res) => {
        return res.response;
      });
  }

  async saveTimeSeriesToEdit(form: ConstantValueRequest, timeSerieId: number) {
    return await this.put(`/time-series/${timeSerieId}`, form)
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
  }

  async saveTimeSeriesUploadData(form: DataUploadRequest) {
    const userData = this.getUserData();

    if (!userData || userData.org.ugridIds.length === 0) return [];

    const ugridId = userData.org.ugridIds[0]; // TODO: Get the first uGrid while the selected one isn't implemented
    form.ugridId = ugridId;

    return this.postFile('/time-series/file-to-process', form)
      .then((res) => {
        return res;
      });
  }

  async saveTimeSeriesToEditUploadData(form: DataUploadRequest, timeSerieId: number) {
    return await this.putFile(`/time-series/file-to-process/${timeSerieId}`, form)
    .then((res) => {
      return res;
    })
    .catch((res) => {
      return res;
    });
  }

  async saveTimeSeriesCrossingData(form: DataCrossingRequest) {
    const userData = this.getUserData();

    if (!userData || userData.org.ugridIds.length === 0) return [];

    form.ugridId = userData.org.ugridIds[0];

    return this.post('/time-series/cross-data', form)
      .then((res) => {
        return res;
      })
      .catch((res) => {
        return res;
      })
  }

  async deleteTimeSeries(id: number) {
    return await this.delete(`/time-series/${id}`).then((resp) => {
      return resp.status;
    })
  }
}
