import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { UgridOperationsViewModel } from '../../models/interfaces/ugrid-operatinos.model';
import { UgridOperationGranularityEnum } from '../../models/enums/ugrid-operation-granularity.enum';
import { UgridMeasurementTypeEnum } from '../../models/enums/ugrid-operation-measurement-type.enum';

@Injectable({
  providedIn: 'root',
})
export class UgridOperationService extends BaseService {
  constructor() {
    super();
  }

  async getAll(
    initDate: Date,
    endDate: Date,
    granularity: UgridOperationGranularityEnum,
    measurementType: UgridMeasurementTypeEnum,
  ): Promise<UgridOperationsViewModel[]> {
    const userData = this.getUserData();

    if (!userData || userData.org.ugridIds.length === 0) return [];

    const ugridId = userData.org.ugridIds[0]; // TODO: Get the first uGrid while the selected one isn't implemented

    return await this.getWithParams(`/ugrids/${ugridId}/operations`, { initDate, endDate, granularity, measurementType }).then(
      (res) => {
        return res.data;
      },
    );
  }
}
