import { Component, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogViewModel } from 'src/app/core/models/interfaces/dialog.model';
import { TranslateService } from '@ngx-translate/core';
import { UserRegisterRequest } from 'src/app/core/models/request/user-register-request.model';

@Component({
  selector: 'app-modal-dialog-register',
  templateUrl: './modal-dialog-register.component.html',
  styleUrls: ['./modal-dialog-register.component.scss'],
})
export class ModalDialogRegisterComponent {
  confirm: Subject<boolean> = new Subject();
  close: Subject<boolean> = new Subject();
  dialogModel: DialogViewModel = {} as DialogViewModel;
  users?: UserRegisterRequest[] = [];
  isModalUserRegister: boolean = true;
  isModalModuleRegister: boolean = false;
  textBtn1: string = '';
  textBtn2: string = '';

  constructor(
    private translate: TranslateService,
    public dialogRef: MatDialogRef<ModalDialogRegisterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.dialogModel.icon = 'error';
    this.dialogModel.iconType = data.info.btnError == true ? 'error' : 'error-warning';
    this.dialogModel.title = data.info.title;
    this.dialogModel.message = data.info.message;
    this.dialogModel.btnError = data.info.btnError;
    this.users = data.users;

    this.dialogModel.btnError
      ? (this.textBtn1 = this.translate.instant('modal.cancel'))
      : (this.textBtn1 = this.translate.instant('modal.edit'));
    this.dialogModel.btnError
      ? (this.textBtn2 = this.translate.instant('modal.delete'))
      : (this.textBtn2 = this.translate.instant('modal.toInvite'));
  }

  builderTypeUsers(): void {}

  onConfirm(): void {
    this.confirm.next(true);
  }

  onDismiss(): void {
    this.close.next(true);
    this.dialogRef.close(false);
  }
}
