import { Component, EventEmitter, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SubTypeModuleEnum } from 'src/app/core/models/enums/subtype-module.enum';
import { TypeModuleEnum } from 'src/app/core/models/enums/typeModuleEnum';
import {
  ConnectionRequest,
  NewModuleRequest,
  OtherAttributesRequest,
} from 'src/app/core/models/request/new-module-request.model';
import { NewModuleService } from 'src/app/core/services/newModule/new-module.service';
import { CustomSnackbarErrorComponent } from 'src/app/shared/components/custom-snackbar-error/custom-snackbar-error.component';
import { CustomSnackbarSuccessComponent } from 'src/app/shared/components/custom-snackbar-success/custom-snackbar-success.component';
import { AppConstants } from 'src/app/shared/constants/app.constants';

@Component({
  selector: 'app-new-resource',
  templateUrl: './new-resource.component.html',
  styleUrls: ['./new-resource.component.scss'],
})
export class NewResourceComponent {
  form: NewModuleRequest = {} as NewModuleRequest;
  otherAttributesForm: OtherAttributesRequest = {} as OtherAttributesRequest;
  connectionForm: ConnectionRequest = {} as ConnectionRequest;
  connectionBuilder: any[] = [];
  connectionFormTags!: Map<string, string>;
  connectionInputUser: string = '';
  attributesForm: FormGroup;
  selectedType: number = NaN;
  selectedSubType: string = '';
  value = this.translate.instant('register.resources.newResource.value');
  select = this.translate.instant('register.resources.newResource.select');
  insertResourceName = this.translate.instant('register.resources.newResource.insertResourceName');
  insertAttributeName = this.translate.instant('register.resources.newResource.insertAttributeName');
  nameTip = this.translate.instant('register.resources.newResource.toolTip.nameTip');
  othersTip = this.translate.instant('register.resources.newResource.toolTip.othersTip');
  typeFormValid: boolean = false;
  labelSnackBar: string = '';
  private storage: Storage;
  moduleId: number = NaN;
  editing: boolean = false;
  @Output() moduleSaved = new EventEmitter<boolean>();
  subTypes: any[] = [];
  typesForm: any[] = [
    {
      type: TypeModuleEnum.storage,
      title: this.translate.instant('register.resources.newResource.types.storage'),
    },
    {
      type: TypeModuleEnum.load,
      title: this.translate.instant('register.resources.newResource.types.load'),
    },
    {
      type: TypeModuleEnum.generator,
      title: this.translate.instant('register.resources.newResource.types.generation'),
    },
  ];

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private newModuleService: NewModuleService,
    private snackBar: MatSnackBar,
  ) {
    this.storage = window.localStorage;
    this.attributesForm = this.fb.group({
      otherAttributes: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    this.editing = JSON.parse(this.storage.getItem('editing') || 'false');
    if (this.editing) {
      this.moduleId = Number(this.storage.getItem('moduleId'));
      this.storage.removeItem('moduleId');
      this.getModuleById();
    } else {
      this.editing = false;
      this.connectionFormTags = new Map<string, string>();
      this.getDataSource();
    }
  }

  tagsConstructor() {
    this.form.connection = this.connectionForm;
    this.form.connection.tags = Object.fromEntries(this.connectionFormTags);
  }

  selectingConnection() {
    this.connectionFormTags = new Map<string, string>();
    for (const [k, v] of Object.entries(this.connectionForm.tags)) {
      this.connectionFormTags.set(k, v);
    }
  }

  getModuleById() {
    this.newModuleService.getModuleById(this.moduleId).then((res) => {
      this.form = res;
      this.selectingType(res.type);
      this.subTypeLabel(res.subtype);
      this.connectionForm = res.connection;
      this.connectionForm.tags = res.connection.tags;
      this.selectingConnection();
      
      this.connectionBuilder = [res.connection];
      const length = res.otherAttributes.length;
      if (length > 0) {
        this.otherAttributesForm = res.otherAttributes[0];
        this.attributesForm.value.otherAttributes.push(res.otherAttributes[1]);
        for (let i = 1; i < length; i++) {
          this.buildOtherAttributesForEdit(res.otherAttributes[i]);
        }
      }
      this.storage.setItem('form', JSON.stringify(this.form));
    });
  }

  getDataSource(): void {
    this.newModuleService.getDataSouce().then((res) => {
      this.conectionBuilder(res);
    });
  }

  conectionBuilder(conection: any): void {
    conection.forEach((element: any) => {
      this.connectionBuilder.push(element);
    });
  }

  get checkConnectionFormValid(): boolean {
    if (!this.connectionForm.connectionId) {
      return false;
    }
    return true;
  }

  get checkConnectionFormTagsValid(): boolean {
    let aux: boolean = true;
    const array = Array.from(this.connectionFormTags, ([name, value]) => ({ name, value }));
    array.forEach((el) => {
      if (!el.value) {
        aux = false;
      }
    });
    return aux;
  }

  otherAttributes(): FormArray {
    return this.attributesForm.get('otherAttributes') as FormArray;
  }

  newAttribute(): FormGroup {
    return this.fb.group({
      name: '',
      unity: '',
      value: '',
    });
  }

  addOtherAttributes(): void {
    this.otherAttributes().push(this.newAttribute());
  }

  buildOtherAttributesForEdit(data: OtherAttributesRequest): void {
    this.otherAttributes().push(this.attributeForEdit(data));
  }

  attributeForEdit(data: OtherAttributesRequest): FormGroup {
    return this.fb.group({
      name: data.name,
      unity: data.unity,
      value: data.value,
    });
  }

  removeOthersAttributes(index: number): void {
    this.otherAttributes().removeAt(index);
    this.builderOttherAttributeObject();
  }

  selectingType(type: any): void {
    if (Number.isNaN(this.selectedType)) {
      this.selectedType = type;
    } else if (this.selectedType != type) {
      switch (this.selectedType) {
        case 0:
          delete this.form.loadAttributes;
          this.selectedType = type;
          break;
        case 1:
          delete this.form.generationAttributes;
          this.selectedType = type;
          break;
        case 3:
          delete this.form.storageAttributes;
          this.selectedType = type;
          break;
        default:
          break;
      }
      delete this.form.subtype;
    }
    this.subTypesConstruction(this.selectedType);
  }

  subTypesConstruction(selectedType: any): void {
    switch (selectedType) {
      case 3:
        this.form.subtype = SubTypeModuleEnum.other;
        break;
      case 0:
        this.subTypes = [
          {
            type: SubTypeModuleEnum.passive,
            title: this.translate.instant('register.resources.newResource.subTypes.fixed'),
            description: this.translate.instant('register.resources.newResource.subTypes.fixedDesc'),
          },
          {
            type: SubTypeModuleEnum.controllable,
            title: this.translate.instant('register.resources.newResource.subTypes.controllable'),
            description: this.translate.instant('register.resources.newResource.subTypes.controllableDesc'),
          },
        ];
        break;
      case 1:
        this.subTypes = [
          {
            type: SubTypeModuleEnum.dispatchable,
            title: this.translate.instant('register.resources.newResource.subTypes.dispatchable'),
            description: this.translate.instant('register.resources.newResource.subTypes.dispatchableDesc'),
          },
          {
            type: SubTypeModuleEnum.solar,
            title: this.translate.instant('register.resources.newResource.subTypes.renewable'),
            description: this.translate.instant('register.resources.newResource.subTypes.renewableDesc'),
          },
        ];
        break;

      default:
        this.subTypes = [];
        break;
    }
  }

  subTypeSelected(value: string): void {
    this.selectedSubType = value;
  }

  subTypeLabel(subtype: number): void {
    switch (subtype) {
      case SubTypeModuleEnum.passive:
        this.selectedSubType = this.translate.instant('register.resources.newResource.subTypes.fixed');
        break;
      case SubTypeModuleEnum.controllable:
        this.selectedSubType = this.translate.instant('register.resources.newResource.subTypes.controllable');
        break;
      case SubTypeModuleEnum.dispatchable:
        this.selectedSubType = this.translate.instant('register.resources.newResource.subTypes.dispatchable');
        break;
      case SubTypeModuleEnum.solar:
        this.selectedSubType = this.translate.instant('register.resources.newResource.subTypes.renewable');
        break;
      default:
        break;
    }
  }

  recievingFormFromChild(formEmitted: any): void {
    this.typeFormValid = formEmitted.valid;
    const name = this.form.name;
    this.form = { ...this.form, ...formEmitted.form };
    this.form.name = name;
  }

  builderOttherAttributeObject(): void {
    this.form.otherAttributes = [this.otherAttributesForm];
    let auxForm: OtherAttributesRequest = {} as OtherAttributesRequest;
    if (this.attributesForm.value.otherAttributes.length > 0) {
      this.attributesForm.value.otherAttributes.forEach((element: any) => {
        auxForm = {
          name: element.name,
          value: element.value,
          unity: element.unity,
        };
        this.form.otherAttributes?.push(auxForm);
      });
    }
  }

  cancelForm(): void {
    this.storage.removeItem('editing');
    this.editing = false;
    this.moduleSaved.emit(true);
  }

  saveForm(formValid: boolean): void {
    if (formValid == true && this.typeFormValid == true && !this.editing) {
      this.form.controlMode = 1;
      this.newModuleService.saveModuleForm(this.form).then((res) => {
        this.openSnackbar(res);
      });
    }
    if (formValid == true && this.typeFormValid == true && this.editing) {
      this.newModuleService.editModule(this.form, this.moduleId).then((res) => {
        this.openSnackbar(res);
      });
    }
  }

  openSnackbar(status: number): void {
    switch (status) {
      case AppConstants.CREATE_SUCESS:
        this.snackBar.openFromComponent(CustomSnackbarSuccessComponent, {
          data: {
            message: this.translate.instant('snackBar.newModule.moduleCreated'),
            snackBar: this.snackBar,
          },
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: AppConstants.TREE_SECOND_WAIT,
        });
        this.moduleSaved.emit(true);
        break;
      case AppConstants.OK:
        this.snackBar.openFromComponent(CustomSnackbarSuccessComponent, {
          data: {
            message: this.translate.instant('snackBar.newModule.moduleEdited'),
            snackBar: this.snackBar,
          },
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: AppConstants.TREE_SECOND_WAIT,
        });
        this.moduleSaved.emit(true);
        break;
      default:
        this.snackBar.openFromComponent(CustomSnackbarErrorComponent, {
          data: {
            message: this.translate.instant('snackBar.newModule.moduleErroCreating'),
            snackBar: this.snackBar,
          },
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: AppConstants.TREE_SECOND_WAIT,
        });
    }
  }

  trackByFn(index: any) {
    return index;
  }
}
