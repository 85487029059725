import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TypeModuleEnum } from 'src/app/core/models/enums/typeModuleEnum';
import { DialogViewModel } from 'src/app/core/models/interfaces/dialog.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalDialogComponent } from '../modal-dialog/modal-dialog.component';
import { ActionModuleControllerEnum } from 'src/app/core/models/enums/actionModuleControllerEnum';
import { ActionModuleController } from 'src/app/core/models/interfaces/action-module-controller.model';
import { ModuleService } from 'src/app/core/services/moduleServices/module.service';
import { TranslateService } from '@ngx-translate/core';
import { CustomSnackbarSuccessComponent } from '../custom-snackbar-success/custom-snackbar-success.component';
import { CustomSnackbarErrorComponent } from '../custom-snackbar-error/custom-snackbar-error.component';
import { AppConstants } from 'src/app/shared/constants/app.constants';
import setIcon from 'src/app/core/utils/setIcons';
import { SchedulesControlMode } from 'src/app/core/models/enums/schedules-control-mode.enum';
import { StatusModuleEnum } from 'src/app/core/models/enums/statusModuleEnum';
import { TechnicalSupportModal } from 'src/app/core/models/interfaces/technical-support-modal.model';
import { TechnicalSupportModalComponent } from '../technical-support/technical-support-modal.component';

@Component({
  selector: 'app-card-module-controller',
  templateUrl: './card-module-controller.component.html',
  styleUrls: ['./card-module-controller.component.scss'],
})
export class CardModuleControllerComponent {
  @Input() name: string = '';
  @Input() moduleId: number = -1;
  @Input() value: number = 0;
  @Input() valueUnit: string = '';
  @Input() status: number = 0;
  @Input() statusName: string = '';
  @Input() tooltipInfo: string = '';
  @Input() typeModule: TypeModuleEnum = TypeModuleEnum.generator;
  @Input() chargingLevel: number = 0;
  @Input() controlMode: SchedulesControlMode = 0;
  @Input() loggerUserRole: string | undefined = '' ;
  @Input() loadingStatus: boolean = false;
  @Input() loadStatusFail: boolean = false;
  height: string = '210px'
  labelSnackBar: string = '';
  dialogRef!: MatDialogRef<any>;
  icon: string = '';
  actions: ActionModuleController[] = [];
  selectedAction: number = 0;
  @Output() changeModule = new EventEmitter();
  @ViewChild('activeTemplate') activeTemplate!: TemplateRef<any>;

  constructor(
    public dialog: MatDialog,
    private moduleService: ModuleService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {    
    if (this.loggerUserRole == 'basic') {
      this.height = '136px';
    }

    this.setActions();

    this.icon = setIcon(this.typeModule);

    this.selectedAction = this.actions?.length > 0 ? this.actions[0].actionCode : 0;
  }

  setActions(): void {
    const turnOn = {
      actionCode: ActionModuleControllerEnum.turnOn,
      actionName: 'module.actionTurnOn',
    };

    const turnOff = {
      actionCode: ActionModuleControllerEnum.turnOff,
      actionName: 'module.actionTurnOff',
    };

    const standBy = {
      actionCode: ActionModuleControllerEnum.standBy,
      actionName: 'module.actionStandBy',
    };

    const charge = {
      actionCode: ActionModuleControllerEnum.charge,
      actionName: 'module.actionCharge',
    };

    const unCharge = {
      actionCode: ActionModuleControllerEnum.uncharge,
      actionName: 'module.actionUncharge',
    };

    if (this.typeModule == TypeModuleEnum.storage) {
      if (this.status == StatusModuleEnum.on) {
        this.actions.push(unCharge);
        this.actions.push(standBy);
      }
      if (this.status == StatusModuleEnum.off) {
        this.actions.push(charge);
        this.actions.push(standBy);
      }
      if (this.status == StatusModuleEnum.unknown) {
        this.actions.push(charge);
        this.actions.push(unCharge);
      }
    } else {
      if (this.status == StatusModuleEnum.on) {
        this.actions.push(turnOff);
        this.actions.push(standBy);
      }
      if (this.status == StatusModuleEnum.off) {
        this.actions.push(turnOn);
        this.actions.push(standBy);
      }
      if (this.status == StatusModuleEnum.unknown) {
        this.actions.push(turnOn);
        this.actions.push(turnOff);
      }
    }
  }

  async openActionControlFailDialog(): Promise<void> {
    const dialogModel: DialogViewModel = {} as DialogViewModel;
    dialogModel.icon = 'error';
    dialogModel.iconType = 'error';
    dialogModel.showButtons = false;
    dialogModel.resource = this.name;
    dialogModel.defaultMessage = true;
    dialogModel.isFailDialog = true;
    dialogModel.title = 'modal.title.actionFail';
    
    this.dialogRef = this.dialog.open(ModalDialogComponent, {
      disableClose: true,
      data: dialogModel,
      width: '445px',
    });
    this.dialogRef.componentInstance.supportRequestAction.subscribe({
      next: async () => {
        this.dialogRef.close();
        const techSupportModal: TechnicalSupportModal = { email:'', location: '', name: '' };
        this.openSupportRequestDialog(techSupportModal);
      }
    });
  }

  async openDialog(action: ActionModuleControllerEnum): Promise<void> {
    const dialogModel: DialogViewModel = {} as DialogViewModel;
    dialogModel.icon = 'warning';
    dialogModel.iconType = 'warning';
    dialogModel.showButtons = true;
    dialogModel.btnError = false;
    dialogModel.btnMessage = '';
    dialogModel.resource = this.name;
    dialogModel.controlMode = this.controlMode;
    dialogModel.actionSelectedInModule = this.selectedAction;
    dialogModel.isControllerModule = true;
    if (action == ActionModuleControllerEnum.turnOn || action == ActionModuleControllerEnum.charge) {
      dialogModel.title = 'modal.title.turnOnFeature';
    }
    if (action == ActionModuleControllerEnum.turnOff || action == ActionModuleControllerEnum.uncharge) {
      dialogModel.title = 'modal.title.turnOffFeature';
    }
    if (action == ActionModuleControllerEnum.standBy) {
      dialogModel.title = 'modal.title.standBy';
    }

    this.dialogRef = this.dialog.open(ModalDialogComponent, {
      disableClose: true,
      data: dialogModel,
      width: '445px',
    });

    this.dialogRef.componentInstance.confirm.subscribe({
      next: async () => {
        this.dialogRef.close();
        this.loadingStatus = true;
        await this.moduleService
          .changeStateControl(this.moduleId, this.selectedAction)
          .then(async () => {
            const changeStatus = await this.moduleService.checkStatus(this.moduleId, this.selectedAction);
            const statusIsChecked = changeStatus ? true : false;
            this.loadStatusFail = !statusIsChecked;
            this.loadingStatus = false;
            if (!statusIsChecked) 
              this.openActionControlFailDialog();
            else {
              this.openSnackBar(this.selectedAction, statusIsChecked);
              this.status = changeStatus.status;
              this.value = changeStatus.power;
            }
          })
      },
      error: () => {
        console.log('error');
      },
      finally: () => {
        this.loadingStatus = false;
      }
    });
  }

  openSnackBar(action: ActionModuleControllerEnum, isSuccess: boolean): void {
    switch (action) {
      case ActionModuleControllerEnum.turnOn:
        isSuccess
          ? (this.labelSnackBar = this.translate.instant('snackBar.module.turnOn'))
          : (this.labelSnackBar = this.translate.instant('snackBar.module.error'));
        this.snackBar.openFromComponent(
          isSuccess ? CustomSnackbarSuccessComponent : CustomSnackbarErrorComponent,
          {
            data: {
              message: this.labelSnackBar,
              snackBar: this.snackBar,
            },
            horizontalPosition: 'right',
            verticalPosition: 'top',
            duration: AppConstants.TREE_SECOND_WAIT,
          },
        );
        break;
      case ActionModuleControllerEnum.turnOff:
        isSuccess
          ? (this.labelSnackBar = this.translate.instant('snackBar.module.turnOff'))
          : (this.labelSnackBar = this.translate.instant('snackBar.module.error'));
        this.snackBar.openFromComponent(
          isSuccess ? CustomSnackbarSuccessComponent : CustomSnackbarErrorComponent,
          {
            data: {
              message: this.labelSnackBar,
              snackBar: this.snackBar,
            },
            horizontalPosition: 'right',
            verticalPosition: 'top',
            duration: AppConstants.TREE_SECOND_WAIT,
          },
        );
        break;
      case ActionModuleControllerEnum.charge:
        isSuccess
          ? (this.labelSnackBar = this.translate.instant('snackBar.module.uploadResource'))
          : (this.labelSnackBar = this.translate.instant('snackBar.module.error'));
        this.snackBar.openFromComponent(
          isSuccess ? CustomSnackbarSuccessComponent : CustomSnackbarErrorComponent,
          {
            data: {
              message: this.labelSnackBar,
              snackBar: this.snackBar,
            },
            horizontalPosition: 'right',
            verticalPosition: 'top',
            duration: AppConstants.TREE_SECOND_WAIT,
          },
        );
        break;
      case ActionModuleControllerEnum.uncharge:
        isSuccess
          ? (this.labelSnackBar = this.translate.instant('snackBar.module.downloadResource'))
          : (this.labelSnackBar = this.translate.instant('snackBar.module.error'));
        this.snackBar.openFromComponent(
          isSuccess ? CustomSnackbarSuccessComponent : CustomSnackbarErrorComponent,
          {
            data: {
              message: this.labelSnackBar,
              snackBar: this.snackBar,
            },
            horizontalPosition: 'right',
            verticalPosition: 'top',
            duration: AppConstants.TREE_SECOND_WAIT,
          },
        );
        break;
      case ActionModuleControllerEnum.injecting:
        isSuccess
          ? (this.labelSnackBar = this.translate.instant('snackBar.module.turnOn'))
          : (this.labelSnackBar = this.translate.instant('snackBar.module.error'));
        this.snackBar.openFromComponent(
          isSuccess ? CustomSnackbarSuccessComponent : CustomSnackbarErrorComponent,
          {
            data: {
              message: this.labelSnackBar,
              snackBar: this.snackBar,
            },
            horizontalPosition: 'right',
            verticalPosition: 'top',
            duration: AppConstants.TREE_SECOND_WAIT,
          },
        );
        break;
      case ActionModuleControllerEnum.consuming:
        isSuccess
          ? (this.labelSnackBar = this.translate.instant('snackBar.module.turnOn'))
          : (this.labelSnackBar = this.translate.instant('snackBar.module.error'));
        this.snackBar.openFromComponent(
          isSuccess ? CustomSnackbarSuccessComponent : CustomSnackbarErrorComponent,
          {
            data: {
              message: this.labelSnackBar,
              snackBar: this.snackBar,
            },
            horizontalPosition: 'right',
            verticalPosition: 'top',
            duration: AppConstants.TREE_SECOND_WAIT,
          },
        );
        break;
      case ActionModuleControllerEnum.standBy:
        isSuccess
          ? (this.labelSnackBar = this.translate.instant('snackBar.module.standBy'))
          : (this.labelSnackBar = this.translate.instant('snackBar.module.error'));
        this.labelSnackBar = this.translate.instant('snackBar.module.standBy');
        this.snackBar.openFromComponent(
          isSuccess ? CustomSnackbarSuccessComponent : CustomSnackbarErrorComponent,
          {
            data: {
              message: this.labelSnackBar,
              snackBar: this.snackBar,
            },
            horizontalPosition: 'right',
            verticalPosition: 'top',
            duration: AppConstants.TREE_SECOND_WAIT,
          },
        );
        break;
      default:
        break;
    }
  }

  openSupportRequestDialog(data: TechnicalSupportModal): void {
    this.dialog.open(TechnicalSupportModalComponent, {
      width: '400px',
      data: data
    });
  }
}
