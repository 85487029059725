import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { CreateScheduleRequest } from '../../models/request/schedules-request.model';

@Injectable({
  providedIn: 'root',
})
export class SchedulingService extends BaseService {
  constructor() {
    super();
  }

  async changeModule(id: number, body: number) {
    return await this.patch(`/modules/${id}/control/mode`, { controlMode: body })
      .then((res) => {
        return true;
      })
      .catch(() => {
        return false;
      });
  }

  async getAllModules() {
    const userData = this.getUserData();

    if (!userData || userData.org.ugridIds.length === 0) return [];

    const ugridId = userData.org.ugridIds[0]; // TODO: Get the first uGrid while the selected one isn't implemented

    return await this.get(`/schedules/${ugridId}/modules`).then((res) => {
      return res.data;
    });
  }

  async createNewStatus(newStatus: CreateScheduleRequest) {
    return await this.post(`/schedules`, newStatus).then((res) => {
      return res.status
    })
  }

  async getSchedulePolicy(moduleId: number) {
    return await this.get(`/schedules/modules/${moduleId}`).then((resp) => {
      return resp.data;
    })
  }

  async editScheduleStatus(moduleId: number, body: any) {
    return await this.put(`/schedules/module/${moduleId}`, body).then((resp) => {
      return resp;
    }).catch((error) => {
      return error;
    })
  }
}
