import { Component } from '@angular/core';
import { BuildMenuMicrogrid } from 'src/app/core/models/interfaces/build-menu-microgrid.model';
import ModuleRequest from 'src/app/core/models/request/module-request.model';
import { ModuleService } from 'src/app/core/services/moduleServices/module.service';

@Component({
  selector: 'app-monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.scss'],
})
export class MonitoringComponent {
  panelOpen: boolean = false;
  optionMenu: BuildMenuMicrogrid[] = [];
  optionMenuStorage: ModuleRequest[] = [];
  optionMenuRenewableGeneration: ModuleRequest[] = [];
  optionMenuDispatchableGeneration: ModuleRequest[] = [];
  optionMenuFixedLoad: ModuleRequest[] = [];
  optionMenuControllableLoad: ModuleRequest[] = [];
  isOptionMenuStorage: boolean = false;
  isOptionMenuMicrored: boolean = false;
  isOptionMenuControllableLoad: boolean = false;
  isOptionMenuFixedLoad: boolean = false;
  isOptionMenuRenewableGeneration: boolean = false;
  isOptionMenuDispatchableGeneration: boolean = false;
  modules: ModuleRequest[] = [];
  menuIsCollapsed: boolean = false;
  expandMenu: string = 'monitoring.expandMenu';
  hideMenu: string = 'monitoring.hideMenu';
  microgrid: string = 'monitoring.dashboard';
  storage: string = 'monitoring.storage';
  renewableGeneration: string = 'monitoring.renewableGeneration';
  dispatchableGeneration: string = 'monitoring.dispatchableGeneration';
  fixedLoad: string = 'monitoring.fixedLoad';
  controllableLoad: string = 'monitoring.controllableLoad';

  constructor(private moduleService: ModuleService) {}

  async ngOnInit(): Promise<void> {
    this.modules = await this.getAllModules();
    this.buildingMenu(this.modules ?? []);
  }

  getAllModules(): Promise<ModuleRequest[]> {
    return this.moduleService.getAll().then((resp) => {
      return resp;
    });
  }

  buildingMenu(modules: ModuleRequest[] | []): void {
    modules.forEach((module) => {
      if (module.type == 0 && module.subtype == 1) {
        this.isOptionMenuControllableLoad = true;
        this.optionMenuControllableLoad.push(module);
      }
      if (module.type == 0 && module.subtype == 0) {
        this.isOptionMenuFixedLoad = true;
        this.optionMenuFixedLoad.push(module);
      }
      if (module.type == 1 && (module.subtype == 3 || module.subtype == 4)) {
        this.isOptionMenuRenewableGeneration = true;
        this.optionMenuRenewableGeneration.push(module);
      }
      if (module.type == 1 && module.subtype == 2) {
        this.isOptionMenuDispatchableGeneration = true;
        this.optionMenuDispatchableGeneration.push(module);
      }
      if (module.type == 2) {
        this.isOptionMenuMicrored = true;
      }
      if (module.type == 3) {
        this.isOptionMenuStorage = true;
        this.optionMenuStorage.push(module);
      }
      this.optionMenu.push({ name: module.name, type: module.type, subtype: module.subtype });
    });
  }

  collapseMenu() {
    this.menuIsCollapsed = !this.menuIsCollapsed;
  }
}
