export enum Timezone {
  America_Araguaina = 'America/Araguaina UTC -03:00',
  America_Bahia = 'America/Bahia UTC -03:00',
  America_Belem = 'America/Belem UTC -03:00',
  America_Boa_Vista = 'America/Boa_Vista UTC -04:00',
  America_Campo_Grande = 'America/Campo_Grande UTC -04:00',
  America_Cuiaba = 'America/Cuiaba UTC -04:00',
  America_Eirunepe = 'America/Eirunepe UTC -05:00',
  America_Fortaleza = 'America/Fortaleza UTC -03:00',
  America_Maceio = 'America/Maceio UTC -03:00',
  America_Manaus = 'America/Manaus UTC -04:00',
  America_Noronha = 'America/Noronha UTC -02:00',
  America_Porto_Velho = 'America/Porto_Velho UTC -04:00',
  America_Recife = 'America/Recife UTC -03:00',
  America_Rio_Branco = 'America/Rio_Branco UTC -05:00',
  America_Santarem = 'America/Santarem UTC -03:00',
  America_Sao_Paulo = 'America/Sao_Paulo UTC -03:00',
}
