export enum ActionModuleControllerEnum {
  unknown,
  turnOff,
  turnOn,
  standBy,
  uncharge,
  charge,
  consuming,
  injecting,
  changeControlMode = 55,
}
