import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { NewModuleRequest } from '../../models/request/new-module-request.model';

@Injectable({
  providedIn: 'root',
})
export class NewModuleService extends BaseService {
  constructor() {
    super();
  }

  async getDataSouce() {
    return await this.get('/connection/modules').then((res) => {
      return res.data;
    });
  }

  async getModuleById(moduleId: number) {
    const userData = this.getUserData();

    if (!userData || userData.org.ugridIds.length === 0) return [];

    const ugridId = userData.org.ugridIds[0]; // TODO: Get the first uGrid while the selected one isn't implemented

    return await this.get(`/modules/${moduleId}/ugrid/${ugridId}`).then((res) => {
      return res.data;
    });
  }

  async saveModuleForm(form: NewModuleRequest) {
    const userData = this.getUserData();

    if (!userData || userData.org.ugridIds.length === 0) return [];

    const ugridId = userData.org.ugridIds[0]; // TODO: Get the first uGrid while the selected one isn't implemented
    form.uGridId = ugridId;

    return this.post('/modules', form)
      .then((res) => {
        return res.status;
      })
      .catch((res) => {
        return res.status;
      });
  }

  async getModule() {
    const userData = this.getUserData();

    if (!userData || userData.org.ugridIds.length === 0) return [];

    const ugridId = userData.org.ugridIds[0]; // TODO: Get the first uGrid while the selected one isn't implemented

    return await this.get(`/modules/${ugridId}/details`).then((res) => {
      return res;
    });
  }

  async deleteModule(id: number) {
    return await this.delete(`/modules/${id}`).then((resp) => {
      return resp.status;
    });
  }

  async editModule(form: NewModuleRequest, moduleId: number) {
    return await this.put(`/modules/${moduleId}`, form).then((res) => {
      return res.status;
    });
  }
}
