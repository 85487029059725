<div class="data-crossing__header">
    <div class="data-crossing__header-info">
        <h2>{{ "analysis.timeSeries.dataCrossing.header" | translate }}</h2>
        <p>{{ "analysis.timeSeries.dataCrossing.info" | translate }}</p>
    </div>
</div>

<div class="contianer">
    <div class="contianer__header">
        <p>{{ "analysisTimeSeries.constantValue.formsHeaderInfo" | translate }}</p>
    </div>

    <form [formGroup]="dataCrossingForm">
        <div class="form-row">
            <div class="form-group form-name">
                <div class="section">
                    <div class="row">
                        <label for="name" class="info">
                            {{ "analysisTimeSeries.constantValue.stName" | translate }}
                        </label>
                        <img 
                            src="assets/icons/circle-info.svg" 
                            alt="Icon Info"
                            matTooltip="{{ nameTip | translate}}"
                            matTooltipPosition="above"
                            matTooltipClass="indicator-tooltip">
                    </div>
                    <input
                        formControlName="name"
                        name="name" 
                        type="text" 
                        class="input-field form-control"
                        placeholder="{{ insertName | translate }}" 
                        required 
                        maxlength="26" 
                        [class.invalid]="isNameValid || nameInvalid"
                        (keyup)="attName()">
                    <mat-hint class="hint" *ngIf="isNameValid == 'INVALID'">{{ "analysisTimeSeries.constantValue.nameHint" | translate }}</mat-hint>
                    <mat-hint class="hintInvalid" **ngIf="nameInvalid">{{ "analysisTimeSeries.constantValue.nameHintInvalid" | translate }}</mat-hint>
                </div>
            </div>
            <div class="select">
                <div class="row">
                    <label for="name" class="info">
                        {{ aggregation | translate }}
                    </label>
                    <img 
                        src="assets/icons/circle-info.svg" 
                        alt="Icon Info"
                        matTooltip="{{ aggregationTip | translate}}"
                        matTooltipPosition="above"
                        matTooltipClass="indicator-tooltip">
                </div>                
                <mat-form-field class="card-module-select" appearance="outline">
                    <mat-select formControlName="dataAggression" name="indicatorType" placeholder="selecione" required>
                        <mat-select-trigger> {{selectedIndicator}} </mat-select-trigger>
                        <mat-option *ngFor="let option of aggregationOptions" [value]="option.type" class="options" (click)="aggregationSelected(option.type)">
                            <p class="title">{{ option.title | translate }}</p>
                            <span class="description">{{ option.description | translate }}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="form-group form-unit">
                <div class="section">
                    <div class="row">
                        <label for="unit" class="info">
                            {{ "analysisTimeSeries.constantValue.mesureUnity" | translate }}
                        </label>
                    </div>
                    <input
                        formControlName="unit"
                        name="unit" 
                        type="text" 
                        class="input-field form-control" 
                        placeholder="{{ insertUnity | translate }}" 
                        required
                        [class.invalid]="isFieldInvalid">
                </div>
            </div>
        </div>

        <div class="form-calc">
            <div class="section">
                <div class="row">
                    <label for="calc" class="info">
                        {{ "analysis.timeSeries.dataCrossing.calculation" | translate }}
                    </label>
                    <img 
                        src="assets/icons/circle-info.svg" 
                        alt="Icon Info"
                        matTooltip="{{ calculationTip | translate}}"
                        matTooltipPosition="above"
                        matTooltipClass="indicator-tooltip">
                </div>
                <mat-form-field class="keywords-chip-list option-calc" appearance="outline">
                    <mat-label class="calculation-label">{{ "analysis.timeSeries.dataCrossing.calculationInfo.calculationLabel" | translate }}</mat-label>
                    <mat-chip-grid #chipGrid>
                        <mat-chip-row *ngFor="let calculationOption of calculationOptions" (removed)="removeOption(calculationOption)">
                          {{calculationOption}}
                          <button matChipRemove [attr.aria-label]="'remove ' + calculationOption" [class.opt-not-valid__cancel]="!isFormValid">
                            <mat-icon>cancel</mat-icon>
                          </button>
                        </mat-chip-row>
                        <input 
                            class="calculation-input-option"
                            #optionInput 
                            [formControl]="optionsCtrl"
                            [matChipInputFor]="chipGrid" 
                            [matAutocomplete]="auto"
                            (matChipInputTokenEnd)="addOptions($event)"/>
                        <mat-label class="remove-chip" (click)="clear()">X</mat-label>
                      </mat-chip-grid>
                      
                      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                        <mat-option *ngFor="let filter of filterCalculationOptions | async" [value]="filter">
                          {{filter}}
                        </mat-option>
                      </mat-autocomplete>
                </mat-form-field>
                <div class="info">
                    <p class="calculation-info" *ngIf="isFormValid; else isNotValid">{{ "analysis.timeSeries.dataCrossing.calculationInfo.text04" | translate }}: +, -, *, /, (, )</p>
                    <ng-template #isNotValid>
                        <p class="opt-not-valid__info">{{ "analysis.timeSeries.dataCrossing.calculationInfo.errorInfo" | translate }}</p>
                    </ng-template>
                </div>
            </div>
        </div>
    </form>

    <div class="tip">
        <span>{{ "analysis.timeSeries.important" | translate}}:</span>
        <ul>
            <li>{{ "analysis.timeSeries.dataCrossing.calculationInfo.text01" | translate }}</li>
            <li>{{ "analysis.timeSeries.dataCrossing.calculationInfo.text02" | translate }}</li>
            <li>{{ "analysis.timeSeries.dataCrossing.calculationInfo.text03" | translate }}</li>
        </ul>
    </div>
    <p class="line"></p>

    <div class="const-buttons">
        <button class="const-buttons-cancel"
            (click)="cancelForm()"
            >
            {{ "analysisTimeSeries.constantValue.buttonCancel" | translate }}
        </button>
        <button class="const-buttons-save"
            [disabled]="!isValidForm"
            [class.disabled]="!isValidForm"
            (click)="saveForm()"
            >
            {{ "analysisTimeSeries.constantValue.buttonSave" | translate }}
        </button>
    </div>
</div>
