import { HttpStatusCode } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { map, Observable, startWith } from 'rxjs';
import { TimeSeriesDataGroupingEnum } from 'src/app/core/models/enums/timeSeriesDataGroupingEnum';
import { TimeSeriesDataSource } from 'src/app/core/models/enums/timeSeriesDataSourceEnum';
import { DialogViewModel } from 'src/app/core/models/interfaces/dialog.model';
import { ConstantValueRequest } from 'src/app/core/models/request/constant-value-request.model';
import { TimeSeriesService } from 'src/app/core/services/timeSeries/time-series.service';
import { measurementOptions } from 'src/app/core/utils/measurement-unit-options.utils';
import { CustomSnackbarErrorComponent } from 'src/app/shared/components/custom-snackbar-error/custom-snackbar-error.component';
import { CustomSnackbarSuccessComponent } from 'src/app/shared/components/custom-snackbar-success/custom-snackbar-success.component';
import { ModalDialogComponent } from 'src/app/shared/components/modal-dialog/modal-dialog.component';
import { AppConstants } from 'src/app/shared/constants/app.constants';

@Component({
  selector: 'app-constant-value',
  templateUrl: './constant-value.component.html',
  styleUrls: ['./constant-value.component.scss'],
})
export class ConstantValueComponent {
  insertName = this.translate.instant('analysisTimeSeries.constantValue.insertName');
  insertUnity = this.translate.instant('analysisTimeSeries.constantValue.insertUnity');
  stValue = this.translate.instant('analysisTimeSeries.constantValue.value');
  insertValidity = this.translate.instant('analysisTimeSeries.constantValue.insertValidity');
  nameTip = this.translate.instant('analysisTimeSeries.constantValue.nameTip');
  unityTip = this.translate.instant('analysisTimeSeries.constantValue.unityTip');
  valueTip = this.translate.instant('analysisTimeSeries.constantValue.valueTip');
  dataAgregationTip = this.translate.instant('analysisTimeSeries.dataUpload.dataAgregationTip');
  select = this.translate.instant('analysisTimeSeries.dataUpload.select');

  form: ConstantValueRequest = {} as ConstantValueRequest;
  options: string[] = measurementOptions;
  filteredOptions: Observable<string[]> | undefined;
  myControl = new FormControl('');
  nameInvalid: boolean = false;
  timeSerieId: number = NaN;
  selectedDataGrouping: string = '';
  editing: boolean = false;
  currentValue: number = NaN;
  currentStartDate!: Date;
  currentEndDate!: Date;
  replacingValue: boolean = false;
  dialogRef!: MatDialogRef<any>;
  @Output() timeSeriesSaved = new EventEmitter<{valid: boolean, tsId: number}>();
  @Output() timeSeriesCanceled = new EventEmitter<boolean>();
  @Input() dataToEdit: any;

  dataGrouping: any[] = [
    {
      type: TimeSeriesDataGroupingEnum.accumulated,
      title: this.translate.instant('analysisTimeSeries.dataUpload.dataGrouping.accumulated'),
      desc: this.translate.instant('analysisTimeSeries.dataUpload.dataGrouping.accumulatedDesc'),
    },
    {
      type: TimeSeriesDataGroupingEnum.average,
      title: this.translate.instant('analysisTimeSeries.dataUpload.dataGrouping.average'),
      desc: this.translate.instant('analysisTimeSeries.dataUpload.dataGrouping.averageDesc'),
    },
    {
      type: TimeSeriesDataGroupingEnum.firstValue,
      title: this.translate.instant('analysisTimeSeries.dataUpload.dataGrouping.firstValue'),
      desc: this.translate.instant('analysisTimeSeries.dataUpload.dataGrouping.firstValueDesc'),
    },
    {
      type: TimeSeriesDataGroupingEnum.lastValue,
      title: this.translate.instant('analysisTimeSeries.dataUpload.dataGrouping.lastValue'),
      desc: this.translate.instant('analysisTimeSeries.dataUpload.dataGrouping.lastValueDesc'),
    },
    {
      type: TimeSeriesDataGroupingEnum.maxValue,
      title: this.translate.instant('analysisTimeSeries.dataUpload.dataGrouping.maxValue'),
      desc: this.translate.instant('analysisTimeSeries.dataUpload.dataGrouping.maxValueDesc'),
    },
    {
      type: TimeSeriesDataGroupingEnum.minValue,
      title: this.translate.instant('analysisTimeSeries.dataUpload.dataGrouping.minValue'),
      desc: this.translate.instant('analysisTimeSeries.dataUpload.dataGrouping.minValueDesc'),
    },
  ];

  constructor(
    private translate: TranslateService,
    private timeSeriesService: TimeSeriesService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    if (this.dataToEdit !== '') {
      this.editing = true;
      this.getTimeSeriesData(this.dataToEdit);
      this.getValueAndEndDate(this.dataToEdit);
      this.dataToEdit = '';
    }
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || '')),
    );
  }

  getTimeSeriesData(timeSerieId: number): void {
    this.timeSeriesService.getById(timeSerieId).then((resp: any) => {
      this.form = resp;
      this.form.startOfValidity = resp.createdAt;
      this.dataGroupingLabel(this.form.dataGrouping);
      this.timeSerieId = resp.timeSeriesId;
    });
  }

  getValueAndEndDate(timeSerieId: number): void {
    this.timeSeriesService.getLastData(timeSerieId).then(resp => {
      this.form.value = resp[0].value;
      this.form.endOfValidity = resp[0].lastDate;
      this.currentValue = this.form.value;
      this.currentStartDate = new Date(this.form.startOfValidity);
      this.currentEndDate = new Date(this.form.endOfValidity);
    });
  }

  cancelForm(): void {
    this.timeSeriesCanceled.emit(true);
    this.editing = false;
  }

  saveFormEdit(formValid: boolean): void {
    if (formValid == true) {
      this.checkDates();
      if (this.replacingValue == true) {
        const dialogModel: DialogViewModel = {} as DialogViewModel;
        dialogModel.icon = 'warning';
        dialogModel.iconType = 'warning';
        dialogModel.showButtons = true;
        dialogModel.btnError = false;
        dialogModel.btnMessage = '';
        dialogModel.title = this.translate.instant('analysisTimeSeries.constantValue.dialogTitle');
        dialogModel.message = this.translate.instant('analysisTimeSeries.constantValue.dialogMessage');
        dialogModel.defaultMessage = true;
        this.dialogRef = this.dialog.open(ModalDialogComponent, {
          disableClose: true,
          data: dialogModel,
          width: '445px',
        });

        this.dialogRef.componentInstance.confirm.subscribe({
          next: () => {
            this.timeSeriesService.saveTimeSeriesToEdit(this.form, this.timeSerieId).then(res => {
              this.openSnackBar(res.status);
              this.dialogRef.close();
            });
          },
        });
      } else {
        this.timeSeriesService.saveTimeSeriesToEdit(this.form, this.timeSerieId).then(res => {
          this.openSnackBar(res.status);
          this.dialogRef.close();
        });
      }
    }
  }

  checkDates(): void {
    let startDate = new Date(this.form.startOfValidity);
    let endDate = new Date(this.form.endOfValidity);

    if (this.form.value !== this.currentValue && (+startDate == +this.currentStartDate && +endDate == +this.currentEndDate)) {
      this.replacingValue = true;
      return;
    }
    else if (this.form.value == this.currentValue && (startDate < this.currentStartDate || endDate < this.currentEndDate)) {
      this.replacingValue = true;
      return;
    } else if (this.form.value !== this.currentValue && (startDate < this.currentStartDate || endDate < this.currentEndDate)) {
      this.replacingValue = true;
      return;
    } else {
      this.replacingValue = false;
    }
  }

  saveForm(formValid: boolean): void {
    if (formValid == true) {
      this.form.dataSource = TimeSeriesDataSource.ConstValue;
      this.form.endOfValidity = '';
      this.timeSeriesService.saveTimeSeriesForm(this.form).then((res) => {
        if (res.status == AppConstants.CREATE_SUCESS) {
          this.timeSerieId = res.data.timeSeriesId;
        }
        this.openSnackBar(res.status);
      });
    }
  }

  openSnackBar(status: any): void {
    debugger
    switch (status) {
      case HttpStatusCode.Ok:
        this.snackBar.openFromComponent(CustomSnackbarSuccessComponent, {
          data: {
            message: this.translate.instant('analysisTimeSeries.page.stCreated'),
            snackBar: this.snackBar,
          },
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: AppConstants.TREE_SECOND_WAIT,
        });
        this.timeSeriesSaved.emit({ valid: false, tsId: 0 });
        break;
      case AppConstants.CREATE_SUCESS:
        this.snackBar.openFromComponent(CustomSnackbarSuccessComponent, {
          data: {
            message: this.translate.instant('analysisTimeSeries.page.stCreated'),
            snackBar: this.snackBar,
          },
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: AppConstants.TREE_SECOND_WAIT,
        });
        const tsId = this.timeSerieId;
        const valid = true;
        this.timeSeriesSaved.emit({ valid, tsId });
        break;
      case AppConstants.BAD_REQUEST:
        this.snackBar.openFromComponent(CustomSnackbarErrorComponent, {
          data: {
            message: this.translate.instant('analysisTimeSeries.constantValue.errorSavingST'),
            snackBar: this.snackBar,
          },
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: AppConstants.TREE_SECOND_WAIT,
        });
        this.nameInvalid = true;
        break;
      default:
        this.snackBar.openFromComponent(CustomSnackbarErrorComponent, {
          data: {
            message: this.translate.instant('analysisTimeSeries.page.stCreatError'),
            snackBar: this.snackBar,
          },
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: AppConstants.TREE_SECOND_WAIT,
        });
    }
  }

  attName(): void {
    this.nameInvalid = false;
  }

  dataGroupingSelected(value: string): void {
    this.selectedDataGrouping = value;
  }

  dataGroupingLabel(dataGrouping: number): void {
    switch (dataGrouping) {
      case TimeSeriesDataGroupingEnum.accumulated:
        this.selectedDataGrouping = this.translate.instant('analysisTimeSeries.dataUpload.dataGrouping.accumulated');
        break;
      case TimeSeriesDataGroupingEnum.average:
        this.selectedDataGrouping = this.translate.instant('analysisTimeSeries.dataUpload.dataGrouping.average');
        break;
      case TimeSeriesDataGroupingEnum.firstValue:
        this.selectedDataGrouping = this.translate.instant('analysisTimeSeries.dataUpload.dataGrouping.firstValue');
        break;
      case TimeSeriesDataGroupingEnum.lastValue:
        this.selectedDataGrouping = this.translate.instant('analysisTimeSeries.dataUpload.dataGrouping.lastValue');
        break;
      case TimeSeriesDataGroupingEnum.maxValue:
        this.selectedDataGrouping = this.translate.instant('analysisTimeSeries.dataUpload.dataGrouping.maxValue');
        break;
      case TimeSeriesDataGroupingEnum.minValue:
        this.selectedDataGrouping = this.translate.instant('analysisTimeSeries.dataUpload.dataGrouping.minValue');
        break;
      default:
        break;
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter((option) => option.toLowerCase().includes(filterValue));
  }
}
