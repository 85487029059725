<div class="card-module-controller" [style.height]="height">
    <div class="card-module-controller__header">
        <h2>
            <i class="fa-regular fa-{{ icon }}"></i> {{ name }}
        </h2>

    </div>

    <div class="loading-area" *ngIf="loadingStatus">
        <div class="loading">
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
          </div>
      </div>

    <div *ngIf="!loadingStatus">

        <div class="card-module-controller__info">
            <div>
                <span>
                    {{ "module.status" | translate }}:
                </span>
                <p *ngIf="!loadStatusFail">{{ statusName }}</p>
                <p *ngIf="loadStatusFail">{{ "module.statusLoadFail" | translate }}</p>
            </div>
            <div>
                <span>
                    {{ "module.currentPower" | translate }}:
                </span>
                <p *ngIf="!loadStatusFail">{{ value }} {{ valueUnit }}</p>
                <p *ngIf="loadStatusFail" class="load-status-fail"></p>
            </div>
            <div *ngIf="typeModule == 3">
                <span>
                    {{ "module.chargingLevel" | translate }}:
                </span>
                <p *ngIf="!loadStatusFail">{{ chargingLevel }} %</p>
                <p *ngIf="loadStatusFail" class="load-status-fail"></p>
            </div>
        </div>

        <div class="card-module-controller__action" *ngIf="loggerUserRole != 'basic'">
            <mat-form-field class="card-module-controller__action-select" appearance="outline">
                <mat-select [(ngModel)]="selectedAction" name="action" [(value)]="selectedAction">
                    <mat-option *ngFor="let action of actions" [value]="action.actionCode" (click)="openDialog(selectedAction)">{{ action.actionName | translate  }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</div>