export enum BatterySetupEnum {
  series,
  parallel,
  seriesParallel,
}

export enum NumberPhasesEnum {
  singlePhase,
  twoPhase,
  threePhase,
}
