import { Component } from '@angular/core';
import { AuthUserViewModel } from 'src/app/core/models/interfaces/auth-user.model';
import ModuleRequest from 'src/app/core/models/request/module-request.model';
import { ModuleService } from 'src/app/core/services/moduleServices/module.service';
import { UserServiceService } from 'src/app/core/services/userService/user-service.service';

@Component({
  selector: 'app-controller',
  templateUrl: './controller.component.html',
  styleUrls: ['./controller.component.scss'],
})
export class ControllerComponent {
  modules: ModuleRequest[] = [];
  loggerUser: AuthUserViewModel | undefined = {} as AuthUserViewModel;

  constructor(private moduleService: ModuleService, private userServiceService: UserServiceService,) {}

  async ngOnInit(): Promise<void> {
    await Promise.all([this.getAllModules(), this.getLoggerUser()]);
  }

  getAllModules(): void {
    this.moduleService.getAll().then((resp) => {
      this.modules = resp;
    });
  }

  changeModulesEvent(event: any) {
    this.getAllModules();
  }

  getLoggerUser(): void {
    this.userServiceService.getUser().then((resp: any) => {
      this.loggerUser = resp;
    });
  }
}
