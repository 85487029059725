<div class="chart-container">
  <ng-container *ngIf="status == -1">
    <div mat-dialog-content class="modal-content message-container">
      <div class="modal-icon">
        <app-tag-icon [icon]="'error'" [type]="'error'" [size]="18"></app-tag-icon>
      </div>
      <p class="modal-text">{{ 'shared.components.line_chart.insufficient_data.modal_text' | translate }}</p>
      <p class="modal-link">{{ 'shared.components.line_chart.insufficient_data.modal_link' | translate }}</p>
    </div>
  </ng-container>
  <ng-container *ngIf="status == 0">
    <div mat-dialog-content class="modal-content message-container">
      <div class="modal-icon">
        <app-tag-icon [icon]="'error'" [type]="'error'" [size]="18"> </app-tag-icon>
      </div>
      <p class="modal-text">{{ getDeletedAtMessage }}</p>
      <p class="modal-link">
        {{ 'shared.components.line_chart.soft_delete.modal_link_prefix' | translate }}
        <a href="#">{{ 'shared.components.line_chart.soft_delete.modal_link' | translate }}</a>
        {{ 'shared.components.line_chart.soft_delete.modal_link_suffix' | translate }}
      </p>
    </div>
  </ng-container>
  <canvas #lineCanvas></canvas>
</div>
