import { afterNextRender, AfterViewInit, Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { TimeSeriesIndicator } from 'src/app/core/models/enums/timeSeriesIndicatorEnum';
import { DialogViewModel } from 'src/app/core/models/interfaces/dialog.model';
import TimeSeriesRequest from 'src/app/core/models/request/time-series-request.model';
import { IndicatorService } from 'src/app/core/services/indicatorServices/indicator.service';
import { CustomSnackbarSuccessComponent } from 'src/app/shared/components/custom-snackbar-success/custom-snackbar-success.component';
import { ModalDialogComponent } from 'src/app/shared/components/modal-dialog/modal-dialog.component';
import { AppConstants } from 'src/app/shared/constants/app.constants';

@Component({
  selector: 'app-indicator',
  templateUrl: './indicator.component.html',
  styleUrls: ['./indicator.component.scss'],
})
export class IndicatorComponent {
  confirm: Subject<boolean> = new Subject();
  close: Subject<boolean> = new Subject();
  selectedIndicator: string = '';
  tsId: number = NaN;
  afterSave: boolean = false;
  indicatorStep: number = 0 // 0 == set indicator & 1 == edit indicator
  valueChanged: boolean = false;
  indicatorChanged: boolean = false;
  firstValue: number = NaN;
  firstIndicator: number = NaN;
  timeSeries: TimeSeriesRequest = {} as TimeSeriesRequest;
  dialogRef2!: MatDialogRef<any>;
  textBtn1: string = this.translate.instant('analysisTimeSeries.indicator.register.cancel');
  textBtn2: string = this.translate.instant('analysisTimeSeries.indicator.register.save');
  select: string = this.translate.instant('analysisTimeSeries.indicator.register.select');
  value: string = this.translate.instant('analysisTimeSeries.indicator.register.value');
  valueTip: string = this.translate.instant('analysisTimeSeries.indicator.register.valueTip');

  @Output()
  indicatorSeted = new EventEmitter<boolean>();

  indicator: any[] = [
    {
      type: TimeSeriesIndicator.goal,
      title: this.translate.instant('analysisTimeSeries.indicator.register.goal'),
      desc: this.translate.instant('analysisTimeSeries.indicator.register.goalDesc'),
    },
    {
      type: TimeSeriesIndicator.limit,
      title: this.translate.instant('analysisTimeSeries.indicator.register.limit'),
      desc: this.translate.instant('analysisTimeSeries.indicator.register.limitDesc'),
    }
  ];
  
  constructor(
    public dialogRef: MatDialogRef<IndicatorComponent>,
    private translate: TranslateService,
    private indicatorService: IndicatorService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.tsId = this.data.info.tsId;
    this.afterSave = this.data.info.afterTs;
    if (this.data.info.indicatorStep) {
      this.indicatorStep = this.data.info.indicatorStep;
    }
  }

  ngOnInit(): void {
    this.getTimeSeriesData();
  }

  adjustWidth(): void {
    // TO-DO : Aplicar método após renderização da modal, assim irá ajustar o tamanho da mesma
    let row: number | undefined = document.getElementById("row")?.clientWidth;
    document.getElementById("dialog")!.style.width = row! + 48 + 'px';
  }

  getTimeSeriesData() {
    this.indicatorService.getById(this.tsId).then((resp) => {
      if (this.indicatorStep == 0) {
        delete resp.indicatorType;
        delete resp.indicatorValue;
      }
      if (this.indicatorStep == 1) {
        this.indicatorTypeLabel(resp.indicatorType);
        this.firstValue = resp.indicatorValue;
        this.firstIndicator = resp.indicatorType;
      }
      this.timeSeries = resp;
    });
  }

  indicatorTypeLabel(type: number) {
    switch (type) {
      case TimeSeriesIndicator.goal:
        this.selectedIndicator = this.translate.instant('analysisTimeSeries.indicator.register.goal');
        break;
      case TimeSeriesIndicator.limit:
        this.selectedIndicator = this.translate.instant('analysisTimeSeries.indicator.register.limit');
        break;
      default:
        break;
    }
  }

  indicatorSelected(value: string, type: number): void {
    this.selectedIndicator = value;
    if (this.indicatorStep == 1) {
      this.checkingIndicatorType(type);
    }
  }

  checkingValue(value: number): void {
    if (this.firstValue == value) {
      this.valueChanged = false;
    } else {
      this.valueChanged = true;
    }
  }

  checkingIndicatorType(type: number): void {
    if (this.firstIndicator == type) {
      this.indicatorChanged = false;
    } else {
      this.indicatorChanged = true;
    }
  }

  registerIndicator(formValid: boolean): void {
    if (formValid == true) {
      let indicatorBody: any = 
        {
          type: this.timeSeries.indicatorType,
          value: this.timeSeries.indicatorValue
        };
  
      this.indicatorService.setIndicator(this.tsId, indicatorBody).then((resp) => {
        this.openSnackbar(resp.status);
        this.confirm.next(true);
      });
    }
  }

  editIndicator(formValid: boolean): void {
    if (formValid == true) {
    let indicatorBody: any = 
      {
        type: this.timeSeries.indicatorType,
        value: this.timeSeries.indicatorValue
      };
      
      const dialogModel: DialogViewModel = {} as DialogViewModel;
      dialogModel.icon = 'warning';
      dialogModel.iconType = 'warning';
      dialogModel.showButtons = true;
      dialogModel.btnError = false;
      dialogModel.btnMessage = '';
      dialogModel.title = this.translate.instant('analysisTimeSeries.indicator.register.dialogTitle');
      dialogModel.message = this.translate.instant('analysisTimeSeries.indicator.register.dialogMessage');
      dialogModel.defaultMessage = true;

      this.dialogRef2 = this.dialog.open(ModalDialogComponent, {
        disableClose: true,
        data: dialogModel,
        width: '445px',
      });

      this.dialogRef2.componentInstance.confirm.subscribe({
        next: () => {
          this.indicatorService.setIndicator(this.tsId, indicatorBody).then((resp) => {
            this.openSnackbar(resp.status);
            this.dialogRef2.close();
            this.confirm.next(true);
          });
        },
      });
    }
  }

  onDismiss(): void {
    this.close.next(true);
    this.dialogRef.close(false);
  }

  openSnackbar(status: number): void {
    switch (status) {
      case AppConstants.OK:
        this.snackBar.openFromComponent(CustomSnackbarSuccessComponent, {
          data: {
            message: this.translate.instant('analysisTimeSeries.indicator.register.snackBarIndicator'),
            snackBar: this.snackBar,
          },
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: AppConstants.TREE_SECOND_WAIT,
        });
        break;
      default:
        break;
    }
  }
}
