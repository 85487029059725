import { TypeModuleEnum } from '../models/enums/typeModuleEnum';

const setIcon = (type: TypeModuleEnum) => {
  switch (type) {
    case TypeModuleEnum.grid:
      return 'utility-pole';
    case TypeModuleEnum.storage:
      return 'car-battery';
    case TypeModuleEnum.generator:
      return 'circle-bolt';
    case TypeModuleEnum.load:
      return 'plug-circle-bolt';
    default:
      return 'loader';
  }
};

export default setIcon;
