import { TimeSeriesIndicatorTypeEnum } from "src/app/shared/enums/time-series-indicator-type.enum";
import { DashboardTimeSeriesRequest } from "./dashboard-time-series-request.model";

export interface UserEditedDashboardModel
{
    name: string;
    email: string;
}

export enum DashboardAnalysisPeriod {
  Today = 0,
  Last_7_Days = 1,
  Last_15_Days = 2,
  Last_30_Days = 3,
  Last_Week = 4,
  Last_Month = 5,
  Last_Quarter = 6,
  Custom_Period = 7,
}

export enum DashboardComparisonPeriod {
  Do_Not_Compare = 0,
  Same_Period_Year_Previous_Year = 1,
  Custom_Period = 2
}

export enum DashboardGraphDataAggregation
{
  Cumulative = 0,
  Average = 1,
  FirstValue = 2,
  LastValue = 3,
  MaximumValue = 4,
  MinimumValue = 5
}

export enum DashboardGraphDataGranularity
{
  Hourly = 0,
  Daily = 1,
  Weekly = 2,
  Monthly = 3,
  Quarterly = 4,
  Yearly = 5
}

export interface DashboardParameter {
    analysisPeriod: DashboardAnalysisPeriod,
    analysisPeriodStartDate?: Date,
    analysisPeriodEndDate?: Date,
    comparisonPeriod: DashboardComparisonPeriod,
    comparisonPeriodStartDate?: Date,
    comparisonPeriodEndDate?: Date
}

export interface DashboardTimesSeries {
    id: number;
    title: string;
    indicatorType: TimeSeriesIndicatorTypeEnum;
    indicatorValue: number;
}

export interface DashboardGraph {
    index:number;
    title: string;
    description: string;
    data: DashboardTimeSeriesRequest;
    timesSeries: DashboardTimesSeries;
    dataGranularity: DashboardGraphDataGranularity;
    dataAggregation: DashboardGraphDataAggregation;
}

export interface DashboardModel {
    dashboardId?: number;
    orgId?: number;
    ugridId?: number;
    ownerId?: number;
    iconType?: number;
    title?: string;
    description?: string;
    lastEdited?: UserEditedDashboardModel;
    parameters?: DashboardParameter;
    graphics?: DashboardGraph[];
    createdAt?: Date;
    updatedAt?: Date;
}

export interface DashboardToReport {
  dashboardId: number;
  comparePeriod: number;
  analisyPeriod: number;
  analysisPeriodStartDate: Date;
  analysisPeriodEndDate: Date;
  comparisonPeriodStartDate?: Date;
  comparisonPeriodEndDate?: Date;
}

export const getAnalysisPeriodString = (value: DashboardAnalysisPeriod): string  => {
    switch (value) {
        case DashboardAnalysisPeriod.Today:
            return 'dashboard_analisys.parameters.analysis_period.today';
        case DashboardAnalysisPeriod.Last_7_Days:
            return 'dashboard_analisys.parameters.analysis_period.last_7_days';
        case DashboardAnalysisPeriod.Last_15_Days:
            return 'dashboard_analisys.parameters.analysis_period.last_15_days';
        case DashboardAnalysisPeriod.Last_30_Days:
            return 'dashboard_analisys.parameters.analysis_period.last_30_days';
        case DashboardAnalysisPeriod.Last_Week:
            return 'dashboard_analisys.parameters.analysis_period.last_week';
        case DashboardAnalysisPeriod.Last_Month:
            return 'dashboard_analisys.parameters.analysis_period.last_month';
        case DashboardAnalysisPeriod.Last_Quarter:
            return 'dashboard_analisys.parameters.analysis_period.last_quarter';
        case DashboardAnalysisPeriod.Custom_Period:
            return 'dashboard_analisys.parameters.analysis_period.custom_period';
        default:
            return 'dashboard_analisys.parameters.analysis_period.custom_period';
    }
};

export const getComparisonPeriodString = (value: DashboardComparisonPeriod): string  => {
    switch (value) {
        case DashboardComparisonPeriod.Do_Not_Compare:
            return 'dashboard_analisys.parameters.comparison_period.do_not_compare';
        case DashboardComparisonPeriod.Same_Period_Year_Previous_Year:
            return 'dashboard_analisys.parameters.comparison_period.same_period_year_previous_year';
        case DashboardComparisonPeriod.Custom_Period:
            return 'dashboard_analisys.parameters.comparison_period.custom_period';
        default:
            return 'dashboard_analisys.parameters.comparison_period.custom_period';
    }
};

export const getGraphDataGranularityString = (value: DashboardGraphDataGranularity): string  => {
  switch (value) {
    case DashboardGraphDataGranularity.Hourly:
        return 'dashboard_analisys.graph_config.data_granularity.hourly';
    case DashboardGraphDataGranularity.Daily:
        return 'dashboard_analisys.graph_config.data_granularity.daily';
    case DashboardGraphDataGranularity.Weekly:
        return 'dashboard_analisys.graph_config.data_granularity.weekly';
    case DashboardGraphDataGranularity.Monthly:
        return 'dashboard_analisys.graph_config.data_granularity.monthly';
    case DashboardGraphDataGranularity.Quarterly:
        return 'dashboard_analisys.graph_config.data_granularity.quarterly';
    case DashboardGraphDataGranularity.Yearly:
        return 'dashboard_analisys.graph_config.data_granularity.yearly';
    default:
        return 'dashboard_analisys.graph_config.data_granularity.daily';
  }
};

export const getGraphDataAggregationString = (value: DashboardGraphDataAggregation): string  => {
  switch (value) {
    case DashboardGraphDataAggregation.Cumulative:
        return 'dashboard_analisys.graph_config.data_aggregation.cumulative';
    case DashboardGraphDataAggregation.Average:
        return 'dashboard_analisys.graph_config.data_aggregation.average';
    case DashboardGraphDataAggregation.FirstValue:
        return 'dashboard_analisys.graph_config.data_aggregation.first_value';
    case DashboardGraphDataAggregation.LastValue:
        return 'dashboard_analisys.graph_config.data_aggregation.last_value';
    case DashboardGraphDataAggregation.MaximumValue:
        return 'dashboard_analisys.graph_config.data_aggregation.maximum_value';
    case DashboardGraphDataAggregation.MinimumValue:
        return 'dashboard_analisys.graph_config.data_aggregation.minimum_value';
    default:
        return 'dashboard_analisys.graph_config.data_aggregation.cumulative';
  }
}
