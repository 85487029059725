<div class="not-found">
    <div class="not-found__header" *ngIf="!noHeader">
        <h2>{{ title | translate }}</h2>
    </div>
    <div class="not-found__container">
        <app-tag-icon
            [icon]="icon"
            [type]="type"
            [size]="size">        
        </app-tag-icon>
        <div class="not-found__container-info">
            <h2>{{ message | translate }}</h2>
            <hr>
            <p *ngIf="role == 'master' && contractMessage == ''">
                {{ "register.users.textAlert1" | translate}}
                <span class="resource">{{ resource | translate }}</span>
                {{ "register.users.textAlert2" | translate}}
            </p>
            <p *ngIf="role == 'master' && contractMessage !== ''">
                {{ "register.users.textAlert1" | translate}}
                <span class="resource">{{ resource | translate }}</span>
                {{ contractMessage | translate}}
            </p>
        </div>
    </div>
</div>
