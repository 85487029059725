import { Component } from '@angular/core';

@Component({
  selector: 'app-analysis',
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.scss'],
})
export class AnalysisComponent {
  menuIsCollapsed: boolean = false;
  expandMenu: string = 'monitoring.expandMenu';
  hideMenu: string = 'monitoring.hideMenu';
  dashboard: string = 'analysis.dashboards.title';
  reports: string = 'analysis.reports.title';
  timeSeries: string = 'analysis.timeSeries.title';

  collapseMenu() {
    this.menuIsCollapsed = !this.menuIsCollapsed;
  }

}
