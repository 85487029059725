import { ChartConfiguration, ChartDataset, ChartOptions } from 'chart.js';
import { UgridOperationsViewModel } from '../models/interfaces/ugrid-operatinos.model';
import { TypeModuleEnum } from '../models/enums/typeModuleEnum';
import { SubTypeModuleEnum } from '../models/enums/subtype-module.enum';
import * as moment from 'moment';
import { distinctByPropertyKey } from 'src/app/shared/helpers/array.helper';
import { TranslateService } from '@ngx-translate/core';
import { UgridOperationGranularityEnum } from '../models/enums/ugrid-operation-granularity.enum';

export class LineChartSetupUtil {
  constructor(private translate: TranslateService) {}

  getLineChartOptions(): ChartOptions<'line'> {
    return {
      responsive: true,
      elements: {
        point: {
          radius: 0,
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          title: {
            text: this.translate.instant('monitoring.power'),
            display: true,
          },
        },
      },
      plugins: {
        legend: {
          labels: {
            boxWidth: 0,
            color: '#00000000',
          },
          position: 'bottom',
          title: {
            display: false,
          },
          display: false,
        },
        zoom: {
          pan: {
            enabled: true,
            modifierKey: 'shift',
          },
          zoom: {
            drag: {
              enabled: true,
            },
            wheel: {
              enabled: true,
              modifierKey: 'ctrl',
            },
            mode: 'x',
          },
        },
      },
      interaction: {
        mode: 'index',
        intersect: false,
      },
    };
  }

  getGridPower(operations: UgridOperationsViewModel[]): number[] {
    return operations.filter((x) => x.moduleType === TypeModuleEnum.grid).map((x) => x.meansValue);
  }

  getStoragePower(operations: UgridOperationsViewModel[]): number[] {
    return operations.filter((x) => x.moduleType === TypeModuleEnum.storage).map((x) => x.meansValue);
  }

  getLoadControllablePower(operations: UgridOperationsViewModel[]): number[] {
    return operations
      .filter(
        (x) => x.moduleType === TypeModuleEnum.load && x.moduleSubType === SubTypeModuleEnum.controllable,
      )
      .map((x) => x.meansValue);
  }

  getLoadFixedPower(operations: UgridOperationsViewModel[]): number[] {
    return operations
      .filter((x) => x.moduleType === TypeModuleEnum.load && x.moduleSubType === SubTypeModuleEnum.passive)
      .map((x) => x.meansValue);
  }

  getGeneratorRenewablePower(operations: UgridOperationsViewModel[]): number[] {
    return operations
      .filter(
        (x) =>
          x.moduleType === TypeModuleEnum.generator &&
          (x.moduleSubType === SubTypeModuleEnum.solar || x.moduleSubType === SubTypeModuleEnum.wind),
      )
      .map((x) => x.meansValue);
  }

  getGeneratorDispatchablePower(operations: UgridOperationsViewModel[]): number[] {
    return operations
      .filter(
        (x) =>
          x.moduleType === TypeModuleEnum.generator && x.moduleSubType === SubTypeModuleEnum.dispatchable,
      )
      .map((x) => x.meansValue);
  }

  getUgridOperationSeriesDataset(
    operations: UgridOperationsViewModel[],
    granularity: UgridOperationGranularityEnum,
  ): ChartConfiguration<'line'>['data'] {
    const gridData = this.getGridPower(operations);
    const storageData = this.getStoragePower(operations);
    const loadControllableData = this.getLoadControllablePower(operations);
    const loadFixedData = this.getLoadFixedPower(operations);
    const generatorRenewableData = this.getGeneratorRenewablePower(operations);
    const generatorDispatchableData = this.getGeneratorDispatchablePower(operations);

    const operationsUnique = distinctByPropertyKey(operations, 'timestamp');
    const contractDemandData = operationsUnique.map((x) => x.contractDemand);
    let tsLabels: string[];

    switch (granularity) {
      case UgridOperationGranularityEnum.day:
        tsLabels = operationsUnique.map(
          (d) =>
            `${moment(d.timestamp).format('DD')} ${moment(d.timestamp)
              .locale(this.translate.currentLang)
              .format('ddd')}`,
        );
        break;
      case UgridOperationGranularityEnum.hour:
        tsLabels = operationsUnique.map((d) => `${moment(d.timestamp).format('HH')}h`);
        break;
      case UgridOperationGranularityEnum.minute:
        tsLabels = operationsUnique.map((d) => moment(d.timestamp).format('HH:mm'));
        break;
    }

    return {
      labels: tsLabels,
      datasets: [
        {
          data: contractDemandData,
          label: this.translate.instant('monitoring.contractedDemand'),
          fill: false,
          borderColor: '#404040',
        },
        {
          data: gridData,
          label: this.translate.instant('monitoring.grid'),
          fill: true,
          borderColor: '#33CC99',
          backgroundColor: 'rgba(225, 248, 240, 0.3)',
          pointBackgroundColor: '#33CC99',
        },
        {
          data: loadControllableData,
          label: this.translate.instant('monitoring.controllableLoad'),
          fill: true,
          borderColor: '#008F5D',
          backgroundColor: 'rgba(217, 238, 231, 0.3)',
          pointBackgroundColor: '#008F5D',
        },
        {
          data: loadFixedData,
          label: this.translate.instant('monitoring.fixedLoad'),
          fill: true,
          borderColor: '#9E0059',
          backgroundColor: 'rgba(241, 217, 230, 0.3)',
          pointBackgroundColor: '#9E0059',
        },
        {
          data: storageData,
          label: this.translate.instant('monitoring.storage'),
          fill: true,
          borderColor: '#48A0F0',
          backgroundColor: 'rgba(228, 241, 253, 0.3)',
          pointBackgroundColor: '#48A0F0',
        },
        {
          data: generatorRenewableData,
          label: this.translate.instant('monitoring.renewableGeneration'),
          fill: false,
          borderColor: '#E062B4',
          backgroundColor: 'rgba(251, 232, 244, 0.3)',
          pointBackgroundColor: '#E062B4',
        },
        {
          data: generatorDispatchableData,
          label: this.translate.instant('monitoring.dispatchableGeneration'),
          fill: true,
          borderColor: '#EC8720',
          backgroundColor: 'rgba(252, 237, 222, 0.3)',
          pointBackgroundColor: '#EC8720',
        },
      ],
    };
  }

  appendCheckboxInput(datasets: ChartDataset[]): void {
    const legend = document.getElementById('legend');
    datasets.forEach((dataset, index) => {
      const checkbox = document.createElement('input');
      checkbox.style.marginRight = '3px';
      checkbox.type = 'checkbox';
      checkbox.name = dataset.label || '';
      checkbox.value = index.toString();
      checkbox.id = `checkbox${index}`;
      checkbox.checked = true;
      checkbox.style.cursor = 'pointer';

      const label = document.createElement('label');
      label.style.marginRight = '30px';
      label.htmlFor = `checkbox${index}`;
      label.className = 'label-checkbox';
      label.classList.add('label-checkbox');
      label.style.cursor = 'pointer';

      const labelText = document.createTextNode(dataset.label || '');

      label.appendChild(labelText);
      legend?.appendChild(checkbox);
      legend?.appendChild(label);

      this.changeColorCheckboxAccent(checkbox, index);
    });
  }

  changeColorCheckboxAccent(element: HTMLInputElement, index: any) {
    switch (index) {
      case 0:
        element.style.accentColor = '#404040';
        break;
      case 1:
        element.style.accentColor = '#33cc99';
        break;
      case 2:
        element.style.accentColor = '#48A0F0';
        break;
      case 3:
        element.style.accentColor = '#E062B4';
        break;
      case 4:
        element.style.accentColor = '#EC8720';
        break;
      case 5:
        element.style.accentColor = '#9E0059';
        break;
      case 6:
        element.style.accentColor = '#008F5D';
        break;

      default:
        break;
    }
  }
}
