import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DashboardModel } from 'src/app/core/models/interfaces/dashboard.model';
import { FormatDateSlimPipe } from 'src/app/core/pipes/format-date-slim.pipe';
import { DashboardService } from 'src/app/core/services/dashboards/dashboard.service';
import { CustomSnackbarErrorComponent } from 'src/app/shared/components/custom-snackbar-error/custom-snackbar-error.component';
import { CustomSnackbarSuccessComponent } from 'src/app/shared/components/custom-snackbar-success/custom-snackbar-success.component';
import { DialogYesOrNoComponent, DialogYesOrNoSelected } from 'src/app/shared/components/dialog-yes-or-no/dialog-yes-or-no.component';
import { AppConstants } from 'src/app/shared/constants/app.constants';

@Component({
  selector: 'app-dashboards',
  templateUrl: './dashboards.component.html',
  styleUrls: ['./dashboards.component.scss'],
})
export class DashboardsComponent {
  cards: DashboardModel[];
  filteredCards: DashboardModel[];
  modeSelect:string;
  notFoundSchedulesMessage: string = 'analysisdashboards.not_dashboard.info';
  info1: string = 'analysisdashboards.not_dashboard.message_1';
  info2: string = 'analysisdashboards.not_dashboard.message_2';
  inputValue: string = '';

  constructor(
    private translate: TranslateService,
    private router: Router,
    private snackBar: MatSnackBar,
    private dashboardService: DashboardService,
    private dialog: MatDialog){
      this.cards = [];
      this.filteredCards = [];
      this.modeSelect = '';
  }

  async ngOnInit(): Promise<void> {
    this.modeSelect = 'ultimo-editado';
    const data = await this.dashboardService.getAll();

    this.cards = data;
    this.filteredCards = data;

    this.sortDashboards();
  }

  filterDashboards(searchValue: string) {
    this.inputValue = searchValue;
    if (searchValue.length == 0) {
      this.filteredCards = this.cards;
    }
    else {
      this.filteredCards = this.cards.filter(dashboard =>
        dashboard.title!.toLowerCase().includes(searchValue.toLowerCase()) ||
        dashboard.description!.toLowerCase().includes(searchValue.toLowerCase())
      );
    }
    this.sortDashboards();
  }

  sortDashboards() {
    if (this.modeSelect === 'ultimo-editado') {
      this.filteredCards.sort((a, b) => b!.updatedAt!.getTime() - a!.updatedAt!.getTime());
    } else if (this.modeSelect === 'nome-dashboard') {
      this.filteredCards.sort((a, b) => a.title!.localeCompare(b.title!));
    } else if (this.modeSelect === 'nome-editor') {
      this.filteredCards.sort((a, b) => a.lastEdited!.name!.localeCompare(b.lastEdited!.name));
    }
  }

  clearInput(input: HTMLInputElement): void {
    input.value = '';
    this.filterDashboards('');
  }

  async duplicateCard(cardId: number) {
    const data = await this.dashboardService.duplicate(cardId);
    if (data === undefined) {
      this.snackBar.openFromComponent(CustomSnackbarErrorComponent,
        {
          data: {
            message: 'Não foi possível duplicar o dashboard.',
            snackBar: this.snackBar,
          },
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: AppConstants.TREE_SECOND_WAIT,
        },
      );
    } else {
      this.snackBar.openFromComponent(CustomSnackbarSuccessComponent,
        {
          data: {
            message: `Dashboard ${cardId} duplicado.`,
            snackBar: this.snackBar,
          },
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: AppConstants.TREE_SECOND_WAIT,
        },
      );
      this.cards.push(data);
      this.filterDashboards(this.inputValue);
    }
  }

  editCard(cardId: number) {
    this.router.navigate(['/analysis/dashboards/edit', cardId]);
  }

  async removeCard(cardId: number) {
    const dialogRef = this.dialog.open(DialogYesOrNoComponent, {
      width: '400px',
      data: {
        title: "Tem certeza que deseja excluir?",
        description: "Os relatórios gerados a partir deste dashboard também serão excluídos e você não poderá desfazer esta ação.",
        labelOkButton: "Excluir",
        labelNoButton: "Cancelar"
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result === DialogYesOrNoSelected.YES) {
        const data = await this.dashboardService.deleteDashboard(cardId);
        if (data === undefined || data === false) {
          this.snackBar.openFromComponent(CustomSnackbarErrorComponent,
            {
              data: {
                message: 'Não foi possível excluir o dashboard.',
                snackBar: this.snackBar,
              },
              horizontalPosition: 'right',
              verticalPosition: 'top',
              duration: AppConstants.TREE_SECOND_WAIT,
            },
          );
        }
        else {
          const dataFilteredCards = this.cards.filter(card => card.dashboardId !== cardId);
          this.cards = dataFilteredCards;
          this.filterDashboards(this.inputValue);

          this.snackBar.openFromComponent(CustomSnackbarSuccessComponent,
            {
              data: {
                message: 'Dashboard excluído com sucesso.',
                snackBar: this.snackBar,
              },
              horizontalPosition: 'right',
              verticalPosition: 'top',
              duration: AppConstants.TREE_SECOND_WAIT,
            },
          );
        }
      }
      else {
        this.filterDashboards(this.inputValue);
      }
      dialogRef.close();
    });
  }
}
