<div class="modal">
    <div class="icon-container">
        <i class="fa-regular fa-triangle-exclamation"></i>
    </div>
    <h1>{{ title }}</h1>
    <mat-dialog-content>
      <p>{{ description }}</p>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button class="dialog-button cancel-button" (click)="onCancel()">{{ labelNoButton }}</button>
      <button mat-button class="dialog-button continue-button" (click)="onContinue()">{{ labelOkButton }}</button>
    </mat-dialog-actions>
  </div>
  