<div class="controller">
    <div class="controller-header">
        <h2>{{ "controlPanel.controller.title" | translate }}</h2>
        <p>{{ "controlPanel.controller.info" | translate }}</p>
    </div>

    <div class="controller-cards">
        <app-card-module-controller *ngFor="let module of modules"
            [moduleId]="module.moduleId"
            [name]="module.name" 
            [value]="module.value" 
            [valueUnit]="module.valueUnit"
            [status]="module.status"
            [statusName]="module.statusName"
            [typeModule]="module.type"
            [chargingLevel]="module.chargingLevel"
            [controlMode]="module.controlMode"
            [loggerUserRole]="loggerUser?.role"
            (changeModule)="changeModulesEvent($event)"
        >
        </app-card-module-controller>
    </div>

</div>
