import { Component, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NumberPhasesEnum } from 'src/app/core/models/enums/formSelectsModuleEnum';
import { LoadFormRequest, NewModuleRequest } from 'src/app/core/models/request/new-module-request.model';

@Component({
  selector: 'app-load-form',
  templateUrl: './load-form.component.html',
  styleUrls: ['./load-form.component.scss'],
})
export class LoadFormComponent {
  basicForm: NewModuleRequest = {} as NewModuleRequest;
  loadForm: LoadFormRequest = {} as LoadFormRequest;
  insertModel = this.translate.instant('register.resources.newResource.insertModel');
  insertManufacturer = this.translate.instant('register.resources.newResource.insertManufacturer');
  insertType = this.translate.instant('register.resources.newResource.insertType');
  value = this.translate.instant('register.resources.newResource.value');
  select = this.translate.instant('register.resources.newResource.select');
  specificationsTip = this.translate.instant('register.resources.newResource.toolTip.specificationsTip');
  private storage: Storage;
  editing: boolean = false;

  @Output() formLoad: EventEmitter<{ form: NewModuleRequest; valid: boolean }> = new EventEmitter();
  numberOfPhases: number = NaN;
  assets: any[] = [
    {
      type: NumberPhasesEnum.singlePhase,
      title: this.translate.instant('register.resources.newResource.load.singlePhase'),
    },
    {
      type: NumberPhasesEnum.twoPhase,
      title: this.translate.instant('register.resources.newResource.load.twoPhase'),
    },
    {
      type: NumberPhasesEnum.threePhase,
      title: this.translate.instant('register.resources.newResource.load.threePhase'),
    },
  ];

  constructor(private translate: TranslateService) {
    this.storage = window.localStorage;
  }

  ngOnInit(): void {
    this.editing = JSON.parse(this.storage.getItem('editing')!);
    if (this.storage.getItem('form')) {
      this.basicForm = JSON.parse(this.storage.getItem('form') || '[]');
      this.loadForm = this.basicForm.loadAttributes!;
      this.storage.removeItem('form');
      this.storage.removeItem('editing');
      this.sendForm(true);
    }
  }

  sendForm(valid: boolean): void {
    this.basicForm.loadAttributes = this.loadForm;
    const form: NewModuleRequest = this.basicForm;
    this.formLoad.emit({ form, valid });
  }
}
