<div class="forms">
    <div class="forms-header">
        <label>{{ "register.resources.newResource.formsHeaderSpecifications" | translate }}</label>
        <img 
            src="assets/icons/circle-info.svg" 
            alt="Icon Info"
            matTooltip="{{specificationsTip}}"
            matTooltipPosition="above"
            matTooltipClass="indicator-tooltip"
            >
    </div>
    <form #form="ngForm">
        <div class="form-row">
            <div class="form-group forms-form">
                <label for="model">{{ "register.resources.newResource.model" | translate }}</label>
                <input 
                    [(ngModel)]="basicForm.model" 
                    #model="ngModel"
                    name="model" 
                    type="text" 
                    class="form-control input-field" 
                    placeholder="{{insertModel}}" 
                    (keyup)="sendForm(form.form.valid)"
                    required
                    [class.invalid]="model.invalid && (model.dirty || model.touched)"
                    >
            </div>
            <div class="form-group forms-form">
                <label for="manufacturer">{{ "register.resources.newResource.manufacturer" | translate }}</label>
                <input 
                    [(ngModel)]="basicForm.manufacturer" 
                    #manufacturer="ngModel"
                    name="manufacturer" 
                    type="text" 
                    class="form-control input-field" 
                    placeholder="{{insertManufacturer}}" 
                    (keyup)="sendForm(form.form.valid)"
                    required
                    [class.invalid]="manufacturer.invalid && (manufacturer.dirty || manufacturer.touched)"
                    >
            </div>
            <div class="form-group forms-form">
                <div>
                    <label for="storageType">{{ "register.resources.newResource.storage.storageType" | translate }}</label>
                    <img 
                        src="assets/icons/circle-info.svg" 
                        alt="Icon Info"
                        matTooltip="{{storageTypeTip}}"
                        matTooltipPosition="above"
                        matTooltipClass="indicator-tooltip"
                        >
                </div>
                <input 
                    [(ngModel)]="storageForm.storageType" 
                    #storageType="ngModel"
                    name="storageType" 
                    type="text" 
                    class="form-control input-field" 
                    placeholder="{{insertType}}" 
                    (keyup)="sendForm(form.form.valid)"
                    required
                    [class.invalid]="storageType.invalid && (storageType.dirty || storageType.touched)"
                    >
            </div>
            <div class="form-group form-div">
                <label for="nominalCapacity">{{ "register.resources.newResource.storage.nominalCapacity" | translate }}</label>
                <div class="input-group input-div">
                    <input 
                        [(ngModel)]="storageForm.nominalCapacity" 
                        #nominalCapacity="ngModel"
                        name="nominalCapacity" 
                        type="number" 
                        class="form-control input-unit" 
                        placeholder="{{value}}" 
                        (keyup)="sendForm(form.form.valid)"
                        required
                        [class.invalid]="nominalCapacity.invalid && (nominalCapacity.dirty || nominalCapacity.touched)"
                        >
                    <div class="input-group-append">
                        <span class="unit input-group-text"> Ah </span>
                    </div>
                </div>
            </div>
            <div class="form-group form-div">
                <label for="nominalLineVoltage">{{ "register.resources.newResource.storage.nominalLineVoltage" | translate }}</label>
                <div class="input-group input-div">
                    <input 
                        [(ngModel)]="storageForm.nominalLineVoltage" 
                        #nominalLineVoltage="ngModel"
                        name="nominalLineVoltage" 
                        type="number" 
                        class="form-control input-unit" 
                        placeholder="{{value}}" 
                        (keyup)="sendForm(form.form.valid)"
                        required
                        [class.invalid]="nominalLineVoltage.invalid && (nominalLineVoltage.dirty || nominalLineVoltage.touched)"
                        >
                    <div class="input-group-append">
                        <span class="unit input-group-text"> V </span>
                    </div>
                </div>
            </div>
            <div class="form-group form-div">
                <label for="nominalVoltage">{{ "register.resources.newResource.storage.nominalVoltage" | translate }}</label>
                <div class="input-group input-div">
                    <input 
                        [(ngModel)]="storageForm.nominalVoltage" 
                        #nominalVoltage="ngModel"
                        name="nominalVoltage" 
                        type="number" 
                        class="form-control input-unit" 
                        placeholder="{{value}}" 
                        (keyup)="sendForm(form.form.valid)"
                        required
                        [class.invalid]="nominalVoltage.invalid && (nominalVoltage.dirty || nominalVoltage.touched)"
                        >
                    <div class="input-group-append">
                        <span class="unit input-group-text"> V </span>
                    </div>
                </div>
            </div>
            <div class="form-group form-div">
                <label for="ratedRechargePower">{{ "register.resources.newResource.storage.ratedRechargePower" | translate }}</label>
                <div class="input-group input-div">
                    <input 
                        [(ngModel)]="storageForm.ratedRechargePower" 
                        #ratedRechargePower="ngModel"
                        name="ratedRechargePower" 
                        type="number" 
                        class="form-control input-unit" 
                        placeholder="{{value}}" 
                        (keyup)="sendForm(form.form.valid)"
                        required
                        [class.invalid]="ratedRechargePower.invalid && (ratedRechargePower.dirty || ratedRechargePower.touched)"
                        >
                    <div class="input-group-append">
                        <span class="unit input-group-text"> W </span>
                    </div>
                </div>
            </div>
            <div class="form-group form-div">
                <label for="ratedDischargePower">{{ "register.resources.newResource.storage.ratedDischargePower" | translate }}</label>
                <div class="input-group input-div">
                    <input 
                        [(ngModel)]="storageForm.ratedDischargePower" 
                        #ratedDischargePower="ngModel"
                        name="ratedDischargePower" 
                        type="number" 
                        class="form-control input-unit" 
                        placeholder="{{value}}" 
                        (keyup)="sendForm(form.form.valid)"
                        required
                        [class.invalid]="ratedDischargePower.invalid && (ratedDischargePower.dirty || ratedDischargePower.touched)"
                        >
                    <div class="input-group-append">
                        <span class="unit input-group-text"> W </span>
                    </div>
                </div>
            </div>
            <div class="form-group form-div">
                <label for="autonomy">{{ "register.resources.newResource.storage.autonomy" | translate }}</label>
                <div class="input-group input-div">
                    <input 
                        [(ngModel)]="storageForm.autonomy" 
                        #autonomy="ngModel"
                        name="autonomy" 
                        type="number" 
                        class="form-control input-unit" 
                        placeholder="{{value}}" 
                        (keyup)="sendForm(form.form.valid)"
                        required
                        [class.invalid]="autonomy.invalid && (autonomy.dirty || autonomy.touched)"
                        >
                    <div class="input-group-append">
                        <span class="unit input-group-text"> h </span>
                    </div>
                </div>
            </div>
            <div class="form-group form-div">
                <label for="chargingHourTime">{{ "register.resources.newResource.storage.chargingHourTime" | translate }}</label>
                <div class="input-group input-div">
                    <input 
                        [(ngModel)]="storageForm.chargingHourTime" 
                        #chargingHourTime="ngModel"
                        name="chargingHourTime" 
                        type="number" 
                        class="form-control input-unit" 
                        placeholder="{{value}}" 
                        (keyup)="sendForm(form.form.valid)"
                        required
                        [class.invalid]="chargingHourTime.invalid && (chargingHourTime.dirty || chargingHourTime.touched)"
                        >
                    <div class="input-group-append">
                        <span class="unit input-group-text"> h </span>
                    </div>
                </div>
            </div>
            <div class="form-group form-div">
                <label for="chargingEfficiency">{{ "register.resources.newResource.storage.chargingEfficiency" | translate }}</label>
                <div class="input-group input-div">
                    <input 
                        [(ngModel)]="storageForm.chargingEfficiency" 
                        #chargingEfficiency="ngModel"
                        name="chargingEfficiency" 
                        type="number" 
                        class="form-control input-unit" 
                        placeholder="{{value}}" 
                        (keyup)="sendForm(form.form.valid)"
                        required
                        [class.invalid]="chargingEfficiency.invalid && (chargingEfficiency.dirty || chargingEfficiency.touched)"
                        >
                    <div class="input-group-append">
                        <span class="unit input-group-text"> % </span>
                    </div>
                </div>
            </div>
            <div class="form-group forms-form">
                <label for="operatingTemperature">{{ "register.resources.newResource.storage.operatingTemperature" | translate }}</label>
                <div class="double-input">
                    <div class="input-group input-div">
                        <input 
                            [(ngModel)]="storageForm.operatingTemperatureMin" 
                            #operatingTemperatureMin="ngModel"
                            name="operatingTemperatureMin" 
                            type="number" 
                            class="form-control input-unit" 
                            placeholder="{{value}}" 
                            (keyup)="sendForm(form.form.valid)"
                            required
                            [class.invalid]="operatingTemperatureMin.invalid && (operatingTemperatureMin.dirty || operatingTemperatureMin.touched)"
                            >
                        <div class="input-group-append">
                            <span class="unit input-group-text"> °C </span>
                        </div>
                    </div>
                    <p style="font-size: 14px;">{{ "register.resources.newResource.to" | translate }}</p>
                    <div class="input-group input-div">
                        <input 
                            [(ngModel)]="storageForm.operatingTemperatureMax" 
                            #operatingTemperatureMax="ngModel"
                            name="operatingTemperatureMax" 
                            type="number" 
                            class="form-control input-unit" 
                            placeholder="{{value}}" 
                            (keyup)="sendForm(form.form.valid)"
                            required
                            [class.invalid]="operatingTemperatureMax.invalid && (operatingTemperatureMax.dirty || operatingTemperatureMax.touched)"
                            >
                        <div class="input-group-append">
                            <span class="unit input-group-text"> °C </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group form-div">
                <label for="protectionIndex">{{ "register.resources.newResource.storage.protectionIndex" | translate }}</label>
                <div class="input-group input-div">
                    <input 
                        [(ngModel)]="storageForm.protectionIndex" 
                        #protectionIndex="ngModel"
                        name="protectionIndex" 
                        type="number" 
                        class="form-control input-unit" 
                        placeholder="{{value}}" 
                        (keyup)="sendForm(form.form.valid)"
                        required
                        [class.invalid]="protectionIndex.invalid && (protectionIndex.dirty || protectionIndex.touched)"
                        >
                    <div class="input-group-append">
                        <span class="unit input-group-text"> IP </span>
                    </div>
                </div>
            </div>
            <div class="form-group forms-form">
                <label for="numberOfAssets">{{ "register.resources.newResource.storage.numberOfAssets" | translate }}</label>
                <input 
                    [(ngModel)]="storageForm.numberOfAssets" 
                    #numberOfAssets="ngModel"
                    name="numberOfAssets" 
                    type="number" 
                    class="form-control input-field" 
                    placeholder="{{value}}" 
                    (keyup)="sendForm(form.form.valid)"
                    required
                    [class.invalid]="numberOfAssets.invalid && (numberOfAssets.dirty || numberOfAssets.touched)"
                    >
            </div>
            <div class="form-group forms-form">
                <label for="batteryConfiguration">{{ "register.resources.newResource.storage.batteryConfiguration" | translate }}</label>
                <mat-form-field class="card-module-select" appearance="outline">
                    <mat-select [(ngModel)]="storageForm.batteryConfiguration" name="batteryConfiguration" [(value)]="batteryConfiguration" placeholder="{{select}}" required>
                        <mat-option *ngFor="let battery of batteryTypes" [value]="battery.type" (click)="sendForm(form.form.valid)">
                            {{ battery.title }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="form-group forms-form">
                <div>
                    <label for="lifeCycles">{{ "register.resources.newResource.storage.lifeCycles" | translate }}</label>
                    <img 
                        src="assets/icons/circle-info.svg" 
                        alt="Icon Info"
                        matTooltip="{{lifeCyclesTip}}"
                        matTooltipPosition="above"
                        matTooltipClass="indicator-tooltip"
                        >
                </div>
                <input 
                    [(ngModel)]="storageForm.lifeCycles" 
                    #lifeCycles="ngModel"
                    name="lifeCycles" 
                    type="number" 
                    class="form-control input-field" 
                    placeholder="{{value}}" 
                    (keyup)="sendForm(form.form.valid)"
                    required
                    [class.invalid]="lifeCycles.invalid && (lifeCycles.dirty || lifeCycles.touched)"
                    >
            </div>
            <div class="form-group form-div">
                <label for="purchaseCost">{{ "register.resources.newResource.storage.purchaseCost" | translate }}</label>
                <div class="input-group input-div">
                    <div class="input-group-prepend">
                        <span class="unit input-group-text"> {{ "register.resources.newResource.label.currency" | translate }} </span>
                    </div>
                    <input 
                        [(ngModel)]="storageForm.purchaseCost" 
                        #purchaseCost="ngModel"
                        name="purchaseCost" 
                        type="number" 
                        class="form-control input-unit" 
                        placeholder="{{value}}" 
                        (keyup)="sendForm(form.form.valid)"
                        required
                        [class.invalid]="purchaseCost.invalid && (purchaseCost.dirty || purchaseCost.touched)"
                        >
                </div>
            </div>
        </div>
        <p class="forms-line"></p>
    </form>
</div>