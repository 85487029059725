<div class="forms">
    <div class="forms-header">
        <label>{{ "register.resources.newResource.formsHeaderSpecifications" | translate }}</label>
        <img 
            src="assets/icons/circle-info.svg" 
            alt="Icon Info"
            matTooltip="{{specificationsTip}}"
            matTooltipPosition="above"
            matTooltipClass="indicator-tooltip"
            >
    </div>
    <form #form="ngForm">
        <div class="form-row">
            <div class="form-group forms-form">
                <label for="model">{{ "register.resources.newResource.model" | translate }}</label>
                <input 
                    [(ngModel)]="basicForm.model" 
                    #model="ngModel" 
                    name="model" 
                    type="text" 
                    class="form-control input-field" 
                    placeholder="{{insertModel}}" 
                    (keyup)="sendForm(form.form.valid)" 
                    required 
                    [class.invalid]="model.invalid && (model.dirty || model.touched)"
                    >
            </div>
            <div class="form-group forms-form">
                <label for="manufacturer">{{ "register.resources.newResource.manufacturer" | translate }}</label>
                <input 
                    [(ngModel)]="basicForm.manufacturer" 
                    #manufacturer="ngModel" 
                    name="manufacturer" 
                    type="text" 
                    class="form-control input-field" 
                    placeholder="{{insertManufacturer}}" 
                    (keyup)="sendForm(form.form.valid)" 
                    required 
                    [class.invalid]="manufacturer.invalid && (manufacturer.dirty || manufacturer.touched)"
                    >
            </div>
            <div class="form-group form-div">
                <label for="nominalVoltage">{{ "register.resources.newResource.load.nominalVoltage" | translate }}</label>
                <div class="input-group input-div">
                    <input 
                        [(ngModel)]="loadForm.nominalVoltage" 
                        #nominalVoltage="ngModel" 
                        name="nominalVoltage" 
                        type="number" 
                        class="form-control input-unit" 
                        placeholder="{{value}}" 
                        (keyup)="sendForm(form.form.valid)" 
                        required 
                        [class.invalid]="nominalVoltage.invalid && (nominalVoltage.dirty || nominalVoltage.touched)"
                        >
                    <div class="input-group-append">
                        <span class="unit input-group-text"> V </span>
                    </div>
                </div>
            </div>
            <div class="form-group form-div">
                <label for="nominalPower">{{ "register.resources.newResource.load.nominalPower" | translate }}</label>
                <div class="input-group input-div">
                    <input 
                        [(ngModel)]="loadForm.nominalPower" 
                        #nominalPower="ngModel" 
                        name="nominalPower" 
                        type="number" 
                        class="form-control input-unit" 
                        placeholder="{{value}}" 
                        (keyup)="sendForm(form.form.valid)" 
                        required 
                        [class.invalid]="nominalPower.invalid && (nominalPower.dirty || nominalPower.touched)"
                        >
                    <div class="input-group-append">
                        <span class="unit input-group-text"> kW </span>
                    </div>
                </div>
            </div>
            <div class="form-group form-div">
                <label for="nominalChain">{{ "register.resources.newResource.load.nominalChain" | translate }}</label>
                <div class="input-group input-div">
                    <input 
                        [(ngModel)]="loadForm.nominalChain" 
                        #nominalChain="ngModel" 
                        name="nominalChain" 
                        type="number" 
                        class="form-control input-unit" 
                        placeholder="{{value}}" 
                        (keyup)="sendForm(form.form.valid)" 
                        required 
                        [class.invalid]="nominalChain.invalid && (nominalChain.dirty || nominalChain.touched)"
                        >
                    <div class="input-group-append">
                        <span class="unit input-group-text"> A </span>
                    </div>
                </div>
            </div>
            <div class="form-group form-div">
                <label for="numberOfPhases">{{ "register.resources.newResource.load.numberOfPhases" | translate }}</label>
                <mat-form-field class="card-module-select" appearance="outline">
                    <mat-select [(ngModel)]="loadForm.numberOfPhases" name="numberOfPhases" [(value)]="numberOfPhases" placeholder="{{select}}" required>
                        <mat-option *ngFor="let asset of assets" [value]="asset.type" (click)="sendForm(form.form.valid)">
                            {{asset.title}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="form-group forms-form">
                <label for="operatingTemperature">{{ "register.resources.newResource.load.operatingTemperature" | translate }}</label>
                <div class="double-input">
                    <div class="input-group input-div">
                        <input 
                            [(ngModel)]="loadForm.operatingTemperatureMin" 
                            #operatingTemperatureMin="ngModel" 
                            name="operatingTemperatureMin" 
                            type="number" 
                            class="form-control input-unit" 
                            placeholder="{{value}}" 
                            (keyup)="sendForm(form.form.valid)" 
                            required 
                            [class.invalid]="operatingTemperatureMin.invalid && (operatingTemperatureMin.dirty || operatingTemperatureMin.touched)"
                            >
                        <div class="input-group-append">
                            <span class="unit input-group-text"> °C </span>
                        </div>
                    </div>
                    <p style="font-size: 14px;">{{ "register.resources.newResource.to" | translate }}</p>
                    <div class="input-group input-div">
                        <input 
                            [(ngModel)]="loadForm.operatingTemperatureMax" 
                            #operatingTemperatureMax="ngModel" 
                            name="operatingTemperatureMax" 
                            type="number" 
                            class="form-control input-unit" 
                            placeholder="{{value}}" 
                            (keyup)="sendForm(form.form.valid)" 
                            required 
                            [class.invalid]="operatingTemperatureMax.invalid && (operatingTemperatureMax.dirty || operatingTemperatureMax.touched)"
                            >
                        <div class="input-group-append">
                            <span class="unit input-group-text"> °C </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group form-div">
                <label for="protectionIndex">{{ "register.resources.newResource.load.protectionIndex" | translate }}</label>
                <div class="input-group input-div">
                    <input 
                        [(ngModel)]="loadForm.protectionIndex" 
                        #protectionIndex="ngModel" 
                        name="protectionIndex" 
                        type="number" 
                        class="form-control input-unit" 
                        placeholder="{{value}}" 
                        (keyup)="sendForm(form.form.valid)" 
                        required 
                        [class.invalid]="protectionIndex.invalid && (protectionIndex.dirty || protectionIndex.touched)"
                        >
                    <div class="input-group-append">
                        <span class="unit input-group-text"> IP </span>
                    </div>
                </div>
            </div>
            <div class="form-group forms-form">
                <label for="numberOfAssets">{{ "register.resources.newResource.load.numberOfAssets" | translate }}</label>
                <input 
                    [(ngModel)]="loadForm.numberOfAssets" 
                    #numberOfAssets="ngModel" 
                    name="numberOfAssets" 
                    type="number" 
                    class="form-control input-field" 
                    placeholder="{{value}}" 
                    (keyup)="sendForm(form.form.valid)" 
                    required 
                    [class.invalid]="numberOfAssets.invalid && (numberOfAssets.dirty || numberOfAssets.touched)"
                    >
            </div>
            <div class="form-group form-div">
                <label for="lifespan">{{ "register.resources.newResource.load.lifespan" | translate }}</label>
                <div class="input-group input-div">
                    <input 
                        [(ngModel)]="loadForm.lifespan" 
                        #lifespan="ngModel" 
                        name="lifespan" 
                        type="number" 
                        class="form-control input-unit" 
                        placeholder="{{value}}" 
                        (keyup)="sendForm(form.form.valid)" 
                        required 
                        [class.invalid]="lifespan.invalid && (lifespan.dirty || lifespan.touched)"
                        >
                    <div class="input-group-append">
                        <span class="unit input-group-text"> {{ "register.resources.newResource.label.years" | translate }} </span>
                    </div>
                </div>
            </div>
            <div class="form-group form-div">
                <label for="purchaseCost">{{ "register.resources.newResource.load.purchaseCost" | translate }}</label>
                <div class="input-group input-div">
                    <div class="input-group-prepend">
                        <span class="unit input-group-text"> {{ "register.resources.newResource.label.currency" | translate }} </span>
                    </div>
                    <input 
                        [(ngModel)]="loadForm.purchaseCost" 
                        #purchaseCost="ngModel" 
                        name="purchaseCost" 
                        type="number" 
                        class="form-control input-unit" 
                        placeholder="{{value}}" 
                        (keyup)="sendForm(form.form.valid)" 
                        required 
                        [class.invalid]="purchaseCost.invalid && (purchaseCost.dirty || purchaseCost.touched)"
                        >
                </div>
            </div>
        </div>
        <p class="forms-line"></p>
    </form>
</div>