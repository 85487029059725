export class EnergyUtil {
  static convertEnergyUnit(value: number): string {
    if (value <= 1000) {
      return EnergyUtil.toDecimalValue(value); // Watt
    }
    if (value >= 1e3 && value < 1e6) {
      return EnergyUtil.toDecimalValue(value / 1e3); // KiloWatt
    }
    if (value >= 1e6 && value < 1e9) {
      return EnergyUtil.toDecimalValue(value / 1e6); // MegaWatt
    }
    if (value >= 1e9) {
      return EnergyUtil.toDecimalValue(value / 1e9); // GigaWatt
    }
    return EnergyUtil.toDecimalValue(value);
  }

  static toDecimalValue(value: number): string {
    return value.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  static getUnitEnergyByHour(value: number): string {
    if (value <= 1000) {
      return 'indicator.energyWattHour';
    }
    if (value >= 1000 && value < 1000000) {
      return 'indicator.energyKiloWattHour';
    }
    if (value >= 1000000 && value < 1000000000) {
      return 'indicator.energyMegaWattHour';
    }
    if (value >= 1000000000) {
      return 'indicator.energyGigaWattHour';
    }
    return '';
  }

  static getUnitEnergy(value: number): string {
    if (value <= 1000) {
      return 'indicator.energyWatt';
    }
    if (value >= 1000 && value < 1000000) {
      return 'indicator.energyKiloWatt';
    }
    if (value >= 1000000 && value < 1000000000) {
      return 'indicator.energyMegaWatt';
    }
    if (value >= 1000000000) {
      return 'indicator.energyGigaWatt';
    }
    return '';
  }
}
