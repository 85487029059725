<div class="dashboards">
  <form [formGroup]="dashboardForm" (ngSubmit)="save()">
    <div class="dashboards-header">
      <div class="header-left" (click)="openConfigurationDialog()">
        <app-card-icon type="{{ icon }}"></app-card-icon>
        <div class="text-container">
          <h2>{{ getHeaderTitle() }}</h2>
          <p>{{ getHeaderDescription() }}</p>
        </div>
      </div>
      <div class="header-right">
        <button type="button" (click)="cancelEdit()" class="cancel-button dashboard-button">
          {{ 'dashboard_analisys.metadata.cancel' | translate }}
        </button>
        <button
          type="submit"
          class="save-button dashboard-button"
          [ngClass]="{ 'disabled-button': !dashboardForm.valid }"
        >
          {{ 'dashboard_analisys.metadata.save' | translate }}
        </button>
      </div>
    </div>

    <div class="main-container">
      <mat-card
        class="parameters-panel card-configuration"
        appearance="outlined"
        [ngClass]="{
          'card-height-max': isCardMaxHeight(),
          'card-height-min': !isCardMaxHeight()
        }"
      >
        <mat-card-content class="card-configuration">
          <div class="parameters">
            <div class="header-parameters">
              <div class="header-left-parameters">
                <h3>{{ 'dashboard_analisys.parameters.title' | translate }}</h3>
                <p>{{ 'dashboard_analisys.parameters.description' | translate }}</p>
              </div>
              <div class="header-right-parameters">
                <div class="checkbox-container">
                  <div *ngIf="filledAnalisyPeriodSelected()">
                    <mat-checkbox
                      id="mat-mdc-checkbox-1-input"
                      color="primary"
                      [checked]="true"
                      >{{ 'dashboard_analisys.parameters.analysis_period_label' | translate }}:
                      {{ dateAnalisyPeriodSelectedString }}</mat-checkbox
                    >
                  </div>
                  <div *ngIf="filledComparePeriodSelected()">
                    <mat-checkbox
                      id="mat-mdc-checkbox-2-input"
                      class="ckb-pink"
                      [checked]="true"
                      >{{ 'dashboard_analisys.parameters.comparison_period_label' | translate }}:
                      {{ dateComparePeriodSelectedString }}</mat-checkbox
                    >
                  </div>
                </div>
              </div>
            </div>

            <mat-divider></mat-divider>

            <div class="body-parameters">
              <div class="select">
                <label class="info" for="tariffSubgroup">{{
                  'dashboard_analisys.parameters.analysis_period.label' | translate
                }}</label>
                <mat-form-field appearance="outline" class="select-analisy-period">
                  <mat-select placeholder="Selecione">
                    <mat-select-trigger>{{ getAnalisyPeriodSelected() }}</mat-select-trigger>

                    <mat-option
                      class="options"
                      (click)="setAnalisyPeriodSelected(DashboardAnalysisPeriod.Today)"
                      [value]="DashboardAnalysisPeriod.Today"
                    >
                      <p class="title">
                        {{ 'dashboard_analisys.parameters.analysis_period.today' | translate }}
                      </p>
                    </mat-option>

                    <mat-option
                      class="options"
                      (click)="setAnalisyPeriodSelected(DashboardAnalysisPeriod.Last_7_Days)"
                      [value]="DashboardAnalysisPeriod.Last_7_Days"
                    >
                      <p class="title">
                        {{ 'dashboard_analisys.parameters.analysis_period.last_7_days' | translate }}
                      </p>
                    </mat-option>

                    <mat-option
                      class="options"
                      (click)="setAnalisyPeriodSelected(DashboardAnalysisPeriod.Last_15_Days)"
                      [value]="DashboardAnalysisPeriod.Last_15_Days"
                    >
                      <p class="title">
                        {{ 'dashboard_analisys.parameters.analysis_period.last_15_days' | translate }}
                      </p>
                    </mat-option>

                    <mat-option
                      class="options"
                      (click)="setAnalisyPeriodSelected(DashboardAnalysisPeriod.Last_30_Days)"
                      [value]="DashboardAnalysisPeriod.Last_30_Days"
                    >
                      <p class="title">
                        {{ 'dashboard_analisys.parameters.analysis_period.last_30_days' | translate }}
                      </p>
                    </mat-option>

                    <mat-option
                      class="options"
                      (click)="setAnalisyPeriodSelected(DashboardAnalysisPeriod.Last_Week)"
                      [value]="DashboardAnalysisPeriod.Last_Week"
                    >
                      <p class="title">
                        {{ 'dashboard_analisys.parameters.analysis_period.last_week' | translate }}
                      </p>
                    </mat-option>

                    <mat-option
                      class="options"
                      (click)="setAnalisyPeriodSelected(DashboardAnalysisPeriod.Last_Month)"
                      [value]="DashboardAnalysisPeriod.Last_Month"
                    >
                      <p class="title">
                        {{ 'dashboard_analisys.parameters.analysis_period.last_month' | translate }}
                      </p>
                    </mat-option>

                    <mat-option
                      class="options"
                      (click)="setAnalisyPeriodSelected(DashboardAnalysisPeriod.Last_Quarter)"
                      [value]="DashboardAnalysisPeriod.Last_Quarter"
                    >
                      <p class="title">
                        {{ 'dashboard_analisys.parameters.analysis_period.last_quarter' | translate }}
                      </p>
                    </mat-option>

                    <mat-option
                      class="options"
                      (click)="setAnalisyPeriodSelected(DashboardAnalysisPeriod.Custom_Period)"
                      [value]="DashboardAnalysisPeriod.Custom_Period"
                    >
                      <p class="title">
                        {{ 'dashboard_analisys.parameters.analysis_period.custom_period' | translate }}
                      </p>
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <div class="form-group" *ngIf="isAnalisyPeriodSelected()">
                  <div class="form-control">
                    <mat-label>{{
                      'dashboard_analisys.parameters.analysis_period_start_date' | translate
                    }}</mat-label>
                    <mat-form-field appearance="outline" class="custom-form-field" required>
                      <input
                        matInput
                        [matDatepicker]="pickerInicialDatePSDi"
                        formControlName="analysisPeriodStartDate"
                      />
                      <mat-datepicker-toggle matIconSuffix [for]="pickerInicialDatePSDi">
                        <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                      </mat-datepicker-toggle>
                      <mat-datepicker #pickerInicialDatePSDi></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div class="form-control">
                    <mat-label>{{
                      'dashboard_analisys.parameters.analysis_period_end_date' | translate
                    }}</mat-label>
                    <mat-form-field appearance="outline" class="custom-form-field" required>
                      <input
                        matInput
                        [matDatepicker]="pickerFinishDatePSdf"
                        formControlName="analysisPeriodEndDate"
                      />
                      <mat-datepicker-toggle matIconSuffix [for]="pickerFinishDatePSdf">
                        <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                      </mat-datepicker-toggle>
                      <mat-datepicker #pickerFinishDatePSdf></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div class="select">
                <label class="info" for="tariffSubgroup">{{
                  'dashboard_analisys.parameters.comparison_period.label' | translate
                }}</label>
                <mat-form-field appearance="outline" class="select-compare-period">
                  <mat-select placeholder="Selecione" required>
                    <mat-select-trigger>{{ getComparePeriodSelected() }}</mat-select-trigger>

                    <mat-option
                      class="options"
                      (click)="setComparePeriodSelected(DashboardComparisonPeriod.Do_Not_Compare)"
                      [value]="DashboardComparisonPeriod.Do_Not_Compare"
                    >
                      <p class="title">
                        {{ 'dashboard_analisys.parameters.comparison_period.do_not_compare' | translate }}
                      </p>
                    </mat-option>

                    <mat-option
                      class="options"
                      (click)="
                        setComparePeriodSelected(DashboardComparisonPeriod.Same_Period_Year_Previous_Year)
                      "
                      [value]="DashboardComparisonPeriod.Same_Period_Year_Previous_Year"
                    >
                      <p class="title">
                        {{
                          'dashboard_analisys.parameters.comparison_period.same_period_year_previous_year'
                            | translate
                        }}
                      </p>
                    </mat-option>

                    <mat-option
                      class="options"
                      (click)="setComparePeriodSelected(DashboardComparisonPeriod.Custom_Period)"
                      [value]="DashboardComparisonPeriod.Custom_Period"
                    >
                      <p class="title">
                        {{ 'dashboard_analisys.parameters.comparison_period.custom_period' | translate }}
                      </p>
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <div class="form-group" *ngIf="comparePeriodSelectedIsCustomPeriod()">
                  <div class="form-control">
                    <mat-label>{{
                      'dashboard_analisys.parameters.comparison_period_start_date' | translate
                    }}</mat-label>
                    <mat-form-field appearance="outline" class="custom-form-field" required>
                      <input
                        matInput
                        [matDatepicker]="pickerInicialDateCPdi"
                        formControlName="comparisonPeriodStartDate"
                      />
                      <mat-datepicker-toggle matIconSuffix [for]="pickerInicialDateCPdi">
                        <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                      </mat-datepicker-toggle>
                      <mat-datepicker #pickerInicialDateCPdi></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div class="form-control">
                    <mat-label>{{
                      'dashboard_analisys.parameters.comparison_period_end_date' | translate
                    }}</mat-label>
                    <mat-form-field appearance="outline" class="custom-form-field" required>
                      <input
                        matInput
                        [matDatepicker]="pickerFinishDateCPdf"
                        formControlName="comparisonPeriodEndDate"
                      />
                      <mat-datepicker-toggle matIconSuffix [for]="pickerFinishDateCPdf">
                        <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                      </mat-datepicker-toggle>
                      <mat-datepicker #pickerFinishDateCPdf></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <button type="button" (click)="setParameters()" class="btn btn-success">
                  {{ 'dashboard_analisys.metadata.parameter_update' | translate }}
                </button>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>

      <div class="container">
        <div class="row"></div>
        <div class="row">
          <mat-card class="warning-card">
            <div class="warning-content" >
              <app-tag-icon [icon]="'warning'" [type]="'warning'" [size]="18"> </app-tag-icon>
              <span>{{ "dashboard_analisys.graphic.scenario_empty" | translate }}</span>
            </div>
          </mat-card>
        </div>
        <div class="row">
          <mat-card class="card-graph" *ngFor="let card of graphs">
            <mat-card-content>
              <div class="card-graph-title">
                <span class="title">{{ card.title }}</span>
                <button
                  type="button"
                  mat-icon-button
                  [matMenuTriggerFor]="menuGraph"
                  class="menu-graph-button"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menuGraph="matMenu">
                  <button class="graph-menu-item" mat-menu-item (click)="editGraph(card.index)">
                    <i class="fa-regular fa-edit"></i>
                    {{ 'dashboard_analisys.menu.edit' | translate }}
                  </button>
                  <button class="graph-menu-item" mat-menu-item (click)="deleteGraph(card.index)">
                    <i class="fa-regular fa-trash"></i>
                    {{ 'dashboard_analisys.menu.delete' | translate }}
                  </button>
                </mat-menu>
              </div>
              <div class="card-graph-body">
                <app-line-chart [graph]="card"></app-line-chart>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="row">
          <mat-card class="card-add">
            <mat-card-content>
              <div class="card-add-body" (click)="openConfigurationGraphDialog()">
                <div class="icon-container">
                  <i class="fa-regular fa-plus"></i>
                </div>
                <div class="card-add-title">
                  <h3>{{ 'dashboard_analisys.graphic.btn_add.add_visualization' | translate }}</h3>
                </div>
                <mat-divider></mat-divider>
                <div class="card-add-description">
                  <p>{{ 'dashboard_analisys.graphic.btn_add.message' | translate }}</p>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </form>
</div>
