import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { GeneralInformationService } from '../../../core/services/general-information/general-information.service';
import { LocationService } from '../../../core/services/locations/location.service';
import { State } from 'src/app/core/models/interfaces/state.model';
import { City } from 'src/app/core/models/interfaces/city.model';
import { Ugrid } from 'src/app/core/models/interfaces/ugrid.model';
import { Org } from 'src/app/core/models/interfaces/org.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppConstants } from 'src/app/shared/constants/app.constants';
import { CustomSnackbarSuccessComponent } from 'src/app/shared/components/custom-snackbar-success/custom-snackbar-success.component';
import { CustomSnackbarErrorComponent } from 'src/app/shared/components/custom-snackbar-error/custom-snackbar-error.component';
import { Timezone } from 'src/app/shared/enums/timezone.enum';
import { StringUtil } from 'src/app/core/utils/string.util';

@Component({
  selector: 'app-general-information',
  templateUrl: './general-information.component.html',
  styleUrls: ['./general-information.component.scss'],
})
export class GeneralInformationComponent {
  formToRegister: FormGroup;
  ugrid?: Ugrid;
  org?: Org;
  timeszones: any = [];
  states: State[] = [];
  cities: City[] = [];
  countries: string[] = ['Brasil'];
  modeSave: boolean = false;

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private generalInformationService: GeneralInformationService,
    private LocationService: LocationService,
    private snackBar: MatSnackBar,
  ) {
    this.formToRegister = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(200)]],
      document: ['', [Validators.required]],
      ugridName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(200)]],
      postcode: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(10)]],
      timeZone: ['', [Validators.required]],
      country: ['', [Validators.required]],
      state: ['', [Validators.required]],
      city: ['', [Validators.required]],
      address: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(400)]],
      number: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(10)]],
      complement: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(250)]],
    });
  }

  async ngOnInit(): Promise<void> {
    this.timeszones = this.generalInformationService.getTimezoneList();

    const [states, org, ugrid] = await Promise.all([
      this.LocationService.listStates(),
      this.generalInformationService.getOrg(),
      this.generalInformationService.getUgrid(),
    ]);

    this.states = states;
    this.org = org;
    this.ugrid = ugrid;

    this.modeSave = this.org == undefined || this.ugrid == undefined;
    this.initializeForm();
    this.formToRegister.disable();
  }

  async initializeForm() {
    let state = undefined;
    if (this.ugrid?.state != undefined) {
      const selectedState = this.states.find((s) => s.name == this.ugrid?.state);
      if (selectedState != null) {
        state = selectedState.uf;
      }
    }

    const document = StringUtil.documentFormat(this.org?.document ?? '');
    const cep = StringUtil.cepFormat(this.ugrid?.cep ?? '');
    const timezone = this.generalInformationService.getTimezone(this.ugrid?.timezone ?? 0);
    const timezoneEnum = timezone ?? Timezone.America_Sao_Paulo;
    this.formToRegister.patchValue({
      name: this.org?.name,
      document: document,
      ugridName: this.ugrid?.name,
      postcode: cep,
      timeZone: timezoneEnum,
      country: this.ugrid?.country ?? 'Brasil',
      state: state,
      city: this.ugrid?.city,
      address: this.ugrid?.address,
      number: this.ugrid?.addressNumber,
      complement: this.ugrid?.addressComplement,
    });

    await this.getCitiesByState();
  }

  patch() {
    this.org!.name = this.formToRegister.get('name')?.value;
    this.org!.document = StringUtil.extractNumbersFromString(this.formToRegister.get('document')?.value);

    this.ugrid!.name = this.formToRegister.get('ugridName')?.value;
    this.ugrid!.cep = StringUtil.extractNumbersFromString(this.formToRegister.get('postcode')?.value);

    const timezone = this.formToRegister.get('state')?.value ?? Timezone.America_Sao_Paulo;
    this.ugrid!.timezone = parseInt(this.generalInformationService.getUtcOffset(timezone));
    this.ugrid!.country = this.formToRegister.get('country')?.value;
    this.ugrid!.state = this.formToRegister.get('state')?.value;
    this.ugrid!.city = this.formToRegister.get('city')?.value;
    this.ugrid!.address = this.formToRegister.get('address')?.value;
    this.ugrid!.addressNumber = this.formToRegister.get('number')?.value;
    this.ugrid!.addressComplement = this.formToRegister.get('complement')?.value;
  }

  validateName(context: string): boolean {
    const flag = this.validateInput('name');

    switch (context) {
      case 'required':
        return flag && this.formToRegister.controls['name'].errors?.['required'];
      case 'minlength':
        return flag && this.formToRegister.controls['name'].errors?.['minlength'];
      case 'maxlength':
        return flag && this.formToRegister.controls['name'].errors?.['maxlength'];
      default:
        return flag;
    }
  }

  validateDocument(context: string): boolean {
    const flag = this.validateInput('document');
    return flag && this.formToRegister.controls['document'].errors?.['required'];
  }

  validateUgridName(context: string): boolean {
    const flag = this.validateInput('ugridName');

    switch (context) {
      case 'required':
        return flag && this.formToRegister.controls['ugridName'].errors?.['required'];
      case 'minlength':
        return flag && this.formToRegister.controls['ugridName'].errors?.['minlength'];
      case 'maxlength':
        return flag && this.formToRegister.controls['ugridName'].errors?.['maxlength'];
      default:
        return flag;
    }
  }

  validatePostcode(context: string): boolean {
    const flag = this.validateInput('postcode');

    switch (context) {
      case 'required':
        return flag && this.formToRegister.controls['postcode'].errors?.['required'];
      case 'minlength':
        return flag && this.formToRegister.controls['postcode'].errors?.['minlength'];
      case 'maxlength':
        return flag && this.formToRegister.controls['postcode'].errors?.['maxlength'];
      default:
        return flag;
    }
  }

  validateTimeZone(context: string): boolean {
    const flag = this.validateInput('timeZone');
    return flag && this.formToRegister.controls['timeZone'].errors?.['required'];
  }

  validateCountry(context: string): boolean {
    const flag = this.validateInput('country');
    return flag && this.formToRegister.controls['country'].errors?.['required'];
  }

  validateState(context: string): boolean {
    const flag = this.validateInput('state');
    return flag && this.formToRegister.controls['state'].errors?.['required'];
  }

  validateCity(context: string): boolean {
    const flag = this.validateInput('city');
    return flag && this.formToRegister.controls['city'].errors?.['required'];
  }

  validateAddress(context: string): boolean {
    const flag = this.validateInput('address');
    switch (context) {
      case 'required':
        return flag && this.formToRegister.controls['address'].errors?.['required'];
      case 'minlength':
        return flag && this.formToRegister.controls['address'].errors?.['minlength'];
      case 'maxlength':
        return flag && this.formToRegister.controls['address'].errors?.['maxlength'];
      default:
        return flag;
    }
  }

  validateNumber(context: string): boolean {
    const flag = this.validateInput('number');
    switch (context) {
      case 'required':
        return flag && this.formToRegister.controls['number'].errors?.['required'];
      case 'minlength':
        return flag && this.formToRegister.controls['number'].errors?.['minlength'];
      case 'maxlength':
        return flag && this.formToRegister.controls['number'].errors?.['maxlength'];
      default:
        return flag;
    }
  }

  validateComplement(context: string): boolean {
    const flag = this.validateInput('complement');

    switch (context) {
      case 'required':
        return flag && this.formToRegister.controls['complement'].errors?.['required'];
      case 'minlength':
        return flag && this.formToRegister.controls['complement'].errors?.['minlength'];
      case 'maxlength':
        return flag && this.formToRegister.controls['complement'].errors?.['maxlength'];
      default:
        return flag;
    }
  }

  enableButton(): boolean {
    return !this.formToRegister.valid;
  }

  private validateInput(name: string): boolean {
    return (
      this.formToRegister.controls[name].invalid &&
      (this.formToRegister.controls[name].dirty || this.formToRegister.controls[name].touched)
    );
  }

  async register() {
    if (this.formToRegister.valid) {
      this.patch();

      if (this.modeSave) {
        await this.generalInformationService.createOrg(this.org!);
        await this.generalInformationService.createUgrid(this.ugrid!);
      } else {
        const codeOrg = await this.generalInformationService.updateOrg(this.org!);
        const codeUgrid = await this.generalInformationService.updateUgrid(this.ugrid!);

        if (codeOrg == 403 && codeUgrid == 403) {
          this.openSnackbar(403);
        } else {
          this.openSnackbar(200);
        }
      }

      this.toggleInputs();
    } else {
      this.formToRegister.markAllAsTouched();
      this.openSnackbar(500);
    }
  }

  async getCitiesByState(): Promise<void> {
    const state = this.formToRegister.get('state')?.value ?? null;
    if (state == null) {
      return;
    }

    this.cities = await this.LocationService.listCitiesByState(state.toString().toUpperCase());
  }

  removeInvalidClass(event: Event): void {
    const element = event.target as HTMLElement;
    element.classList.remove('invalid');
  }

  addInvalidClass(event: Event, controlName: string): void {
    const control = this.formToRegister.get(controlName);
    const element = event.target as HTMLElement;
    if (control?.invalid && control?.touched) {
      element.classList.add('invalid');
    }
  }

  openSnackbar(status: number): void {
    switch (status) {
      case AppConstants.CREATE_SUCESS:
        this.snackBar.openFromComponent(CustomSnackbarSuccessComponent, {
          data: {
            message: this.translate.instant('register.general_information.register_success'),
            snackBar: this.snackBar,
          },
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: AppConstants.TREE_SECOND_WAIT,
        });
        break;
      case AppConstants.OK:
        this.snackBar.openFromComponent(CustomSnackbarSuccessComponent, {
          data: {
            message: this.translate.instant('register.general_information.register_success'),
            snackBar: this.snackBar,
          },
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: AppConstants.TREE_SECOND_WAIT,
        });
        break;
      default:
        this.snackBar.openFromComponent(CustomSnackbarErrorComponent, {
          data: {
            message: this.translate.instant('register.general_information.register_error'),
            snackBar: this.snackBar,
          },
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: AppConstants.TREE_SECOND_WAIT,
        });
        break;
    }
  }

  toggleInputs() {
    if (this.formToRegister.disabled) {
      this.formToRegister.enable();
    } else {
      this.formToRegister.disable();
    }
  }
}
