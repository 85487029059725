<div class="register-grid">
    <div class="register-grid__header">
        <div class="register-grid__header-info">
            <h2>{{ "register.grid.title" | translate }}</h2>
            <p>{{ "register.grid.info" | translate }}</p>
        </div>
    </div>

    <div class="register-grid__container">
        <div class="register-grid__container-register">
            <h2 class="register-grid__container-register__header">{{ "register.basicInfo" | translate }}</h2>
            <form action="" class="register-grid__container-register__fields" [formGroup]="gridForm">
                <div class="register-grid__container-register__fields-sections">
                    <div>
                        <label class="info" for="energyDistributor">{{ "register.grid.energyDistributor" | translate }}</label>
                        <input 
                            formControlName="energyDistributor" 
                            type="text" 
                            class="input-field "
                            [class.disabled]="disabled"
                            required
                            matInput
                            [matAutocomplete]="auto"
                            placeholder="{{ enterTheDistributor | translate }}">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                {{ option }}
                            </mat-option>
                        </mat-autocomplete>
                    </div>
                    <div class="select">
                        <label class="info" for="tariffSubgroup">{{ "register.grid.tariffSubgroup" | translate }}</label>
                        <mat-form-field appearance="outline" class="select-tariff" [class.disabled]="disabled">
                            <mat-select placeholder="{{ select | translate}}" formControlName="tariffSubgroup">
                                <mat-option *ngFor="let option of tariffSubgroupOptions" [value]="option.value" class="options">
                                    <p class="title">{{ option.title | translate}}</p>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="select">
                        <label class="info" for="type">{{ "register.grid.typeSupply" | translate }}</label>
                        <mat-form-field appearance="outline" class="select-supply" [class.disabled]="disabled">
                            <mat-select placeholder="{{ select | translate}}" formControlName="typeSupply">
                                <mat-option *ngFor="let option of typeOfSupplyOptions" [value]="option.value" class="options">
                                    <p class="title">{{ option.title | translate}}</p>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="register-grid__container-register__fields-sections">
                    <div>
                        <label for="installNumber">{{ "register.grid.installNumber" | translate }}</label>
                        <input 
                            formControlName="installNumber" 
                            type="text" class="input-field"
                            [class.disabled]="disabled"
                            placeholder="{{ number | translate }}">
                    </div>
                    <div>
                        <label for="customerCode">{{ "register.grid.customerCode" | translate }}</label>
                        <input 
                            formControlName="customerCode" 
                            type="text" class="input-field" 
                            [class.disabled]="disabled"
                            placeholder="{{ code | translate }}">
                    </div>
                    <div class="form-group form-supply-voltage">
                        <label class="info" for="supplyVoltage">{{ "register.grid.supplyVoltage" | translate }}</label>
                        <div class="input-group input-div">
                            <input
                                formControlName="supplyVoltage"
                                name="nominalLineVoltage"
                                type="number" 
                                [class.disabled]="disabled"
                                class="form-control input-unit"
                                placeholder="{{ value | translate }}"
                                required
                                >
                            <div class="input-group-append" [class.disabled]="disabled">
                                <span class="unit input-group-text" [class.disabled]="disabled"> V </span>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            <hr>

            <div class="register-grid__container-register__btns">
                <button
                    *ngIf="showBtnCancel"
                    class="cancel"
                    (click)="disableEdtion()"
                >
                    {{ "modal.cancel" | translate }}
                </button>
                <button
                    *ngIf="!disabled"
                    class="save"
                    [disabled]="!formValidation"
                    [class.disabled]="!formValidation"
                    [class.enabled]="formValidation"
                    (click)="save()"
                >
                    {{ "modal.save" | translate }}
                </button>
                <button
                    *ngIf="disabled"
                    class="edit"
                    (click)="enableEdition()"
                >
                    {{ "modal.upadateRegister" | translate }}
                </button>
            </div>

        </div>
    </div>
</div>