import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TechnicalSupportModalComponent } from './shared/components/technical-support/technical-support-modal.component';
import { TechnicalSupportModal } from './core/models/interfaces/technical-support-modal.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'autopilot-dashboard';

  constructor(public dialog: MatDialog) {}

  openSupportRequestDialog(data: TechnicalSupportModal): void {
    this.dialog.open(TechnicalSupportModalComponent, {
      width: '400px',
      data: data
    });
  }
}
