import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import IndicatorResquest from 'src/app/core/models/request/indicator-request.model';
import { IndicatorService } from 'src/app/core/services/indicatorServices/indicator.service';
import { Chart, ChartConfiguration, ChartDataset, ChartOptions } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { BaseChartDirective } from 'ng2-charts';
import { LineChartSetupUtil } from 'src/app/core/utils/line-chart-setup.util';
import { UgridOperationService } from 'src/app/core/services/ugrid-services/ugrid-operation.service';
import { UgridOperationGranularityEnum } from 'src/app/core/models/enums/ugrid-operation-granularity.enum';
import * as moment from 'moment';
import { UgridMeasurementTypeEnum } from 'src/app/core/models/enums/ugrid-operation-measurement-type.enum';

Chart.register(zoomPlugin);

@Component({
  selector: 'app-dashboard-monitoring',
  templateUrl: './dashboard-monitoring.component.html',
  styleUrls: ['./dashboard-monitoring.component.scss'],
})
export class DashboardMonitoringComponent {
  @ViewChild(BaseChartDirective)
  public chart?: BaseChartDirective;
  currentDate = new Date().toLocaleDateString(this.translate.currentLang, { month: 'long', year: 'numeric' });
  lineChartCfgDataSet: ChartConfiguration<'line'>['data'] | undefined;
  lineChartOptions: ChartOptions<'line'> | undefined;
  chartSetupUtil: LineChartSetupUtil;
  chartLoaded = false;
  indicators: IndicatorResquest[] = [];
  operations: any;

  constructor(
    private translate: TranslateService,
    private indicatorService: IndicatorService,
    private readonly ugridOperationService: UgridOperationService,
  ) {
    this.chartSetupUtil = new LineChartSetupUtil(translate);
  }

  async ngOnInit(): Promise<void> {
    this.indicators = await this.indicatorService.getAll();
  }

  async ngAfterViewInit(): Promise<void> {    
    const intDate = moment.utc().startOf('month').toDate();
    const endDate = moment.utc().toDate();
    this.operations = await this.ugridOperationService.getAll(
      intDate,
      endDate,
      UgridOperationGranularityEnum.day,
      UgridMeasurementTypeEnum.Power
    );
    this.lineChartCfgDataSet = await this.getOperationSeries();
    this.lineChartOptions = this.chartSetupUtil.getLineChartOptions();
    this.chartLoaded = true;
    this.chartSetupUtil.appendCheckboxInput(this.lineChartCfgDataSet?.datasets);
    this.addEventInCheckbox(this.lineChartCfgDataSet?.datasets);
  }

  async getOperationSeries(): Promise<ChartConfiguration<'line'>['data']> {
    return this.chartSetupUtil.getUgridOperationSeriesDataset(this.operations, UgridOperationGranularityEnum.day);
  }

  checkboxChangeEffect(event: any): void {
    const index = event.target.value;
    if (this.chart?.chart?.isDatasetVisible(index)) {
      this.chart?.chart?.hide(index);
    } else {
      this.chart?.chart?.show(index);
    }
  }

  addEventInCheckbox(datasets: ChartDataset[]): void {
    datasets.forEach((dataset, index) => {
      const ckBox = document.getElementById(`checkbox${index}`);
      ckBox?.addEventListener('change', (e) => {
        this.checkboxChangeEffect(e);
      });
    });
  }

  resetZoom(): void {
    this.chart?.chart?.resetZoom();
  }
}
