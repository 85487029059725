import { HttpStatusCode } from '@angular/common/http';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable, startWith, Subscription } from 'rxjs';
import { TimeSeriesDataGroupingEnum } from 'src/app/core/models/enums/timeSeriesDataGroupingEnum';
import { TimeSeriesDataSource } from 'src/app/core/models/enums/timeSeriesDataSourceEnum';
import { TimeSeriesSamplingFrequencyTypeEnum } from 'src/app/core/models/enums/timeSeriesSamplingFrequencyTypeEnum';
import { DataUploadRequest } from 'src/app/core/models/request/data-upload-request.model';
import { IndicatorService } from 'src/app/core/services/indicatorServices/indicator.service';
import { TimeSeriesService } from 'src/app/core/services/timeSeries/time-series.service';
import { measurementOptions } from 'src/app/core/utils/measurement-unit-options.utils';
import { LoadingService } from 'src/app/pages/auth/loading.service';
import { CustomSnackbarErrorComponent } from 'src/app/shared/components/custom-snackbar-error/custom-snackbar-error.component';
import { CustomSnackbarSuccessComponent } from 'src/app/shared/components/custom-snackbar-success/custom-snackbar-success.component';
import { AppConstants } from 'src/app/shared/constants/app.constants';

@Component({
  selector: 'app-data-upload',
  templateUrl: './data-upload.component.html',
  styleUrls: ['./data-upload.component.scss'],
})
export class DataUploadComponent {
  insertName = this.translate.instant('analysisTimeSeries.dataUpload.insertName');
  insertUnity = this.translate.instant('analysisTimeSeries.dataUpload.insertUnity');
  select = this.translate.instant('analysisTimeSeries.dataUpload.select');
  value = this.translate.instant('analysisTimeSeries.dataUpload.value');
  nameTip = this.translate.instant('analysisTimeSeries.dataUpload.nameTip');
  unityTip = this.translate.instant('analysisTimeSeries.dataUpload.unityTip');
  dataTimeIntervalTip = this.translate.instant('analysisTimeSeries.dataUpload.dataTimeIntervalTip');
  dataAgregationTip = this.translate.instant('analysisTimeSeries.dataUpload.dataAgregationTip');

  form: DataUploadRequest = {} as DataUploadRequest;
  fileToUpload: File[] = [];
  fileUploaded: boolean = false;
  invalid: boolean = false;
  options: string[] = measurementOptions;
  filteredOptions: Observable<string[]> | undefined;
  myControl = new FormControl('');
  nameInvalid: boolean = false;
  loading: boolean = false;
  timeSerieId: number = NaN;
  editing: boolean = false;
  firstName: string = '';
  nameChanged: boolean = false;
  private loadingSubscription: Subscription;
  @ViewChild('attachments') attachment: any;
  @Output() uploadDataTsSaved = new EventEmitter<{valid: boolean, tsId: number}>();
  @Output() timeSeriesCanceled = new EventEmitter<boolean>();
  @Input() dataToEdit: any;
  selectedDataGrouping: string = '';
  samplingOptions: any[] = [
    {
      type: TimeSeriesSamplingFrequencyTypeEnum.Second,
      title: this.translate.instant('analysisTimeSeries.dataUpload.samplingFrequency.second'),
    },
    {
      type: TimeSeriesSamplingFrequencyTypeEnum.Minute,
      title: this.translate.instant('analysisTimeSeries.dataUpload.samplingFrequency.minute'),
    },
    {
      type: TimeSeriesSamplingFrequencyTypeEnum.Hour,
      title: this.translate.instant('analysisTimeSeries.dataUpload.samplingFrequency.hour'),
    },
    {
      type: TimeSeriesSamplingFrequencyTypeEnum.Day,
      title: this.translate.instant('analysisTimeSeries.dataUpload.samplingFrequency.day'),
    },
    {
      type: TimeSeriesSamplingFrequencyTypeEnum.Week,
      title: this.translate.instant('analysisTimeSeries.dataUpload.samplingFrequency.week'),
    },
    {
      type: TimeSeriesSamplingFrequencyTypeEnum.Month,
      title: this.translate.instant('analysisTimeSeries.dataUpload.samplingFrequency.month'),
    },
    {
      type: TimeSeriesSamplingFrequencyTypeEnum.Year,
      title: this.translate.instant('analysisTimeSeries.dataUpload.samplingFrequency.year'),
    },
  ];

  dataGrouping: any[] = [
    {
      type: TimeSeriesDataGroupingEnum.accumulated,
      title: this.translate.instant('analysisTimeSeries.dataUpload.dataGrouping.accumulated'),
      desc: this.translate.instant('analysisTimeSeries.dataUpload.dataGrouping.accumulatedDesc'),
    },
    {
      type: TimeSeriesDataGroupingEnum.average,
      title: this.translate.instant('analysisTimeSeries.dataUpload.dataGrouping.average'),
      desc: this.translate.instant('analysisTimeSeries.dataUpload.dataGrouping.averageDesc'),
    },
    {
      type: TimeSeriesDataGroupingEnum.firstValue,
      title: this.translate.instant('analysisTimeSeries.dataUpload.dataGrouping.firstValue'),
      desc: this.translate.instant('analysisTimeSeries.dataUpload.dataGrouping.firstValueDesc'),
    },
    {
      type: TimeSeriesDataGroupingEnum.lastValue,
      title: this.translate.instant('analysisTimeSeries.dataUpload.dataGrouping.lastValue'),
      desc: this.translate.instant('analysisTimeSeries.dataUpload.dataGrouping.lastValueDesc'),
    },
    {
      type: TimeSeriesDataGroupingEnum.maxValue,
      title: this.translate.instant('analysisTimeSeries.dataUpload.dataGrouping.maxValue'),
      desc: this.translate.instant('analysisTimeSeries.dataUpload.dataGrouping.maxValueDesc'),
    },
    {
      type: TimeSeriesDataGroupingEnum.minValue,
      title: this.translate.instant('analysisTimeSeries.dataUpload.dataGrouping.minValue'),
      desc: this.translate.instant('analysisTimeSeries.dataUpload.dataGrouping.minValueDesc'),
    },
  ];

  constructor(
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private timeSeriesService: TimeSeriesService,
    private loadingService: LoadingService,
    private indicatorService: IndicatorService,
  ) {
    this.loadingSubscription = this.loadingService.loading$.subscribe((loading) => {
      this.loading = loading;
    });
  }

  ngOnInit(): void {
    if (this.dataToEdit !== '') {
      this.editing = true;
      this.getTimeSeriesData(this.dataToEdit);
      this.dataToEdit = '';
    }
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || '')),
    );

    this.loadingService.getLoadStatus().subscribe((status) => {
      this.loading = status;
    });
  }

  ngOnDestroy() {
    this.loadingSubscription.unsubscribe();
  }

  getTimeSeriesData(timeSerieId: number): void {
    this.timeSeriesService.getById(timeSerieId).then((resp: any) => {
      this.form = resp;
      this.dataGroupingLabel(this.form.dataGrouping);
      this.timeSerieId = resp.timeSeriesId;
      this.firstName = resp.name;
    });
  }

  cancelForm(): void {
    this.timeSeriesCanceled.emit(true);
  }

  saveForm(formValid: boolean): void {
    if (formValid) {
      this.form.dataSource = TimeSeriesDataSource.DataUpload;
      this.form.file = this.fileToUpload[0];
      this.loadingService.setLoadingStatus(true);
      this.timeSeriesService.saveTimeSeriesUploadData(this.form).then((res) => {
        this.timeSerieId = res.data.timeSeriesId;
        if (res.status == HttpStatusCode.Ok) 
          this.openSnackBarSuccess();
        else
          this.openSnackBarError(res);
      }).catch((res) => {
          this.openSnackBarError(res.status);
      });
    }
    this.loadingService.setLoadingStatus(false);
  }

  saveFormToEdit(formValid: boolean): void {
    if (formValid) {
      this.form.dataSource = TimeSeriesDataSource.DataUpload;
      this.form.file = this.fileToUpload[0];
      this.loadingService.setLoadingStatus(true);
      this.timeSeriesService.saveTimeSeriesToEditUploadData(this.form, this.timeSerieId).then((res) => {
        
        if (res.status == HttpStatusCode.Ok) {
          this.timeSerieId = res.data.timeSeriesId;
          this.openSnackBarSuccess();
        } else
          this.openSnackBarError(res.status);
      });
    }
    this.loadingService.setLoadingStatus(false);
  }

  checkIndicator(): void {
    this.indicatorService.getById(this.timeSerieId).then((resp) => {
      if (resp.indicatorType !== 0) {
        const tsId = 0;
        const valid = false;
        this.uploadDataTsSaved.emit({ valid, tsId });
      } else {
        const tsId = this.timeSerieId;
        const valid = true;
        this.uploadDataTsSaved.emit({ valid, tsId });
      }
    });
  }

  openSnackBarSuccess(): void {
    this.snackBar.openFromComponent(CustomSnackbarSuccessComponent, {
      data: {
        message: this.translate.instant('snackBar.newModule.moduleCreated'),
        snackBar: this.snackBar,
      },
      horizontalPosition: 'right',
      verticalPosition: 'top',
      duration: AppConstants.TREE_SECOND_WAIT,
    });
    this.checkIndicator();
  }

  openSnackBarError(status: any): void {
    let msg = this.translate.instant('snackBar.newModule.moduleErroCreating');
    switch (status) {
      
      case HttpStatusCode.UnprocessableEntity:
        msg = this.translate.instant('analysisTimeSeries.dataUpload.errorFileDataInvalid');
        break;
      case HttpStatusCode.BadRequest:
        msg = this.translate.instant('analysisTimeSeries.dataUpload.errorSavingST');
        this.nameInvalid = true;
        break;
      case HttpStatusCode.NoContent:
        msg = this.translate.instant('analysisTimeSeries.dataUpload.errorFileEmpty');
        break;
      case HttpStatusCode.UnsupportedMediaType:
          msg = this.translate.instant('analysisTimeSeries.dataUpload.errorFileInvalid');
          break;
      case 'multipleFiles':
        msg = this.translate.instant('analysisTimeSeries.dataUpload.errorMultipleFiles');
        break;
      case 'fileSize':
        msg = this.translate.instant('analysisTimeSeries.dataUpload.errorFileSize');
        break;
      case 'fileType':
        msg = this.translate.instant('analysisTimeSeries.dataUpload.errorFileType');
        break;
      default:
        msg = this.translate.instant('snackBar.newModule.moduleErroCreating');
    }

    this.snackBar.openFromComponent(CustomSnackbarErrorComponent, {
      data: {
        message: msg,
        snackBar: this.snackBar,
      },
      horizontalPosition: 'right',
      verticalPosition: 'top',
      duration: AppConstants.TREE_SECOND_WAIT,
    });
  }

  dowloadTemplate(): void {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', 'assets/csvs/timeseries-exemple.csv');
    link.setAttribute('download', `timeseries-exemple.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  onFileChange(event: any): void {
    const file: File = event.srcElement.files[0];    
    if (this.fileTreatment(file)) {
      this.verifyFile(file);
    }
  }

  onFileDroped(file: FileList): void {
    if (file.length == 1) {
      this.fileTreatment(file[0])
    }
    if (file.length > 1) {
      this.invalid = true;
      this.openSnackBarError('multipleFiles');
    }
  }

  verifyFile(file: any): void {
    if (!this.fileUploaded) {
      this.fileToUpload.push(file);
      this.fileUploaded = true;
      this.invalid = false;
    }
  }

  fileTreatment(file: any): boolean {
    const allowed_type = 'text/csv';
    if (this.fileUploaded) {
      return false;
    }
    if (file.size > 30000 * 1024) {
      this.openSnackBarError('fileSize');
      this.invalid = true;
      return false;
    }
    if (file.type !== allowed_type) {
      this.openSnackBarError('fileType');
      this.invalid = true;
      return false;
    }
    this.verifyFile(file);
    return true;
  }

  removeFile(): void {
    this.fileToUpload = [];
    this.attachment.nativeElement.value = '';
    this.fileUploaded = false;
  }

  dataGroupingSelected(value: string): void {
    this.selectedDataGrouping = value;
  }

  dataGroupingLabel(dataGrouping: number): void {
    switch (dataGrouping) {
      case TimeSeriesDataGroupingEnum.accumulated:
        this.selectedDataGrouping = this.translate.instant('analysisTimeSeries.dataUpload.dataGrouping.accumulated');
        break;
      case TimeSeriesDataGroupingEnum.average:
        this.selectedDataGrouping = this.translate.instant('analysisTimeSeries.dataUpload.dataGrouping.average');
        break;
      case TimeSeriesDataGroupingEnum.firstValue:
        this.selectedDataGrouping = this.translate.instant('analysisTimeSeries.dataUpload.dataGrouping.firstValue');
        break;
      case TimeSeriesDataGroupingEnum.lastValue:
        this.selectedDataGrouping = this.translate.instant('analysisTimeSeries.dataUpload.dataGrouping.lastValue');
        break;
      case TimeSeriesDataGroupingEnum.maxValue:
        this.selectedDataGrouping = this.translate.instant('analysisTimeSeries.dataUpload.dataGrouping.maxValue');
        break;
      case TimeSeriesDataGroupingEnum.minValue:
        this.selectedDataGrouping = this.translate.instant('analysisTimeSeries.dataUpload.dataGrouping.minValue');
        break;
      default:
        break;
    }
  }

  checkName(name: string): void {
    if (this.firstName == name) {
      this.nameChanged = false;
    } else {
      this.nameChanged = true;
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter((option) => option.toLowerCase().includes(filterValue));
  }
}
