import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-resource-not-found',
  templateUrl: './resource-not-found.component.html',
  styleUrls: ['./resource-not-found.component.scss'],
})
export class ResourceNotFoundComponent {
  @Input() title: string = '';
  @Input() icon: string = '';
  @Input() type: string = '';
  @Input() size: number = 18;
  @Input() message: string = '';
  @Input() resource: string = '';
  @Input() contractMessage: string = '';
  @Input() noHeader: boolean = false;
  @Input() role: string | undefined = 'master';
}
