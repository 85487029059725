export const measurementOptions: string[] = [
  'W',
  'Wh',
  'Kw',
  'KWh',
  'MW',
  'MWh',
  'GW',
  'GWh',
  'KVAr',
  'KVArh',
  'V',
  'VA',
  'VAr',
  'VArh',
  'A',
  'Ah',
  'Km',
  'm',
  'cm',
  'mm',
  'kg',
  'g',
  'mg',
  'R$',
  'hL',
  'L',
  'mL',
  'psi',
  'bar',
  'km³',
  'hm³',
  'dam³',
  'm³',
  's',
  'min',
  'h',
  'ºC',
  'ºF',
  'ºK',
  'm³/s',
  'm³/h',
  'L/h',
  'L/min',
  'GPM',
  'Nm³/h',
  'kg/s',
  'kg/h',
  't/h',
  'lb/h',
  'KB',
  'Mb',
  'GB',
  'TB',
  'ppm',
  'CO',
  'CO2',
  'Hz',
  'nº',
  '%',
  'tCO2e',
];
