import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";

@Injectable({
  providedIn: 'root',
})
export class TechnicalSupportService extends BaseService {
  constructor() {
    super();
  }

  async register(message: string, origin: string) {
    return await this.post('/technical-support', {
      message: message,
      origin: origin,
    });
  }
}
