import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { DialogViewModel } from 'src/app/core/models/interfaces/dialog.model';
import EnergyContractsRequest from 'src/app/core/models/request/energy-contracts-request.model';
import { EnergyContractsService } from 'src/app/core/services/energyContracts/energy-contracts.service';
import { CustomSnackbarSuccessComponent } from 'src/app/shared/components/custom-snackbar-success/custom-snackbar-success.component';
import { AppConstants } from 'src/app/shared/constants/app.constants';
import { ModalDialogRegisterComponent } from '../components/modal-dialog-register/modal-dialog-register.component';
import { CustomSnackbarErrorComponent } from 'src/app/shared/components/custom-snackbar-error/custom-snackbar-error.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-energy-contracts',
  templateUrl: './energy-contracts.component.html',
  styleUrls: ['./energy-contracts.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class EnergyContractsComponent {
  @ViewChild(MatPaginator) set paginator(value: MatPaginator) {
    this.translateMatPaginator(value);
    if (this.dataSource) {
      this.dataSource.paginator = value;
    }
  }

  @ViewChild(MatSort) set sort(value: MatSort) {
    if (this.dataSource) {
      this.dataSource.sort = value;
    }
  }

  icon: string = 'error';
  type: string = 'default';
  message: string = 'register.energyContracts.message';
  resource: string = 'register.energyContracts.selectRule';
  contractMessage: string = 'register.energyContracts.contractMessage';
  noHeader: boolean = true;
  selectedRow: any;
  contractsNumber: number = 0;

  dialogRef!: MatDialogRef<any>;
  dataSource = new MatTableDataSource();
  contracts: EnergyContractsRequest[] = [];
  columnsToDisplay = ['name', 'validityPeriod', 'description'];
  columnsToDisplayWithExpand = ['expand', ...this.columnsToDisplay, 'modal'];
  expandedElement: EnergyContractsRequest | null | undefined;
  isListContract: boolean = true;
  isContractRegister: boolean = false;
  isContractEdit: boolean = false;
  isContractDuplicate: boolean = false;
  private storage: Storage;

  constructor(
    private translate: TranslateService,
    private energyContractsService: EnergyContractsService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {
    this.storage = window.localStorage;
  }

  ngOnInit(): void {
    this.getAllContracts();
  }

  ngAfterViewInit() {
    this.translateMatPaginator(this.paginator);
    this.dataSource.paginator = this.paginator;
  }

  getAllContracts(): void {
    this.energyContractsService.getAllEnergyContracts().then((resp) => {
      this.contracts = resp;
      this.convertData(this.contracts);
    });
  }

  convertData(contracts: EnergyContractsRequest[]): void {
    contracts.forEach((element) => {
      const startDate = new Date(element.startValidityPeriod);
      startDate.getTime();
      element.startValidityPeriod = moment(startDate).format('DD/MM/YYYY');

      const endDate = new Date(element.endValidityPeriod);
      endDate.getTime();
      element.endValidityPeriod = moment(endDate).format('DD/MM/YYYY');

      element.isExpired = moment().isAfter(endDate);
    });
    this.dataSource.data = contracts;
    this.dataSource.filteredData = contracts;
    this.dataSource.sort = this.sort;
    this.contractsNumber = this.dataSource.data.length;
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  translateMatPaginator(paginator: MatPaginator) {
    this.translate.get('scheduling.paginator').subscribe(() => {
      paginator._intl.itemsPerPageLabel = this.translate.instant('scheduling.paginator.itemsPerPage');
      paginator._intl.firstPageLabel = this.translate.instant('scheduling.paginator.firstPageLabel');
      paginator._intl.lastPageLabel = this.translate.instant('scheduling.paginator.lastPageLabel');
      paginator._intl.nextPageLabel = this.translate.instant('scheduling.paginator.nextPageLabel');
      paginator._intl.previousPageLabel = this.translate.instant('scheduling.paginator.previousPageLabel');
      paginator._intl.getRangeLabel = this.rangeLabel;
    });
  }

  rangeLabel = (page: number, pageSize: number, length: number) => {
    if (length == 0 || pageSize == 0) {
      return `0 ${this.translate.instant('scheduling.paginator.of')} ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} ${this.translate.instant('scheduling.paginator.of')} ${length}`;
  };

  goToRegisterContract(): void {
    this.isContractRegister = true;
    this.isListContract = false;
  }

  cancelEditForm(isSaved: boolean): void {
    this.isContractRegister = false;
    this.isContractEdit = false;
    this.isContractDuplicate = false;
    this.isListContract = true;
    if (isSaved) {
      this.getAllContracts();
    }
  }

  savedEditForm(value: boolean): void {
    if (value) {
      this.isContractRegister = false;
      this.isContractEdit = false;
      this.isContractDuplicate = false;
      this.isListContract = true;
      this.getAllContracts();
    }
  }

  editContract(contract: any): void {
    this.isContractRegister = false;
    this.isListContract = false;
    this.isContractEdit = true;
    this.isContractDuplicate = false;

    this.storage.setItem('contractId', contract.contractId);
    this.storage.setItem('editing', JSON.stringify(true));
  }

  async duplicateContract(contract: any): Promise<void> {
    this.isContractRegister = false;
    this.isListContract = false;
    this.isContractEdit = false;
    this.isContractDuplicate = true;

    this.storage.setItem('contractId', contract.contractId);
    this.storage.setItem('duplicating', JSON.stringify(true));
  }

  async deleteContract(contract: any): Promise<void> {
    this.openDialogDelete(contract.contractId);
  }
  
  openDialogDelete(contractId: number) {
    const dialogModel: DialogViewModel = {} as DialogViewModel;
    dialogModel.title = this.translate.instant('register.modal.deletetitle');
    dialogModel.message = this.translate.instant('register.modal.deleteMessage');
    dialogModel.btnError = true;

    this.dialogRef = this.dialog.open(ModalDialogRegisterComponent, {
      disableClose: true,
      data: { info: dialogModel },
      width: '402px',
    });

    this.dialogRef.componentInstance.confirm.subscribe({
      next: async () => {
        await this.energyContractsService.deleteContract(contractId).then((result) => {
          this.openSnackbarRemoveContract(result);
          this.getAllContracts();
        });
        this.dialogRef.close();
      },
    });
  }

  openSnackbarRemoveContract(status: number): void {
    switch (status) {
      case AppConstants.OK:
        this.snackBar.openFromComponent(CustomSnackbarSuccessComponent, {
          data: {
            message: this.translate.instant('register.energyContracts.contractRemoved'),
            snackBar: this.snackBar,
          },
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: AppConstants.TREE_SECOND_WAIT,
        });
        break;
      default:
        this.snackBar.openFromComponent(CustomSnackbarErrorComponent, {
          data: {
            message: this.translate.instant('register.energyContracts.contractRemoveError'),
            snackBar: this.snackBar,
          },
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: AppConstants.TREE_SECOND_WAIT,
        });
        break;
    }
  }
}
