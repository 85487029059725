import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root',
})
export class EnergyContractsService extends BaseService {
  constructor() {
    super();
  }

  async getAllEnergyContracts() {
    const userData = this.getUserData();

    if (!userData || userData.org.ugridIds.length === 0) return [];

    const ugridId = userData.org.ugridIds[0]; // TODO: Get the first uGrid while the selected one isn't implemented
    //const ugridId = 2;

    return await this.get(`/contracts/ugrid/${ugridId}`).then((res) => {
      return res.data;
    });
  }

  async getAllKeywords() {
    return await this.get(`/contracts/keywords`).then((res) => {
      return res.data;
    });
  }

  async getAllContractRules() {
    return await this.get(`/contracts/rules`).then((res) => {
      return res.data;
    });
  }
}
