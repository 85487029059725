<div className="app">
    <div class="dashboard-title" >
        <span>{{ "monitoring.dasboard-title" | translate }} </span> 
        <span class="dashboard-date"> {{currentDate}}</span>
    </div> 
    <div class="dashboard-subtitle" >
        <span>{{ "monitoring.dasboard-subtitle" | translate }} </span> 
    </div>

    <section class="indicators-grid">
          <app-card-indicator *ngFor="let indicator of indicators" 
            [width]="'330px'"
            [title]="indicator.title"
            [isExpansionPanel]="indicator.isExpansionPanel"
            [value]="indicator.value"
            [valueUnit]="indicator.valueUnit"
            [inPeakTimeValueUnit]="indicator.inPeakTimeValueUnit"
            [outPeakTimeValueUnit]="indicator.outPeakTimeValueUnit"
            [tooltipInfo]="indicator.tooltipInfo"
            [inPeakTimeValue]="indicator.inPeakTimeValue"
            [outPeakTimeValue]="indicator.outPeakTimeValue"
            [type]="indicator.type">
          </app-card-indicator>
      </section>

    <div class="dashboard-body">
        <button mat-button class="reset-zoom-button" (click)="resetZoom()">{{ "dashboard.resetZoom" | translate }}</button>
        <canvas *ngIf="chartLoaded" baseChart width="4" height="1"
            [type]="'line'"
            [data]="lineChartCfgDataSet"
            [options]="lineChartOptions"
            [legend]="true">
        </canvas>
        <div id="legend" class="section-checkbox"></div>
    </div>
</div>