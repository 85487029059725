import { Component, Inject, Input } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { SnackBarData } from '../../interfaces/snackbar.model';

@Component({
  selector: 'app-custom-snackbar-error',
  templateUrl: './custom-snackbar-error.component.html',
  styleUrls: ['./custom-snackbar-error.component.scss'],
})
export class CustomSnackbarErrorComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackBarData) {}

  closeSnackBar(): void {
    this.data.snackBar.dismiss();
  }
}
