import { Moment } from "moment";
import { DashboardAnalysisPeriod } from "../models/interfaces/dashboard.model";
import * as moment from "moment";

export const formatDateFromDashboard = (date: Date | Moment): string => {
  if (date instanceof Date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);
    return `${day}/${month}/${year}`;
  }
  else if (moment.isMoment(date)) {
    return date.format('DD/MM/YY');
  }
  else {
    return "";
  }
};

export const getPreviousYearDate = (date: Date | Moment): Date => {

  if (date instanceof Date) {
    const previousYearDate = new Date(date);
    previousYearDate.setFullYear(date.getFullYear() - 1);
    return previousYearDate;
  }
  else if (moment.isMoment(date)) {
    return date.clone().subtract(1, 'year').toDate();
  }
  else {
    return new Date();
  }
};

export const getDaysBetweenDates = (startDate: Date, endDate: Date): number => {
  const start = new Date(startDate).setHours(0, 0, 0, 0);
  const end = new Date(endDate).setHours(0, 0, 0, 0);

  const timeDifference = end - start;
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

  return Math.round(daysDifference);
};


export const getDateRangesFromDashboard = () => {
    const today = new Date();
    const endDate = new Date(today);

    const startOfToday = new Date(today.setHours(0, 0, 0, 0));
    const startOfWeek = new Date(startOfToday);
    const startOfMonth = new Date(startOfToday);
    const startOfQuarter = new Date(startOfToday);

    // Ajustar para o início da semana (segunda-feira)
    startOfWeek.setDate(startOfToday.getDate() - startOfToday.getDay() + 1);

    // Ajustar para o início do mês
    startOfMonth.setDate(1);

    // Ajustar para o início do trimestre
    const currentMonth = today.getMonth();
    const startMonthOfQuarter = currentMonth - (currentMonth % 3);
    startOfQuarter.setMonth(startMonthOfQuarter);
    startOfQuarter.setDate(1);

    return [
      {
        label: DashboardAnalysisPeriod.Today,
        startDate: startOfToday,
        endDate: endDate
      },
      {
        label: DashboardAnalysisPeriod.Last_7_Days,
        startDate: new Date(startOfToday.getTime() - 7 * 24 * 60 * 60 * 1000),
        endDate: endDate
      },
      {
        label: DashboardAnalysisPeriod.Last_15_Days,
        startDate: new Date(startOfToday.getTime() - 15 * 24 * 60 * 60 * 1000),
        endDate: endDate
      },
      {
        label: DashboardAnalysisPeriod.Last_30_Days,
        startDate: new Date(startOfToday.getTime() - 30 * 24 * 60 * 60 * 1000),
        endDate: endDate
      },
      {
        label: DashboardAnalysisPeriod.Last_Week,
        startDate: startOfWeek,
        endDate: endDate
      },
      {
        label: DashboardAnalysisPeriod.Last_Month,
        startDate: startOfMonth,
        endDate: new Date(startOfMonth.getFullYear(), startOfMonth.getMonth() + 1, 0)
      },
      {
        label: DashboardAnalysisPeriod.Last_Quarter,
        startDate: startOfQuarter,
        endDate: new Date(startOfQuarter.getFullYear(), startOfQuarter.getMonth() + 3, 0)
      }
    ];
  };
