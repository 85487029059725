<mat-dialog-content class="support-container">
  <form [formGroup]="supportForm" (ngSubmit)="onSubmit()">
    <div class="header">
      <h2 class="title">{{ "technical_support_modal.title" | translate }}</h2>
      <p class="subtitle">{{ "technical_support_modal.subtitle" | translate }}</p>
    </div>

    <mat-form-field appearance="outline" class="full-width">
      <input matInput class="cursorNull" formControlName="name" readonly="true" required />
    </mat-form-field>

    <mat-form-field appearance="outline" class="full-width">
      <input matInput class="cursorNull" formControlName="email" readonly="true" type="email" required />
    </mat-form-field>

    <mat-form-field appearance="outline" class="full-width">
      <textarea matInput formControlName="message" placeholder="{{ 'technical_support_modal.message.placeholder' | translate }}" rows="4" required></textarea>
      <mat-error *ngIf="supportForm.get('message')?.hasError('required')">{{ "technical_support_modal.message.required" | translate }}</mat-error>
    </mat-form-field>

    <div class="action-buttons">
      <button mat-stroked-button color="primary" type="button" (click)="onCancel()">{{ "technical_support_modal.buttons.cancel" | translate }}</button>
      <button mat-raised-button class="green-button" type="submit" [disabled]="supportForm.invalid">{{ "technical_support_modal.buttons.submit" | translate }}</button>
    </div>
  </form>
</mat-dialog-content>
