<div class="control-panel">
  <div class="header">
    <app-header></app-header>
  </div>

  <div class="panel">
    <div class="control-panel__nav" [class.collapsed-menu]="menuIsCollapsed">
      <div class="control-panel__nav__header" *ngIf="!menuIsCollapsed">
        <h2>
          {{ "controlPanel.title" | translate }}
          <i class="fa-regular fa-chevrons-left" (click)="collapseMenu()"
           matTooltip="{{ hideMenu | translate }}"
           matTooltipPosition="right"
           matTooltipClass="tooltip hide"></i>
        </h2>
        <p>
          {{ "controlPanel.info" | translate }}
        </p>
      </div>

      <a *ngIf="menuIsCollapsed" class="collapsed-menu"
        matTooltip="{{ expandMenu | translate }}"
        matTooltipPosition="right"
        matTooltipClass="tooltip">
        <i class="fa-regular fa-chevrons-right icon" (click)="collapseMenu()"></i>
      </a>
  
      <a routerLink="/control-panel/controller" routerLinkActive="active" [class.collapsed-menu]="menuIsCollapsed"
        matTooltip="{{ controller | translate }}"
        matTooltipPosition="right"
        matTooltipClass="tooltip controller"
        [matTooltipDisabled]="!menuIsCollapsed"
      >
        <i class="fa-solid fa-sliders-up teste" [class.collapsed-menu]="menuIsCollapsed"></i>
        <span [class.collapsed-menu]="menuIsCollapsed">{{ "header.nav.controller" | translate }}</span>
      </a>

      <a routerLink="/control-panel/scheduling" routerLinkActive="active" [class.collapsed-menu]="menuIsCollapsed"
        matTooltip="{{ schedules | translate }}"
        matTooltipPosition="right"
        matTooltipClass="tooltip schedules"
        [matTooltipDisabled]="!menuIsCollapsed"
      >
        <i class="fa-regular fa-clock" [class.collapsed-menu]="menuIsCollapsed"></i>
        <span [class.collapsed-menu]="menuIsCollapsed">{{ "header.nav.schedules" | translate }}</span>
      </a>

      <a routerLink="/control-panel/activity-feed" routerLinkActive="active" [class.collapsed-menu]="menuIsCollapsed"
        matTooltip="{{ activityFeed | translate }}"
        matTooltipPosition="right"
        matTooltipClass="tooltip activity"
        [matTooltipDisabled]="!menuIsCollapsed"
      >
        <i class="fa-regular fa-list-ul" [class.collapsed-menu]="menuIsCollapsed"></i>
        <span [class.collapsed-menu]="menuIsCollapsed">{{ "header.nav.activityFeed" | translate }}</span>
      </a>
    </div>
    
    <div class="control-panel__container">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>