import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  CanMatchFn,
  Route,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../../pages/auth/auth.service';
import { inject } from '@angular/core';

export const authActivateGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const authService: AuthService = inject(AuthService);
  return authService.checkAuthentication(state.url);
};

export const authMatchGuard: CanMatchFn = (route: Route) => {
  const authService: AuthService = inject(AuthService);
  return authService.checkAuthentication(route.path);
};
