import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root',
})
export class ActivityFeedService extends BaseService {
  constructor() {
    super();
  }

  async getActivities() {
    const userData = this.getUserData();

    if (!userData || userData.org.ugridIds.length === 0) return [];

    const ugridId = userData.org.ugridIds[0]; // TODO: Get the first uGrid while the selected one isn't implemented

    return await this.get(`/modules/${ugridId}/control`).then((res) => {
      return res.data;
    });
  }
}
