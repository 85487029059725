import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/pages/auth/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  userName?: string = '';
  nameInitials: string = '';
  userEmail?: string = '';

  constructor(
    private router: Router,
    private translate: TranslateService,
    private authService: AuthService,
  ) {
    this.router = router;
    this.translate.use('pt');
  }

  ngOnInit(): void {
    this.userName = this.authService.getLoggedUser()?.name;
    this.userEmail = this.authService.getLoggedUser()?.email;

    this.nameInitials =
      this.userName !== undefined ? this.onlyCapitalLetters(this.userName).substring(0, 2).toUpperCase() : '';
  }

  onlyCapitalLetters(str: string) {
    return str.replace(/[^A-Z]+/g, '');
  }

  goToPage(path: string): void {
    this.router.navigate([path]);
  }

  signOut(): void {
    this.authService.removeStoredUser();
    this.router.navigate(['/auth/login']);
  }

  switchLanguage(language: string): void {
    this.translate.use(language);
  }
}
