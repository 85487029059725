import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from 'src/app/core/services/dashboards/dashboard.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  DashboardAnalysisPeriod,
  DashboardComparisonPeriod,
  DashboardGraph,
  DashboardModel,
  DashboardParameter,
  DashboardTimesSeries,
  DashboardToReport,
  getAnalysisPeriodString,
  getComparisonPeriodString,
} from 'src/app/core/models/interfaces/dashboard.model';
import {
  formatDateFromDashboard,
  getDateRangesFromDashboard,
  getPreviousYearDate,
} from 'src/app/core/utils/date.util';
import { IconNameEnum } from 'src/app/core/models/enums/icon-name.enum';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { CustomSnackbarErrorComponent } from 'src/app/shared/components/custom-snackbar-error/custom-snackbar-error.component';
import { AppConstants } from 'src/app/shared/constants/app.constants';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'pt-BR' }, provideMomentDateAdapter()],
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent {
  DashboardAnalysisPeriod = DashboardAnalysisPeriod;
  DashboardComparisonPeriod = DashboardComparisonPeriod;
  title: string = '';
  description: string = '';
  reportForm: FormGroup;
  graphs: DashboardGraph[] = [];
  comparePeriodSelected?: DashboardComparisonPeriod;
  analisyPeriodSelected?: DashboardAnalysisPeriod;
  dashboardAnalysisPeriodList: { value: DashboardAnalysisPeriod; label: string }[] = [];
  dashboardComparisonPeriodList: { value: DashboardComparisonPeriod; label: string }[] = [];
  dateAnalisyPeriodSelectedString: string = '';
  dateComparePeriodSelectedString: string = '';
  dashboards: DashboardModel[] = [];
  dashboardSelected?: DashboardModel;

  constructor(
    private translate: TranslateService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private dashboardService: DashboardService,
    private formBuilder: FormBuilder,
  ) {
    this.reportForm = this.formBuilder.group({
      analisyPeriod: ['', [Validators.required]],
      comparePeriod: ['', [Validators.required]],
      dashboard: ['', [Validators.required]],
      displayForecast: [true, [Validators.required]],
      analysisPeriodStartDate: ['', [Validators.required]],
      analysisPeriodEndDate: ['', [Validators.required]],
      comparisonPeriodStartDate: ['', []],
      comparisonPeriodEndDate: ['', []],
    });
  }

  async ngOnInit() {
    this.dashboardService
      .getAll()
      .then((collection) => (this.dashboards = collection))
      .catch((err) => {});
    this.dashboardAnalysisPeriodList = this.getDashboardAnalysisPeriodList();
    this.dashboardComparisonPeriodList = this.getDashboardComparisonPeriodList();
  }

  getHeaderTitle(): string {
    if (this.title == null || this.title === '') {
      return this.translate.instant('reports.parameters.export');
    } else {
      return this.title;
    }
  }

  getHeaderDescription(): string {
    if (this.description == null || this.description === '') {
      return this.translate.instant('reports.parameters.description');
    } else {
      return this.description;
    }
  }

  selectDashboard(value: DashboardModel) {
    this.dashboardSelected = value;
    this.reportForm.patchValue({
      dashboard: value,
    });
  }

  filledComparePeriodSelected(): boolean {
    return (
      this.comparePeriodSelected !== DashboardComparisonPeriod.Do_Not_Compare &&
      this.comparePeriodSelected !== null
    );
  }

  filledAnalisyPeriodSelected(): boolean {
    return this.analisyPeriodSelected !== null;
  }

  getComparePeriodSelected(): string {
    const label = getComparisonPeriodString(this.comparePeriodSelected!);
    return this.translate.instant(label);
  }

  getAnalisyPeriodSelected(): string {
    const label = getAnalysisPeriodString(this.analisyPeriodSelected!);
    return this.translate.instant(label);
  }

  setComparePeriodSelected(value: DashboardComparisonPeriod): void {
    this.comparePeriodSelected = value;
    this.reportForm.patchValue({
      comparePeriod: value,
    });
  }

  setAnalisyPeriodSelected(value: DashboardAnalysisPeriod): void {
    this.analisyPeriodSelected = value;
    this.reportForm.patchValue({
      analisyPeriod: value,
    });
  }

  setParameters() {
    const listDate = getDateRangesFromDashboard();
    let analisyPeriodSelectedStartDate: Date | null;
    let analisyPeriodSelectedEndDate: Date | null;
    let comparisonPeriodSelectedStartDate: Date | null;
    let comparisonPeriodSelectedEndDate: Date | null;

    // Definir Primeiro Parâmetro
    if (this.analisyPeriodSelected == DashboardAnalysisPeriod.Custom_Period) {
      analisyPeriodSelectedStartDate = this.reportForm.get('analysisPeriodStartDate')?.value;
      analisyPeriodSelectedEndDate = this.reportForm.get('analysisPeriodEndDate')?.value;
    } else {
      const tmp = listDate.find((x) => x.label === this.analisyPeriodSelected!);
      analisyPeriodSelectedStartDate = tmp?.startDate ?? null;
      analisyPeriodSelectedEndDate = tmp?.endDate ?? null;

      this.reportForm.patchValue({
        analysisPeriodStartDate: analisyPeriodSelectedStartDate,
        analysisPeriodEndDate: analisyPeriodSelectedEndDate,
      });
    }

    // Definir Segundo Parâmetro
    if (this.comparePeriodSelected == DashboardComparisonPeriod.Same_Period_Year_Previous_Year) {
      comparisonPeriodSelectedStartDate = getPreviousYearDate(analisyPeriodSelectedStartDate!);
      comparisonPeriodSelectedEndDate = getPreviousYearDate(analisyPeriodSelectedEndDate!);

      this.reportForm.patchValue({
        comparisonPeriodStartDate: comparisonPeriodSelectedStartDate,
        comparisonPeriodEndDate: comparisonPeriodSelectedEndDate,
      });
    } else if (this.comparePeriodSelected == DashboardComparisonPeriod.Custom_Period) {
      comparisonPeriodSelectedStartDate = this.reportForm.get('comparisonPeriodStartDate')?.value ?? null;
      comparisonPeriodSelectedEndDate = this.reportForm.get('comparisonPeriodEndDate')?.value ?? null;

      this.reportForm.patchValue({
        comparisonPeriodStartDate: comparisonPeriodSelectedStartDate,
        comparisonPeriodEndDate: comparisonPeriodSelectedEndDate,
      });
    } else {
      comparisonPeriodSelectedStartDate = null;
      comparisonPeriodSelectedEndDate = null;

      this.reportForm.patchValue({
        comparisonPeriodStartDate: comparisonPeriodSelectedStartDate,
        comparisonPeriodEndDate: comparisonPeriodSelectedEndDate,
      });
    }
  }

  async generateReport() {
    this.setParameters();
    console.log(this.reportForm.value);
    if (this.reportForm.valid) {
      const requestData = {
        dashboardId: this.reportForm.get('dashboard')?.value?.dashboardId,
        comparePeriod: this.reportForm.get('comparePeriod')?.value,
        analisyPeriod: this.reportForm.get('analisyPeriod')?.value,
        analysisPeriodStartDate: this.reportForm.get('analysisPeriodStartDate')?.value,
        analysisPeriodEndDate: this.reportForm.get('analysisPeriodEndDate')?.value,
        comparisonPeriodStartDate: this.reportForm.get('comparisonPeriodStartDate')?.value ?? null,
        comparisonPeriodEndDate: this.reportForm.get('comparisonPeriodEndDate')?.value ?? null,
      } as DashboardToReport;

      await this.dashboardService.download(requestData);
    } else {
      this.snackBar.openFromComponent(CustomSnackbarErrorComponent, {
        data: {
          message: this.translate.instant('dashboard_analisys.snackbar.invalid.message'),
          snackBar: this.snackBar,
        },
        horizontalPosition: 'right',
        verticalPosition: 'top',
        duration: AppConstants.TREE_SECOND_WAIT,
      });
    }
  }

  isCardMaxHeight(): boolean {
    return (
      this.analisyPeriodSelected === DashboardAnalysisPeriod.Custom_Period ||
      this.comparePeriodSelected === DashboardComparisonPeriod.Custom_Period ||
      this.comparePeriodSelected === DashboardComparisonPeriod.Same_Period_Year_Previous_Year
    );
  }

  comparePeriodSelectedIsCustomPeriod() {
    return this.comparePeriodSelected === DashboardComparisonPeriod.Custom_Period;
  }

  comparePeriodSelectedSamePeriodYearPreviousYear() {
    return this.comparePeriodSelected === DashboardComparisonPeriod.Same_Period_Year_Previous_Year;
  }

  isAnalisyPeriodSelected() {
    return this.analisyPeriodSelected === DashboardAnalysisPeriod.Custom_Period;
  }

  private getDashboardAnalysisPeriodList() {
    const data = [
      { value: DashboardAnalysisPeriod.Today, label: getAnalysisPeriodString(DashboardAnalysisPeriod.Today) },
      {
        value: DashboardAnalysisPeriod.Last_7_Days,
        label: getAnalysisPeriodString(DashboardAnalysisPeriod.Last_7_Days),
      },
      {
        value: DashboardAnalysisPeriod.Last_15_Days,
        label: getAnalysisPeriodString(DashboardAnalysisPeriod.Last_15_Days),
      },
      {
        value: DashboardAnalysisPeriod.Last_30_Days,
        label: getAnalysisPeriodString(DashboardAnalysisPeriod.Last_30_Days),
      },
      {
        value: DashboardAnalysisPeriod.Last_Week,
        label: getAnalysisPeriodString(DashboardAnalysisPeriod.Last_Week),
      },
      {
        value: DashboardAnalysisPeriod.Last_Month,
        label: getAnalysisPeriodString(DashboardAnalysisPeriod.Last_Month),
      },
      {
        value: DashboardAnalysisPeriod.Last_Quarter,
        label: getAnalysisPeriodString(DashboardAnalysisPeriod.Last_Quarter),
      },
      {
        value: DashboardAnalysisPeriod.Custom_Period,
        label: getAnalysisPeriodString(DashboardAnalysisPeriod.Custom_Period),
      },
    ];

    return data;
  }

  private getDashboardComparisonPeriodList() {
    const data = [
      {
        value: DashboardComparisonPeriod.Do_Not_Compare,
        label: getComparisonPeriodString(DashboardComparisonPeriod.Do_Not_Compare),
      },
      {
        value: DashboardComparisonPeriod.Custom_Period,
        label: getComparisonPeriodString(DashboardComparisonPeriod.Custom_Period),
      },
      {
        value: DashboardComparisonPeriod.Same_Period_Year_Previous_Year,
        label: getComparisonPeriodString(DashboardComparisonPeriod.Same_Period_Year_Previous_Year),
      },
    ];
    return data;
  }
}
