export enum StatusModuleEnum {
  unknown,
  off,
  on,
  standby,
  uncharge,
  charge,
  consuming,
  injecting,
  changecontrolmode = 55,
}
