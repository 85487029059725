import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ControlPainelComponent } from './pages/control-painel/control-painel.component';
import { MonitoringComponent } from './pages/monitoring/monitoring.component';
import { authActivateGuard, authMatchGuard } from './core/guards/auth.guard';
import { ControllerComponent } from './pages/control-painel/controller/controller.component';
import { SchedulingComponent } from './pages/control-painel/scheduling/scheduling.component';
import { ActivityFeedComponent } from './pages/control-painel/activity-feed/activity-feed.component';
import { DashboardMonitoringComponent } from './pages/monitoring/dashboard-monitoring/dashboard-monitoring.component';
import { AuthComponent } from './pages/auth/auth.component';
import { SigninComponent } from './pages/auth/signin/signin.component';
import { ActiveAccountComponent } from './pages/auth/active-account/active-account.component';
import { ForgotPasswordComponent } from './pages/auth/forgot-password/forgot-password.component';
import { RetrieveAccountComponent } from './pages/auth/retrieve-account/retrieve-account.component';
import { RegisterComponent } from './pages/register/register.component';
import { UsersComponent } from './pages/register/users/users.component';
import { EnergyResourcesComponent } from './pages/register/energy-resources/energy-resources.component';
import { NewResourceComponent } from './pages/register/energy-resources/new-resource/new-resource.component';
import { GeneralInformationComponent } from './pages/register/general-information/general-information.component';
import { DistributionGridComponent } from './pages/register/distribution-grid/distribution-grid.component';
import { AnalysisComponent } from './pages/analysis/analysis.component';
import { DashboardsComponent } from './pages/analysis/dashboards/dashboards/dashboards.component';
import { ReportsComponent } from './pages/analysis/reports/reports/reports.component';
import { TimeSeriesComponent } from './pages/analysis/time-series/time-series.component';
import { EnergyContractsComponent } from './pages/register/energy-contracts/energy-contracts.component';
import { DashboardCreateComponent } from './pages/analysis/dashboards/dashboard-create/dashboard-create.component';
import { ModuleMonitoringComponent } from './pages/monitoring/module-monitoring/module-monitoring.component';
import { CreateScheduleComponent } from './pages/control-painel/scheduling/create-schedule/create-schedule.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [authActivateGuard],
    canMatch: [authMatchGuard],
  },
  {
    path: 'auth',
    component: AuthComponent,
    children: [
      {
        path: 'login',
        component: SigninComponent,
      },
      {
        path: 'login/:to',
        component: SigninComponent,
      },
      {
        path: 'active-account/:token',
        component: ActiveAccountComponent,
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
      },
      {
        path: 'retrieve-account/:token',
        component: RetrieveAccountComponent,
      },
      {
        path: '**',
        redirectTo: 'login',
      },
    ],
  },
  {
    path: 'control-panel',
    component: ControlPainelComponent,
    canActivate: [authActivateGuard],
    canMatch: [authMatchGuard],
    children: [
      {
        path: 'controller',
        component: ControllerComponent,
      },
      {
        path: 'scheduling',
        component: SchedulingComponent,
      },
      {
        path: 'create-schedule',
        component: CreateScheduleComponent,
      },
      {
        path: 'create-schedule/:moduleId',
        component: CreateScheduleComponent,
      },
      {
        path: 'activity-feed',
        component: ActivityFeedComponent,
      },
    ],
  },
  {
    path: 'monitoring',
    component: MonitoringComponent,
    canActivate: [authActivateGuard],
    canMatch: [authMatchGuard],
    children: [
      {
        path: 'dashboard-monitoring',
        component: DashboardMonitoringComponent,
      },
      {
        path: 'module-monitoring/ugrid/:ugrid/module/:module',
        component: ModuleMonitoringComponent,
      },
    ],
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [authActivateGuard],
    canMatch: [authMatchGuard],
    children: [
      {
        path: 'distribution-grid',
        component: DistributionGridComponent,
      },
      {
        path: 'user',
        component: UsersComponent,
      },
      {
        path: 'energy-resources',
        component: EnergyResourcesComponent,
      },
      {
        path: 'new-resource',
        component: NewResourceComponent,
      },
      {
        path: 'general-information',
        component: GeneralInformationComponent,
      },
      {
        path: 'energy-contracts',
        component: EnergyContractsComponent,
      },
      {
        path: '',
        component: GeneralInformationComponent,
      },
    ],
  },
  {
    path: 'analysis',
    component: AnalysisComponent,
    canActivate: [authActivateGuard],
    canMatch: [authMatchGuard],
    children: [
      {
        path: 'dashboards',
        children: [
          {
            path: '',
            component: DashboardsComponent,
          },
          {
            path: 'create',
            component: DashboardCreateComponent,
          },
          {
            path: 'edit/:dashboardId',
            component: DashboardCreateComponent,
          },
        ],
      },
      {
        path: 'reports',
        component: ReportsComponent,
      },
      {
        path: 'time-series',
        component: TimeSeriesComponent,
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'home',
  },
];

@NgModule({
  imports: [BrowserModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
