import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, ElementRef, EventEmitter, inject, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable, startWith } from 'rxjs';
import { AggregationOptions } from 'src/app/core/models/enums/aggregation-options.enum';
import { OperatorOptionsEnum } from 'src/app/core/models/enums/operator-options.enum';
import { TimeSeriesCrossDataOperationTypeEnum } from 'src/app/core/models/enums/time-series-data-crossing.enum';
import { DashboardTimesSeries } from 'src/app/core/models/interfaces/dashboard.model';
import { DataCrossingRequest, MathOperations } from 'src/app/core/models/request/data-crossing.model';
import { TimeSeriesService } from 'src/app/core/services/timeSeries/time-series.service';
import { DataCrossingComponentAggregationOpstions } from 'src/app/core/utils/data-aggregation-options.utils';
import { CustomSnackbarErrorComponent } from 'src/app/shared/components/custom-snackbar-error/custom-snackbar-error.component';
import { CustomSnackbarSuccessComponent } from 'src/app/shared/components/custom-snackbar-success/custom-snackbar-success.component';
import { AppConstants } from 'src/app/shared/constants/app.constants';

@Component({
  selector: 'app-data-crossing',
  templateUrl: './data-crossing.component.html',
  styleUrls: ['./data-crossing.component.scss']
})
export class DataCrossingComponent {
  @Output() dataCrossingSaved = new EventEmitter<{valid: boolean, tsId: number}>();
  @Output() crossingDataCanceled = new EventEmitter<boolean>();

  @ViewChild('optionInput') optionInput?: ElementRef<HTMLInputElement>;

  form: DataCrossingRequest = {} as DataCrossingRequest;
  nameTip: string = "analysis.timeSeries.dataCrossing.nameTip";
  aggregationTip: string = "analysis.timeSeries.dataCrossing.aggregationTip";
  aggregation: string = "analysis.timeSeries.dataCrossing.aggregation";
  calculationTip: string = "analysis.timeSeries.dataCrossing.calculationTip";
  insertName: string = "analysisTimeSeries.constantValue.insertName";
  insertUnity: string = "analysisTimeSeries.constantValue.insertUnity";
  nameInvalid: boolean = false;
  selectedIndicator: string = '';
  dataCrossingForm: FormGroup;
  allTimeSeries: DashboardTimesSeries[] = [];
  optionsCtrl = new FormControl('');
  timeSeriesId!: number;

  calculationOptions: string[] = [];
  filterCalculationOptions?: Observable<string[]>;
  allCalculationOpttions: string[] = [];
  calculation: MathOperations[] = [];
  announcer = inject(LiveAnnouncer);
  aggregationOptions: any[] = DataCrossingComponentAggregationOpstions;
  isFormValid: boolean = true;
  isEnabled: boolean = false;
  indexChip!: number;

  constructor(
    private translate: TranslateService, 
    private fb: FormBuilder, 
    private snackBar: MatSnackBar,
    private timeSeriesService: TimeSeriesService
  ) 
  {
    this.dataCrossingForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      dataAggression: ['', [Validators.required]],
      unit: ['', [Validators.required]]
    })
  }

  async ngOnInit(): Promise<void> {
    await this.getAllTimeSeries();
    this.filterCalculationOptions = this.optionsCtrl.valueChanges.pipe(
      startWith(null),
      map((option: string | null) => (option ? this._filter(option) : this.allCalculationOpttions.slice()))
    )
  }

  async getAllTimeSeries() {
    const operations = ["(", ")", "+", "-", "*", "/"];
    await this.timeSeriesService.getAllTimeSeriesSingle().then((resp) => {
      this.allTimeSeries = resp;
      resp.forEach((ts) => this.allCalculationOpttions.push(ts.title))
    }).then(() => {
      operations.forEach((op) => this.allCalculationOpttions.push(op))
    })
  }

  addOptions(event: MatChipInputEvent) {
    const value = (event.value || '').trim();

    if (value) {
      this.calculationOptions.push(value);
    }

    event.chipInput!.clear();
    this.optionsCtrl.setValue(null);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.calculationOptions.push(event.option.viewValue);
    this.optionInput!.nativeElement.value = '';
    this.optionsCtrl.setValue(null);
  }

  removeOption(value: string): void {
    const index = this.calculationOptions.indexOf(value);

    if (index >= 0) {
      this.calculationOptions.splice(index, 1);

      this.announcer.announce(`Removed ${value}`);
      this.calculation = [];
      this.isFormValid = true;
    }
  }

  clear() {
    this.calculationOptions = [];
    this.calculation = [];
    this.isFormValid = true;
  }

  cancelForm(): void {
    this.crossingDataCanceled.emit(true);
  }

  saveForm(): void {
    this.buildMathOperations();
    if (this.isFormValid) {
      this.timeSeriesService.saveTimeSeriesCrossingData(this.form).then((res) => {
        if (res.status == AppConstants.CREATE_SUCESS) {
          this.timeSeriesId = res.data.timeSeriesId;
        }
        this.openSnackBar(res.status)
      })
    }    
  }

  buildMathOperations() {
    if (this.calculationOptions.length > 2) {
      let index = 0;
      this.calculationOptions.forEach((opt) => {
        switch (opt) {
          case "(":
            this.calculation.push({
              index: index,
              type: TimeSeriesCrossDataOperationTypeEnum.Operator,
              operator: OperatorOptionsEnum.OpenParentheses
            })
          break;
  
          case ")":
            this.calculation.push({
              index: index,
              type: TimeSeriesCrossDataOperationTypeEnum.Operator,
              operator: OperatorOptionsEnum.CloseParentheses
            })
          break;
  
          case "+":
            this.calculation.push({
              index: index,
              type: TimeSeriesCrossDataOperationTypeEnum.Operator,
              operator: OperatorOptionsEnum.Plus
            })
          break;
  
          case "-":
            this.calculation.push({
              index: index,
              type: TimeSeriesCrossDataOperationTypeEnum.Operator,
              operator: OperatorOptionsEnum.Minus
            })
          break;
  
          case "*":
            this.calculation.push({
              index: index,
              type: TimeSeriesCrossDataOperationTypeEnum.Operator,
              operator: OperatorOptionsEnum.Multiply
            })
          break;
  
          case "/":
            this.calculation.push({
              index: index,
              type: TimeSeriesCrossDataOperationTypeEnum.Operator,
              operator: OperatorOptionsEnum.Divider
            })
          break;
          
          default:
            const timeSeries = this.allTimeSeries.find((ts) =>
              ts.title.toLocaleLowerCase() === opt.toLocaleLowerCase()
            );

            if (timeSeries) {
              this.calculation.push({
                index: index,
                type: TimeSeriesCrossDataOperationTypeEnum.TimeSeries,
                operatorTimeseriesId: timeSeries.id
              })
            } else {
              this.calculation.push({
                index: index,
                type: TimeSeriesCrossDataOperationTypeEnum.FixValue,
                fixValue: parseInt(opt)
              })
            }
          break;
        }
        index++;
        this.form.mathOperations = this.calculation
      })
    } else {
      this.form.mathOperations = []
    }
  
    this.form.name = this.dataCrossingForm.get('name')?.value
    this.form.dataGrouping = this.dataCrossingForm.get('dataAggression')?.value
    this.form.unit = this.dataCrossingForm.get('unit')?.value
    this.calculation = [];
    this.validateForm();
  }

  validateForm() {
    this.isFormValid = true;
    let positionOpenParentheses!: number;
    let positionCloseParentheses!: number;


    if (this.form.mathOperations) {
      const lastPosition = this.form.mathOperations?.length - 1

      if (this.form.mathOperations.length < 3) {
        this.isFormValid = false;
        return;
      }

      if ((this.form.mathOperations[0].type == TimeSeriesCrossDataOperationTypeEnum.Operator) && 
        (this.form.mathOperations[0].operator != OperatorOptionsEnum.OpenParentheses)) {
        this.isFormValid = false;
        this.drawChipComponent(0);
        return;
      } 

      if ((this.form.mathOperations[lastPosition].type == TimeSeriesCrossDataOperationTypeEnum.Operator) &&
        (this.form.mathOperations[lastPosition].operator != OperatorOptionsEnum.CloseParentheses)) {
        this.isFormValid = false;
        this.drawChipComponent(lastPosition);
        return;
      }

      for (let index = 0; index < this.form.mathOperations.length; index++) {

        if (index < lastPosition) {
          if (((this.form.mathOperations[index].operator == OperatorOptionsEnum.OpenParentheses) ||
            (this.form.mathOperations[index].operator == OperatorOptionsEnum.CloseParentheses)) &&
            ((this.form.mathOperations[index + 1].operator == OperatorOptionsEnum.OpenParentheses) ||
            (this.form.mathOperations[index + 1].operator == OperatorOptionsEnum.CloseParentheses))) {
              this.isFormValid = false;
              this.drawChipComponent(index);
              this.drawChipComponent(index + 1);
              return;
          }
        }

        if (this.form.mathOperations[index].operator == OperatorOptionsEnum.OpenParentheses) {
          if (((this.form.mathOperations[index + 1].type == TimeSeriesCrossDataOperationTypeEnum.TimeSeries) ||
              (this.form.mathOperations[index + 1].type == TimeSeriesCrossDataOperationTypeEnum.FixValue)) &&
              (this.form.mathOperations[index + 2].operator == OperatorOptionsEnum.CloseParentheses)) {
                this.isFormValid = false;
                this.drawChipComponent(index);
                this.drawChipComponent(index + 1);
                this.drawChipComponent(index + 2);
                return;
          }
        }

        if (index < lastPosition) {
          if (((this.form.mathOperations[index].type == TimeSeriesCrossDataOperationTypeEnum.TimeSeries) || 
              (this.form.mathOperations[index].type == TimeSeriesCrossDataOperationTypeEnum.FixValue)) &&
              ((this.form.mathOperations[index + 1].type == TimeSeriesCrossDataOperationTypeEnum.TimeSeries) ||
              (this.form.mathOperations[index + 1].type == TimeSeriesCrossDataOperationTypeEnum.FixValue))
              ) {
                this.isFormValid = false;
                this.drawChipComponent(index);
                this.drawChipComponent(index + 1);
                return;
          }
        }


        if (this.form.mathOperations[index].type == TimeSeriesCrossDataOperationTypeEnum.Operator) {
          if ((this.form.mathOperations[index].operator != OperatorOptionsEnum.CloseParentheses) &&
              (this.form.mathOperations[index + 1].operator == OperatorOptionsEnum.CloseParentheses)) {
                this.isFormValid = false;
                this.drawChipComponent(index);
                this.drawChipComponent(index + 1);
                return;
          }
        }

        if ( index < lastPosition) {
          if ((this.form.mathOperations[index].type == TimeSeriesCrossDataOperationTypeEnum.Operator) && 
            (this.form.mathOperations[index + 1].type == TimeSeriesCrossDataOperationTypeEnum.Operator)) {
            if (((this.form.mathOperations[index].operator != OperatorOptionsEnum.OpenParentheses) &&
                (this.form.mathOperations[index].operator != OperatorOptionsEnum.CloseParentheses)) &&
                (this.form.mathOperations[index + 1].operator != OperatorOptionsEnum.OpenParentheses)) {
                  this.isFormValid = false;
                  this.drawChipComponent(index);
                  this.drawChipComponent(index + 1);
                  return;
            }
          }
        }       

        if (index < this.form.mathOperations.length - 2) {
          if ((this.form.mathOperations[index].type == TimeSeriesCrossDataOperationTypeEnum.FixValue) &&
              (this.form.mathOperations[index + 2].type == TimeSeriesCrossDataOperationTypeEnum.FixValue)) {
                this.isFormValid = false;
                this.drawChipComponent(index);
                this.drawChipComponent(index + 1);
                this.drawChipComponent(index + 2);
              return;
              }
        }

        if (index < lastPosition) {
          if (this.form.mathOperations[index].operator == OperatorOptionsEnum.OpenParentheses) {
            let isOpenParentheses = true;
            positionOpenParentheses = index;
            for (let j = index + 1; j < this.form.mathOperations.length; j++) {
              if (this.form.mathOperations[j].operator == OperatorOptionsEnum.CloseParentheses) {
                isOpenParentheses = false;
              }
            }

            if (isOpenParentheses) {
              this.isFormValid = false;
              this.drawChipComponent(positionOpenParentheses);
            } else {
              this.isFormValid = true;
            }
          }
        } 

        if (this.form.mathOperations[index].operator == OperatorOptionsEnum.CloseParentheses) {
          let isCloseParentheses = true;
          positionCloseParentheses = index;
          for (let j = 0; j < index; j++) {
            if (this.form.mathOperations[j].operator == OperatorOptionsEnum.OpenParentheses) {
              isCloseParentheses = false;
            }
          }

          if (isCloseParentheses) {
            this.isFormValid = false;
            this.drawChipComponent(positionCloseParentheses);
          } else {
            this.isFormValid = true;
          }
        }
      }
    } else {
      this.isFormValid = false;
    }
  }

  drawChipComponent(index: number) {
    const element = document.getElementById(`mat-mdc-chip-${index}`);
    element?.classList.add('opt-not-valid__chip');    
  }

  attName(): void {
    this.nameInvalid = false;
  }

  get isValidForm(): boolean {
    return this.dataCrossingForm.valid && this.fieldCalculationEnable;
  }

  get fieldCalculationEnable(): boolean {
    return this.calculationOptions.length > 2 ? this.isEnabled = true : this.isEnabled = false;
  }

  get isFieldInvalid(): boolean {
    return this.dataCrossingForm.invalid && (this.dataCrossingForm.dirty || this.dataCrossingForm.touched)
  }

  get isNameValid(): string | undefined {
    return this.dataCrossingForm.get(['name'])?.status;
  }

  aggregationSelected(value: number): void {
    switch (value) {
      case AggregationOptions.Accumulated:
        this.selectedIndicator = this.translate.instant('analysis.timeSeries.dataCrossing.aggregationOption.accumulated');
        break;
      case AggregationOptions.AVG:
        this.selectedIndicator = this.translate.instant('analysis.timeSeries.dataCrossing.aggregationOption.avg');
        break;
      case AggregationOptions.FirstVlue:
        this.selectedIndicator = this.translate.instant('analysis.timeSeries.dataCrossing.aggregationOption.firstValue');
        break;
      case AggregationOptions.LastValue:
        this.selectedIndicator = this.translate.instant('analysis.timeSeries.dataCrossing.aggregationOption.lastValue');
        break;
      case AggregationOptions.MaximumValue:
        this.selectedIndicator = this.translate.instant('analysis.timeSeries.dataCrossing.aggregationOption.maximumValue');
        break;
      case AggregationOptions.MinimumValue:
        this.selectedIndicator = this.translate.instant('analysis.timeSeries.dataCrossing.aggregationOption.minimumValue');
        break;
      default:
        break;
    }
  }

  openSnackBar(status: number): void {    
    switch (status) {
      case AppConstants.CREATE_SUCESS:
        this.snackBar.openFromComponent(CustomSnackbarSuccessComponent, {
          data: {
            message: this.translate.instant('snackBar.newModule.moduleCreated'),
            snackBar: this.snackBar,
          },
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: AppConstants.TREE_SECOND_WAIT,
        });
        const valid = true;
        const tsId = this.timeSeriesId;
        this.dataCrossingSaved.emit({valid, tsId});
        break;
      case AppConstants.BAD_REQUEST:
        this.snackBar.openFromComponent(CustomSnackbarErrorComponent, {
          data: {
            message: this.translate.instant('analysisTimeSeries.constantValue.errorSavingST'),
            snackBar: this.snackBar,
          },
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: AppConstants.TREE_SECOND_WAIT,
        });
        this.nameInvalid = true;
        break;
      default:
        this.snackBar.openFromComponent(CustomSnackbarErrorComponent, {
          data: {
            message: this.translate.instant('snackBar.newModule.moduleErroCreating'),
            snackBar: this.snackBar,
          },
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: AppConstants.TREE_SECOND_WAIT,
        });
    }
  }

  private _filter(value: string): string[] {
    if (value?.length > 0) {
      const filterValue = value.toLocaleLowerCase();
      return this.allCalculationOpttions.filter((value) => value.toLocaleLowerCase().includes(filterValue))
    }
    return []
  }
}
