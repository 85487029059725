import { Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { SubTypeModuleEnum } from 'src/app/core/models/enums/subtype-module.enum';
import { TypeModuleEnum } from 'src/app/core/models/enums/typeModuleEnum';
import { DialogViewModel } from 'src/app/core/models/interfaces/dialog.model';
import { NewModuleRequest } from 'src/app/core/models/request/new-module-request.model';
import { NewModuleService } from 'src/app/core/services/newModule/new-module.service';
import { ModalDialogRegisterComponent } from '../components/modal-dialog-register/modal-dialog-register.component';
import { AppConstants } from 'src/app/shared/constants/app.constants';
import { CustomSnackbarSuccessComponent } from 'src/app/shared/components/custom-snackbar-success/custom-snackbar-success.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomSnackbarErrorComponent } from 'src/app/shared/components/custom-snackbar-error/custom-snackbar-error.component';
import { UserServiceService } from 'src/app/core/services/userService/user-service.service';

@Component({
  selector: 'app-energy-resources',
  templateUrl: './energy-resources.component.html',
  styleUrls: ['./energy-resources.component.scss'],
})
export class EnergyResourcesComponent {
  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator) set paginator(value: MatPaginator) {
    this.translateMatPaginator(value);
    if (this.dataSource) {
      this.dataSource.paginator = value;
    }
  }
  @ViewChild(MatSort) set sort(value: MatSort) {
    if (this.dataSource) {
      this.dataSource.sort = value;
    }
  }

  columnsToDisplay = ['name', 'type', 'subtype', 'id'];
  modulesNumber: number = 0;
  title: string = 'register.resources.header';
  icon: string = 'error';
  type: string = 'default';
  message: string = 'register.resources.message';
  resource: string = 'register.resources.registerANewResource';
  dialogRef!: MatDialogRef<any>;
  private storage: Storage;
  logedUserRole: string | undefined = '';
  addNewResource: boolean = false;
  modules: NewModuleRequest[] = [];

  constructor(
    private newModuleService: NewModuleService,
    private userServiceService: UserServiceService,
    private translate: TranslateService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {
    this.storage = window.localStorage;
  }

  ngOnInit() {
    this.storage.removeItem('editing');
    this.getModules();
    this.getLoggedUser();
  }

  getLoggedUser(): void {
    this.userServiceService.getUser().then((res) => {
      this.logedUserRole = res?.role;
    });
  }

  translateMatPaginator(paginator: MatPaginator): void {
    this.translate.get('scheduling.paginator').subscribe(() => {
      paginator._intl.itemsPerPageLabel = this.translate.instant('scheduling.paginator.itemsPerPage');
      paginator._intl.firstPageLabel = this.translate.instant('scheduling.paginator.firstPageLabel');
      paginator._intl.lastPageLabel = this.translate.instant('scheduling.paginator.lastPageLabel');
      paginator._intl.nextPageLabel = this.translate.instant('scheduling.paginator.nextPageLabel');
      paginator._intl.previousPageLabel = this.translate.instant('scheduling.paginator.previousPageLabel');
      paginator._intl.getRangeLabel = this.rangeLabel;
    });
  }

  rangeLabel = (page: number, pageSize: number, length: number) => {
    if (length == 0 || pageSize == 0) {
      return `0 ${this.translate.instant('scheduling.paginator.of')} ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} ${this.translate.instant('scheduling.paginator.of')} ${length}`;
  };

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getModules(): void {
    this.newModuleService.getModule().then((res) => {
      this.modules = res.data;
      this.convertData(this.modules);
    });
  }

  convertData(modules: NewModuleRequest[]) {
    const filtereds = modules.filter((p) => p.type !== TypeModuleEnum.grid);
    filtereds.forEach((element) => {
      switch (element.type) {
        case TypeModuleEnum.generator:
          element.type = this.translate.instant('register.resources.newResource.types.generation');
          break;
        case TypeModuleEnum.load:
          element.type = this.translate.instant('register.resources.newResource.types.load');
          break;
        case TypeModuleEnum.storage:
          element.type = this.translate.instant('register.resources.newResource.types.storage');
          break;
        default:
          element.type = this.translate.instant('register.resources.newResource.types.grid');
          break;
      }

      switch (element.subtype) {
        case SubTypeModuleEnum.passive:
          element.subtype = this.translate.instant('register.resources.newResource.subTypes.fixed');
          break;
        case SubTypeModuleEnum.controllable:
          element.subtype = this.translate.instant('register.resources.newResource.subTypes.controllable');
          break;
        case SubTypeModuleEnum.dispatchable:
          element.subtype = this.translate.instant('register.resources.newResource.subTypes.dispatchable');
          break;
        case SubTypeModuleEnum.solar || SubTypeModuleEnum.wind:
          element.subtype = this.translate.instant('register.resources.newResource.subTypes.renewable');
          break;
        default:
          element.subtype = this.translate.instant('register.resources.newResource.subTypes.nothing');
          break;
      }
    });
    this.dataSource.filteredData = filtereds;
    this.dataSource.data = filtereds;
    this.modulesNumber = this.dataSource.data.length;
  }

  newResource(): void {
    this.addNewResource = true;
  }

  moduleIsSaved(value: boolean): void {
    if (value) {
      this.getModules();
      this.addNewResource = false;
    }
  }

  openDialog(moduleId: number) {
    const dialogModel: DialogViewModel = {} as DialogViewModel;
    dialogModel.title = this.translate.instant('register.modal.deletetitle');
    dialogModel.message = this.translate.instant('register.modal.deleteMessage');
    dialogModel.btnError = true;

    this.dialogRef = this.dialog.open(ModalDialogRegisterComponent, {
      disableClose: true,
      data: { info: dialogModel },
      width: '402px',
    });

    this.dialogRef.componentInstance.confirm.subscribe({
      next: async () => {
        await this.newModuleService.deleteModule(moduleId).then((result) => {
          this.openSnackbarRemoveModule(result);
          this.getModules();
        });
        this.dialogRef.close();
      },
    });
  }

  openSnackbarRemoveModule(status: number): void {
    switch (status) {
      case AppConstants.OK:
        this.snackBar.openFromComponent(CustomSnackbarSuccessComponent, {
          data: {
            message: this.translate.instant('snackBar.newModule.removeModuleSucess'),
            snackBar: this.snackBar,
          },
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: AppConstants.TREE_SECOND_WAIT,
        });
        break;
      default:
        this.snackBar.openFromComponent(CustomSnackbarErrorComponent, {
          data: {
            message: this.translate.instant('snackBar.newModule.removeModuleError'),
            snackBar: this.snackBar,
          },
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: AppConstants.TREE_SECOND_WAIT,
        });
        break;
    }
  }

  editModule(module: any): void {
    this.addNewResource = true;
    this.storage.setItem('moduleId', module.moduleId);
    this.storage.setItem('editing', JSON.stringify(true));
  }
}
