<div className="app">
    <div class="dashboard-title" >
        <span class="title">{{ "home.dasboard-title" | translate }} </span> 
        <span class="dashboard-date"> {{currentDate}}</span>
    </div> 
    <div class="dashboard-subtitle" >
        <span>{{ "home.dasboard-subtitle" | translate }} </span> 
    </div>
    
    <div class="dashboard-body">
        <button mat-button class="reset-zoom-button" (click)="resetZoom()">{{ "dashboard.resetZoom" | translate }}</button>
        <canvas *ngIf="loaded" baseChart width="4" height="1" id="chart"
            [type]="'line'"
            [data]="lineChartCfgDataSet"
            [options]="lineChartOptions"
            [legend]="true">
        </canvas>
        <div id="legend" class="section-checkbox"></div>
    </div>
</div>
