import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import setIcon from 'src/app/core/utils/setIcons';
import ModuleRequest from 'src/app/core/models/request/module-request.model';

@Component({
  selector: 'app-card-module',
  templateUrl: './card-module.component.html',
  styleUrls: ['./card-module.component.scss'],
})
export class CardModuleComponent {
  @Input() module: ModuleRequest = {} as ModuleRequest;
  icon: string = '';
  notRecentUpdates: string = '';

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.icon = setIcon(this.module.type);
    this.notRecentUpdates = this.translate.instant('module.notRecentUpdates');
  }

  typeModules(): void {}
}
