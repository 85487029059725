export class AppConstants {
  public static STORED_USER_KEY = 'api-t';

  //Time
  public static TREE_SECOND_WAIT = 3000;
  public static FIVE_SECOND_WAIT = 5000;

  //Status code
  public static CONTINUE = 100;
  public static OK = 200;
  public static CREATE_SUCESS = 201;
  public static BAD_REQUEST = 400;
  public static NOT_FOUND = 404;
  public static INTERNAL_SERVER_ERROR = 500;
}
