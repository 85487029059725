import { AggregationOptions } from "../models/enums/aggregation-options.enum";

export const DataCrossingComponentAggregationOpstions = [
    {
        type: AggregationOptions.Accumulated,
        title: "analysis.timeSeries.dataCrossing.aggregationOption.accumulated",
        description: "analysis.timeSeries.dataCrossing.aggregationOption.accumulatedDesc"
    },
    {
        type: AggregationOptions.AVG,
        title: "analysis.timeSeries.dataCrossing.aggregationOption.avg",
        description: "analysis.timeSeries.dataCrossing.aggregationOption.avgDesc"
    },
    {
        type: AggregationOptions.FirstVlue,
        title: "analysis.timeSeries.dataCrossing.aggregationOption.firstValue",
        description: "analysis.timeSeries.dataCrossing.aggregationOption.firstValueDesc"
    },
    {
        type: AggregationOptions.LastValue,
        title: "analysis.timeSeries.dataCrossing.aggregationOption.lastValue",
        description: "analysis.timeSeries.dataCrossing.aggregationOption.lastValueDesc"
    },
    {
        type: AggregationOptions.MaximumValue,
        title: "analysis.timeSeries.dataCrossing.aggregationOption.maximumValue",
        description: "analysis.timeSeries.dataCrossing.aggregationOption.maximumValueDesc"
    },
    {
        type: AggregationOptions.MinimumValue,
        title: "analysis.timeSeries.dataCrossing.aggregationOption.minimumValue",
        description: "analysis.timeSeries.dataCrossing.aggregationOption.minimumValueDesc"
    },
]
