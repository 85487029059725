import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SchedulesControlMode } from 'src/app/core/models/enums/schedules-control-mode.enum';
import { SchedulePolicyStatusEnum } from 'src/app/core/models/enums/schedules-policy-status.enum';
import { DialogViewModel } from 'src/app/core/models/interfaces/dialog.model';
import SchedulesResquest from 'src/app/core/models/request/schedules-request.model';
import { ModuleService } from 'src/app/core/services/moduleServices/module.service';
import { SchedulingService } from 'src/app/core/services/schedulingServices/scheduling.service';
import { CustomSnackbarErrorComponent } from 'src/app/shared/components/custom-snackbar-error/custom-snackbar-error.component';
import { CustomSnackbarSuccessComponent } from 'src/app/shared/components/custom-snackbar-success/custom-snackbar-success.component';
import { ModalDialogComponent } from 'src/app/shared/components/modal-dialog/modal-dialog.component';
import { AppConstants } from 'src/app/shared/constants/app.constants';

@Component({
  selector: 'app-scheduling',
  templateUrl: './scheduling.component.html',
  styleUrls: ['./scheduling.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class SchedulingComponent implements AfterViewInit {
  @ViewChild('paginator') paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('innerSort') innerSort!: MatSort;

  modules: SchedulesResquest[] = [];
  dataSource = new MatTableDataSource();
  columnsToDisplay = ['name', 'status'];
  columnsToDisplayWithExpand = ['expand', ...this.columnsToDisplay, 'action', 'edit'];
  innerDisplayedColumns = ['status', 'days', 'start_time'];
  expandedElement?: SchedulesResquest;
  dialogRef!: MatDialogRef<any>;
  icon: string = '';
  labelSnackBar: string = '';
  actions: string[] = [];
  schedulingQuantity: number = 0;
  notFoundSchedulesMessage: string = 'scheduling.infoNotfoundSchedules.notSchedulesRegister';
  info1: string = 'scheduling.infoNotfoundSchedules.contact';
  info2: string = 'scheduling.infoNotfoundSchedules.support';

  constructor(
    private moduleService: ModuleService,
    public dialog: MatDialog,
    private schedulingService: SchedulingService,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getAllModules();
  }

  ngAfterViewInit() {
    this.translateMatPaginator(this.paginator);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getAllModules(): void {
    this.schedulingService.getAllModules().then((resp) => {
      this.modules = resp;
      this.schedulingQuantity = resp.length;
      this.convertData(this.modules);
    });
  }

  translateMatPaginator(paginator: MatPaginator) {
    this.translate.get('scheduling.paginator').subscribe(() => {
      paginator._intl.itemsPerPageLabel = this.translate.instant('scheduling.paginator.itemsPerPage');
      paginator._intl.firstPageLabel = this.translate.instant('scheduling.paginator.firstPageLabel');
      paginator._intl.lastPageLabel = this.translate.instant('scheduling.paginator.lastPageLabel');
      paginator._intl.nextPageLabel = this.translate.instant('scheduling.paginator.nextPageLabel');
      paginator._intl.previousPageLabel = this.translate.instant('scheduling.paginator.previousPageLabel');
      paginator._intl.getRangeLabel = this.rangeLabel;
    });
  }

  rangeLabel = (page: number, pageSize: number, length: number) => {
    if (length == 0 || pageSize == 0) {
      return `0 ${this.translate.instant('scheduling.paginator.of')} ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} ${this.translate.instant('scheduling.paginator.of')} ${length}`;
  };

  openDialog(moduleId: number, action: number, name: string): void {
    const dialogModel: DialogViewModel = {} as DialogViewModel;
    dialogModel.icon = 'warning';
    dialogModel.iconType = 'warning';
    dialogModel.showButtons = true;
    dialogModel.btnError = false;
    dialogModel.btnMessage = '';
    dialogModel.resource = name;
    switch (action) {
      case 2:
        dialogModel.title = 'modal.schedulingModal.activateScheduling';
        dialogModel.message = this.translate.instant('modal.schedulingModal.activateMessage');
        break;
      case 1:
        dialogModel.title = 'modal.schedulingModal.deactivateScheduling';
        dialogModel.message = this.translate.instant('modal.schedulingModal.deactivateMessage');
        break;
      default:
        break;
    }

    this.dialogRef = this.dialog.open(ModalDialogComponent, {
      disableClose: true,
      data: dialogModel,
      width: '445px',
    });

    this.dialogRef.componentInstance.confirm.subscribe({
      next: () => {
        this.schedulingService
          .changeModule(moduleId, action)
          .then((res) => {
            this.openSnackbar(res, action);
          })
          .then(() => {
            this.getAllModules();
          });
        this.dialogRef.close();
      },
      error: () => {
        console.log('error');
      },
    });
  }

  openSnackbar(isSuccess: boolean, action: number): void {
    switch (action) {
      case 1:
        isSuccess
          ? (this.labelSnackBar = this.translate.instant('snackBar.schedule.schedulingDisabled'))
          : (this.labelSnackBar = this.translate.instant('snackBar.schedule.error'));
        this.snackBar.openFromComponent(
          isSuccess ? CustomSnackbarSuccessComponent : CustomSnackbarErrorComponent,
          {
            data: {
              message: this.labelSnackBar,
              snackBar: this.snackBar,
            },
            horizontalPosition: 'right',
            verticalPosition: 'top',
            duration: AppConstants.TREE_SECOND_WAIT,
          },
        );
        break;
      case 2:
        isSuccess
          ? (this.labelSnackBar = this.translate.instant('snackBar.schedule.schedulingEnable'))
          : (this.labelSnackBar = this.translate.instant('snackBar.schedule.error'));
        this.snackBar.openFromComponent(
          isSuccess ? CustomSnackbarSuccessComponent : CustomSnackbarErrorComponent,
          {
            data: {
              message: this.labelSnackBar,
              snackBar: this.snackBar,
            },
            horizontalPosition: 'right',
            verticalPosition: 'top',
            duration: AppConstants.TREE_SECOND_WAIT,
          },
        );
        break;

      default:
        break;
    }
  }

  convertData(modules: SchedulesResquest[]) {
    modules.forEach((element) => {
      element.controlMode === SchedulesControlMode.Scheduler
        ? (element.controlMode = this.translate.instant('scheduling.column.statusActive'))
        : (element.controlMode = this.translate.instant('scheduling.column.statusInative'));
      element.schedulesItems.forEach((element) => {
        switch (element.status) {
          case SchedulePolicyStatusEnum.on:
            element.status = this.translate.instant('scheduling.rowExpand.statusOn');
            break;
          case SchedulePolicyStatusEnum.off:
            element.status = this.translate.instant('scheduling.rowExpand.statusOff');
            break;
          case SchedulePolicyStatusEnum.unknown:
            element.status = this.translate.instant('scheduling.rowExpand.statusUnknown');
            break;
          default:
            break;
        }
        const days = element.days;
        days.forEach((day, i) => {
          switch (day) {
            case 0:
              days[i] = this.translate.instant('scheduling.column.days.monday');
              break;
            case 1:
              days[i] = this.translate.instant('scheduling.column.days.tuesday');
              break;
            case 2:
              days[i] = this.translate.instant('scheduling.column.days.wednesday');
              break;
            case 3:
              days[i] = this.translate.instant('scheduling.column.days.thursday');
              break;
            case 4:
              days[i] = this.translate.instant('scheduling.column.days.friday');
              break;
            case 5:
              days[i] = this.translate.instant('scheduling.column.days.saturday');
              break;
            case 6:
              days[i] = this.translate.instant('scheduling.column.days.sunday');
              break;
            default:
              break;
          }
        });
      });
    });
    this.dataSource.filteredData = this.modules;
    this.dataSource.data = this.modules;
  }

  goToSaveSchedule(id?: number) {
    const path = '/control-panel/create-schedule';
    if (id) {
      this.router.navigate([path, id]);
    } else {
      this.router.navigate([path]);
    }
  }
}
