export enum TypeSupplyEnum {
  singlePhase,
  biphasic,
  threePhase,
}

export enum TariffSubgroupEnum {
  A1,
  A2,
  A3,
  A3a,
  A4,
  AS,
  B1,
  B2,
  B3,
  B4,
}
