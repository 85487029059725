export class StringUtil {
  static cepFormat(value: string): string {
    value = value.replace(/\D/g, '');

    if (value.length === 0) {
      return '';
    }
    if (value.length <= 5) {
      return value;
    }
    if (value.length <= 8) {
      return `${value.substring(0, 5)}-${value.substring(5)}`;
    }

    return `${value.substring(0, 5)}-${value.substring(5, 8)}`;
  }

  static documentFormat(value: string): string {
    if (value.length === 0) {
      return '';
    }
    if (value.length <= 2) {
      return value;
    }
    if (value.length <= 5) {
      return `${value.substring(0, 2)}.${value.substring(2)}`;
    }
    if (value.length <= 8) {
      return `${value.substring(0, 2)}.${value.substring(2, 5)}.${value.substring(5)}`;
    }
    if (value.length <= 12) {
      return `${value.substring(0, 2)}.${value.substring(2, 5)}.${value.substring(5, 8)}/${value.substring(
        8,
      )}`;
    }
    return `${value.substring(0, 2)}.${value.substring(2, 5)}.${value.substring(5, 8)}/${value.substring(
      8,
      12,
    )}-${value.substring(12)}`;
  }

  static extractNumbersFromString(value: string): string {
    return value.replace(/\D/g, '');
  }
}
