import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { AuthUserViewModel } from 'src/app/core/models/interfaces/auth-user.model';
import { DialogViewModel } from 'src/app/core/models/interfaces/dialog.model';
import { UserRegisterRequest } from 'src/app/core/models/request/user-register-request.model';
import { UserServiceService } from 'src/app/core/services/userService/user-service.service';
import { ModalDialogRegisterComponent } from '../components/modal-dialog-register/modal-dialog-register.component';
import { TypeModalRegister } from 'src/app/core/models/enums/typeModalRegister.enum';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomSnackbarSuccessComponent } from 'src/app/shared/components/custom-snackbar-success/custom-snackbar-success.component';
import { AppConstants } from 'src/app/shared/constants/app.constants';
import { CustomSnackbarErrorComponent } from 'src/app/shared/components/custom-snackbar-error/custom-snackbar-error.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent {
  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator) set paginator(value: MatPaginator) {
    this.translateMatPaginator(value);
    if (this.dataSource) {
      this.dataSource.paginator = value;
    }
  }
  @ViewChild(MatSort) set sort(value: MatSort) {
    if (this.dataSource) {
      this.dataSource.sort = value;
    }
  }

  columnsToDisplay = ['name', 'email', 'role', 'status', 'id'];
  users: UserRegisterRequest[] = [];
  adminTypeUsers: UserRegisterRequest[] = [];
  userEdit: UserRegisterRequest = {} as UserRegisterRequest;
  auxUser: UserRegisterRequest = {} as UserRegisterRequest;
  title: string = 'register.users.header';
  select: string = 'register.users.select';
  message: string = 'register.users.message';
  resource: string = 'register.users.registerANewUser';
  search: string = 'register.search';
  isListUsers: boolean = true;
  isUserRegister: boolean = false;
  isUserEdit: boolean = false;
  isValidForm: boolean = false;
  isValidUsersFieldsDynamic: boolean = false;
  placeholderTypeUserEdit: string = '';
  indexTypeUser: number = 0;
  loggerUser: AuthUserViewModel | undefined = {} as AuthUserViewModel;
  usersForm: FormGroup;
  userForm: FormGroup;
  disabledBtn: boolean = true;
  selectedUserType: string = '';
  usersNumber: number = 0;
  typeUser: any[] = [];
  dialogRef!: MatDialogRef<any>;
  modelConfirm: TypeModalRegister = TypeModalRegister.confirmation;
  modelExclusion: TypeModalRegister = TypeModalRegister.exclusion;

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private userServiceService: UserServiceService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {
    this.userForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, Validators.email]],
      role: ['', Validators.required],
    });

    this.usersForm = this.fb.group({
      userFields: this.fb.array([]),
    });
  }

  async ngOnInit(): Promise<void> {
    await Promise.all([this.getAllUsers(), this.getLoggerUser()]);
  }

  getLoggerUser(): void {
    this.userServiceService.getUser().then((resp: any) => {
      this.loggerUser = resp;
      this.builderRoleOptionsForSelect(resp);
    });
  }

  getAllUsers(): Promise<void> {
    return this.userServiceService
      .getAllUsers()
      .then((resp: any) => {
        this.users = resp;
      })
      .then(() => {
        this.setStatusUsers(this.users);
      });
  }

  userFields(): FormArray {
    return this.usersForm.get('userFields') as FormArray;
  }

  newUser(): FormGroup {
    return this.fb.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, Validators.email]],
      role: ['', Validators.required],
    });
  }

  addUserField(): void {
    this.userFields().push(this.newUser());
  }

  removeUserField(index: number): void {
    this.userFields().removeAt(index);
  }

  removeUserAllFields(): void {
    this.userForm.setValue({
      name: '',
      email: '',
      role: '',
    });
    this.userEdit.role = '';
    this.dataSource.filter = '';
    this.userFields().clear();
    this.isValidForm = false;
  }

  generalFormValidation(): boolean {
    if (this.usersForm.value.userFields.length > 0) {
      return this.usersForm.valid && this.usersForm.dirty && this.userForm.valid;
    }
    return this.userForm.valid;
  }

  goToRegisterUser(): void {
    this.isUserRegister = true;
    this.isListUsers = false;
    this.isUserEdit = false;
  }

  goToListUsers(): void {
    this.removeUserAllFields();
    this.getAllUsers();
    this.isListUsers = true;
    this.isUserRegister = false;
    this.isUserEdit = false;
  }

  translateMatPaginator(paginator: MatPaginator) {
    this.translate.get('scheduling.paginator').subscribe(() => {
      paginator._intl.itemsPerPageLabel = this.translate.instant('scheduling.paginator.itemsPerPage');
      paginator._intl.firstPageLabel = this.translate.instant('scheduling.paginator.firstPageLabel');
      paginator._intl.lastPageLabel = this.translate.instant('scheduling.paginator.lastPageLabel');
      paginator._intl.nextPageLabel = this.translate.instant('scheduling.paginator.nextPageLabel');
      paginator._intl.previousPageLabel = this.translate.instant('scheduling.paginator.previousPageLabel');
      paginator._intl.getRangeLabel = this.rangeLabel;
    });
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  builderRoleOptionsForSelect(user: AuthUserViewModel): void {
    switch (user?.role) {
      case 'master':
        this.typeUser = [
          {
            title: this.translate.instant('register.users.roleUser.master'),
            description: this.translate.instant('register.users.roleUser.masterInfo'),
            value: 'master',
          },
          {
            title: this.translate.instant('register.users.roleUser.admin'),
            description: this.translate.instant('register.users.roleUser.adminInfo'),
            value: 'admin',
          },
          {
            title: this.translate.instant('register.users.roleUser.basic'),
            description: this.translate.instant('register.users.roleUser.basicInfo'),
            value: 'basic',
          },
        ];
        break;
      case 'admin':
        this.typeUser = [
          {
            title: this.translate.instant('register.users.roleUser.admin'),
            description: this.translate.instant('register.users.roleUser.adminInfo'),
            value: 'admin',
          },
          {
            title: this.translate.instant('register.users.roleUser.basic'),
            description: this.translate.instant('register.users.roleUser.basicInfo'),
            value: 'basic',
          },
        ];
        break;

      default:
        break;
    }
  }

  setStatusUsers(users: UserRegisterRequest[]): void {
    this.adminTypeUsers = [];
    users.forEach((user) => {
      if (
        user.accountActivatedIn === null ||
        user.accountActivatedIn === undefined ||
        user.accountActivatedIn == ''
      ) {
        user.status = this.translate.instant('register.users.statusUser.invitationSend');
      } else {
        user.status = this.translate.instant('register.users.statusUser.active');
      }

      if (user.role != 'master') {
        this.adminTypeUsers.push(user);
      }

      switch (user.role) {
        case 'basic':
          user.role = this.translate.instant('register.users.roleUser.basic');
          break;
        case 'admin':
          user.role = this.translate.instant('register.users.roleUser.admin');
          break;
        case 'master':
          user.role = this.translate.instant('register.users.roleUser.master');
          break;
        default:
          break;
      }
    });

    this.loggerUser?.role == 'Master'
      ? (this.dataSource.data = users)
      : (this.dataSource.data = this.adminTypeUsers);
    this.usersNumber = this.dataSource.data.length;
  }

  setRoleUser(users: UserRegisterRequest[]): UserRegisterRequest[] {
    users.forEach((user) => {
      switch (user.role) {
        case 'register.users.roleUser.master':
          user.role = 'master';
          break;
        case 'register.users.roleUser.admin':
          user.role = 'admin';
          break;
        case 'register.users.roleUser.basic':
          user.role = 'basic';
          break;
        default:
          break;
      }
    });
    return users;
  }

  openDialog(type: TypeModalRegister): void {
    let users = this.builderJson();
    const dialogModel: DialogViewModel = {} as DialogViewModel;
    dialogModel.title =
      type == TypeModalRegister.confirmation
        ? this.translate.instant('register.modal.invitationUser')
        : this.translate.instant('register.modal.deletetitle');
    dialogModel.message =
      type == TypeModalRegister.confirmation
        ? this.translate.instant('register.modal.invitationUserMessage')
        : this.translate.instant('register.modal.deleteMessage');
    dialogModel.btnError = type == TypeModalRegister.exclusion ? true : false;

    this.dialogRef = this.dialog.open(ModalDialogRegisterComponent, {
      disableClose: true,
      data: { info: dialogModel, users: users },
      width: type == TypeModalRegister.confirmation ? '378px' : '402px',
    });

    this.dialogRef.componentInstance.confirm.subscribe({
      next: async () => {
        const usersToRegister = this.setRoleUser(users);
        await this.userServiceService.sendInvitationToUser(usersToRegister).then((result) => {
          this.openSnackbar(result);
        });
        this.goToListUsers();
        this.dialogRef.close();
      },
    });
  }

  openDialogRemoveUser(userId: number) {
    const dialogModel: DialogViewModel = {} as DialogViewModel;
    dialogModel.title = this.translate.instant('register.modal.deletetitle');
    dialogModel.message = this.translate.instant('register.modal.deleteMessage');
    dialogModel.btnError = true;

    this.dialogRef = this.dialog.open(ModalDialogRegisterComponent, {
      disableClose: true,
      data: { info: dialogModel, users: this.usersForm.value.userFields },
      width: '402px',
    });

    this.dialogRef.componentInstance.confirm.subscribe({
      next: async () => {
        await this.userServiceService.deleteUser(userId).then((result) => {
          this.openSnackbarRemoveUser(result);
        });
        this.goToListUsers();
        this.dialogRef.close();
      },
    });
  }

  openSnackbar(status: number): void {
    switch (status) {
      case AppConstants.CREATE_SUCESS:
        this.snackBar.openFromComponent(CustomSnackbarSuccessComponent, {
          data: {
            message: this.translate.instant('register.modal.saveUserSucess'),
            snackBar: this.snackBar,
          },
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: AppConstants.TREE_SECOND_WAIT,
        });
        break;
      case AppConstants.OK:
        this.snackBar.openFromComponent(CustomSnackbarSuccessComponent, {
          data: {
            message: this.translate.instant('register.modal.updateUserSucess'),
            snackBar: this.snackBar,
          },
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: AppConstants.TREE_SECOND_WAIT,
        });
        break;
      default:
        this.snackBar.openFromComponent(CustomSnackbarErrorComponent, {
          data: {
            message: this.translate.instant('register.modal.saveUserError'),
            snackBar: this.snackBar,
          },
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: AppConstants.TREE_SECOND_WAIT,
        });
        break;
    }
  }

  openSnackbarRemoveUser(status: number): void {
    switch (status) {
      case AppConstants.OK:
        this.snackBar.openFromComponent(CustomSnackbarSuccessComponent, {
          data: {
            message: this.translate.instant('register.modal.removeUserSucess'),
            snackBar: this.snackBar,
          },
          horizontalPosition: 'right',
          verticalPosition: 'top',
          duration: AppConstants.TREE_SECOND_WAIT,
        });
        break;
      default:
        break;
    }
  }

  builderJson(): any[] {
    const users = [...this.usersForm.value.userFields, this.userForm.value];
    users.forEach((user: UserRegisterRequest) => {
      // toDo -> Atribuir organização selecionada pelo usuário
      user.orgId = 1;
    });
    return users;
  }

  editTypeUser(user: UserRegisterRequest): void {
    this.isListUsers = false;
    this.isUserRegister = false;
    this.isUserEdit = true;
    this.userEdit = user;

    switch (user.role) {
      case 'Master':
        this.placeholderTypeUserEdit = this.translate.instant('register.users.roleUser.master');
        break;
      case 'Administrador':
        this.placeholderTypeUserEdit = this.translate.instant('register.users.roleUser.admin');
        break;
      case 'Básico':
        this.placeholderTypeUserEdit = this.translate.instant('register.users.roleUser.basic');
        break;
      default:
        break;
    }
  }

  editUserRole() {
    switch (this.userEdit.role) {
      case 'register.users.roleUser.master':
        this.userEdit.role = 'master';
        break;
      case 'register.users.roleUser.admin':
        this.userEdit.role = 'admin';
        break;
      case 'register.users.roleUser.basic':
        this.userEdit.role = 'basic';
        break;
      default:
        break;
    }

    this.userServiceService.changeUserRole(this.userEdit).then((result) => {
      this.openSnackbar(result);
      this.goToListUsers();
    });
  }

  rangeLabel = (page: number, pageSize: number, length: number) => {
    if (length == 0 || pageSize == 0) {
      return `0 ${this.translate.instant('scheduling.paginator.of')} ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} ${this.translate.instant('scheduling.paginator.of')} ${length}`;
  };
}
