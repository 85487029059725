import { Component } from '@angular/core';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent {
  menuIsCollapsed: boolean = false;
  expandMenu: string = 'monitoring.expandMenu';
  hideMenu: string = 'monitoring.hideMenu';
  generalInformation: string = 'register.nav.generalInformation';
  distributionNetwork: string = 'register.nav.distributionNetwork';
  energyContracts: string = 'register.nav.energyContracts';
  energyResources: string = 'register.nav.energyResources';
  users: string = 'register.nav.users';

  collapseMenu() {
    this.menuIsCollapsed = !this.menuIsCollapsed;
  }
}
