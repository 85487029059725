import { Component, Input } from '@angular/core';
import { IndicatorTypeEnum } from 'src/app/core/models/enums/indicator-type.enum';

@Component({
  selector: 'app-card-indicator',
  templateUrl: './card-indicator.component.html',
  styleUrls: ['./card-indicator.component.scss'],
})
export class CardIndicatorComponent {
  public panelOpen: boolean = false;
  @Input() isExpansionPanel: boolean = true;
  @Input() tooltipInfo: string = '';
  @Input() title: string = '';
  @Input() value: string = '';
  @Input() valueUnit: string = '';
  @Input() inPeakTimeValue: string = '';
  @Input() inPeakTimeValueUnit: string = '';
  @Input() outPeakTimeValueUnit: string = '';
  @Input() outPeakTimeValue: string = '';
  @Input() type: IndicatorTypeEnum = IndicatorTypeEnum.Consumption;
  @Input() width: string = '250px';
  @Input() height: string = '105px';
  @Input() marginBottom: string = '0px';
  constructor() {}
}
