import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, OnInit, Inject } from '@angular/core';
import { ReactiveFormsModule, FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DashboardService } from 'src/app/core/services/dashboards/dashboard.service';

@Component({
  selector: 'dashboard-configuration-dialog',
  templateUrl: './dashboard-configuration-dialog.component.html',
  styleUrls: ['./dashboard-configuration-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardConfigurationDialog implements OnInit {
  formGroup: FormGroup;
  selectedIcon: string | null = null;

  constructor(
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<DashboardConfigurationDialog>,
    private dashboardService: DashboardService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.formGroup = this.formBuilder.group({
      title: [this.data.title, [Validators.required, Validators.maxLength(32)], this.validateTitleNotTaken.bind(this)],
      description: [this.data.description, [Validators.required, Validators.maxLength(200)]],
      icon: [this.data.icon, Validators.required],
    });

    this.selectedIcon = this.data.icon;
  }

  ngOnInit() {}

  selectIcon(icon: string) {
    this.selectedIcon = icon;
    this.formGroup.get('icon')?.setValue(icon);
  }

  onSubmit() {
    if (this.formGroup.valid) {
      const formData = this.formGroup.value;
      this.dialogRef.close(formData);
    } else {
      this.formGroup.markAllAsTouched();
    }
  }

  validateTitleNotTaken(control: AbstractControl) {
    return new Promise((resolve) => {
      if (!control.value) {
        resolve(null);
      } else {
        this.dashboardService.checkTitleUnique(control.value).then(isUnique => {
          if (isUnique) {
            resolve(null);
          } else {
            resolve({ titleTaken: true });
          }
        }).catch(() => {
          resolve({ titleTaken: true });
        });
      }
    });
  }
}
