import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SchedulePolicyStatusEnum } from 'src/app/core/models/enums/schedules-policy-status.enum';

@Component({
  selector: 'app-schedule-card',
  templateUrl: './schedule-card.component.html',
  styleUrls: ['./schedule-card.component.scss']
})
export class ScheduleCardComponent {
  backgroundColor: string = "#A3A3A3";
  color: string = "#fff";
  height!: number;
  @Input() status: SchedulePolicyStatusEnum = 1;
  @Input() startTime: string = "00:00";
  @Input() endTime: string = "00:00";
  title: string = "";
  @Output() removeStatusSchedule = new EventEmitter<boolean>();

  get hideHours(): boolean {
    return !(this.startTime == "00:00" && this.endTime == "00:00")
  }

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.builCard(this.status);

    this.setHeight(this.endTime, this.startTime);
  }

  builCard(status: SchedulePolicyStatusEnum): void {
    switch (status) {
      case SchedulePolicyStatusEnum.off:
        this.title = this.translate.instant('module.statusLoadOff');
        this.backgroundColor = "#A3A3A3"
        this.color = "#FFF"
      break;
      case SchedulePolicyStatusEnum.on:
        this.title = this.translate.instant('module.statusLoadOn');
        this.backgroundColor = "#DAFBF0"
        this.color = "#21937B"
      break;
      case SchedulePolicyStatusEnum.charge:
        this.title = this.translate.instant('module.statusCharge');
        this.backgroundColor = "#CCE5FB"
        this.color = "#266197"
      break;
      case SchedulePolicyStatusEnum.uncharge:
        this.title = this.translate.instant('module.statusUncharge');
        this.backgroundColor = "#FFDBDB"
        this.color = "#B02424"
      break;
      case SchedulePolicyStatusEnum.standBy:
        this.title = this.translate.instant('module.statusStandby');
        this.backgroundColor = "#FEE9CA"
        this.color = "#B87004"
      break;
    
      default:
        break;
    }
  }

  setHeight(endTime: string, startTime: string): void {
    if (endTime == '00:00' && startTime == '00:00') {
      this.height = 22;
      return;
    }
    const endNumber = this.convertHoursToNumber(endTime);
    const startNumber = this.convertHoursToNumber(startTime);
    const [integer, fractional] = (endNumber - startNumber).toString().split('.').map(Number);
    

    if (integer <= 1) {
      this.height = 48;
    }else {
      if (fractional > 30) {
        this.height = 48 + (52 * (integer + 1)) - 52;
      } else {
        this.height = 48 + (52 * integer) - 52;
      }
    }
  }

  convertHoursToNumber(time: string): number {
    const [hours, minutes] = time.split(':').map(Number);
    const value = `${hours}.${minutes}`
    return +value
  }

  sendRemoveEmmit(): void {
    this.removeStatusSchedule.emit(true);
  }
}
