import { BaseService } from 'src/app/core/services/base.service';
import { Timezone } from 'src/app/shared/enums/timezone.enum';
import { Org } from '../../models/interfaces/org.model';
import { Ugrid } from '../../models/interfaces/ugrid.model';

export class GeneralInformationService extends BaseService {
  constructor() {
    super();
  }

  async createUgrid(data: Ugrid): Promise<any> {
    return await this.post('/ugrids', data)
      .then((res) => res?.data );
  }

  async createOrg(data: Org): Promise<any> {
    return await this.post('/orgs', data)
      .then((res) => res?.data );
  }

  async updateOrg(data: Org): Promise<number> {
    return await this.patch(`/orgs/${data.orgId}`, {
      name: data.name,
      document: data.document,
    })
      .then((res) => res.statusCode);
  }

  async updateUgrid(data: Ugrid): Promise<number> {
    return await this.patch(`/ugrids/${data.uGridId}`, data)
      .then((res) => res.statusCode);
  }

  async getOrg(): Promise<Org | undefined> {
    return await this.get('/users/me')
      .then((res) => {

        return res.data?.organization as Org;
      })
      .catch((err) => undefined);
  }

  async getUgrid(): Promise<Ugrid | undefined> {
    return await this.get('/users/me')
      .then((res) => {
        if (res.data?.ugrids !== undefined) {
          return res.data?.ugrids[0] as Ugrid;
        }
        return undefined;
      })
      .catch((err) => undefined);
  }

  getTimezoneList() {
    return Object.keys(Timezone).map((key, index) => ({
      key: index,
      value: Timezone[key as keyof typeof Timezone],
    }));
  }

  getUtcOffset(timezone: Timezone): string {
    const match = timezone.match(/UTC ([\-\+]\d{2}:\d{2})/);
    return match ? match[1] : '';
  }

  getTimezone(index: number): Timezone | undefined {
    const data = this.getTimezoneList();

    if (data[index] !== undefined) {
      return data[index].value;
    }

    return undefined;
  }
}
