import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { TranslateService } from '@ngx-translate/core';
import ModuleRequest from '../../models/request/module-request.model';
import ModuleStateRequest from '../../models/request/module-state-request.model';
import { TypeModuleEnum } from '../../models/enums/typeModuleEnum';
import { AppConstants } from 'src/app/shared/constants/app.constants';
import { StatusModuleEnum } from '../../models/enums/statusModuleEnum';

@Injectable({
  providedIn: 'root',
})
export class ModuleService extends BaseService {
  constructor(private translate: TranslateService) {
    super();
  }

  async changeStateControl(moduleId: number, ctrl: number): Promise<boolean> {
    return await this.post(`/modules/${moduleId}/control`, { ctrl: ctrl })
      .then((result) => {
        return true;
      })
      .catch(() => {
        return false;
      });
  }

  async checkStatus(moduleId: number, statusToCheck: number): Promise<ModuleStateRequest> {
    return await this.get(`/modules/${moduleId}/check-state?statusToCheck=${statusToCheck}`)
      .then((result) => {
        if (result?.status == AppConstants.OK)
          return result?.data;
        else
          return null;
      });
  }

  async getAll(): Promise<ModuleRequest[]> {
    const userData = this.getUserData();

    if (!userData || userData.org.ugridIds.length === 0) return [];

    const ugridId = userData.org.ugridIds[0]; // TODO: Get the first uGrid while the selected one isn't implemented

    const modules = await this.get(`/modules/${ugridId}/details`).then((res) => {
      return res.data as ModuleRequest[];
    });

    const modulesStates = await this.get(`/modules/${ugridId}/states`).then((res) => {
      return res.data as ModuleStateRequest[];
    });

    modules.forEach((m) => {
      const moduleState = modulesStates.find((x) => x.moduleId === m.moduleId);
      if (moduleState) {
        m.value = moduleState.power;
        m.valueUnit = moduleState.powerUnit;
        m.status = moduleState.status;
        m.tooltipInfo = `${this.translate.instant('module.lastUpdateStatus')}${new Date(
          moduleState.timestamp,
        ).toLocaleString()}`;

        m.statusName = this.getStatusName(moduleState.status);
      } else {
        m.status = 0; // unknown as default
      }
    });

    return modules;
  }

  getStatusName(status: number): string {
    switch (status) {
      case StatusModuleEnum.unknown:
        return this.translate.instant('module.statusUnknown');
      case StatusModuleEnum.off:
        return this.translate.instant('module.statusLoadOff');
      case StatusModuleEnum.on:
          return this.translate.instant('module.statusLoadOn');
      case StatusModuleEnum.standby:
          return this.translate.instant('module.statusStandby');
      case StatusModuleEnum.uncharge:
         return this.translate.instant('module.statusUncharge');
      case StatusModuleEnum.charge:
         return this.translate.instant('module.statusCharge');
      case StatusModuleEnum.consuming:
          return this.translate.instant('module.statusGridConsuming');
      case StatusModuleEnum.injecting:
        return this.translate.instant('module.statusGridInjecting');
      default:
        return this.translate.instant('module.statusUnknown');
    }
  }
}
