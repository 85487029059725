import { Component } from '@angular/core';

@Component({
  selector: 'app-control-painel',
  templateUrl: './control-painel.component.html',
  styleUrls: ['./control-painel.component.scss'],
})
export class ControlPainelComponent {
  menuIsCollapsed: boolean = false;
  expandMenu: string = 'monitoring.expandMenu';
  hideMenu: string = 'monitoring.hideMenu';
  controller: string = 'controlPanel.controller.title';
  schedules: string = 'controlPanel.schedules.title';
  activityFeed: string = 'controlPanel.activityFeed.title';

  collapseMenu() {
    this.menuIsCollapsed = !this.menuIsCollapsed;
  }
}
