<div class="header">
  <div class="header-logo">
    <img src="assets/imgs/logo.svg" alt="Logo Autopilot" (click)="goToPage('/home')">
  </div>
  <nav class="header-nav">
    <a routerLink="/home" routerLinkActive="active">{{ "header.overview" | translate }}</a>

    <a routerLink="/monitoring/dashboard-monitoring" routerLinkActive="active">{{ "header.monitoring" | translate }}</a>

    <a routerLinkActive="active" [matMenuTriggerFor]="navMenu" class="nav-panel">
      {{ "header.controlPanel" | translate }}  <i class="fa-regular fa-chevron-down"></i>
    </a>
    <mat-menu #navMenu="matMenu" xPosition="after" class="header-nav__menu-controller">
      <button mat-menu-item (click)="goToPage('/control-panel/controller')" class="header-nav__menu-controller-item">
        <i class="fa-solid fa-sliders-up"></i>
        <span>{{ "header.nav.controller" | translate }}</span>
        <p>{{ "header.nav.controllerInfo" | translate }}</p>
      </button>
      <button mat-menu-item  (click)="goToPage('/control-panel/scheduling')" class="header-nav__menu-controller-item">
        <i class="fa-regular fa-clock"></i>
        <span>{{ "header.nav.schedules" | translate }}</span>
        <p>{{ "header.nav.schedulesInfo" | translate }}</p>
      </button>
      <button mat-menu-item  (click)="goToPage('/control-panel/activity-feed')" class="header-nav__menu-controller-item">
        <i class="fa-regular fa-list-ul"></i>
        <span>{{ "header.nav.activityFeed" | translate }}</span>
        <p>{{ "header.nav.activityFeedInfo" | translate }}</p>
      </button>
    </mat-menu>

    <a routerLink="/register" routerLinkActive="active">{{ "header.register" | translate }}</a>

    <a routerLinkActive="active" [matMenuTriggerFor]="navMenuAnalysis" class="nav-panel">
      {{ "header.analysis" | translate }}  <i class="fa-regular fa-chevron-down"></i>
    </a>
    <mat-menu #navMenuAnalysis="matMenu" xPosition="after" class="header-nav__menu-controller">
      <button mat-menu-item (click)="goToPage('/analysis/dashboards')" class="header-nav__menu-controller-item">
        <i class="fa-regular fa-table-columns"></i>
        <span>{{ "header.nav.dashboards" | translate }}</span>
        <p>{{ "header.nav.dashboardsInfo" | translate }}</p>
      </button>
      <button mat-menu-item  (click)="goToPage('/analysis/reports')" class="header-nav__menu-controller-item">
        <i class="fa-regular fa-file-chart-column"></i>
        <span>{{ "header.nav.reports" | translate }}</span>
        <p>{{ "header.nav.reportsInfo" | translate }}</p>
      </button>
      <button mat-menu-item  (click)="goToPage('/analysis/time-series')" class="header-nav__menu-controller-item">
        <i class="fa-regular fa-chart-line"></i>
        <span>{{ "header.nav.timeSeries" | translate }}</span>
        <p>{{ "header.nav.timeSeriesInfo" | translate }}</p>
      </button>
    </mat-menu>

  </nav>
  <div class="header-profile">
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" class="header-profile-btn">
      {{ nameInitials }}
    </button>
    <mat-menu #menu="matMenu" xPosition="before" class="header-profile-menu">
      <div mat-menu-item class="header-profile-menu-info">
        <div class="header-profile-menu-info__initials">
          {{ nameInitials }}
        </div>
        <div class="header-profile-menu-info__profile">
          <span class="header-profile-menu-info__profile-name">{{ userName }}</span>
          <p class="header-profile-menu-info__profile-email">{{ userEmail }}</p>
        </div>
      </div>
      <hr class="header-profile-menu-separator">
      <button mat-menu-item (click)="signOut()" class="header-profile-menu__btn">
        <i class="fa-regular fa-power-off"></i>
        <span>{{ "header.signOut" | translate }}</span>
      </button>
    </mat-menu>
  </div>
</div>