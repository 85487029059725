<div class="form-content">
  <div *ngIf="!erro403">
    <h2>{{ "login.greeting" | translate }}</h2>
    <h4>{{ "login.info.access" | translate }}</h4>


    <form [formGroup]="loginForm" (ngSubmit)="login()" autocomplete="off">
      <div class="form-action">

        <div class="form-group">
          <label for="email" class="form-label">{{ "login.email" | translate }}</label>
          <mat-form-field appearance="outline" class="login-access__form-input" [class.error]="errorLogin">
            <input matInput
                  placeholder='{{ "login.formPlaceholder.email" | translate }}'
                  (blur)="shouldShowEmailError()"
                  (focus)="errorLogin = false"
                  formControlName="email" />

            <mat-icon matSuffix svgIcon="error" *ngIf="errorLogin" class="text-color-red"></mat-icon>

            <mat-hint class="mat-padding-null" *ngIf="(loginForm.controls['email'].invalid && (loginForm.controls['email'].dirty || loginForm.controls['email'].touched) && loginForm.controls['email']?.errors?.['required']) && !errorLogin">
              <span class="message-error">{{ "login.validate.email.required" | translate }}</span>
            </mat-hint>

            <mat-hint class="mat-padding-null" *ngIf="(loginForm.controls['email'].invalid && (loginForm.controls['email'].dirty || loginForm.controls['email'].touched) && loginForm.controls['email']?.errors?.['email']) && !errorLogin">
              <span class="message-error">{{ "login.validate.email.invalid" | translate }}</span>
            </mat-hint>
          </mat-form-field>
        </div>

        <div class="form-group">
          <label for="password" class="form-label">{{ "login.password" | translate }}</label>
          <mat-form-field appearance="outline" class="login-access__form-input">
            <input matInput
                  type="{{ hide ? 'password' : 'text' }}"
                  (blur)="shouldShowPasswordError()"
                  (focus)="errorLogin = false"
                  placeholder='{{ "login.formPlaceholder.password" | translate }}'
                  formControlName="password" />

            <mat-icon matSuffix svgIcon="error" *ngIf="errorLogin" class="text-color-red"></mat-icon>
            <mat-icon matSuffix *ngIf="!errorLogin" svgIcon="{{ hide ? 'eye' : 'eye-slash'}}" (click)="hide = !hide"></mat-icon>

            <mat-hint class="mat-padding-null" class="message-error" *ngIf="((loginForm.controls['password'].invalid && (loginForm.controls['password'].dirty || loginForm.controls['password'].touched)) && loginForm.controls['password']?.errors?.['required']) && !errorLogin">
              <span class="message-error">{{ "login.validate.password.required" | translate }}</span>
            </mat-hint>

            <mat-hint class="mat-padding-null" class="message-error" *ngIf="errorLogin && erro401">
              <span class="message-error">{{ "login.auth.401" | translate }}</span>
            </mat-hint>
            <mat-hint class="mat-padding-null" class="message-error" *ngIf="errorLogin && erro403">
              <span class="message-error">{{ "login.auth.403" | translate }}</span>
            </mat-hint>

          </mat-form-field>
        </div>

        <br />
        <button type="submit"
                class="submit-button"
                [class.disabled]="loginForm.invalid"
                [class.enable]="!loginForm.invalid"
                [disabled]="loginForm.invalid">
          {{ "login.buttonAccess" | translate }}
        </button>
      </div>

      <div class="disclaimer">
        <span class="text-base">
          {{ "login.signin.phrase1" | translate }}
          <a href="https://www.greenant.com.br/termos-de-servico/" target="_blank"><span class="underline text-color-green">{{ "login.signin.termsOfService" | translate }}</span></a>
          {{ "login.signin.phrase2" | translate }}
          <a href="https://www.greenant.com.br/politica-de-privacidade" target="_blank"><span class="underline text-color-green">{{ "login.signin.privacyPolicy" | translate }}</span></a>.
        </span>
      </div>

      <div class="forgotPasswordArea">
        <a [routerLink]="['/auth/forgot-password']"><span class="underline text-color-green"> {{ "login.forgotPasswordLabel" | translate }}</span></a>
      </div>
    </form>
  </div>

  <ng-container *ngIf="erro403">
    <div class="disclaimer">
      <h3>{{ "login.feedbacks.login.title" | translate }}</h3>
      <br />
      <p [innerHTML]="'login.feedbacks.login.activeMessage' | translate"></p>
      <br />
      <p>
        <button (click)="resetForm()" class="submit-button">
          {{ "login.feedbacks.login.buttonClose" | translate }}
        </button>
      </p>
    </div>
  </ng-container>
</div>
